import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./supportlinks.scss";
import { Trans } from "react-i18next";
import { history } from "../../_helpers";
import { config } from "../../_config";

class supportlinks extends Component {
  constructor(props){
    super(props);
    this.showIntroPage = this.showIntroPage.bind(this);
    sessionStorage.removeItem('showTutorial');
  }
  showIntroPage(){
    sessionStorage.setItem('showTutorial',true);
    history.push('/IntroSlider1')
  }
  render() {
    let style={color: '#000',
      fontSize: '16px',
      fontWeight: '500',
      cursor:'pointer'
    }
    return (
      <div className="support-box">
        <ul className="list-line">
          <li className="" onClick={this.showIntroPage} >
            <p className="mb-0" style={style}>
              <Trans i18nKey="HowToUse">How to use</Trans>
            </p>
          </li>
          <Link to={config.faq} >
          <li className="">
              <Trans i18nKey="FAQ">FAQs</Trans>
          </li>
          </Link>
          {
            config.isLatamCountry
            ?
              <Link to={
                        {
                          pathname:`/NeedHelp`,
                          state:{
                            fromPurchaseHistory: 'supportMenu',
                            purchaseDate: undefined,
                            SupportButtonDuration: undefined 
                          }
                        }
                      }
              >
                <li className="">
                  <Trans i18nKey="EmailSupport">Email support</Trans>
                </li>
              </Link>
            :
              <a href={`mailto:${config.supportEmail}`} >
                <li className="">
                  <Trans i18nKey="EmailSupport">Email support</Trans>
                </li>
              </a>
          }
          <a href={config.termsOfUseAndSale} >
          <li className="">            
              <Trans i18nKey="TermsofUseandSale">Warunki użytkowania i sprzedaży</Trans>
          </li>
          </a>
          <Link to={process.env.PUBLIC_URL + "/viewlicense"} >
          <li className="">            
              <Trans i18nKey="ViewLicense">View Licenses</Trans>
          </li>
            </Link>
          {/* <li className="bottomline">
              <Link to={process.env.PUBLIC_URL + "/"}>Exchange product</Link>
            </li>
            <li className="bottomline">
              <Link to={process.env.PUBLIC_URL + "/"}>Expiration date of product</Link>
            </li>
            <li className="bottomline">
              <Link to={process.env.PUBLIC_URL + "/WrongAmountcharged"}>Wrong amount bieng charged</Link>
            </li>
            <li className="bottomline">
              <Link to={process.env.PUBLIC_URL + "/IntroSlider1"}>Special needs</Link>
            </li> */}
        </ul>
      </div>
    );
  }
}

export default supportlinks;
