import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../_assets/images/icons/logo.png";
import back from "../../_assets/images/icons/back.png";
import { config } from "../../_config";

const publicHeader = ({ Title, subHeading, Desc, Required, backLink }) => {
    return (
        <div className="row justify-content-center publicheader">
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                <div className="col-12 px-1">
                    <Link to={backLink}><img src={back} className="ml-2 mb-2"/></Link><br/>
                    <img className="logo" src={logo} />
                    <h3 className='text-white pl-2 mt-3 font-size-25'>{Title}</h3>
                    {
                        subHeading &&
                        <h6 className="text-white pl-2">{subHeading}</h6>
                    }
                    {
                        (Desc && config.applicationTypeId!=9) &&                        
                        <p className="text-white pl-2 font-size-12">{Desc}</p>
                    }
                    {
                        Required &&         
                        <p className="text-white pl-2 font-size-12">* {Required}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default publicHeader