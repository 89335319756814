import { purchaseHistoryConstants } from "../_constants";

export function purchaseHistory(state = {}, action) {
  switch (action.type) {
    case purchaseHistoryConstants.PURCHASEHISTORY_REQUEST:
      return {
        products: action.products
      };
    case purchaseHistoryConstants.PURCHASEHISTORY_REQUEST_SUCCESS:
      return {
        products: action.products.data
      };
    case purchaseHistoryConstants.PURCHASEHISTORY_FAILURE:
      return {};
    default:
      return state;
  }
}
