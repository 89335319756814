import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackWhiteData from "../components/BackWhiteData/BackWhiteData";
import Button from "../components/button/button";
import CartProduct from "../containers/CartProduct/CartProduct";
import ProductDatas from "../data/ProductDatas/ProductDatas";
import "../assets/scss/defaults.scss";
import { productService } from "../_services";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Elepay from "elepay-js-sdk";
import { paymentService } from "../_services/payment.service";
import { history } from "../_helpers";
import { connect } from "react-redux";
import { addRemoveProductsAction } from "../_actions";
import ManualPopup from "../components/AddManualPopup/ManualPopup";
import PaymentPopup from "../components/PaymentPopup/PaymentPopup";
import TransactionFailedPopup from "../components/TransactionFailPopup/Popup";
import i18n from "i18next";
import { Trans } from "react-i18next";
import CoolerPopup from "../components/Model-Popup/Popup";
import { config } from "../_config/index";
import { userConstants } from "../_constants";
import { userService } from "../_services";
import moment from "moment";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";

class ManuallyCorrection extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | ManuallyCorrection`;
    CapturePage({hitType:"pageview",page:"/ManuallyCorrection"})    
        
    const query = new URLSearchParams(this.props.location.search);
    const purchaseNumber = query.get("purchaseNumber");
    localStorage.setItem("purchaseNumber", purchaseNumber);
    const token = "ED22595A-FE82-475B-8E53-3C5D05B159B7";
    localStorage.setItem("token", token);
    let reward = JSON.parse(localStorage.getItem("rewardPoint"));
    this.state = {
      productsPickedFinal: Array.isArray(this.props.addedProductsList)
        ? this.props.addedProductsList
        : [],
      productsfetched: Array.isArray(this.props.addedProductsList)
        ? true
        : false,
      totalPrice: 0,
      showPopup: false,
      showPaymentPopup: false,
      showTransactionFailPopup: false,
      connectionLost: false,
      paymentInitiated: false,
      rewardPoint: reward,
      isChecked: true,
      rewardChecked: false,
      showRewardPaymentPopup: false,
      totalReward: "",
      token: token,
      purchaseNumber: purchaseNumber,
      showNoInternetPopup:false
    };

    var cartData,
      coolerId,
      productsPickedFinal = [];
    var deviceData = JSON.parse(localStorage.getItem("deviceData"));
    var transactionpopup = false;
    var paramObj = {
      authToken: this.state.token,
      purchaseNumber: this.state.purchaseNumber,
    };
    var TotalPrice = 0;
    if (!Array.isArray(this.props.addedProductsList)) {
      // this runs when data is published
      //call the service for mapping device data to readable page we know as cart
      productService.getMannualAllProducts(paramObj).then((allProds) => {
        if (allProds.data.isLinkExpire === true) {
          history.push({ pathname: "/NoLongerScreen" });
        } else {
          if (allProds.data.isManualCorrected === true) {
            history.push({ pathname: "/MannualCorrected" });
          } else {
            coolerId = allProds.data.coolerId;
            localStorage.setItem("coolerId", coolerId);
            localStorage.setItem("macAddress", allProds.data.deviceMacAddress);
            localStorage.setItem(
              "rewardPoint",
              allProds.data.remainingRewardPoint
            );
            localStorage.setItem("userId", allProds.data.userId);
            this.setState({
              rewardPoint: allProds.data.remainingRewardPoint,
              coolerId: coolerId,
            });
            cartData = allProds.data.products;
            let prodDetails = cartData;
            if (prodDetails.length > 0) {
              for (let product of prodDetails) {
                TotalPrice =
                  TotalPrice +
                  parseInt(product.productPrice * product.productQuantity);
                productsPickedFinal.push({
                  productId: product.productId,
                  productName: product.productName,
                  productLocalName: product.productLocalName,
                  price: product.productPrice,
                  quantity: product.productQuantity,
                  devicequantity: product.productQuantity,
                  imgIcon: product.productImage,
                  orderUpdateReasonId: 0, //default value is 0
                });
              }
            }
            this.setState({
              productsPickedFinal: Array.isArray(this.props.addedProductsList)
                ? this.props.addedProductsList
                : productsPickedFinal,
              productsfetched: true,
              totalPrice: TotalPrice,
              coolerId: coolerId,
              showTransactionFailPopup: transactionpopup,
            });
            //call V3 API here for adding products to pending status
            var paymentProds = [];
            if (
              Array.isArray(this.props.addedProductsList) &&
              this.props.addedProductsList.length > 0
            ) {
              this.props.addedProductsList.forEach(function(pp) {
                paymentProds.push({
                  productID: pp.productId,
                  count: pp.quantity,
                  userQuantity: pp.devicequantity,
                  orderUpdateReasonId: pp.orderUpdateReasonId,
                });
              });
            }
          }
        }
      });
      this.props.initialProductsAction(productsPickedFinal); //this is called for setting props and initial products
    }
    this.paymentFunction = this.paymentFunction.bind(this);
    this.closingPopup = this.closingPopup.bind(this);
    this.TryAgainLater = this.TryAgainLater.bind(this)
  }

  componentDidMount() {
    var that = this;
    if (!Array.isArray(this.props.addedProductsList)) {
      that.setState({ showPopup: true });
    }
    setTimeout(function() {
      that.setState({ showPopup: false });
    }, 5000);
    var TotalPrice = 0;
    if (Array.isArray(this.props.addedProductsList)) {
      this.props.addedProductsList.forEach((prod) => {
        TotalPrice =
          TotalPrice +
          parseInt(
            prod.productPrice
              ? prod.productPrice * prod.quantity
              : prod.price * prod.quantity
          );
      });
      this.setState({
        totalPrice: TotalPrice,
      });
    }
  }

  Cartcallback = (countdiff, price, operation, productId) => {
    //updatecartproduct for setting updatereasonid
    if (operation === "increment") {
      this.setState({ totalPrice: this.state.totalPrice + countdiff * price });
    } else if (operation === "decrement") {
      this.setState({ totalPrice: this.state.totalPrice - countdiff * price });
    }
    this.props.updateCartProduct(productId);
  };

  deleteProduct = (productId) => {
    var totalPrice = 0;
    //now update the total price in state
    if (
      Array.isArray(this.props.addedProductsList) &&
      this.props.addedProductsList.length > 1
    ) {
      this.props.deleteProducts(productId);
      //since props are not updated when delete is done, we have to update totalcount in a way ignoring the deleted row.
      var TotalPriceData = this.props.addedProductsList.filter((dat) => {
        return dat.productId !== productId && dat.orderUpdateReasonId<3;
      });
      TotalPriceData.forEach((prod) => {
        totalPrice =
          totalPrice +
          parseInt(
            prod.productPrice
              ? prod.productPrice * prod.quantity
              : prod.price * prod.quantity
          );
      });
      this.setState({
        productsPickedFinal: this.props.addedProductsList,
        totalPrice: totalPrice,
      });
    }
  };

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }

  togglePaymentPopup() {
    localStorage.removeItem("qrcode");
    localStorage.removeItem("deviceData");
    localStorage.removeItem("coolerId");
    localStorage.removeItem("paymentcompleted");
    this.props.initialProductsAction({});
    this.setState({
      showPaymentPopup: false,
    });
    history.push("/ThankyouPage");
  }

  toggleTransactionPopup() {
    localStorage.removeItem("deviceData");
    localStorage.removeItem("qrcode");
    localStorage.removeItem("coolerId");
    //called so that get coolers api is called again next time
    this.props.initialProductsAction({});
    this.setState({
      showTransactionFailPopup: false,
    });
    history.push("/Scanbutton");
  }

  toggleTransactionPopupNoredirect() {
    this.setState({
      showTransactionFailPopup: false,
    });
  }

  closingPopup() {
    this.setState({
      connectionLost: false,
    });
  }

  TryAgainLater() {
    window.location.reload();
  }

  paymentFunction() {
    this.setState({
      paymentInitiated: true,
    });
    var paymentProds = [];
    this.props.addedProductsList.forEach(function(pp) {
      paymentProds.push({
        productID: pp.productId,
        count: pp.devicequantity,
        userQuantity: pp.quantity,
        orderUpdateReasonId: pp.orderUpdateReasonId,
      });
    });
    // console.log('paymentProds',paymentProds);
    var paymentObj = {
      authToken: this.state.token,
      products: JSON.stringify(paymentProds),
      coolerID: localStorage.getItem("coolerId"),
      purchaseNumber: localStorage.getItem("purchaseNumber"),
      totalAmountCharged: this.state.totalPrice,
      totalRewardPointsEarned: localStorage.getItem("rewardPoint"),
      paymentGatewayTransactionDetails: "paypay",
      transactionMethod: 2,
      eventId: 1,
      eventTimeUTC: moment.utc().valueOf(),
      transactionId: moment.utc().valueOf(),
      isRFID: "true",
      userId: localStorage.getItem("userId"),
    };
    //proceed only when the amount is greater than zero
    if (paymentObj.totalAmountCharged && paymentObj.totalAmountCharged > 0) {
      var myself = this;
      fetch(config.envUrl + "VendingAppApi/manualCorrectedConsumerPurchase", {
        method: "POST",
        body: new URLSearchParams(paymentObj),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            myself.setState(
              {
                showPaymentPopup: true,
                paymentCompleted: true,
                paymentInitiated: false,
              },
              () => {
                localStorage.setItem(
                  "paymentcompleted",
                  JSON.stringify(myself.state.paymentCompleted)
                );
              }
            );
          } else {
            console.log(data.message);
          }
        })
        .catch(() => {
          this.setState({
            showNoInternetPopup:true,
            paymentInitiated: false
          });
          console.log(
            'No internet connection found. App is running in offline mode.'
          );
        });
        
    }
  }

  _onParent = (e) => {
    if (!this.state.productsfetched) e.preventDefault();
  };

  logout() {
    userService.logout();
    history.push("/login");
    return {
      type: userConstants.LOGOUT,
    };
  }

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <div className="ProductListing ">
        <div className="container">
        {!this.state.productsfetched ?
          <div className="row">
            <div className="col-12">
              <div style={style}>
                <ClipLoader
                  size={50}
                  color={"#FF0000"}
                  loading={true}
                />
              </div>
            </div>
          </div>
          :
          <div className="row">
            <div className="BackButton2 hide-arrrow col-12 py-4">
              <BackWhiteData
                BackLink=""
                title={i18n.t("MannualProductsPicked")}
              ></BackWhiteData>{" "}
            </div>{" "}
            <div className="AddProductManually col-12 pb-4">
              <div className="float-right">
                <Link
                  to={{
                    pathname: "/ChooseMannualProduct",
                  }}
                  onClick={(e) => this._onParent(e)}
                  className="addProduct"
                >
                  <Trans i18nKey="AddProductManually">
                    + Add Product Manually
                  </Trans>{" "}
                </Link>{" "}
              </div>{" "}
            </div>{" "}
            {/* <div style={style}>
              <ClipLoader
                size={50}
                color={"#FF0000"}
                loading={this.state.productsfetched ? false : true}
              />
            </div> */}
            <div className="CartProduct col-12 pb-3">
              <div className="cooler-product">
                {" "}
                {this.props.addedProductsList &&
                  this.state.productsfetched &&
                  this.props.addedProductsList.map((single, key) => {
                    if(single.orderUpdateReasonId<3)
                      return (
                        <CartProduct
                          data={single}
                          key={key}
                          poductClassName="product-box"
                          parentCallback={this.Cartcallback}
                          deleteProduct={this.deleteProduct}
                        />
                      );
                  })}{" "}
                <div className="productk-box">
                  <div className="col-md-4">
                    <span
                      style={{
                        marginLeft: "-9px",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Reward Point : {this.state.rewardPoint}
                    </span>
                    {this.state.isCompletePayment &&
                    this.state.isPaymentCancel &&
                    this.state.rewardChecked ? (
                      <div>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "13px",
                            marginLeft: "-8px",
                            marginTop: "10px",
                            color: "red",
                          }}
                        >
                          {i18n.t("isDeduct")}
                        </div>
                        <br></br>
                        <button
                          onClick={this.logout}
                          style={{
                            minWidth: "78px",
                            color: "#fff",
                            padding: "7px 20px",
                            boxShadow: "0px 10px 40px rgb(0 0 0 / 20%)",
                            borderRadius: "20px",
                            border: "0",
                            background: "#F40000",
                          }}
                          className="btn"
                        >
                          {" "}
                          <Trans i18nKey="Logout">Logout</Trans>{" "}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-8"></div>
                </div>
                <div className="totalAmount col-12 text-right clrblack">
                  <Trans i18nKey="Total">Total</Trans> ¥ {this.state.totalPrice}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="mybtn col-12">
              <div className="float-right  py-2">
                <Button
                  type="button"
                  text={i18n.t("Submit") + " ¥ " + this.state.totalPrice}
                  look="center"
                  btnStyle="Red"
                  clickHandler={this.paymentFunction}
                  disabled={
                    (this.state.totalPrice ? false : true) ||
                    this.state.paymentInitiated ||
                    this.state.paymentCompleted ||
                    (this.state.isCompletePayment &&
                      this.state.isPaymentCancel &&
                      this.state.rewardChecked)
                      ? true
                      : false
                  }
                />
              </div>{" "}
            </div>{" "}
            {this.state.showPaymentPopup ? (
              <PaymentPopup
                text={i18n.t("RequestSubmitted", {
                  pendingAmount: this.state.totalPrice,
                })}
                closePopup={this.togglePaymentPopup.bind(this)}
              />
            ) : null}
            {this.state.showNoInternetPopup ? (
              <PaymentPopup
                text="Oops! An error occurred, please try again"
                closePopup={this.TryAgainLater.bind(this)}
              />
            ) : null}
            {this.state.showTransactionFailPopup ? (
              <TransactionFailedPopup
                openPopup={true}
                message={i18n.t("NoProdsSelected")}
                message2={i18n.t("TransactionFailed")}
                redirectHome={this.toggleTransactionPopup.bind(this)}
                redirectSame={this.toggleTransactionPopupNoredirect.bind(this)}
              />
            ) : null}
            {this.state.connectionLost ? (
              <CoolerPopup
                openPopup={this.state.connectionLost}
                closePopup={this.closingPopup}
                message={i18n.t("ConnectionIssue")}
              />
            ) : null}
            <div style={style}>
              <ClipLoader
                size={50}
                color={"#FF0000"}
                loading={this.state.paymentInitiated ? true : false}
              />
            </div>
          </div>}
        </div>
            
      </div>
    );
  }
}

function mapState(state) {
  const addedProductsList = state.addedProductsList;
  return { addedProductsList };
}

const actionCreators = {
  initialProductsAction: addRemoveProductsAction.initialProducts,
  deleteProducts: addRemoveProductsAction.deleteProducts,
  updateCartProduct: addRemoveProductsAction.updateCartProducts,
};

const connectedCart = connect(mapState, actionCreators)(ManuallyCorrection);

export { connectedCart as ManuallyCorrection };
