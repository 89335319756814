import {
  userConstants
} from '../_constants';
import { decryptData } from '../_helpers/encryptData';

let user = JSON.parse(decryptData(localStorage.getItem('user')));
const initialState = user ? {
  loggedIn: true,
  user
} : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
          user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
          user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    case userConstants.USERUPDATE_REQUEST:
      return {
        updating: true,
          user: action.user
      };
    case userConstants.USERUPDATE_SUCCESS:
      return {
        updated: true,
          user: state.user.user
      };
    case userConstants.USERUPDATE_FAILURE:
      return {
        user: user
      };
    default:
      return state
  }
}