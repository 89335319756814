import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../components/button";
import "../assets/scss/defaults.scss";
import BackData from "../components/BackData/BackData";
import ResetPasswordData from "../containers/ResetPasswordData";
import { history } from "../_helpers";
import Popup from "../components/Model-Popup/Popup";
import i18n from "i18next";
import ClipLoader from "react-spinners/ClipLoader";
import CoolerPopup from "../components/Model-Popup/Popup";
import { config } from "../_config/index";
import PublicHeading from "../components/PublicHeader/publicHeader";
import Heading from "../containers/Heading/Heading";
import FooterNav from "../components/FooterNav/FooterNav";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { v4 as uuidv4 } from "uuid";
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | ResetPassword`;
    CapturePage({hitType:"pageview",page:"/ResetPassword"})    
        
    this.state = {
      password: "",
      confirmPassword: "",
      openPopup: false,
      popupmsg: "",
      popupimgtype: "",
      loading: false,
      connectionLost: false,
      isInvalidPassword:false,
      isPasswordMatched:false,
      isDisable:true
    };
    this.setPassword = this.setPassword.bind(this);
    this.popupClosing = this.popupClosing.bind(this);
    this.closingConnectionPopup = this.closingConnectionPopup.bind(this);
  }

  closingConnectionPopup() {
    this.setState({
      connectionLost: false,
    });
  }

  setPassword(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var resetPasswordprop = this.props.location.state;
    var OTP = resetPasswordprop.userOTP.actualOTP;
    var Password = this.state.password.trim();
    var confirmPassword = this.state.confirmPassword.trim();
    var Email = resetPasswordprop.userOTP.emailId;

    if (!Password) {
      this.setState({
        openPopup: true,
        popupmsg: i18n.t("EnterNewPassword"),
        popupimgtype: "error",
        loading: false,
      });
    }
    else if(!confirmPassword){
      this.setState({
        openPopup: true,
        popupmsg: i18n.t("EnterConfirmPassword"),
        popupimgtype: "error",
        loading: false,
      });
    }
     else if (Password === confirmPassword) {
      var validatelowerCaseLettersBool = Password.match(/[a-z]/g)
        ? true
        : false;
      var validateupperCaseLettersBool = Password.match(/[A-Z]/g)
        ? true
        : false;
      var validatenumbersBool = Password.match(/[0-9]/g) ? true : false;
      var validateLengthBool = Password.length >= 8 ? true : false;
      var validatePassword =
        validatelowerCaseLettersBool &&
        validateupperCaseLettersBool &&
        validatenumbersBool &&
        validateLengthBool;
      if (validatePassword) {
        const applicationTypeId = config.applicationTypeId;
        let sessionUId = sessionStorage.getItem("sessionId");
        if (!sessionUId) {
          sessionUId = uuidv4();
          sessionStorage.setItem('sessionId', sessionUId);
        }
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            Email,
            Password,
            OTP,
            applicationTypeId,
            sessionUId
          }),
        };
        var self = this;
        return fetch(
          `${config.envUrl}VendingAppApi/resetPassword`,
          requestOptions
        )
          .then((response) => {
            return response.json();
          })
          .catch(function (error) {
            if (error == "TypeError: Failed to fetch") {
              self.setState({
                connectionLost: true,
                loading: false,
              });
            }
          })
          .then((data) => {
            if (data && data.code === 5004) {
              console.log(data.message);
              this.setState({
                openPopup: true,
                popupmsg: i18n.t("PasswordUpdated"),
                popupimgtype: "success",
                loading: false,
              });
            } else if (data && data.code == 1525) {
              this.setState({
                openPopup: true,
                popupmsg: i18n.t("otpExpired"),
                popupimgtype: "error",
                loading: false,
              });
            } else if (data) {
              this.setState({
                openPopup: true,
                popupmsg: data.message,
                popupimgtype: "error",
                loading: false,
              });
            }
          });
      } else {
        this.setState({
          openPopup: true,
          popupmsg: i18n.t("ValidateMsg_ForInvalidFormatPassword"),
          popupimgtype: "error",
          loading: false,
        });
      }
    } else {
      this.setState({
        openPopup: true,
        popupmsg: i18n.t("ValidateMsg_ForPasswordNotMatch"),
        popupimgtype: "error",
        loading: false,
      });
    }
  }

  onChangePassword(e) {
    const { name, value } = e.target;
    if(name=='password'){
      let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
      const validationRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");      
      // const validationRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      if(format.test(value) && validationRegExp.test(value)){
        this.setState({
          isInvalidPassword:false,
          isDisable:(this.state.confirmPassword==value)?false:true,
          isPasswordMatched:this.state.confirmPassword==""?false:(this.state.confirmPassword==value)?false:true,
        })
      }
      else{
        this.setState({
          isInvalidPassword:true,
          isDisable:true
        })
      }
    }
    else if(name=='confirmPassword'){
      if(this.state.password==value){
        this.setState({
          isPasswordMatched:false,
          isDisable:false
        })
      }
      else{
        this.setState({
          isPasswordMatched:true,
          isDisable:true
        })
      }
    }
    
    this.setState({
      [name]: value,
    });
  }

  popupClosing() {
    if (this.state.popupimgtype === "success") {
      history.push({
        pathname: "/login",
      });
    } else {
      this.setState({
        openPopup: false,
      });
    }
  }

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    let user = localStorage.getItem('user');
    const head = user?<Heading title={i18n.t("ResetPassword")} firstIcon={true}  secondIcon={false} backLink="/ChangePassword" LinkIcon="back"/>: <PublicHeading Title={i18n.t('ResetPassword')} backLink="/ForgotPassword"  />

    const button = user ? <Button size="large" btnType="submit" text={i18n.t('ResetPassword')} className="btn mt-3 btn-theme-dark"  disabled={this.state.isDisable} /> : <Button size="large" text={i18n.t("ResetPassword")} btnType="submit" disabled={this.state.isDisable} />
    return (
      <div className={user?"":"loginwith"}>        
        <div className="container">
          {head}
          <form className="row justify-content-center" onSubmit={this.setPassword}>            
            <div className="col-lg-6 col-md-6  d-flex flex-column align-items-center justify-content-center">
              <div  className={user?"col-12 mt-5 Editform":"col-12 mt-5"}>
                <ResetPasswordData
                  onChangePassword={(event) => this.onChangePassword(event)}
                  passwords={this.state}
                  isInvalidPassword={this.state.isInvalidPassword}
                  isPasswordMatched={this.state.isPasswordMatched}
                >
                  {" "}
                </ResetPasswordData>{" "}        
                <div className="mt-5">
                  {button}
                  {/* <Button
                    size="large"
                    text={i18n.t("ResetPassword")}
                    btnType="submit"
                  /> */}
                </div>
              </div>      
            </div>
            {
              user && 
              <FooterNav />
            }  
            <Popup
                color="Red"
                openPopup={this.state.openPopup}
                closePopup={this.popupClosing}
                message={this.state.popupmsg}
                imagetype={this.state.popupimgtype}
              />
            <div className="spinner" style={{display:this.state.loading?'block':'none'}}>
              <div style={style}>
                <ClipLoader
                  size={50}
                  color={"#FF0000"}
                  loading={this.state.loading ? true : false}
                />
              </div>
            </div>
            {this.state.connectionLost ? (
              <CoolerPopup
                color="Red"
                openPopup={this.state.connectionLost}
                closePopup={this.closingConnectionPopup}
                message={i18n.t("ConnectionIssue")}
              />
            ) : null}
          </form>{" "}
        </div>{" "}
      </div>
    );
  }
}
export default ResetPassword;
