import React, { Component } from "react";
import "../assets/scss/defaults.scss";
import Supportlinks from "../containers/supportlinks/supportlinks";
import BackWhiteData from "../components/BackWhiteData/BackWhiteData";
import { history } from "../_helpers";
import Button from "../components/button";
import Elepay from "elepay-js-sdk";
import PaymentPopup from "../components/PaymentPopup/PaymentPopup";
import i18next from "i18next";
import { Trans, I18nextProvider } from "react-i18next";
import CoolerPopup from "../components/Model-Popup/Popup";
import ClipLoader from "react-spinners/ClipLoader";
import { config } from "../_config/index";
import ManualPopup from "../components/AddManualPopup/ManualPopup";
import ModelPopup from "../components/Model-Popup/Popup";
import { pendingpaymentService, purchaseHistoryService } from "../_services";
import Heading from "../containers/Heading/Heading";
import next from "../assets/images/icons/next.png";
import { cardService } from "../_services/card.service";
import { Link } from "react-router-dom";
import { ProductCard } from "../components/ProductCard/ProductCard";
import country  from "iso-country-currency"
import FooterNav from "../components/FooterNav/FooterNav";
import moment from "moment";
import SaferpayWallet from "../components/SaferpayWallet/SaferpayWallet";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";

class PendingAmount extends Component {
  constructor(props) {
    super(props); 
    document.title=`Coke & Go ${config.countryName} | PendingAmount`;
    CapturePage({hitType:"pageview",page:"/PendingAmount"})    
        
    this.state = {
      items:null,
      isLoading:true,
      showPopUp:false,
      imgType:"error",
      message:"",
      errorCode:0,
      cardList:[],
      OpenWallet:false,
      paymentTitle:i18next.t("Select Payment Method"),
      paymentType:"",
      paymentMethod:[]
    }
    let user = JSON.parse(decryptData(localStorage.getItem('user')));
    let authUser = user.authToken;
    let Iduser = user.userId;
    let that = this
    cardService.getCardList()
    .then((result)=>{         
      if(result.success){
        that.setState({
          cardList:result.cardList
        })                     
      }
    }) 
    pendingpaymentService
      .getPendingPayment({
        authUser,
        Iduser,
      })
      .then((pending) => {
        if(pending.success && pending.code==5011){
          this.setState({
            items:pending.data,
            isLoading:false,
            paymentMethod:pending.data.paymentMethods
          })
          // let ProductwithCurrency = pending.data.product.filter((item)=>item.currencyCode!='');
          // sessionStorage.setItem('currencyCode',ProductwithCurrency[0].currencyCode)
        }
        else{
          history.push("/ScanButton");
        }
      });
      // this.CompletependingPayment = this.CompletependingPayment.bind(this);
      this.closingShowPopup = this.closingShowPopup.bind(this);
  }
  // CompletependingPayment() {
  //   this.setState({
  //     isLoading: true,
  //   });
  //   let defaultcard = this.state.cardList.filter((item)=>(item.isDefault)?item:null)  
  //   console.log(defaultcard)  
  //   let user = JSON.parse(localStorage.getItem('user'));
  //   let authToken = user.authToken;
  //   let PurchaseNumber = this.state.items.purchaseNumber;
  //   let cardId = defaultcard[0].cardID;
  //   let CustomerName = user.firstName+' '+user.lastName;
  //   var self = this;
  //   const applicationTypeId = config.applicationTypeId;
  //   fetch(config.envUrl + "VendingAppApi/completePendingPaymentWithSwipe", {
  //     method: "POST",
  //     body: new URLSearchParams({
  //       authToken,
  //       PurchaseNumber,
  //       cardId,
  //       CustomerName,
  //       applicationTypeId
  //     }),
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //   })
  //   .then(function (response) {
  //     return response.json();
  //   })
  //   .catch(function (error) {
  //     if (error == "TypeError: Failed to fetch") {
  //       self.setState({
  //         connectionLost: true,
  //         isLoading: false,
  //       });
  //     }
  //     else{
        
  //     }
  //   })
  //   .then(function (body) {
  //     if(body.success){
  //       self.setState({
  //         imgType:"success",
  //         message:i18next.t("PaymentSuccess"),
  //         showPopUp:true,
  //         isLoading: false,
  //       })
  //     }
  //     else{
  //       if(body.code==503){
  //         self.setState({
  //           message: i18next.t("cardAuthError")
  //         })
  //       }
  //       else if(body.code==5015){
  //         self.setState({
  //           message: i18next.t("CommunicationError")
  //         })
  //       }
  //       else if(body.code==401){
  //         self.setState({
  //           message: i18next.t("CommunicationError")
  //         })
  //       }
  //       else{
  //         self.setState({
  //           message: i18next.t("CommonError")
  //         })
  //       }
  //       self.setState({
  //         errorCode:body.code,
  //         imgType:"error",          
  //         showPopUp:true,
  //         isLoading: false,
  //       })
  //     }
  //   });    
  // }

  closingShowPopup(){
    if(this.state.message==i18next.t("PaymentSuccess")){
      history.push("/ScanButton")
    }
    else{
      if(this.state.errorCode==401){
        history.push("/Logout")
      }
      else{
        
        this.setState({
          showPopUp:false,
          imgType:"error",
          message:""
        })
      }
    }
  }
  OpenWalletPopup=()=>{
    this.setState({
      OpenWallet:true
    })
  }
  handlePaymentChange=(e)=>{
    sessionStorage.setItem('walletType',e.target.value)
    this.setState({
      paymentType:e.target.value,
      paymentTitle:i18next.t(e.target.value=="creditCard"?"Pay via Credit Card":e.target.value=="GOOGLEPAY"?"Pay via GPay":"Pay via Apple Pay")
    })
  }
  closeWalletModal=()=>{
    this.setState({
      OpenWallet:false
    })
  }
  PayViaWallet=()=>{
    this.setState({isLoading:true})
    const applicationTypeId = config.applicationTypeId;
    const amount = this.state.items.amount;
    const returnSuccessUrl = config.baseURL+'/successResponse';
    const returnFailUrl = config.baseURL+'/CardFail'
    const CurrencyCode = config.currencyCode;
    const user = JSON.parse(decryptData(localStorage.user))
    const authToken = user.authToken
    const walletType = this.state.paymentType
    const deviceid = "";
    const purchasenumber=this.state.items.purchaseNumber;
    let sessionUId = sessionStorage.getItem("sessionId");
    let RequestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({applicationTypeId,amount,returnSuccessUrl,returnFailUrl,CurrencyCode,authToken,walletType,deviceid,purchasenumber,sessionUId})
    }
    // checking device status
    fetch(`${config.envUrl}VendingAppApi/saferPaymentPageInitialize`, RequestOptions)
    .then(function (response) {
      return response.json();
    })
    .then((data)=>{
      if(data.success && data.code==5041){
        let token = data.data.Token;
        sessionStorage.setItem('saferWalletToken',token);
        sessionStorage.setItem('intiatedFromPending','true')
        sessionStorage.setItem('purchasenumber',purchasenumber)
        window.location.href = data.data.RedirectUrl
      }
      else{
        this.setState({
          ErrorPopup:true,
          ErrorMsg:i18next.t("CommonError"),
          isLoading:false
        })
      }
    })
    .catch((err)=>{
      this.setState({
        ErrorPopup:true,
        ErrorMsg:i18next.t("CommonError"),
        isLoading:false
      })
    })
  }
  closingErrorPopup=()=>{
    this.setState({
      ErrorPopup:false
    })
  }

  render() {
    let purchaseDate='';
    var dataSummary = this.state.items;
    let fromEditProfile = sessionStorage.getItem('clickedDelete')
    var totalAmount=0;
    var paidAmount=0;
    var duesAmount=0;
    const currencyCode = config.currencyCode
    let lang = currencyCode=="USD"?"en-US":config.langCode
    if(dataSummary){
      purchaseDate = moment(dataSummary.purchaseDate).format("DD-MM-YYYY")
      var options = { style: 'currency', currency: currencyCode};
      // var paidAmount = new Intl.NumberFormat(lang, options).format(dataSummary.paidAmount);
      // var discountAmount = new Intl.NumberFormat(lang, options).format(dataSummary.discountAmount);
      totalAmount = new Intl.NumberFormat(lang, options).format(dataSummary.totalAmount);
      paidAmount = new Intl.NumberFormat(lang, options).format(dataSummary.totalPaidAmount);
      duesAmount = new Intl.NumberFormat(lang, options).format(dataSummary.amount);
      // var totalAmount = new Intl.NumberFormat(lang, options).format(dataSummary.paidAmount-dataSummary.discountAmount);
    }
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    };
    return (
      <div className="h-100">        
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
            <div className="col-12">
              <Heading title=""  firstIcon={true} secondIcon={false} backLink={fromEditProfile=='true'?"/Profile":"/ScanButton"} LinkIcon="back"/>   
              
              <p className="text-danger text-center pendingSubTitle mb-1">{i18next.t('PendingSubTitle')}</p>   
              <div className="row purchaseDate">
                <div className="col-6">
                  {i18next.t("Date")}
                </div>
                <div className="col-6 text-right text-dark">
                  {purchaseDate}
                </div>
              </div>
              <div className="orderSummary">                  
                <div className="d-inline-flex">
                { dataSummary && dataSummary.product.map((single, key) => {                  
                  return (
                    <div className='productCard' key={key}>
                    <ProductCard data={single}  />
                    </div>
                  )
                  })
                }
                </div>
              </div>
              <div className="totalPrice">
                <p>{i18next.t("Total")}</p>
                <h4>{totalAmount}</h4>
              </div>
              <h6 className="mb-3 text-success">{i18next.t('Paid')} {paidAmount}</h6>
              <button className="btn payment-status error w-100">
                <span>{i18next.t('OutstandingBalance')} {duesAmount}</span>
              </button>
              { dataSummary &&
              <>
              {
                config.isSaferPayWalletEnabled ?
                <button  className="btn mt-3 btn-theme-dark payoutstanding " style={{width:'120px',float:'right'}} onClick={this.OpenWalletPopup}>{i18next.t("Pay")}</button>
                :
                <Link to={`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+this.state.items.purchaseNumber}`} className="btn mt-3 btn-theme-dark payoutstanding " style={{width:'120px',float:'right'}}>{i18next.t("Pay")}</Link>
              }
              <SaferpayWallet 
              paymentMethod={this.state.paymentMethod}
              isModalOpen={this.state.OpenWallet} 
              preAuthAmt={dataSummary?dataSummary.amount:0} 
              title={this.state.paymentTitle}
              currencyCode={config.currencyCode}
              handlePaymentChange={this.handlePaymentChange}
              paymentType={this.state.paymentType}
              closeWalletModal={this.closeWalletModal}
              PayViaWallet={this.PayViaWallet}
              paywithCard={{pathname:`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+this.state.items.purchaseNumber}`}}
            /></>
              }
            </div>
            {
                this.state.ErrorPopup &&
                  <ModelPopup
                      color="Red"
                      openPopup={this.state.ErrorPopup}
                      closePopup={this.closingErrorPopup}
                      message={this.state.ErrorMsg}
                      imagetype="error"
                          
                  />
              }
           
          </div>
        </div>
        <FooterNav />
      </div>
      <div className="spinner" style={{display:this.state.isLoading ? 'block' : 'none'}}>
        <div style={style}>
          <ClipLoader
            size={50}
            color={"#FFFFFF"}
            loading={this.state.isLoading ? true : false}
          />
        </div>
      </div>
    </div>
    )
  }
}
export default PendingAmount;
