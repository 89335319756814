import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./profilecard.scss";
import { connect } from "react-redux";
import { config } from "../../_config/index";
import defaultuser  from "../../assets/images/home/defaultuser.png"
class ProfileCard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    var userData = this.props.user;
    let firstName = userData.firstName[0].toUpperCase()+(userData.firstName).substring(1)
    return (      
        <span className="profileName text-white mb-5">
          {firstName+' '+userData.lastName}{" "}
        </span>
    );
  }
}

function mapState(state) {
  const { user } = state.authentication;
  return { user };
}

const connectedProfilePage = connect(mapState, null)(ProfileCard);
export { connectedProfilePage as ProfileCard };
