import React from "react";
import PropTypes from "prop-types";
import { Image } from 'antd';
import i18next from "i18next";
import { config } from "../../../_config";
import { getAppId } from "../../../_helpers/getAppId";
import { decryptData } from "../../../_helpers/encryptData";
import LazyLoader from "../../LazyLoader/LazyLoader";
const ApplicationTypeId = getAppId();

const Productlist = ({ data, currencyCode, poductClassName }) => {
  // const styleBottleImg = {
  //   height: "79px",
  //   width: "22px",
  // };
  let lang = config.langCode
  let options = { style: 'currency', currency: currencyCode?currencyCode:"USD"};
  var price = new Intl.NumberFormat(lang, options).format(data.productPrice)
  let reward = JSON.parse(decryptData(localStorage.getItem('reward')));
  const getPrice = (offerProductList, offer) => {
    let prodData = offerProductList.filter((item)=>item.productId == data.productId)
    if(prodData.length > 0)
    {
      let discountRate = offer.discountPercentage;
      let discountedPrice = data.productPrice * (1 - discountRate/100);
      discountedPrice = new Intl.NumberFormat(lang, options).format(discountedPrice);
      return <div className="price-with-off">
        <span className="product-price clrGreen">{discountedPrice}</span>
        <span style={{fontSize:'13px'}} className="product-price clrblack"><s>{price}</s></span>
      </div>
    }
    else{
      return <div className="product-price clrGreen">{price}</div> 
    }
  }
  let findDeep = function(data, productId) {
    return data.some(function(e) {
      if(e.productId == productId) return true;
      else if(e.productList) return findDeep(e.productList, productId)
    })
  }
  const searchProduct=()=>{
    const status = findDeep(reward.promotionalOffer,data.productId)
    if(status){
      var DiscPrice;
      reward.promotionalOffer.map((offer)=>{
        const {productList} = offer
       for (let i = 0; i < productList.length; i++) {
          const element = productList[i];
         
          if(element.productId==data.productId){
            let discountRate = offer.discountPercentage;
            let discountedPrice = data.productPrice * (1 - discountRate/100);
            discountedPrice = new Intl.NumberFormat(lang, options).format(discountedPrice);
            // i=productList.length
            DiscPrice = <div className="price-with-off">
              <span className="product-price clrGreen pl-0">{discountedPrice}</span>
              <span style={{fontSize:'13px'}} className="product-price clrblack"><s>{price}</s></span>
            </div>
            break;
          }          
        }
      })
      return DiscPrice
    }
    else{
      return <div className="product-price clrGreen pl-0">{price}</div> 
    }
  }
  return (
    <div className={` ${poductClassName ? poductClassName : "row py-2 product-box"}`}>
      <div className="col-3">
        <div className="productimg text-center">
          {/* <Image src={data.productImage} alt="voip"  /> */}
          <LazyLoader src={data.productImage} page="home" />
        </div>
        
      </div>
      <div className="col-9">
        <div className="product-title">
          {data.productLocalName==""?data.shortName==""?data.productName:data.shortName:data.productLocalName}
        </div>
          {/* {
          (reward && reward.promotionalOffer.length > 0)
            ?
            reward.promotionalOffer.map((offer, key) => {
              return getPrice(offer.productList, offer)
            })

            :
            <div className="product-price clrGreen">{price}</div>    
          } */}
          {
            searchProduct()
          }
          {/* <div className="product-price clrRed">{price}</div>
          {
            (reward && reward.promotionalOffer) &&
            <>
              {
                reward.promotionalOffer.map((offer,key)=>{
                  return offer.productList.map((prod,pkey)=>{
                    if(prod.productId==data.productId){
                      return <div className="off text-success" key={pkey}>
                          <b>
                            {
                              ApplicationTypeId == 21 
                              ? `${offer.discountPercentage} ${i18next.t("OFF")}`
                              : `${offer.discountPercentage} % ${i18next.t("OFF")}`
                            }
                          </b>
                        </div>
                      // return <div className="off text-success" key={pkey}><b>{offer.discountPercentage}% {i18next.t("OFF")}</b></div>
                    }
                  })
                })
              }
            </> 
          } */}
      </div>
      
    </div>
  );
};

Productlist.propTypes = {
  data: PropTypes.object,
};

export default Productlist;
