import React, { Component } from 'react'
import { config } from '../_config';
import Button from '../components/button/button';
import queryString from 'query-string';
import { history } from '../_helpers';
import i18next from 'i18next';
import ModelPopup from "../components/Model-Popup/Popup";
import ClipLoader from "react-spinners/ClipLoader";
import FooterNav from '../components/FooterNav/FooterNav';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from '../_helpers/encryptData';
import { purchaseHistoryService } from '../_services';
export default class PaymentSuccess extends Component {
    constructor(props){
        super(props);     
        document.title=`Coke & Go ${config.countryName} | successResponse`;
        CapturePage({hitType:"pageview",page:"/successResponse"})       
        // let params = queryString.parse(this.props.location.search);
        this.state = {
            isLoading:true,
            showPopUp:false,
            message:'',
            imagetype:'',
            purchaseNumber:sessionStorage.getItem('purchasenumber')
        }
        if(config.paymentGateway==2){
            let walletToken = sessionStorage.getItem('saferWalletToken');
            if(config.isSaferPayWalletEnabled && walletToken){
                const qrcode = decryptData(localStorage.getItem('qrcode'));
                if(qrcode){
                  this.verifyWalletAuth(qrcode)
                }
                else{
                  this.getDeviceInf()
                }
            }
            else{
                history.push('/ScanButton')
            }
        }
        else{
            history.push('/ScanButton')
        }
    }
    getDeviceInf=()=>{
      let that = this;
      var userData = JSON.parse(decryptData(localStorage.getItem("user")));
      var historyobj = {
          authToken: userData.authToken,
          userId: userData.userId,
          purchaseNumber: sessionStorage.getItem('purchasenumber')
      };
      purchaseHistoryService.getPurchaseHistory(historyobj).then((result) => {
          if(result.success){
              if(result.data){
                  // sessionStorage.setItem('qrcode',result.data[0].macAddress)
                  that.verifyWalletAuth(result.data[0].macAddress)
              }
              else{
                  history.push('/Scanbutton')
              }
          }
          else{
              history.push('/Scanbutton')
          }
      })
      .catch(error=>{
        this.setState({
          message: i18next.t("CommonError"),
          imagetype:"error",          
          showPopUp:true,
          isLoading:false
        })
      })
    }
    verifyWalletAuth(qrcode){
        this.setState({isLoading:true})
        const applicationTypeId = config.applicationTypeId;
        const user = JSON.parse(decryptData(localStorage.user))
        const authToken = user.authToken
        const saferpayToken = sessionStorage.getItem('saferWalletToken');
        const deviceid = qrcode;
        let sessionUId = sessionStorage.getItem("sessionId");
        let RequestOptions = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({applicationTypeId,authToken,saferpayToken,deviceid,sessionUId})
        }
        // checking device status
        fetch(`${config.envUrl}VendingAppApi/saferPaymentAsset`, RequestOptions)
        .then(function (response) {
          return response.json();
        })
        .then((data)=>{
          if(data.success && data.code==5044){
            let tid = data.purchaseNumber
            let walletCardId = data.walletCardId
            let intiatedFromPending = sessionStorage.getItem('intiatedFromPending')
            if(intiatedFromPending=='true'){
                
                var self = this;
                let cardId = ""
                const CustomerName = user.firstName+' '+user.lastName;
                const applicationTypeId = config.applicationTypeId;
                const currencyCode = config.currencyCode
                const uniqueDeviceId = user.userId
                const languageCode = localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
                const walletTransactionId = tid;
                const preAuthTransactionId=tid;
                const PurchaseNumber = sessionStorage.getItem('purchasenumber')
                const walletType = sessionStorage.getItem('walletType');
                let sessionUId = sessionStorage.getItem('sessionId');
                fetch(config.envUrl + "VendingAppApi/completePendingPaymentWithSwipe", {
                  method: "POST",
                  body: new URLSearchParams({
                    authToken,
                    PurchaseNumber,
                    cardId,
                    CustomerName,
                    applicationTypeId,
                    currencyCode,
                    uniqueDeviceId,
                    languageCode,
                    walletTransactionId,
                    preAuthTransactionId,
                    walletType,
                    sessionUId
                  }),
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                })
                .then(function (response) {
                  return response.json();
                })
                .catch(function (error) {
                  if (error == "TypeError: Failed to fetch") {
                    self.setState({
                      isLoading:false,
                      message: i18next.t("CommonError"),
                      imagetype:"error",          
                      showPopUp:true,
                    });
                  }
                })
                .then(function (body) {
                  if(body.success){
                    sessionStorage.clear()
                    self.setState({
                        isLoading:false,
                        imagetype:"success",
                        message:i18next.t("PaymentSuccess"),
                        showPopUp:true
                    })
                  }
                  else{
                    self.setState({
                      message: i18next.t("CommonError"),
                      imagetype:"error",          
                      showPopUp:true,
                      isLoading:false
                    })
                  }
                }); 
            }
            else{
                sessionStorage.setItem('purchaseNumber',tid);
                sessionStorage.setItem('walletCardId',walletCardId); 
                history.push("/Home");
            }
            // send purchase no for pending payment instead of walletcardid
            
          }
          else{
            this.setState({
              message: i18next.t("CommonError"),
              imagetype:"error",          
              showPopUp:true,
              isLoading:false
            })
          }
        })
        .catch((err)=>{
          this.setState({
            message: i18next.t("CommonError"),
            imagetype:"error",          
            showPopUp:true,
            isLoading:false
          })
        })
    }
    closingShowPopup=()=>{
        history.push({
          pathname: '/OrderSummary/'+this.state.purchaseNumber,
          state: 'fromHome'
        })
    }
    render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        return (
            <div style={{padding:'50px'}}>
                {
                    this.state.isLoading && 
                      <div className="" style={{height:'400px'}}>
                        <div className="spinner">
                            <div style={style}>
                                <ClipLoader
                                    size={50}
                                    color={"#000000"}
                                    loading={this.state.isLoading}
                                />
                            </div>
                        </div>
                      </div>
                  }
              {this.state.showPopUp ? (
                <ModelPopup
                openPopup={this.state.showPopUp}
                closePopup={this.closingShowPopup}
                message={this.state.message}
                imagetype={this.state.imagetype}
                />
            ) : null}  
            
                <FooterNav/>
            </div>
        )
    }
}
