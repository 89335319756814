import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackWhiteData from "../components/BackWhiteData/BackWhiteData";
import Button from "../components/button/button";
import CartProduct from "../containers/CartProduct/CartProduct";
import ProductDatas from "../data/ProductDatas/ProductDatas";
import "../assets/scss/defaults.scss";
import { productService } from "../_services";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Elepay from "elepay-js-sdk";
import { paymentService } from "../_services/payment.service";
import { history } from "../_helpers";
import { connect } from "react-redux";
import { addRemoveProductsAction } from "../_actions";
import ManualPopup from "../components/AddManualPopup/ManualPopup";
import PaymentPopup from "../components/PaymentPopup/PaymentPopup";
import TransactionFailedPopup from "../components/TransactionFailPopup/Popup";
import i18n from "i18next";
import { Trans } from "react-i18next";
import CoolerPopup from "../components/Model-Popup/Popup";
import { config } from "../_config/index";
import { userConstants } from "../_constants";
import { userService } from "../_services";
import { Select } from 'antd';
import ModelPopup from "../components/Model-Popup/Popup";
import { pendingpaymentService } from "../_services";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";

const { Option } = Select;
class Cart extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | Cart`;
    CapturePage({hitType:"pageview",page:"/Cart"})
    
    let reward = JSON.parse(localStorage.getItem("rewardPoint"));
    this.state = {
      productsPickedFinal: Array.isArray(this.props.addedProductsList)
        ? this.props.addedProductsList
        : [],
      productsfetched: Array.isArray(this.props.addedProductsList)
        ? true
        : false,
      totalPrice: 0,
      showPopup: false,
      showPaymentPopup: false,
      showTransactionFailPopup: false,
      connectionLost: false,
      paymentInitiated: false,
      paymentCompleted:
        JSON.parse(localStorage.getItem("paymentcompleted")) || false,
      rewardPoint: reward,
      isChecked: true,
      rewardChecked: false,
      showRewardPaymentPopup: false,
      totalReward: "",
      isCompletePayment: false,
      isPaymentCancel: false,
      NoRewardPoint:false,
      purchaseNumber: "",
      errorPopup:false,
      errorMsg:'',
      offerCoupon:[],
      couponCode:null,
      isCouponApplied:false,
      freeProductSKU:'',
      discountPrice:0,
      preAuthAmount:0,
      isDisabledApplyButton:false,
      deductedAmount:0
    };

    if (this.state.paymentCompleted) {
      //payment's completed reroute user to scanner page
      localStorage.removeItem("paymentcompleted");
      history.push({ pathname: "/Scanbutton" });
      localStorage.removeItem("qrcode");
      localStorage.removeItem("deviceData");
      localStorage.removeItem("coolerId");
      this.props.initialProductsAction({});
    } else {
      var cartData,
        coolerId,
        productsPickedFinal = [];
      var deviceData = JSON.parse(localStorage.getItem("deviceData"));
      if (!deviceData) {
        //for random errors regarding cart screen, if devicedata is not saved then redirect to either home or scanbutton
        let qrmacAddress = localStorage.getItem("qrcode");
        if (qrmacAddress) {
          history.push("/Home");
        } else {
          history.push("/Scanbutton");
        }
      } else {
        var transactionpopup = deviceData["NPro"] === 0 ? true : false;
        var userData = JSON.parse(decryptData(localStorage.getItem("user")));
        var qrcode = localStorage.getItem("qrcode");
        var paramObj = {
          authToken: userData.authToken,
          userId: userData.userId,
          macAddress: qrcode,
        };
        let authToken = userData.authToken;
        var TotalPrice = 0;
        if (!Array.isArray(this.props.addedProductsList)) {
          // this runs when data is published
          //call the service for mapping device data to readable page we know as cart
          productService.getAllProducts(paramObj).then((allProds) => {
            coolerId = allProds.data[0].coolerId;
            localStorage.setItem("coolerId", coolerId);
            cartData = allProds.data[0].products;

            for (let i = 1; i <= deviceData["NPro"]; i++) {
              let prodObj = deviceData["P" + i];
              // let prodObjId = prodObj.SKUId.replace(/^0+/, "");
              let prodObjId = prodObj.SKUId;
              
              let prodDetails = cartData.filter(function(fil) {
                let cnt = 15-fil.productSKU.length;
                let prefix = "";
                for (let index = 0; index < cnt; index++) {
                  prefix+="0";
                }
                let newSKU = prefix+""+fil.productSKU;
                return prodObjId === newSKU;
              });
              if (prodDetails.length > 0) {
                TotalPrice =
                  TotalPrice +
                  (prodDetails[0].productPrice * prodObj.Q);
                productsPickedFinal.push({
                  productId: prodDetails[0].productId,
                  productName: prodDetails[0].productName,
                  productLocalName: prodDetails[0].productLocalName,
                  price: prodDetails[0].productPrice,
                  quantity: prodObj.Q,
                  devicequantity: prodObj.Q,
                  SKUId:prodDetails[0].productSKU,
                  imgIcon: prodDetails[0].productImage,
                  orderUpdateReasonId: 0, //default value is 0
                });
              }
            }
            this.setState({
              productsPickedFinal: Array.isArray(this.props.addedProductsList)
                ? this.props.addedProductsList
                : productsPickedFinal,
              productsfetched: true,
              totalPrice: TotalPrice,
              coolerId: coolerId,
              showTransactionFailPopup: transactionpopup,
            });
            // if(reward<TotalPrice){
            //   this.setState({
            //     NoRewardPoint: true,
            //     showPopup:false,
            //   });
            // }
            //call V3 API here for adding products to pending status
            var paymentProds = [];
            if (
              Array.isArray(this.props.addedProductsList) &&
              this.props.addedProductsList.length > 0
            ) {
              this.props.addedProductsList.forEach(function(pp) {
                paymentProds.push({
                  productID: pp.productId,
                  count: pp.quantity,
                  userQuantity: pp.devicequantity,
                  orderUpdateReasonId: pp.orderUpdateReasonId,
                });
              });
              let preauth = JSON.parse(localStorage.getItem('preauth'));
              var paymentObj = {
                authToken: userData.authToken,
                coolerId: this.state.coolerId
                  ? this.state.coolerId
                  : localStorage.getItem("coolerId"), //EquipmentNumber
                purchaseNumber: preauth.purchaseNo, //from device end Sid
                products: JSON.stringify(paymentProds),
                paymentTransactionId:deviceData.TId,
                paymentMethod:"Swipe",
                isRewardPointDeduction:false,
                edgeSessionId:deviceData.edgeSessionId ? deviceData.edgeSessionId : "",
                transactionDuration:deviceData.analyticalParams &&
                  deviceData.analyticalParams.transactionDuration
                    ? deviceData.analyticalParams.transactionDuration
                    : "",
                irResultResponseTime: deviceData.analyticalParams &&
                  deviceData.analyticalParams.irResultResponseTime
                    ? deviceData.analyticalParams.irResultResponseTime
                    : "",
                dataConsumedDuringTransaction:
                    deviceData.analyticalParams &&
                    deviceData.analyticalParams.dataConsumedDuringTransaction
                      ? deviceData.analyticalParams.dataConsumedDuringTransaction
                      : "",                
                transactionMethod:2,
                couponCode:null,
                totalAmountCharged: this.state.totalPrice,
                totalRewardPointsEarned:0,
                paymentGatewayTransactionDetails:"swipe",
                isCouponApplied:false,
                freeSKU:'',
                swipeTransactionId:preauth.purchaseNo
              };
              this.setState({purchaseNumber:preauth.purchaseNo,preAuthAmount:preauth.amount});
              paymentService.getCharge(paymentObj).then(function(result) {
                console.log(
                  "addconsumerpurchasev9 API called for first time and purchase saved",
                  result
                );
                // localStorage.setItem('rewardPoint',JSON.stringify(result.data.totalRewardBalance));
                //Once addconsumerpurchaseV3 is called then also set its status to cancel for handling exception such as browser close etc
                let authToken = userData.authToken;
                let purchaseNumber = preauth.purchaseNo;
                let userId = userData.userId;
                let status = 3; //hardcoded value

                // default payment status
                fetch(config.envUrl + "VendingAppApi/updatePaymentStatusV5", {
                  method: "POST",
                  body: new URLSearchParams({
                    authToken,
                    purchaseNumber,
                    userId,
                    status,
                    isDeduction: true,
                  }),
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                })
                  .then((response) => {
                    return response.json();
                  })
                  .then((data) => {
                    if (data.success) {
                      console.log(data);
                    } else {
                      console.log(data.message);
                    }
                  });
              });
            }
          });          
          this.props.initialProductsAction(productsPickedFinal); //this is called for setting props and initial products
          
          fetch(config.envUrl + "VendingAppApi/getConsumerOfferDetails", {
            method: "POST",
            body: new URLSearchParams({
              authToken
            }),
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.setState({
              offerCoupon:data.OfferCoupon
            })
          });
        }
      }
      this.paymentFunction = this.paymentFunction.bind(this);
      this.closingPopup = this.closingPopup.bind(this);
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.ApplyCoupon = this.ApplyCoupon.bind(this);
      this.removeDiscount = this.removeDiscount.bind(this);
    }
  }

  componentDidMount() {
    var that = this;
    // if (!Array.isArray(this.props.addedProductsList)) {
    //   that.setState({ showPopup: true });
    // }
    setTimeout(function() {
      that.setState({ showPopup: false });
    }, 5000);
    
    var TotalPrice = 0;
    if (Array.isArray(this.props.addedProductsList)) {
      this.props.addedProductsList.forEach((prod) => {
        if(prod.orderUpdateReasonId<3){
          TotalPrice =
            TotalPrice +
            (
              prod.productPrice
                ? prod.productPrice * prod.quantity
                : prod.price * prod.quantity
            );
        }
      });
      this.setState({
        totalPrice: TotalPrice,
      });
    }
  }

  Cartcallback = (countdiff, price, operation, productId) => {
    //updatecartproduct for setting updatereasonid
    if (operation === "increment") {
      this.setState({ totalPrice: this.state.totalPrice + countdiff * price });
    } else if (operation === "decrement") {
      this.setState({ totalPrice: this.state.totalPrice - countdiff * price });
    }
    this.props.updateCartProduct(productId);
  };

  deleteProduct = (productId) => {
    var totalPrice = 0;
    //now update the total price in state
    let currProduct = this.props.addedProductsList.filter((prod)=>prod.orderUpdateReasonId<3);
    if (
      Array.isArray(this.props.addedProductsList) &&
      currProduct.length > 1
    ) {
      this.props.deleteProducts(productId);
      //since props are not updated when delete is done, we have to update totalcount in a way ignoring the deleted row.
      var TotalPriceData = this.props.addedProductsList.filter((dat) => {
        return dat.productId !== productId && dat.orderUpdateReasonId<3;
      });
      
      TotalPriceData.forEach((prod) => {
        if(prod.orderUpdateReasonId<3){
          totalPrice =
            totalPrice +
            (
              prod.productPrice
                ? prod.productPrice * prod.quantity
                : prod.price * prod.quantity
            );
        }
      });
      this.setState({
        productsPickedFinal: this.props.addedProductsList,
        totalPrice: totalPrice,
      });
    }
  };

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }
  toggleNoRewardPopup() {
    this.setState({
      NoRewardPoint: !this.state.NoRewardPoint,
    });
  }

  togglePaymentPopup() {
    localStorage.removeItem("qrcode");
    localStorage.removeItem("deviceData");
    localStorage.removeItem("coolerId");
    localStorage.removeItem("paymentcompleted");
    localStorage.removeItem("preauth");
    
    this.setState({
      showPaymentPopup: false,
    });
    let user = JSON.parse(localStorage.getItem('user'));
    let authUser = user.authToken;
    let Iduser = user.userId;
    pendingpaymentService
      .getPendingPayment({
        authUser,
        Iduser,
      })
      .then((pending) => {
        if (pending.success && pending.message !== "No Records Found") {
          //do nothing
          history.push({
            pathname: "/PendingAmount",
            state: {
              pendingAmount: pending.data.amount,
              authtoken: authUser,
              purchaseNumber: pending.data.purchaseNumber,
              userId: Iduser,
            },
          });
          localStorage.removeItem("user"); //so that user cant login
          localStorage.removeItem("deviceData");
          localStorage.removeItem("coolerId");
          localStorage.removeItem("PurchaseNo");
          localStorage.removeItem("paymentcompleted");
          this.props.initialProductsAction({});
        }
        else if(pending.success && pending.message=="No Records Found"){
          history.push("/Scanbutton");
        }
        else if (!pending.success) {
          this.setState({
            connectionLost: true,
          });
        }
      });
  }

  toggleTransactionPopup() {
    localStorage.removeItem("deviceData");
    localStorage.removeItem("qrcode");
    localStorage.removeItem("coolerId");
    //called so that get coolers api is called again next time
    this.props.initialProductsAction({});
    this.setState({
      showTransactionFailPopup: false,
    });    
    history.push("/Scanbutton");
  }

  toggleTransactionPopupNoredirect() {
    this.setState({
      showTransactionFailPopup: false,
    });
  }

  closingPopup() {
    this.setState({
      connectionLost: false,
      errorPopup:false
    });
  }

  toggleCheck = () => {
    this.setState({
      isChecked: !this.state.isChecked,
      isCompletePayment: false,
      isPaymentCancel: false,
    });
    this.setState({
      rewardChecked: this.state.isChecked,
    });
  };

  handleSelectChange(value){
    console.log(value)
    this.setState({
      couponCode:value      
    })
  }

  ApplyCoupon(){
    if(this.state.couponCode){
      let product_list  = this.props.addedProductsList
      product_list.sort((a, b) => a.price - b.price);
      let discountProduct = product_list[0];
      this.setState({
        freeProductSKU:discountProduct.SKUId,
        discountPrice:discountProduct.price,
        isCouponApplied:true,
        isDisabledApplyButton:true
      })
    }
    else{
      this.setState({
        errorMsg:"Please select Coupon to Apply!",
        errorPopup:true,
        isCouponApplied:false,
        couponCode:null
      })
    }
  }

  removeDiscount(){
    this.setState({
      freeProductSKU:'',
      discountPrice:'',
      isCouponApplied:false,
      couponCode:null,
      isDisabledApplyButton:false
    })
  }

  paymentFunction() {
    this.setState({
      paymentInitiated: true,
    });
    var data = JSON.parse(decryptData(localStorage.getItem("user")));
    var paymentProds = [];
    var deviceData = JSON.parse(localStorage.getItem("deviceData"));
    this.props.addedProductsList.forEach(function(pp) {
      paymentProds.push({
        productID: pp.productId,
        count: pp.devicequantity,
        userQuantity: pp.quantity,
        orderUpdateReasonId: pp.orderUpdateReasonId,
      });
    });
    let preauth = JSON.parse(localStorage.getItem('preauth'));
    var paymentObj = {
      authToken: data.authToken,
      coolerId: this.state.coolerId
        ? this.state.coolerId
        : localStorage.getItem("coolerId"), //EquipmentNumber
      purchaseNumber: preauth.purchaseNo, //from device end Sid
      products: JSON.stringify(paymentProds),
      paymentTransactionId:deviceData.TId,
      paymentMethod:"Swipe",
      isRewardPointDeduction:this.state.rewardChecked,
      edgeSessionId:deviceData.edgeSessionId ? deviceData.edgeSessionId : "",
      transactionDuration:deviceData.analyticalParams &&
        deviceData.analyticalParams.transactionDuration
          ? deviceData.analyticalParams.transactionDuration
          : "",
      irResultResponseTime: deviceData.analyticalParams &&
        deviceData.analyticalParams.irResultResponseTime
          ? deviceData.analyticalParams.irResultResponseTime
          : "",
      dataConsumedDuringTransaction:
          deviceData.analyticalParams &&
          deviceData.analyticalParams.dataConsumedDuringTransaction
            ? deviceData.analyticalParams.dataConsumedDuringTransaction
            : "",      
      transactionMethod:2,
      couponCode:this.state.couponCode,
      totalAmountCharged: this.state.totalPrice,
      totalRewardPointsEarned:0,
      paymentGatewayTransactionDetails:"swipe",
      isCouponApplied:this.state.isCouponApplied,
      freeSKU:this.state.freeProductSKU,
      swipeTransactionId:preauth.purchaseNo              
      
    };

    //proceed only when the amount is greater than zero
    if (paymentObj.totalAmountCharged && paymentObj.totalAmountCharged > 0) {
      var myself = this;
      paymentService.getCharge(paymentObj).then(function(result) {
        //v3 is called again
        //v3 sent the result and purchase number

        if (result.success) {          
          let authToken = data.authToken
          let purchaseNumber= preauth.purchaseNo
          let userId= data.userId
          let status = 1
          let isDeduction=true
          fetch(config.envUrl + "VendingAppApi/updatePaymentStatusV5", {
            method: "POST",
            body: new URLSearchParams({
              authToken,
              purchaseNumber,
              userId,
              status,
              isDeduction
            }),
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.success) {
              // history.push("/login");
              myself.setState({
                showPaymentPopup:true,
                paymentInitiated: false,
                deductedAmount:data.amount
              })
            } else {
              console.log(data.message);
            }
          });
          myself.setState({ isCompletePayment: true });
        } else {
          myself.setState({
            connectionLost: true,
            paymentCompleted: false,
            paymentInitiated: false,
          });
        }
      });
    }
  }

  _onParent = (e) => {
    if (!this.state.productsfetched) e.preventDefault();
  };

  logout() {
    userService.logout();
    history.push("/login");
    return {
      type: userConstants.LOGOUT,
    };
  }

  render() {
    const custStyle = {
      padding: '5px 10px',
      border: '1.5px solid #f40000',
      borderRadius: '8px',
      fontWeight: '600'
    }
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <div className="ProductListing ">
        <div className="container">
          <div className="row">
            <div className="BackButton2 hide-arrrow col-12 py-4">
              <BackWhiteData
                BackLink=""
                title={i18n.t("ProductsPicked")}
              ></BackWhiteData>{" "}
            </div>{" "}
            <div className="AddProductManually col-12 pb-4">
              <div className="float-right">
                {/* <Link
                  to={{
                    pathname: "/ChooseProduct",
                  }}
                  onClick={(e) => this._onParent(e)}
                  className="addProduct"
                >
                  <Trans i18nKey="AddProductManually">
                    + Add Product Manually
                  </Trans>{" "}
                </Link>{" "} */}
              </div>{" "}
            </div>{" "}
            <div style={style}>
              <ClipLoader
                size={50}
                color={"#FF0000"}
                loading={this.state.productsfetched ? false : true}
              />
            </div>
            <div className="CartProduct col-12 pb-3">
              <div className="cooler-product">
                {" "}
                {this.props.addedProductsList &&
                  this.state.productsfetched &&
                  this.props.addedProductsList.map((single, key) => {
                    if(single.orderUpdateReasonId < 3)
                    return (
                      <CartProduct
                        data={single}
                        key={key}
                        poductClassName="product-box"
                        parentCallback={this.Cartcallback}
                        deleteProduct={this.deleteProduct}
                      />
                    );
                  })}{" "}
                <div className="productk-box">
                  <div className="col-md-4">
                    {this.state.offerCoupon && this.state.offerCoupon.length>0 ? (
                      <>
                    <div className="input-group mb-3">                      
                      <Select placeholder="Please select coupon" value={this.state.couponCode} showArrow={false} className="form-control" onChange={this.handleSelectChange}>
                      { 
                        this.state.offerCoupon.map((coupon,key)=>{
                          return <Option value={coupon.CouponCode} key={key}>{coupon.OfferName}</Option>
                        })
                      }
                      </Select>
                      <div className="input-group-append">
                        <button className="btn btn-dark" type="button" onClick={this.ApplyCoupon} disabled={this.state.isDisabledApplyButton}>Apply Coupon</button>
                      </div>
                    </div> 
                    {this.state.isCouponApplied && <p className="alert alert-success ml-1">Coupon Applied <span className="text-danger" style={{fontWeight:'bolder',cursor:'pointer',float:'right'}} onClick={this.removeDiscount}>X</span></p>}
                    </>
                    ):null}
                    {this.state.isCompletePayment &&
                    this.state.isPaymentCancel &&
                    this.state.rewardChecked ? (
                    <div>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          marginLeft: "-8px",
                          marginTop: "10px",
                          color: "red",
                        }}
                      >
                        {i18n.t("isDeduct")}
                      </div>
                      <br></br>
                      <button
                        onClick={this.logout}
                        style={{
                          minWidth: "78px",
                          color: "#fff",
                          padding: "7px 20px",
                          boxShadow: "0px 10px 40px rgb(0 0 0 / 20%)",
                          borderRadius: "20px",
                          border: "0",
                          background: "#F40000",
                        }}
                        className="btn"
                      >
                        {" "}
                        <Trans i18nKey="Logout">Logout</Trans>{" "}
                      </button>
                    </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-8"></div>
                </div>
                <div className="totalAmount col-12 text-right clrblack">
                  <span  className="cartamount"><Trans i18nKey="Total">Total</Trans> $ {this.state.totalPrice}</span> 
                </div>{" "}
                {
                  (this.state.discountPrice>0) ? (
                    <div className="totalAmount col-12 text-right clrblack">
                    <span className="cartamount"><Trans i18nKey="Discount">Discount</Trans> $ {this.state.discountPrice}</span>
                  </div>
                  ):null
                }
              </div>{" "}
            </div>{" "}
            <div className="mybtn col-12">
              <div className="float-right  py-2">
                <Button
                  type="button"
                  text={i18n.t("Pay") + " $ " + (this.state.totalPrice-this.state.discountPrice)}
                  look="center"
                  btnStyle="Red"
                  clickHandler={this.paymentFunction}
                  disabled={
                    (this.state.totalPrice ? false : true) ||
                    this.state.paymentInitiated ||
                    this.state.paymentCompleted ||
                    (this.state.isCompletePayment &&
                      this.state.isPaymentCancel &&
                      this.state.rewardChecked)
                      ? true
                      : false
                  }
                />
              </div>{" "}
            </div>{" "}
            {this.state.showPopup ? (
              <ManualPopup
                text={i18n.t("AddManualPopup")}
                closePopup={this.togglePopup.bind(this)}
              />
            ) : null}

            {this.state.NoRewardPoint ? (
              <ManualPopup
                text={i18n.t("NoRewardPoints")}
                closePopup={this.toggleNoRewardPopup.bind(this)}
              />
            ) : null}
            {this.state.showPaymentPopup ? (
              <ModelPopup
                color="Red"
                openPopup={this.state.showPaymentPopup}
                closePopup={this.togglePaymentPopup.bind(this)}
                message={(this.state.deductedAmount==this.state.totalPrice)?i18n.t("PaymentSuccess"):i18n.t("PaymentSuccesswithPending",{ deductedAmount: this.state.deductedAmount, pendingAmount:(this.state.totalPrice-this.state.deductedAmount) })}
                imagetype="success"                    
            />
            ) : null}
            {this.state.showTransactionFailPopup ? (
              <TransactionFailedPopup
                openPopup={true}
                message={i18n.t("NoProdsSelected")}
                message2={i18n.t("TransactionFailed")}
                redirectHome={this.toggleTransactionPopup.bind(this)}
                redirectSame={this.toggleTransactionPopupNoredirect.bind(this)}
              />
            ) : null}
            {this.state.connectionLost ? (
              <CoolerPopup
                openPopup={this.state.connectionLost}
                closePopup={this.closingPopup}
                message={i18n.t("ConnectionIssue")}
              />
            ) : null}
            {this.state.errorPopup ? (
              <CoolerPopup
                color="Red"
                openPopup={this.state.errorPopup}
                closePopup={this.closingPopup}
                message={this.state.errorMsg}
              />
            ) : null}
            <div style={style}>
              <ClipLoader
                size={50}
                color={"#FF0000"}
                loading={this.state.paymentInitiated ? true : false}
              />
            </div>
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}

function mapState(state) {
  const addedProductsList = state.addedProductsList;
  return { addedProductsList };
}

const actionCreators = {
  initialProductsAction: addRemoveProductsAction.initialProducts,
  deleteProducts: addRemoveProductsAction.deleteProducts,
  updateCartProduct: addRemoveProductsAction.updateCartProducts,
};

const connectedCart = connect(mapState, actionCreators)(Cart);

export { connectedCart as Cart };
