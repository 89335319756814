import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../components/button";
import "../assets/scss/defaults.scss";
import BackData from "../components/BackData/BackData";
import VerifyOTPs from "../containers/VerifyOTP";
import { history } from "../_helpers";
import Popup from "../components/Model-Popup/Popup";
import ClipLoader from "react-spinners/ClipLoader";
import i18n from "i18next";
import CoolerPopup from "../components/Model-Popup/Popup";
import { config } from "../_config/index";
import PublicHeading from "../components/PublicHeader/publicHeader";
import Heading from "../containers/Heading/Heading";
import FooterNav from "../components/FooterNav/FooterNav";
import ReactInputMask from "react-input-mask";
import { v4 as uuidv4 } from "uuid";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";
class LinkPaytm extends Component {
  constructor(props) {
    super(props);   
    document.title=`Coke & Go ${config.countryName} | link-with-paytm`;
    CapturePage({hitType:"pageview",page:"/link-with-paytm"})    
        
    this.state = {
      isLoading:false,
      mobileNumber:'',
      showDialog:"",
      dialogmsg:"",
      popupimgtype:"",
      purchaseNumber:props.location.state,
      redirectpage:props.location.state=='Home'?props.location.state:props.location.state=='ScanButton'?'ScanButton': props.location.state && props.location.state .length > 10 ? `OrderSummary/${props.location.state}`:'Card' 
    };
    this.closingPopup = this.closingPopup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    if (!localStorage.getItem('qrcode') && !sessionStorage.getItem('qrcode')){
      history.push('/Card')
    }
  }

  closingConnectionPopup() {
    
  }

  submitOTP(event) {
    
  }

  handleChange(event) {
    let value = event.target.value
    let mob = value.split('-').join('');
    mob = mob.split('(').join('');
    mob = mob.split(' ').join('');
    mob = mob.split(')').join('');
    mob = mob.split('_').join('');
    this.setState({
      mobileNumber:mob
    });
  }

  sendRequest(event) {
    event.preventDefault();
    let mobile = this.state.mobileNumber;
    mobile = mobile.replace(/^0+/, '');
    if((mobile.length>0 && mobile.length>config.mobileLength) || (mobile.length>0 && mobile.length<config.mobileLength) || mobile.length==0){
      this.setState({
        showDialog: true,
        dialogmsg: i18n.t("ValidateMsg_ForMobile",{'length':config.mobileLength}),
        popupimgtype:'error'
      });
    }
    else{
      this.setState({
        isLoading:true
      })
      const user = JSON.parse(decryptData(localStorage.getItem("user")));
      let authToken = user.authToken;
      let uniquedeviceId = user.userId;
      sessionStorage.setItem("uniquedeviceId",uniquedeviceId);
      let phoneNumber = this.state.mobileNumber
      let sessionUId = sessionStorage.getItem('sessionId');
      const deviceId = sessionStorage.getItem('qrcode') || decryptData(localStorage.getItem("qrcode"));
      let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,uniquedeviceId,phoneNumber,sessionUId,deviceId})
      }
      // checking device status
      fetch(`${config.envUrl}VendingAppApi/sendPaytmOTPrequest`, RequestOptions)
      .then(function (response) {
        return response.json();
      })
      .then((result)=>{
        this.setState({
          isLoading:false
        })
        if(result.success){
          sessionStorage.setItem("stateToken",result.state_token);
          sessionStorage.setItem("phoneNumber",this.state.mobileNumber)
          history.push({pathname:'/verify-paytm-otp',state:this.state.purchaseNumber || this.state.redirectpage})
        }
        else{
          this.setState({
            showDialog: true,
            dialogmsg: result.message,
            popupimgtype:'error'
          });
        }
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }

  closingPopup() {
    this.setState({
      showDialog: false
    })
  }

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    const mobilePlaceholder = (config.mobileFormat).replaceAll('9','X')
    return (
      <div>        
        <div className="container">
        <Heading title={i18n.t("Link to Paytm Wallet")} align="center" firstIcon={true}  secondIcon={false} backLink={`/${this.state.redirectpage}`} LinkIcon="back"/>
          <form className="row justify-content-center" onSubmit={this.sendRequest}>
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
              <div className="col-12 mt-5 Editform">
                <div className="">
                    <label className="text-white">{i18n.t("Mobile Number")}</label>
                    <ReactInputMask name="mobileNumber" className="form-control" mask={config.mobileFormat} maskChar="_" placeholder={mobilePlaceholder} onChange={this.handleChange} />
                </div>               
                <div className="text-center mt-5">
                    <Button size="large" btnType="submit" text={i18n.t('Link to Paytm Wallet')} className="btn mt-2 btn-theme-dark" />
                </div>
              </div>
              
            </div>
          </form>
          <FooterNav />
          {this.state.showDialog ? (
            <Popup
              color="Red"
              openPopup={this.state.showDialog}
              closePopup={this.closingPopup}
              message={this.state.dialogmsg}
              imagetype={this.state.popupimgtype}
            />
          ) : (
            ""
          )}
          <div className="spinner" style={{display:this.state && this.state.isLoading ? 'block' : 'none'}}>
            <div style={style}>
              <ClipLoader
                size={50}
                color={"#000000"}
                loading={this.state && this.state.isLoading ? true : false}
              />
            </div>
          </div>
          {/* {this.state.connectionLost ? (
            <CoolerPopup
              color="Red"
              openPopup={this.state.connectionLost}
              closePopup={this.closingConnectionPopup}
              message={i18n.t("ConnectionIssue")}
            />
          ) : null} */}
        </div>
      </div>
    );
  }
}
export default LinkPaytm;
