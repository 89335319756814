import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cart from "../../assets/images/icons/cart.svg";
import moment from "moment";
import { Trans } from "react-i18next";
import country  from "iso-country-currency"
import i18n from "i18next";
import { config } from "../../_config";
import card from "../../_assets/images/icons/card.png"
import mailSupport from "../../_assets/images/icons/mailSupport.png"
import "./PurchaseHistoryListStyle.scss"
import { Link } from "react-router-dom";
import { useState } from "react";
import LazyLoader from "../LazyLoader/LazyLoader";
const PurchaseHistoryList = ({ onClick, data, handleSupport }) => {
  const currency = config.currencyCode;
  const applicationTypeId = config.applicationTypeId;
  let lang = currency=="USD"?"en-US":config.langCode
  let options = { style: 'currency', currency: currency };
  let price = new Intl.NumberFormat(lang, options).format(data.paidAmount)
  
  return (
    <li className="history-box">
      <div className="cart-img" onClick={()=>onClick(data)}>
        <LazyLoader src={data.product[0].productOtherUrl?data.product[0].productOtherUrl:cart} page="history"/>
      </div>{" "}
      <div className="history-detail">
        <div className="d-flex">
          <div onClick={()=>onClick(data)}>
          <p className="history-date">
            {" "}
            {moment(data.date).format("DD MMM YYYY hh:mm A")}{" "}
          </p>{" "}
          <h5 className="history-title"> 
            {" "}
            {data.product[0].productLocalName==""?data.product[0].shortName==""?data.product[0].productName:data.product[0].shortName:data.product[0].productLocalName}{" "}
            {data.product.length > 1
              ? " + " + (data.product.length - 1) + i18n.t("Items")
              : ""}{" "}
          </h5>{" "}
          <p className="history-order">
            {" "}
            <Trans i18nKey="OrderNo">Order No</Trans>: {data.orderID.length>20?data.orderID.slice(0,17)+'...':data.orderID}{" "}
          </p>{" "}
         
          </div>
          <div className="history-price" style={{paddingRight:'10px'}}>
          <p className="price-detail mt-2">
            {" "}
            {price}{" "}
          </p>{" "}
          {
            (data.SupportEnabled == true && config.isLatamCountry && config.showSupportButton == true && data.paymentStatus == "Completed")
            && 
            // <Link 
            //   to={{
            //   pathname: `/NeedHelp/${data.orderID}`,
            //   state:{
            //     fromPurchaseHistory: 'purchaseHistory',
            //     purchaseDate: data.date,
            //     SupportButtonDuration: data.SupportButtonDuration 
            //   }
            // }}
            // >
              <img src={mailSupport} className="mw-100" alt="cart icon" height={25} onClick={()=>handleSupport(data)}/>
            // </Link>
          }
          {
            (applicationTypeId == 20 && config.showSupportButton == true && data.paymentStatus == "Completed")
            && 
            // <Link 
            //   to={{
            //   pathname: `/NeedHelp/${data.orderID}`,
            //   state:{
            //     fromPurchaseHistory: 'purchaseHistory',
            //     purchaseDate: data.date,
            //     SupportButtonDuration: data.SupportButtonDuration 
            //   }
            // }}
            // >
              <span style={{fontWeight: '600', cursor: 'pointer', textDecoration: 'underline', color: 'black'}} onClick={()=>handleSupport(data)}>{i18n.t("Support")}</span>
            // </Link>
          }
        </div>{" "}
        </div>        
        {/* <p className="history-order">
          {" "}
          <Trans i18nKey="CoolerId">CoolerId</Trans> : {data.coolerId}{" "}
        </p>{" "} */}
        {
          (data.paymentDetails && data.paymentDetails.length>0)  &&
          <table className="history_card_details">
            <thead>
              {
                data.paymentDetails.map((cards,key)=>{
                  const amount = new Intl.NumberFormat(lang, options).format(cards.amount)
                  return <tr key={key}>
                      <th key={"label"+key} style={{display:'contents'}}>
                        <img src={card} height={12}/>{config.applicationTypeId == 19 ? <span className="ml-2">{i18n.t(cards.lastDigits==""?cards.payType=="APPLEPAY"?"Paid with Apple pay":"Paid with GPay":"Wallet/Card ending in")}: {cards.lastDigits}</span> : <span className="ml-2">{i18n.t(cards.lastDigits==""?cards.payType=="APPLEPAY"?"Paid with Apple pay":"Paid with GPay":"Card ending in")}: {cards.lastDigits}</span>}
                      </th>
                      <th key={"price"+key} className="text-right text-success pr-3">{amount}</th>
                    </tr>
                })
              }
              
            </thead>
          </table>
        }
         {(data.discountAmount>0 && data.freeBeverageApplied==false)?(
              <p className="my-2 text-success " style={{fontWeight:'bold',fontSize:'12px'}}>{i18n.t('Discount applied')}: {i18n.t('Buy 10, enjoy 1 from us')}</p>
            ):null
            }
          <p className={`history-msg mb-0 text-uppercase ${data.paymentStatus === "Processing"
              ? "text-success"
              : data.paymentStatus === "Completed"
                ? "text-success"
                  : data.paymentStatus === "Refunded"
                    ? "text-success"
                    : (data.paymentStatus === "Cancelled" ||  data.paymentStatus=="Unrecognized")
                      ? "text-danger"
                        : "text-danger"}` } style={{fontWeight:'bold'}}>
            {" "}
            {data.paymentStatus === "Processing"
              ? i18n.t("Processing")
              : data.paymentStatus === "Completed"
                ? i18n.t("Completed")
                : data.paymentStatus === "Cancelled"
                  ? i18n.t("Cancelled")
                    : data.paymentStatus === "Pending" 
                      ? i18n.t("Pending")
                        : data.paymentStatus === "Refunded"
                          ? i18n.t("Refund Successful")
                            : data.paymentStatus === "UnderAnalysis"
                              ? i18n.t("Under Analysis")
                                :data.paymentStatus=="Unrecognized"
                                ?i18n.t("Unrecognized")
                                : i18n.t("Failed")}{" "}
          </p>{" "}
          {
            (config.applicationTypeId==19 && data.paymentStatus == "Completed") &&
            <div className="tax-wrapper">
              <div style={{width:'80%'}}>
                <p >CGST @02.50% </p>
                <p >SGST @02.50% </p>
              </div>
              <div>
              <span>₹{data.CGSTAmount}</span>
              <span>₹{data.SGSTAmount}</span>
              </div>
            </div>
          }
        
      </div>{" "}
      
    </li>
  );
};

PurchaseHistoryList.propTypes = {
  data: PropTypes.object,
};

export default PurchaseHistoryList;
