import i18next from 'i18next';
import React, { Component } from 'react'
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../components/button';
import FooterNav from '../components/FooterNav/FooterNav';
import Heading from '../containers/Heading/Heading';
import { cdsConfig, config } from '../_config';
import { history } from '../_helpers';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData, encryptData } from '../_helpers/encryptData';
import CoolerPopup from '../components/Model-Popup/Popup';
import { ClipLoader } from 'react-spinners';

export default class editPermission extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | edit-permission`;
        CapturePage({hitType:"pageview",page:"/edit-permission"})
        
        var user = JSON.parse(decryptData(localStorage.getItem('user')));
        this.state = {
            smsMarketing: user.mailMarketing && user.mobileNumber ? true: false,
            mailMarketing: user.mailMarketing,
            openPopup:false
        }

        this.marketingChecked = this.marketingChecked.bind(this)
        this.SubmitPermission = this.SubmitPermission.bind(this)
    }

    marketingChecked(event){
        var user = JSON.parse(decryptData(localStorage.getItem('user')));
        const { name, checked } = event.target;
        this.setState({
            mailMarketing:checked,
            smsMarketing:checked && user.mobileNumber ? true: false
        })
    }

    async SubmitPermission(){
        this.setState({
            isLoading:true
        })
        const cdsEnabled = localStorage.getItem('cdsEnabled')
        if(config.isLatamCountry && cdsEnabled=='true'){
            try {
                const sdk = await window.tcccLogin.LoginSdk(cdsConfig);
                await sdk.isSDKReady();
                window.sdk = sdk;
                const headers = {
                    "Content-Type": "application/json",
                };
                const method = "POST";
                const domain = "https://frontend.gamma.latam.gcds.coke.com/v2";
                const endPoint = "/consumers/update";
                const body = {
                    consents: `basicProcessing${this.state.mailMarketing?',marketing,personalization':''}`, //
                    originClientId: cdsConfig.clientID // This is the clientID that you used to initialize the SDK
                };
                
                const data = await window.sdk.createAuthRequest({
                    method,
                    domain,
                    endPoint,
                    headers,
                    body
                });
                if(data.success){
                    this.savePermission();
                }
            } catch (error) {
                console.log(error)
                this.setState({openPopup:true,isLoading:false})
            } 
        }
        else{
            this.savePermission()
        }  
    }

    savePermission=()=>{
        var user = JSON.parse(decryptData(localStorage.getItem('user')));
        let sessionUId = sessionStorage.getItem("sessionId");
        let formData = new FormData();
        let that = this
        formData.append('authToken',user.authToken)
        formData.append('smsMarketing',that.state.smsMarketing)
        formData.append('mailMarketing',that.state.mailMarketing)
        formData.append('sessionUId',sessionUId)
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        fetch(`${config.envUrl}VendingAppApi/updateProfile`,requestOptions)
        .then(function (response) {
            return response.json();
        })
        .catch(function (err) {
            console.log(err)
            that.setState({openPopup:true,isLoading:false})
        })
        .then(function (body) {
            if(body.success && body.code==5007){
                user.smsMarketing = that.state.smsMarketing
                user.mailMarketing = that.state.mailMarketing
                localStorage.setItem('user',encryptData(JSON.stringify(user)))
                history.push({pathname:'/scanButton',state:'back'})
            }
            that.setState({openPopup:false,isLoading:false})
        })
    }

    closingPopup=()=>{
        this.setState({
            openPopup:false
        })
    }
    render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        
        return (
            <div className="h-100">        
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                            <div className="col-12">
                                <Heading title={i18next.t("Nav_EditPermission")} firstIcon={true} secondIcon={false} backLink="/ScanButton" LinkIcon="back"/> 
                          
                                <div className="mt-3">
                                    <div className="row">  
                                        <div className='col-10'>
                                            <label className="text-black" htmlFor="subscription">
                                            {
                                                config.applicationTypeId==17 ?
                                                i18next.t("I hereby give my consent to receive direct marketing of Coke & Go promotions and all products that are marketed by Coca-Cola Africa (Proprietary) Limited whose details are found on the Terms of Use, by means of email. By clicking on this box, I confirm my direct marketing consent.")
                                                :
                                                <Trans i18nKey="SignUpCheckBox2" components={[<LinkText href={config.marketingTerms} />]} />
                                            }
                                            </label>
                                        </div>                                      
                                        
                                        <div className='col-2 pt-2'>
                                            <input
                                            type="checkbox"
                                            className=""
                                            id="edit-permission-subscription"
                                            name="mailMarketing"
                                            onChange={this.marketingChecked}
                                            checked={this.state.mailMarketing}
                                            />
                                            <label htmlFor="edit-permission-subscription"></label> 
                                        </div>
                                        {/* <div className="col-12">
                                            <input
                                                type="checkbox"
                                                className="mt-2"
                                                id="smsMarkingSMS"
                                                name="smsMarketing"
                                                onChange={this.marketingChecked}
                                                checked={this.state.smsMarketing}
                                                disabled={this.state.smsMarketingDisable}
                                                />
                                            <label className=" smsMarkingSMS" htmlFor="smsMarkingSMS">
                                                <p className="mb-0" style={{fontWeight:'bold'}}><Trans i18nKey="smsMarketingMsg"></Trans></p>
                                            </label>
                                        </div> */}
                                        <div className="col-12">
                                            <div className="text-center py-2">
                                                <Button size="large" btnType="button" clickHandler={this.SubmitPermission} text={i18next.t('Done')} className="btn mt-3 btn-theme-dark" />
                                                {/* <Button
                                                    type="submit"
                                                    text={i18next.t("Done")}
                                                    look="checked"
                                                    btnStyle="Red"
                                                    clickHandler={this.SubmitPermission}
                                                /> */}
                                            </div>{" "}
                                        </div>{" "}
                                    </div>
                                </div>
                                <FooterNav /> 
                            </div>
                        </div>
                    </div>
                </div>
                <CoolerPopup
                    openPopup={this.state.openPopup}
                    closePopup={this.closingPopup}
                    message={i18next.t("CommonError")}
                    imagetype={"error"}
                />
                <div className="spinner" style={{display:this.state.isLoading ? 'block' : 'none'}}>
                    <div style={style}>
                        <ClipLoader
                        size={50}
                        color={"#000000"}
                        loading={this.state.isLoading ? true : false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
function LinkText({ href, children }) {
    return <a href={href}><b>{children}</b></a>
}