import React from "react";
import "./style.css";

class ManualPopup extends React.Component {
  render() {
    return (
      <div className="popup">
        <div className="modal-content">
          <div className="modal-body">
            <span>{this.props.text}</span>
            <br />
            <span className="modal-footer">
              <button
                onClick={this.props.closePopup}
                className="mr-auto btn btn-danger"
              >
                OK
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ManualPopup;
