import React, { Component } from "react";
import ChooseProductList from "../../components/ChooseProductList/ChooseProductList";
import "./ChooseProductListing.scss";
import { productService } from "../../_services";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import { productActions } from "../../_actions";
import { decryptData } from "../../_helpers/encryptData";

class ProductListing extends Component {
  constructor(props) {
    super(props);
    if (
      this.props.products.products &&
      this.props.products.products.data[0].products
    ) {
      this.state = {
        chooseProducts: this.props.products.products.data[0].products,
        chosenFetched: true,
        addedProducts: [],
      };
    } else {
      this.state = {
        chooseProducts: [],
        chosenFetched: false,
        addedProducts: [],
      };
    }

    var userData = JSON.parse(decryptData(localStorage.getItem("user")));
    var qrcode = decryptData(localStorage.getItem("qrcode"));
    var paramObj = {
      authToken: userData.authToken,
      userId: userData.userId,
      macAddress: qrcode,
    };
    if (!this.props.products.products) {
      //fetch products when its not there
      this.props.getAllProducts(paramObj);
    }
  }

  componentDidUpdate(previousProps, previousState) {
    //products received now do rest as it is
    if (this.props.products.products && this.props.products.products.success) {
      if (previousProps.products.products !== this.props.products.products)
        this.setState({
          chooseProducts: this.props.products.products.data[0].products,
          chosenFetched: true,
          addedProducts: [],
        });
    }
  }

  incrementCount = (prodId) => {
    this.props.incrementNumber(prodId);
  };
  decrementCount = (prodId) => {
    this.props.decrementNumber(prodId);
  };
  onProductSelect = (e, bool) => {
    this.props.onClickCheck(
      this.state.chooseProducts.filter((fil) => fil.productId === e),
      bool
    );
  };

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };

    return (
      <div className="purchaseHistoryData">
        <div style={style}>
          <ClipLoader
            size={50}
            color={"#FF0000"}
            loading={this.state.chosenFetched ? false : true}
          />
        </div>
        <div className="history-sec-box">
          <div className="row m-0">
            <div className="col-11 offset-1">
              <ul className="list-box">
                {this.state.chooseProducts &&
                  this.state.chooseProducts.map((single, key) => {
                    return (
                      <ChooseProductList
                        data={single}
                        key={key}
                        initialcount={0}
                        incrementFun={this.incrementCount}
                        decrementFun={this.decrementCount}
                        sliderClassName="list-boxs"
                        onProductSelect={this.onProductSelect}
                      />
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { products } = state;
  return {
    products,
  };
}

const actionCreators = {
  getAllProducts: productActions.getAllProducts,
};

const connectedChooseProductListingPage = connect(
  mapState,
  actionCreators
)(ProductListing);
export { connectedChooseProductListingPage as ProductListing };
