import { Modal } from 'antd'
import i18next from 'i18next'
import React from 'react'
import OTPInput from 'react-otp-input'
import Button from '../button'

const CardValidation = (props) => {
    const {value,onChange,onCancel,showCardValidation,verifyCVV,text} = props
    let checkCVVLength=value.toString()
  return (
    <>
        <Modal open={showCardValidation} footer={false} centered className='CardValidation'  closable={false} onCancel={onCancel}>
            <div className='text-center'>
                <p>{i18next.t(text)}</p>
                
                <OTPInput
                    value={value}
                    onChange={onChange}
                    containerStyle={{justifyContent:'center'}}
                    inputType='number'
                    numInputs={3}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => <input {...props} />}
                />
                <Button size="large" btnType="button" text={i18next.t('Submit')} className="btn mt-3 btn-theme-dark" clickHandler={verifyCVV} disabled={checkCVVLength.length==3?false:true}/>
            </div>
        </Modal>
    </>
  )
}

export default CardValidation