import {
    purchaseHistoryConstants
} from '../_constants';
import {
    purchaseHistoryService
} from '../_services';

export const purchaseHistoryActions = {
    getPurchaseHistory
};

function getPurchaseHistory(products) {
    return dispatch => {
        dispatch(request());
        purchaseHistoryService.getPurchaseHistory(products)
            .then(
                products => dispatch(success(products)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {
            type: purchaseHistoryConstants.PURCHASEHISTORY_REQUEST
        }
    }

    function success(products) {
        return {
            type: purchaseHistoryConstants.PURCHASEHISTORY_REQUEST_SUCCESS,
            products
        }
    }

    function failure(error) {
        return {
            type: purchaseHistoryConstants.PURCHASEHISTORY_FAILURE,
            error
        }
    }
}