import React, { Component } from 'react';
import i18n from "i18next";
import validator from 'validator';
import { isAndroid, isIOS } from "react-device-detector";
import { Modal, Button} from 'antd';
import {config} from "../_config/index";
import { ClipLoader } from "react-spinners";
import { history } from "../_helpers";
const modal = Modal;

class HurryForm extends Component {
  constructor(props) {
    document.title=`Coke & Go US | Hurry Form`;
    super(props);
    this.state = {
      firstName: '',
      email: '',
      acceptedTerms: false,
      isInvalidEmail:false,
      isInvalidFirstName: false,
      isLoading: false,
      showPopup: false,
      popupmsg: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    let isInvalidEmail = this.state.isInvalidEmail;
    let isInvalidFirstName = this.state.isInvalidFirstName;

    if (name === 'email') {
      isInvalidEmail = !validator.isEmail(value);
    }

    if (name === 'firstName') {
      if (/[^A-Za-z\s]/.test(value)) {
        return;
      }
    }

    this.setState({
      [name]: type === 'checkbox' ? checked : value,
      isInvalidEmail: name === 'email' ? isInvalidEmail : this.state.isInvalidEmail,
      isInvalidFirstName: name === 'firstName' ? isInvalidFirstName : this.state.isInvalidFirstName
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, email} = this.state;
    const trimmedFirstName = firstName.trim();
    const trimmedEmail = email.trim();

    const params = new URLSearchParams({
      primaryEmail: trimmedEmail,
      applicationTypeId: 8,
      firstName: trimmedFirstName,
      platform: 'web',
      redirectURL: config.baseURL+"/NewSignUp"
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: params.toString()
    };

    this.setState({ isLoading: true });

    fetch(`${config.envUrl}VendingAppApi/userRegistrationV3`, requestOptions)
      .then(response => response.json())
      .then(data => {
        let msg = '';
        if (data.success && (data.code === 5002 || data.code === 5067)) {
          this.RedirectoEmailSent()
        } else {
          switch (data.code) {
            case 15487:
              msg = i18n.t("Email Id already registered.");
              break;
            case 1523:
              msg = i18n.t("Email has not send successful.");
              break;
            case 503:
              msg = i18n.t("Email Id already registered.");
              break;
            case 422:
              msg = i18n.t("Missing Parameters");
              break;
            default:
              msg = i18n.t("CommunicationError");
          }
          this.setState({
            showPopup: true,
            popupmsg: msg,
          });
        }
        this.setState({ isLoading: false });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          isLoading: false,
          showPopup: true,
          popupmsg: i18n.t("CommunicationError"),
        });
      });
  };

  handleClick = () => {    
    if (isAndroid) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.ebest.vending.cocaColaUS";
    } else if (isIOS) {
      window.location.href = "https://apps.apple.com/in/app/coke-go/id1594145032";
    } else {
      window.location.href = "https://play.google.com/store/apps/details?id=com.ebest.vending.cocaColaUS";
    }
  };

  RedirectoEmailSent(){
    const {firstName, email} = this.state
    history.push({
      pathname: '/emailsent',
      state: { firstName, email }
    });
  }


  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    const { firstName, email, acceptedTerms, showPopup, isLoading, popupmsg, isInvalidEmail, isInvalidFirstName  } = this.state;
    const isFormValid = firstName && email && acceptedTerms && !isInvalidEmail && !isInvalidFirstName;

    return (
      <div className='mainarea'>
        <div className='container sub_area text-center'>
          <div className='download_area'>
            <h5 className='text-area m-0'>Download</h5>
            <h5 className='text-area m-0'>Coke&Go app to enjoy your first</h5>
            <h5 className='text-area m-0'>beverage for free</h5>
          </div>

          <button className='btn download-button w-100 text-white' onClick={this.handleClick}>
            Download
          </button>
        </div>
        <div className="separator">
          <span>OR</span>
        </div>
        <div className="hurry-text">
          <h5 className='m-0'>No worries</h5>
          <p style={{fontSize: '10.5px', marginTop: '3px', marginBottom: '0px'}}>The following will just take you 30 sec</p>
        </div>
        <section className="d-flex flex-column align-items-center justify-content-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10 d-flex flex-column align-items-center justify-content-center p-0">
                <div className="col-12 p-0">
                  <form onSubmit={this.handleSubmit} className="w-100 mt-3" autoComplete="off">
                    <div className="form-groupus">
                      <label htmlFor="firstname"><h5>{i18n.t("FirstName") + "*"}</h5></label>
                      <input
                        id="firstname"
                        type="text"
                        className="place-hold shadow-none border-radius-none pl-0"
                        name="firstName"
                        placeholder={i18n.t("FirstName")}
                        value={firstName}
                        onChange={this.handleChange}
                        aria-label="John"
                        aria-describedby="basic-addon2"
                        maxLength="50"
                      />
                    </div>
                    <div className="form-email-group">
                      <label htmlFor="email"><h5>{i18n.t("Email") + "*"}</h5></label>
                      <input
                        id="email"
                        type="email"
                        className="place-hold shadow-none border-radius-none pl-0"
                        name="email"
                        placeholder={i18n.t("EnterEmailID")}
                        value={email}
                        onChange={this.handleChange}
                        aria-label="Email"
                        aria-describedby="basic-addon2"
                      />
                    </div>
                    {
                      isInvalidEmail &&              
                      <label className="text-danger" style={{margin: 0, fontWeight: '600', fontSize: '13px'}}>{i18n.t("Please enter a valid Email Address")}</label>
                    } 
                    <p className="verification-text">
                      We will send a verification link to that email to verify you own it.
                    </p>
                    <div className="form-checkus">
                      <input
                        id="terms"
                        type="checkbox"
                        className="policyus-checkbox"
                        name="acceptedTerms"
                        checked={acceptedTerms}
                        onChange={this.handleChange}
                      />
                      <label className="form-check-labelus" htmlFor="terms">
                        I accept Coca-Cola's <a style={{color: '#000000'}} href="https://us.coca-cola.com/privacy-policy">privacy policy</a> and <a style={{color: '#000000'}} href="https://us.coca-cola.com/terms-of-use">terms of use and sale</a> *
                      </label>
                    </div>
                    <button type="submit" className="btn submit-button w-100 text-white" disabled={!isFormValid}>Continue</button>
                  </form>
                </div>
              </div>
            </div>
            <Modal
              open={showPopup}
              footer={false}
              headers={false}
              title={false}
              closable={false}
              centered
              style={{ maxWidth: '200px' }}
              bodyStyle={{ padding: '15px'}}
            >
              <div style={{ textAlign: 'center'}}>
                <p style={{fontWeight: '500', marginBottom: '15px'}}>{popupmsg}</p>
                <Button style={{ borderRadius: '25px', width:'75%', outline:'none', borderColor: '#000000', textDecorationColor: '#000000' }} onClick={() => this.setState({ showPopup: false })}>Ok</Button>
              </div>
            </Modal>
          </div>
        </section>
        <div className="spinner" style={{display:this.state && isLoading ? 'block' : 'none'}}>
          <div style={style}>
            <ClipLoader
              size={50}
              color={"#FF0000"}
              loading={this.state && isLoading ? true : false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default HurryForm;
