import PropTypes from "prop-types";
import React from "react";
import styles from "./button.scss";
import ArrowBack from "./../../assets/images/LogIn/Arrow-Right.svg";

const Button = ({ size, text, btnType, clickHandler, disabled, className }) => {
  if(size=="large"){
    return (
      <button 
        className={className?className:"btn btn-theme-lg"}
        type={btnType} 
        disabled={disabled}
        onClick={clickHandler}
      >
        {text}
      </button>
    )
  }
  if(size=="small"){
    return (
      <button 
        className="btn btn-theme-sm" 
        type={btnType} 
        disabled={disabled}
      >
        {text}
      </button>
    )
  }
  if(size=="popup-btn"){
    return (
      <button 
        className="btn popup-btn" 
        type={btnType} 
        disabled={disabled}
        onClick={clickHandler}
      >
        {text}
      </button>
    )
  }
  if(size=="popup-btn-sm"){
    return (
      <button 
        className={`btn popup-btn-sm ${className}`} 
        type={btnType} 
        disabled={disabled}
        onClick={clickHandler}
      >
        {text}
      </button>
    )
  }

  return "";
};

Button.propTypes = {
  look: PropTypes.string,
  text: PropTypes.string,
  btnStyle: PropTypes.string,
  url: PropTypes.string,
};

export default Button;
