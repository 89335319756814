import React from 'react';
import WebSDK from "@loginid/sdk"; 
import { config } from '../_config';
export const BioSDK =  new WebSDK(config.bioMetric.baseURL,config.bioMetric.clientID);
export async function isBiometricSupported() {
    try {
        let supported = await BioSDK.isFido2Supported()
        return supported;
      } catch (error) {
        return false;
      }
}