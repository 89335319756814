import i18next from 'i18next';
import React, {useEffect, useState} from 'react'
import useModal from "react-hooks-use-modal";
import "./SaferpayWallet.scss"
import closeIcon from "../../assets/images/icons/close.png";
import creditCardIcon from "../../assets/images/paymentgateway/credit-card.png";
import gPayIcon from "../../assets/images/paymentgateway/googlePay.png";
import applePayIcon from "../../assets/images/paymentgateway/apple-pay.png";
import appleBlackLogo from "../../assets/images/paymentgateway/apple-black-logo.png";
import googleIcon from "../../assets/images/paymentgateway/google.png";
import { config } from '../../_config';
import { Link } from 'react-router-dom';
import { isIOS, osVersion } from "react-device-detect";
import { Trans } from 'react-i18next';

const SaferpayWallet = (props) => {
    const [Modal, open, close, isOpen] = useModal();
    const {paymentMethod} = props
    const [EnabledPG,SetEnablePG]=useState({
        googlePay:true,
        applePay:true,
        creditCard:true
    })
    useEffect(() => {
        if(props.isModalOpen && !isOpen){
            open();
        }
        if(!props.isModalOpen){
            close();
        }
    })
    useEffect(()=>{
        let googlePay = paymentMethod.filter(item=>item.code=='GOOGLEPAY')
        let applePay = paymentMethod.filter(item=>item.code=='APPLEPAY')
        let creditCard = paymentMethod.filter(item=>item.code=='CREDITCARD')
        SetEnablePG({
            googlePay:googlePay.length>0?true:false,
            applePay:applePay.length>0?true:false,
            creditCard:creditCard.length>0?true:false
        })
    },[paymentMethod])
    let lang = config.langCode
    let options = { style: 'currency', currency: props.currencyCode?props.currencyCode:"USD"};
    let preAuthAmount = new Intl.NumberFormat(lang, options).format(props.preAuthAmt)

    return (
        <div className="model">
            <div className="PopupCenter">
                <Modal >
                    <div className="popup-box">
                        <div className="whitebody-popup saferpayModal">
                            <div className='row mt-2'>
                                <div className='col-9 title'>
                                    <h4 className='text-left'>{props.title}</h4>
                                </div>
                                <div className='col-3 text-right' style={{marginTop:'-5px'}}>
                                    <span className='saferclose' onClick={props.closeWalletModal}><img src={closeIcon} height={15}/></span>
                                </div>
                            </div>
                            <div className='paymentMethodList text-left'>
                                {
                                EnabledPG.creditCard && 
                                
                                <div className='mt-3'>
                                    <div className='addCreditCard'>
                                        <label htmlFor='creditCard'> 
                                            <span>
                                                <img src={creditCardIcon}/>
                                                <p className='mb-0'>{i18next.t("Pay with Credit Card")}</p>
                                            </span>
                                            <input 
                                                id="creditCard"
                                                type="radio" name="walletType" value="creditCard"
                                                checked={props.paymentType=="creditCard"?true:false}
                                                onChange={props.handlePaymentChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                }
                                {
                                (EnabledPG.applePay || EnabledPG.googlePay) &&
                                <>
                                    <p className='text-underline my-3' style={{fontSize: '17px', fontWeight: '600'}}>{i18next.t("Add Wallets")}</p>                                
                                
                                {
                                    EnabledPG.googlePay &&
                                    <div className='mt-3'>
                                        <div className='addCreditCard'>
                                            <label htmlFor='googlePay'>
                                                <span>
                                                    <img src={gPayIcon}/>
                                                    <p className='mb-0'><Trans i18nKey="Paywithgooglepay" components={[<img src={googleIcon} style={{height: '20px', marginTop: '-5px'}}/>]}>Pay with pay</Trans></p>
                                                    
                                                </span>
                                                <input 
                                                    id='googlePay'
                                                    type="radio" name="walletType" value="GOOGLEPAY" 
                                                    onChange={props.handlePaymentChange}
                                                    checked={props.paymentType=="GOOGLEPAY"?true:false}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                }
                                {(isIOS && EnabledPG.applePay) &&
                                <div className='mt-3'>
                                    <div className='addCreditCard'>
                                        <label htmlFor='applePay'>
                                            <span>
                                                <img src={applePayIcon}/>
                                                <p className='mb-0'><Trans i18nKey="Paywithapplepay" components={[<img src={appleBlackLogo} style={{height: '20px', marginTop: '-5px'}}/>]}>Pay with pay</Trans></p>
                                            </span>
                                            <input   
                                                id='applePay'
                                                type="radio" name="walletType" value="APPLEPAY" 
                                                onChange={props.handlePaymentChange}
                                                checked={props.paymentType=="APPLEPAY"?true:false}
                                            />
                                        </label>
                                    </div>
                                </div>
                                }   
                                </>
                                }
                                {
                                    (props.paymentType == "creditCard" && props.paywithCard=="") ?
                                    <div className='mt-3'>
                                        <button onClick={props.PayViaWallet} className="w-100 btn btn-addcreditcard" >{i18next.t('Pay')}</button>
                                    </div>
                                    :
                                    props.paymentType == "creditCard" ?
                                    <div className='mt-3'>
                                        <Link to={props.paywithCard} className="w-100 btn btn-addcreditcard" >{i18next.t('Pay')}</Link>
                                    </div>
                                    :null

                                }

                                {
                                    (props.paymentType=="GOOGLEPAY" || props.paymentType=="APPLEPAY") &&
                                    <div>
                                        { props.isPreAuthEnable && 
                                        <div className='paymentMethodClass mt-3 p-3'>
                                            <div>
                                                <span className='payment-currency'>{props.currencyCode}</span>
                                                <span className='payment-amount'>{preAuthAmount}</span>
                                            </div>
                                            {/* <div className='purchase-history'>
                                                <span>Purchase History</span>
                                                <span style={{marginLeft: '5px'}}>#123456</span>
                                            </div> */}
                                            {/* <hr/> */}
                                            {/* <div>
                                                <p className='payment-desc-heading'>Six Payment Services Gmbh</p>
                                                <p className='payment-desc-content'>
                                                    Langenhorner Chaussee, 92-94
                                                    22425 Hamburg Germany test@mail.de +41 
                                                    123 345 567, https://testwebsite.com
                                                </p>
                                            </div> */}

                                        </div>
                                        }

                                        <div className='mt-3'>
                                            <button onClick={props.PayViaWallet} className="w-100 btn btn-addcreditcard" >{i18next.t('Pay')}</button>
                                        </div>
                                    </div>
                                    
                                }

                                {
                                    props.googlePayService &&
                                    <div>
                                        <div className='paymentMethodClass mt-3 p-3'>
                                            <div>
                                                <span className='payment-currency'>EUR</span>
                                                <span className='payment-amount'>$123.45</span>
                                            </div>
                                            <div className='purchase-history'>
                                                <span>Purchase History</span>
                                                <span style={{marginLeft: '5px'}}>#123456</span>
                                            </div>
                                            {/* <hr/>
                                            <div>
                                                <p className='payment-desc-heading'>Six Payment Services Gmbh</p>
                                                <p className='payment-desc-content'>
                                                    Langenhorner Chaussee, 92-94
                                                    22425 Hamburg Germany test@mail.de +41 
                                                    123 345 567, https://testwebsite.com
                                                </p>
                                            </div> */}

                                        </div>

                                        <div className='mt-3'>
                                            <button type="submit" className="w-100 btn btn-addcreditcard" >{i18next.t('Pay')}</button>
                                        </div>
                                    </div>
                                    
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default SaferpayWallet