import React, { Component } from "react";
import scan from "../../_assets/images/icons/scan.png";
import home from "../../_assets/images/icons/home.png";
import reward from "../../_assets/images/icons/rewards.png";
import profile from "../../_assets/images/icons/profile.png";
import i18next from "i18next";
import "./footernav.scss";
import Navigation from "../navigation";
import { Link } from "react-router-dom";
import { history } from "../../_helpers";
import { config } from "../../_config";
import ModelPopup from "../Model-Popup/Popup";
import { decryptData } from "../../_helpers/encryptData";
class FooterNav extends Component {
    constructor(props){
        super(props);
        this.state={
            isMenuOpen:props.isMenuOpen?true:false,
            isPendingAvailable:props.isPending?true:false,
            ShowPopUp:false,
            popupmsg:''
        }
        this.openMenu = this.openMenu.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.openScanner = this.openScanner.bind(this);
        this.handleCloseShowPopUp = this.handleCloseShowPopUp.bind(this);
        this.ManageSaferPay = this.ManageSaferPay.bind(this);
    }
    openMenu(){
        this.setState((prevState)=>({
            isMenuOpen:!prevState.isMenuOpen
        }))
    }
    handleClick(){
        if(this.state.isMenuOpen && window.location.pathname=='/ScanButton'){
            this.setState((prevState)=>({
                isMenuOpen:!prevState.isMenuOpen
            }))
        }
        else{
            history.push('/ScanButton')
        }
    }

    openScanner() {
        // let checkCard = localStorage.getItem('cardlist')
        if(navigator.onLine){
            history.push({
                pathname: "/Scanner"
            });
        //   if(!checkCard){
        //     history.push({
        //       pathname: "/Scanner"
        //     });
        //   }
        //   else{
        //     this.setState({
        //       ShowPopUp:true,
        //       popupmsg:i18next.t(config.applicationTypeId==19?"Payment method is not added, Please link your Paytm Wallet to open the cooler":'PlzAddCard')
        //     })
        //   }
        }
        else{ 
          this.setState({
            isOffline:true
          })     
          
        }
    }
    
    handleCloseShowPopUp(){
        if(config.paymentGateway==1 || config.paymentGateway==3  || config.paymentGateway==9){
          sessionStorage.setItem('scanbutton','true');
          history.push("/AddCard");
        }
        else if(config.paymentGateway==2){
          this.ManageSaferPay();
        }
        else if(config.paymentGateway==4){
          history.push({pathname:'/link-with-paytm',state:"ScanButton"});
        }
    }
    ManageSaferPay(){
        let that = this;
        this.setState({
          isLoading:true,
          ShowPopUp:false
        })
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let authToken = user.authToken;
        const applicationTypeId = config.applicationTypeId;
        const returnSuccessUrl = config.baseURL+'/AddCard';
        let sessionUId = sessionStorage.getItem('sessionId');
        let RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({authToken,applicationTypeId, returnSuccessUrl, sessionUId})
        }
    
        fetch(`${config.envUrl}VendingAppApi/addPayment`, RequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            if(result.success && result.returnCode==5041){
                sessionStorage.setItem('token',result.token);
                sessionStorage.setItem('scanbutton','true');
                window.location = result.redirectURL;
            }
            else{
                if(result.code==401){
                    that.setState({
                        isLoading:false,
                        errorCode:result.code,
                        imagetype:'error',
                        ShowPopUp:true,
                        popupmsg:result.message
                    })
                }
                else{
                    that.setState({
                        isLoading:false,
                        errorCode:result.code,
                        imagetype:'error',
                        ShowPopUp:true,
                        popupmsg:result.message
                    })
                }
            }
        }).catch((error) =>{
            that.setState({
                isLoading:false,
                imagetype:'error',
                ShowPopUp:true,
                popupmsg:i18next.t('CommonError')
            })
        });
    }
    render(){
        let rewardData = JSON.parse(decryptData(localStorage.getItem('isRewardAvailable')));
        let rewards = JSON.parse(decryptData(localStorage.getItem('reward')));
        let qrcode = decryptData(localStorage.getItem('qrcode'));
        return (
            <>
                <div className="footer-nav">  
                    <table>
                        <tbody>
                            {
                                (qrcode && (rewardData.length>0 || (rewards.success && ( rewards.rewardPointOffers.length>0 || rewards.OfferCoupon.length>0 || rewards.promotionalOffer.length>0)))) ?
                                <tr>
                                    <td onClick={this.handleClick} width="25%">
                                        <img src={home} />
                                        <p>{i18next.t('homeButton')}</p>
                                    </td>
                                    <td width="25%" onClick={this.openScanner}>
                                        <img src={scan} />
                                        <p>{i18next.t('MenuScan')}</p> 
                                    </td>
                                    <td width="25%">
                                        <Link to="/offer">
                                        <img src={reward} />
                                        <p>{i18next.t('MenuReward')}</p> 
                                        </Link>
                                    </td>
                                    <td onClick={this.openMenu} width="25%">
                                        <img src={profile} />
                                        <p>{i18next.t('MenuProfile')}</p>
                                    </td>
                                </tr>
                                :
                                <tr>
                                    <td onClick={this.handleClick} width="33%">
                                        <img src={home} />
                                        <p>{i18next.t('homeButton')}</p>
                                    </td>
                                    <td width="33%" onClick={this.openScanner}>
                                        <img src={scan} />
                                        <p>{i18next.t('MenuScan')}</p> 
                                    </td>
                                    <td onClick={this.openMenu} width="33%">
                                        <img src={profile} />
                                        <p>{i18next.t('MenuProfile')}</p>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>  
                </div>
                {
                    this.state.ShowPopUp && 
                    <ModelPopup
                        openPopup={this.state.ShowPopUp}
                        closePopup={this.handleCloseShowPopUp}
                        message={i18next.t(this.state.popupmsg)}
                        imagetype="error"
                            
                    />
                }
                <Navigation isMenuOpen={this.state.isMenuOpen} toggleMenu={this.openMenu} />
            </>
        )
    }
}

export default FooterNav;