import React, { Component, useEffect } from "react";
import Button from "../button";
import error from "../../assets/images/icons/error.png";
import done from "../../assets/images/icons/success.png";
import verify from "../../assets/images/icons/verify.png";
import "./style.scss";
import useModal from "react-hooks-use-modal";

const ConfirmPopup = openPopup => {
  const [Modal, open, close, isOpen] = useModal();

  useEffect(() => {
    if (openPopup.openPopup && !isOpen) {
      open();
    }
    if(!openPopup.openPopup){
      close();
    }
  });

const style = {fontSize:'16px'}  

  return (
    
    <div className="model">
      <div className="PopupCenter">
        <Modal >
          <div className="popup-box">
            <div className="whitebody-popup">
              <div className="">
                <table width="100%">
                  <tbody>
                    <tr>
                      {
                        openPopup.image ?
                        <td scope="col" width="30%" className="text-center" valign="top">
                          <img src={openPopup.image} className="img-fluid rounded-start" alt="..." />
                        </td>:null
                      }
                      
                      <td className={`popup-msg ${!openPopup.image?'text-center':''}`} style={!openPopup.image?style:null}>
                        {openPopup.message}
                      </td>
                    </tr>                    
                  </tbody>
                </table>

                <div className={`text-center ${!openPopup.image?'mt-3':''}`}>
                    <Button size="popup-btn-sm" btnType="button" disabled={false} text={openPopup.CancelText}
                      clickHandler={openPopup.handleCancelClick}  />

                    <Button size="popup-btn-sm" className="ml-4" btnType="button" disabled={false} text={openPopup.EventText} clickHandler={openPopup.handleEventClick} />
                </div>
              </div>
              {/* <div className="popup-button">
                <button onClick={openPopup.handleCancelClick} className="btn btn-theme" style={{width:'120px',marginRight:'30px',borderRadius:'15px'}}>{openPopup.CancelText}</button>
                <button onClick={openPopup.handleEventClick} className="btn btn-theme" style={{width:'120px',borderRadius:'15px'}}>{openPopup.EventText}</button>
              </div> */}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ConfirmPopup;
