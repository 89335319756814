import React, { Component } from "react";
import "./NoLongerScreen.scss";
import { config } from '../../_config';
import { CaptureEvent, CapturePage } from "../../_helpers/googleAnalytics";

export default class NoLongerScreen extends Component {
  constructor(props){
    super(props);
    document.title=`Coke & Go ${config.countryName} | NoLongerScreen`;
    CapturePage({hitType:"pageview",page:"/NoLongerScreen"})    
        
  }
  render() {
    return (
      <body className="NoLongerBody">
        <div className="">
          <div className="wrapper">
            <div className="box">
              <h1 className="NoHeading" style={{visibility:'hidden'}}>401</h1>
              <p className="Para">The link is no longer valid on the screen</p>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
