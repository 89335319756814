import React from 'react';
import PropTypes from "prop-types";
import DoorPopup from '../../components/DoorPopup/DoorPopup'
import './PopUpModel.scss';


const PopUpModel = ({ Type }) => {
    if(Type === "a"){
        return (
            <DoorPopup 
                Message="DoorOpen" 
                Desc1="No Product Selected" 
                br="Transition Fail" 
                ButtonText="Ok" 
                ButtonLink="/"
            /> 
        )
    }
    if(Type === "b"){
        return (
            <DoorPopup 
                Message="DoorOpen" 
                Desc1="Looks like you forget to close the door. Please close the door otherwise other user purchase amount will be deducted from your account" 
                ButtonText="Ok" 
                ButtonLink="/" 
            />
        )
    }
    if(Type === "c"){
        return (
            <DoorPopup
                Message="noproduct"
                Desc1="No Product Selected" 
                br="Transition Failed" 
                ButtonText="Ok" 
                ButtonLink="/"
                ButtonText2="Add Product Manually" 
                ButtonLink2="/ChooseProduct"
            />
        )
    }
    else{
        return (
            window.location.href='/DoorUnlocked'
        )
    }
}
PopUpModel.propTypes = {
    Type: PropTypes.elementType,
};

export default PopUpModel
