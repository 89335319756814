import React, { Component } from 'react'
import "./style.scss"
import moment from "moment";
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
export default class DiscountOffer extends Component {
    constructor(props){
        super(props);
    }
    checknth(d){
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
        }
    }
    render() {
        let month = moment(this.props.offer.toDate).format("MMMM")
        let year = moment(this.props.offer.toDate).format("Y")
        let d = moment(this.props.offer.toDate).format("DD");
        let day = this.checknth(d)
        let toDate = i18next.t(month) +' '+year
        let offertype = this.props.offertype;
        let offername = this.props.offer.offerName;
        let offerid = this.props.offer.offerId;
        if(this.props.offertype=='promotionalOffer'){
            return (
                <div className='offer'>
                    <h4>{i18next.t("PromotionalOfferTitle",{discountPercentage:this.props.offer.discountPercentage})}</h4>
                    <div className='offerDetail'>
                        <p><Trans i18nKey="PromotionalOfferDesc" components={[<sup/>]} values={{d:d,day:day,toDate:toDate,discountPercentage:this.props.offer.discountPercentage}} /></p>
                        {/* <p>{i18next.t("PromotionalOfferDesc",{d:d,day:day,toDate:toDate})}</p>
                        <p>You have {this.props.offer.discountPercentage}% OFF on Selected Products till {d}<sup>{day}</sup> {toDate}</p> */}
                        <Link to={`${process.env.PUBLIC_URL}/offer-detail/${offertype}/${offerid}`} className='btn btn-sm bg-black text-white'>{i18next.t("See Products")}</Link>
                    </div>
                </div>
            )
        }
        else if(this.props.offertype=='rewardPointOffers'){
            let offer = this.props.offer;
            let offertype = this.props.offertype;
            let offerName = offer.offerName;
            let fromMonth = moment(this.props.offer.fromDate).format("MMMM")
            let fyear = moment(this.props.offer.fromDate).format("Y")
            let fromd = moment(this.props.offer.fromDate).format("DD");
            let fromday = this.checknth(fromd)
            let fromDate = i18next.t(fromMonth) +' '+fyear;
            let productURL = '';
            if(offer.productList.length>0){
                const product = offer.productList.filter((item)=>item.isImageDefault);
                productURL = product.length>0?product[0].image:''
            }
            return (
                <div className='offer'>
                    <h4><Trans i18nKey="RewardOfferTitle" values={{rewardPoint: parseInt(offer.rewardPoints)}} /></h4>
                    <div className='rewardoffer'>
                        <div className='offerDetail'>
                            {productURL!=''?<img className="image_right_product" src={productURL} />:''}
                            <p className={productURL?"left70":""}><Trans i18nKey="RewardOfferDesc" components={[<sup/>]} values={{toDate:toDate,fromDate:fromDate,offerName:offerName,fromDay:fromday,fromD:fromd,toDay:day,toD:d}}/></p>
                            {/* <p>{i18next.t("PromotionalOfferDesc",{d:d,day:day,toDate:toDate})}</p>
                            <p>You have {this.props.offer.discountPercentage}% OFF on Selected Products till {d}<sup>{day}</sup> {toDate}</p> */}
                            <Link to={`${process.env.PUBLIC_URL}/offer-detail/${offertype}/${offerid}`} className='btn btn-sm bg-black text-white'>{i18next.t("See Products")}</Link>
                        </div>
                        
                    </div>
                </div>
            )
        }
        
    }
}
