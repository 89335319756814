import i18next from 'i18next'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Button from '../components/button'
import FooterNav from '../components/FooterNav/FooterNav'
import CoolerPopup from '../components/Model-Popup/Popup'
import Heading from '../containers/Heading/Heading'
import { config } from '../_config'
import { history } from '../_helpers'
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from '../_helpers/encryptData';
import { cardService } from '../_services/card.service'

export default class Welcome extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | welcome`;
        CapturePage({hitType:"pageview",page:"/welcome"})
        this.state = {
            isLoading:false,
            imagetype:'error',
            popupmsg:'',
            showpopup:false
        }
        this.checkPaymentGateway();
        this.addCard = this.addCard.bind(this)
        this.closingPopup = this.closingPopup.bind(this)
    }

    checkPaymentGateway = () => {
        cardService.getCardList().then(function(result){         
            if(result.success){
                sessionStorage.setItem('paymentGateway',result.paymentGateway)
            }
            else{
                if(result.code==401){
                    history.push("/logout")
                    this.setState({
                        isLoading:false,
                        errorCode:result.code,
                        imagetype:'error',
                        showpopup:true,
                        popupmsg:result.message
                    })
                }
                else{
                    this.setState({
                        isLoading:false,
                    })
                }
            }
        })
    }
    addCard(){
        let that = this;
        // for ceilo
        if(config.paymentGateway==1 || config.paymentGateway==3 || config.paymentGateway==6 || config.paymentGateway==9){
            sessionStorage.removeItem('scanbutton');
            history.push("/AddCard");
        }
        else if(config.paymentGateway==4){
            history.push('/link-with-paytm')
        }
        else if(config.paymentGateway==5){//Global Pay
            that.setState({isLoading:true})  
            const applicationTypeId = config.applicationTypeId;
            const redirectURL = config.envUrl+"VendingAppApi/getResponsefromHK"; //https://portal-qa.instagng.com/VendingAppApi/getResponsefromHK
            const returnURL = window.location.origin+"/payment-status"
            let user = JSON.parse(decryptData(localStorage.getItem("user")));
            let authToken = user.authToken;
            let sessionUId = sessionStorage.getItem("sessionId");
            let RequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({authToken,applicationTypeId, sessionUId, redirectURL,returnURL})
            }
            fetch(`${config.envUrl}VendingAppApi/getRequestParametersForHK`, RequestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result) => {
                if(result.success)
                {
                    sessionStorage.setItem('returnData',JSON.stringify(result.returnData));
                    history.push("/AddCard");
                }
                else{
                    that.setState({
                        isLoading:false,
                        imagetype:'error',
                        showpopup:true,
                        popupmsg:i18next.t('CommonError')
                    })
                }
            })
            .catch((e)=>{
                that.setState({
                    isLoading:false,
                    imagetype:'error',
                    showpopup:true,
                    popupmsg:i18next.t('CommonError')
                })
            })
        }
        else{
            that.setState({isLoading:true})            
            let user = JSON.parse(decryptData(localStorage.getItem("user")));
            let authToken = user.authToken;
            const applicationTypeId = config.applicationTypeId;
            const returnSuccessUrl = config.baseURL+'/AddCard';
            const returnFailUrl = config.baseURL+'/CardFail';
            let sessionUId = sessionStorage.getItem('sessionId');
            let RequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({authToken,applicationTypeId, returnSuccessUrl,returnFailUrl,sessionUId})
            }
        
            fetch(`${config.envUrl}VendingAppApi/addPayment`, RequestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result) => {
                if(result.success && result.returnCode==5041){
                    sessionStorage.setItem('token',result.token);
                    window.location = result.redirectURL;
                }
                else{
                    if(result.code==401){
                        that.setState({
                            isLoading:false,
                            errorCode:result.code,
                            imagetype:'error',
                            showpopup:true,
                            popupmsg:result.message
                        })
                    }
                    else{
                        that.setState({
                            isLoading:false,
                            errorCode:result.code,
                            imagetype:'error',
                            showpopup:true,
                            popupmsg:result.message
                        })
                    }
                }
            }).catch((error) =>{
                that.setState({
                    isLoading:false,
                    imagetype:'error',
                    showpopup:true,
                    popupmsg:i18next.t('CommonError')
                })
            });
        }
    }
    closingPopup(){
        this.setState({
            showpopup:false
        })
    }
  render() {
    return (
        <>
            <div className="">        
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="content">
                                <Heading title={i18next.t("Payment Method")} firstIcon={true} secondIcon={false} backLink={`${process.env.PUBLIC_URL + "/Scanbutton"}`} LinkIcon="back"/> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='mt-0'/>
            <div className="pb-4">        
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 p-0">
                            <div className="content p-0">                               
                                <div className="main-content text-center position-relative" >
                                    <div className='p-2 mt-3'>
                                        <h5 className='text-center'>{i18next.t("Anytime, Anywhere")}</h5>
                                        <p>{i18next.t("Experience the best of Coke & Go by adding payment mode to be able to purchase your favourite drinks.")}</p>
                                        <Button text={i18next.t("Add Payment Method")} size="large" btnType={'button'} className="btn bg-white btn-with-border mt-3" clickHandler={this.addCard}/>
                                    </div>
                                    <div className='divider-div'></div>
                                    {/* <div className='clear-both'></div> */}
                                    <div className='p-2 skip-section'>
                                        <h6 className='mb-4'><Link to="/ScanButton" className='text-underline text-black'>{i18next.t("Skip for now")}</Link></h6>
                                        
                                        <p>{i18next.t("Note – Payment info is mandatory for purchase.")}</p>
                                    </div>
                                </div>
                            </div>
                            <FooterNav />
                            { this.state.showpopup ?
                            <CoolerPopup
                                imagetype={this.state.imagetype}
                                color="Red"
                                openPopup={this.state.showpopup}
                                closePopup={this.closingPopup}
                                message={this.state.popupmsg}
                            /> : null }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
  }
}
