import React, { Component } from 'react';
import { ProfileCard } from "../../components/profile-card/profilecard";
import ProfileRewards from "../../components/Profile-Rewards/ProfileRewards";
import "./profileHeader.scss"

class ProfileHeader extends Component{
    
    render () {
      return (
          <div className="profileheader">
              <div className="row m-0">
                <div className="col-12">
                    <ProfileCard></ProfileCard>
                </div>
                <div className="col-4">
                    {/* <ProfileRewards></ProfileRewards> */}
                </div>
              </div>
          </div>
          
            
      );
    }
  }
   
  
  
export default ProfileHeader