import React from 'react'
import { config } from '../_config'

const LoyaltyTerms = () => {
  return (
    <div>
      <iframe src={config.loyaltyterms} style={{width:'100%', height: '100vh', overflow: 'scroll', border: 'none'}}>
        
      </iframe>
    </div>
  )
}

export default LoyaltyTerms
