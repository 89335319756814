import React, { Component } from "react";
import Heading from "../containers/Heading/Heading";
import i18n from "i18next";
import Popup from "../components/Model-Popup/Popup";
import { history } from "../_helpers";
import FooterNav from "../components/FooterNav/FooterNav";
import { Link } from "react-router-dom";
import { config } from '../_config';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";


export default class Receipt extends Component {
    constructor(props) {
        super(props);
        document.title=`Coke & Go ${config.countryName} | invoice-receipt`;
        CapturePage({hitType:"pageview",page:"/invoice-receipt"})    
        
        this.state = {
            receiptType: '',
            hasError:false,
            errorpopupmsg:'',
            orderId: props.location.state
        }
        this.handleChange = this.handleChange.bind(this)    
        this.handleOK = this.handleOK.bind(this)    
        this.closingerrorPopup = this.closingerrorPopup.bind(this)    
      }
      handleChange(event){
        var value = event.target.value
        this.setState({
            receiptType:value
        })
      }
      closingerrorPopup(){
        this.setState({
            hasError:false
        })
      }
      handleOK(){
          var {receiptType, orderId} = this.state
          if(receiptType==''){
            this.setState({
                hasError:true,
                errorpopupmsg:i18n.t("Please select receipt type")
            })
          }
          else{
            history.push({pathname:'receipt-type',state:{receiptType:receiptType,orderId:orderId}})
          }          
      }
      render() {
        return (
            <div className="h-100">        
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                            <div className="col-12 p-0">
                                <Heading title={i18n.t("Generate Invoice")} firstIcon={true} secondIcon={false} backLink={`/OrderSummary/${this.props.location.state}`} LinkIcon="back"/> 
                                <div className="">
                                    <ul className="generate_receipt">
                                        <li>
                                            <input type="radio" name="receipttype" id="firstradio" onChange={this.handleChange} value="SIMPLE"/>
                                            <label htmlFor="firstradio">{i18n.t('Boleta de venta simples')}</label>
                                        </li>
                                        <li>
                                            <input type="radio" name="receipttype" id="secondradio" onChange={this.handleChange} value="ELECTRONIC"/>
                                            <label htmlFor="secondradio">{i18n.t('Boleta de venta electronica')}</label>
                                        </li>
                                        <li>
                                            <input type="radio" name="receipttype" id="thirdradio" onChange={this.handleChange} value="FACTURA"/>
                                            <label htmlFor="thirdradio">{i18n.t('Factura')}</label>
                                        </li>
                                    </ul>
                                    <div className="row text-center mt-5">
                                        <div className="col-6">
                                            <button className="theme-btn" style={{width:'90%'}} onClick={this.handleOK}>{i18n.t('OK')}</button>
                                        </div>
                                        <div className="col-6">
                                            <Link to={`/OrderSummary/${this.props.location.state}`} className="text-dark">
                                                <button className="theme-btn" style={{width:'90%'}}>{i18n.t('Cancel')}</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <FooterNav />                                
                            </div>

                            <Popup
                            openPopup={this.state.hasError}
                            closePopup={this.closingerrorPopup}
                            message={this.state.errorpopupmsg}
                            imagetype={"error"}
                            color={"Red"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
      }
}
