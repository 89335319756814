import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { ProfileCard } from "../profile-card/profilecard";
import menu from "../../assets/images/icons/account.png";
import "./nav.scss";
import { Trans } from "react-i18next";
import { Modal, Button as Btn, Skeleton  } from 'antd';
import { history } from "../../_helpers";
import i18next from "i18next";
import { CaptureEvent, CapturePage } from "../../_helpers/googleAnalytics";

class Navigation extends Component {
  constructor(props){
    super(props);
    this.state ={
      isConfirmModalVisible:false,
      open:false
    }
    this.confirmlogout = this.confirmlogout.bind(this);
    this.logout = this.logout.bind(this);
    this.CloseModal = this.CloseModal.bind(this);
  }
  showSettings(event) {
    event.preventDefault();
  }
  confirmlogout(){
    CaptureEvent({
      category:"log out",
      action:"button clicked",
      label:"logOut"
    })
    this.setState({
      isConfirmModalVisible:true
    })
  }
  logout(){
    history.push("/Logout");
  }
  CloseModal(){
    this.setState({
      isConfirmModalVisible:false
    })
  }
  render() {
    let rewardData = JSON.parse(localStorage.getItem('reward'));
    return (
      <div className="navigation">
        <Menu customBurgerIcon={<img src={menu} />} isOpen={this.state.open}> 
          <ProfileCard />

          <Link to={process.env.PUBLIC_URL + "/Profile"} className="menu-item mt-4">
            <span className="menu-name">
              <Trans i18nKey="Profile">Editar Perfil</Trans>
            </span>
          </Link>
          {
            rewardData && rewardData.success?
          <Link
            to={process.env.PUBLIC_URL + "/offer"}
            className="menu-item"
          >
            <span className="menu-name">
              <Trans i18nKey="Reward">Ofertas e cupons</Trans>
            </span>
          </Link>
          :null}
          
          <Link
            to={process.env.PUBLIC_URL + "/ChangePassword"}
            className="menu-item"
          >
            <span className="menu-name">
              <Trans i18nKey="ChangePassword">Alterar Senha</Trans>
            </span>
          </Link>

          <Link
            to={process.env.PUBLIC_URL + "/PurchaseHistory"}
            className="menu-item"
          >
            <span className="menu-name">
              <Trans i18nKey="PurchaseHistory">Histórico de transações</Trans>
            </span>
          </Link>

          <Link
            to={process.env.PUBLIC_URL + "/Card"}
            className="menu-item"
          >
            <span className="menu-name">
              <Trans i18nKey="cardMethod">Editar método de pagamento</Trans>
            </span>
          </Link>
          
          <Link to={process.env.PUBLIC_URL + "/support"} className="menu-item">
            <span className="menu-name">
              <Trans i18nKey="Support">Suporte</Trans>
            </span>
          </Link>

          <Link to={process.env.PUBLIC_URL + "/edit-permission"} className="menu-item">
            <span className="menu-name">
              <Trans i18nKey="editPermission">Edite suas permissões</Trans>
            </span>
          </Link>

          {/* <a href="https://us.coca-cola.com/privacy-policy#rights" className="menu-item">
            <span className="menu-name">
              <Trans i18nKey="PersonalInfo">Do not sell my personal information</Trans>
            </span>
          </a> */}

          <a href="https://privacidade.cocacola.com.br/" className="menu-item">
            <span className="menu-name">
              <Trans i18nKey="Privacynav">Política de Privacidade</Trans>
            </span>
          </a>

          <a onClick={this.confirmlogout} className="menu-item">
            <span className="menu-name">
              <Trans i18nKey="Logout">Sair</Trans>
            </span>
          </a>
        </Menu>
        <Modal title={false} closable={false} centered={true} visible={this.state.isConfirmModalVisible} footer={false}>
          <div className="text-center">
              {/* <img src={questionmark} /><br/> */}
              <p style={{fontSize:'18px',fontWeight:'600'}}>{i18next.t('LogoutConfirm')}</p>
              
              <div className="p-3 mb-2">                                    
                  <Btn                                   
                      shape="round"  
                      size="large"                                   
                      style={{background:"#F40000",color:"#ffffff",fontWeight:"500",marginLeft:'15px',float:'left',minWidth:'100px'}}
                      onClick={this.CloseModal}
                  >Não</Btn>
                  <Btn
                      shape="round"   
                      size="large"                                 
                      style={{background:"#F40000",color:"#ffffff",fontWeight:"500",float:'right',minWidth:'100px'}}
                      onClick={this.logout}
                  >Sim</Btn>
              </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Navigation;
