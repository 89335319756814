import React, { Component } from 'react';
import logo from "../_assets/images/icons/logo.png";
import facebook from "../_assets/images/icons/facebook.png";
import apple from "../_assets/images/icons/apple.png";
import email from "../_assets/images/icons/email.png";
import google from "../_assets/images/icons/google.png";
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { config } from "../_config";
class Loginwith extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | loginwith`;
        CapturePage({hitType:"pageview",page:"/loginwith"})

    }
  render() {
    return (
        <div className="loginwith">
            <div className="container">
                <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                            <div className="col-12">
                                <img src={logo} height={80} className="mt-5"/>
                                <h4 className='text-white pl-2 mt-2'>{i18next.t('loginWith_Heading')}</h4>
                                {/* <p className='text-white'>{i18next.t('loginWith_SubHeading')}</p> */}
                            </div>
                        </div>
                </div>
            </div>
            <section className="d-flex flex-column align-items-center justify-content-center mt-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center loginBtn">
                            <div className="col-12">
                                <ul className='p-0'>
                                    <li>
                                        <Link to="/signUp"  className="btn bg-white w-100 btn_radius text-center text-black" >
                                            <span className='ml-2'>{i18next.t('HaveNoAccount')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/login"  className="btn bg-white w-100 btn_radius text-center text-black" >
                                            <span className='ml-2'>{i18next.t('Login')}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
  }
}

export default Loginwith
