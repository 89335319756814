import React, { useEffect, useState } from 'react'
import trash from '../../_assets/images/icons/delete.png'
import i18next from 'i18next'
const IndiaPG = ({cardList,defaultPG,addCard,openConfirmModal,setAsDefault}) => {
    const [paytm, setPaytm] = useState([])
    const [payU, setPayU] = useState([])
    useEffect(()=>{
        let paytmlist = cardList.filter((item)=>item.paymentGateway.toLowerCase()=='paytm')
        setPaytm(paytmlist);
        let payulist = cardList.filter((item)=>item.paymentGateway.toLowerCase()=='payu')
        payulist.sort((a,b)=>b.isDefault-a.isDefault)
        setPayU(payulist);
    },[cardList])
    const style={
        fontSize:'16px',
        fontWeight:'600',
        paddingBottom: '8px',
        borderBottom: '2px solid #D2D2D2',
        marginBottom: '10px'
    }
    const paymentFlow = sessionStorage.getItem('paymentFlow')
    return (
        <>
            <div className="bg-white mt-3 cardList" style={{height:window.innerHeight-220}} >
                {
                    paytm.length>0 ?
                    paymentFlow!='CARD' ?
                    <>
                        <p style={style}>Paytm</p>
                        <div className="custom-card">
                            <h5>{`XXXX-XX${paytm[0].lastDigits}`}</h5>
                            <div className="py-3 pl-2 mt-1">
                                <span style={{textTransform:'capitalize'}}>Paytm</span>
                            </div>
                        </div>
                        {
                            <div className="text-md-right text-right action-icon">
                            {/* <b>{i18next.t('defaultCard')}</b>&nbsp; */}
                            <img src={trash} id={paytm[0].cardID} onClick={openConfirmModal}/>
                            </div>
                        }
                    </>
                    :
                    paymentFlow=='CARD' &&
                    paytm.map((card,key)=>{
                        return <>                            
                            <div className="mb-3" key={key}>
                                <div className="custom-card" style={!card.isDefault?{backgroundColor:'#555'}:{}}>
                                    <h5>XXXX XXXX XXXX {card.lastDigits}</h5>
                                    <div className="py-3 pl-2 mt-1">
                                        <span>{card.cardType}</span>
                                    </div>
                                </div>
                                {
                                    card.isDefault ?
                                    <div className="text-md-right text-right action-icon">
                                    {/* <b>{i18next.t('defaultCard')}</b>&nbsp; */}
                                    <img src={trash} id={card.cardID} onClick={openConfirmModal}/>
                                    </div>
                                    :
                                    
                                    <div className="row">
                                            <div className='col-2'>
                                                <div className="form-check form-switch bx-pull-right ">
                                                    <input className="form-check-input on_off" type="checkbox" id={card.cardID} name='raido' onChange={setAsDefault}  checked={card.isDefault} />
                                                </div>
                                            </div>
                                            <div className='col-8'>
                                                <label className=" text-left" htmlFor={card.cardID} style={{cursor:'pointer'}}>{i18next.t('SetDefaultCard')}</label>
                                            </div>
                                            <div className='col-2'>
                                                <img src={trash} id={card.cardID} onClick={openConfirmModal} className="" height={20}/>
                                            </div>
                                    </div> 
                                }
                            </div>
                        </>
                    })
                    :
                    null
                }
                {
                    payU.length>0 ?
                    <>
                        <p style={style}>PayU</p>                  
                    {   payU.map((card,key)=>{
                            return <>                            
                                <div className="mb-3" key={key}>
                                    <div className="custom-card" style={!card.isDefault?{backgroundColor:'#555'}:{}}>
                                        <h5>XXXX XXXX XXXX {card.lastDigits}</h5>
                                        <div className="py-3 pl-2 mt-1">
                                            <span>{card.cardType}</span>
                                        </div>
                                    </div>
                                    {
                                        card.isDefault ?
                                        <div className="text-md-right text-right action-icon">
                                        {/* <b>{i18next.t('defaultCard')}</b>&nbsp; */}
                                        <img src={trash} id={card.cardID} onClick={openConfirmModal}/>
                                        </div>
                                        :
                                        
                                        <div className="row">
                                                <div className='col-2'>
                                                    <div className="form-check form-switch bx-pull-right ">
                                                        <input className="form-check-input on_off" type="checkbox" id={card.cardID} name='raido' onChange={setAsDefault}  checked={card.isDefault} />
                                                    </div>
                                                </div>
                                                <div className='col-8'>
                                                    <label className=" text-left" htmlFor={card.cardID} style={{cursor:'pointer'}}>{i18next.t('SetDefaultCard')}</label>
                                                </div>
                                                <div className='col-2'>
                                                    <img src={trash} id={card.cardID} onClick={openConfirmModal} className="" height={20}/>
                                                </div>
                                        </div> 
                                    }
                                </div>
                            </>
                        })
                    }
                    </>
                    :
                    ''
                }
                {
                    cardList.length==0 &&
                    <div className="emptycard">
                        <h4 className="text-center">{(defaultPG=='paytm' && paymentFlow!='CARD')?i18next.t("Payment method is not added. Please link your Paytm walltet"):i18next.t("CardEmpty")}</h4>
                    </div>
                }
                
            </div>
        </>
    )
}

export default IndiaPG
