import React, { Component } from "react";
import { Link } from "react-router-dom";
import Rewards from "../../assets/images/home/Reward.svg";
import refresh from "../../assets/images/icons/refresh.png";
import { config } from "../../_config";
import "./ProfileRewards.scss";
import i18n from "i18next";
import { decryptData } from "../../_helpers/encryptData";
class ProfileRewards extends Component {
  constructor(props) {
    super(props);
    let reward = JSON.parse(localStorage.getItem("rewardPoint"));
    
    this.state = {
      isLoading:false,
      rewardPoint: reward,
      showpop:false,
      errorMsg:"",
    };
    
    this.RefreshRewardPoint = this.RefreshRewardPoint.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }
  
  RefreshRewardPoint(){
    this.setState({
      isLoading:true
    });
    let that = this;
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    let userId = user.userId;
    fetch(config.envUrl + "VendingAppApi/fetchRewardBalance", {
      method: "POST",
      body: new URLSearchParams({
        authToken,
        userId
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((response) => {
      return response.json();
    })
    .then((data)=>{
      if(data.success){
        that.setState({
          isLoading:false,
          rewardPoint: data.data.totalRewardBalance,
        })
        localStorage.setItem('rewardPoint',data.data.totalRewardBalance);
      }
      else{        
        that.setState({
          isLoading:false,
          showpop:true,
          errorMsg:"Your session has been Expired. Please login and Try again!"
        })
      }
    })
    .catch((err)=>{
      that.setState({
        showpop:true,
        isLoading:false,
        errorMsg:i18n.t("ConnectionIssue")
      })
    })
  }
  closePopup(){
    this.setState({
      showpop:false
    })
  }
  render() {
    return (
      <div className="whiteboxratings">
        <span className="profileImage">
          {" "}
          <img src={Rewards} alt="Rewards" className="starIcon" />{" "}
          <img src={refresh} alt="Refresh Point" onClick={this.RefreshRewardPoint} className="mobileRefresh" style={{height:'20px'}}/>{" "}
        </span>
        <span className="profileName clrRed"> {this.state.isLoading?<div className="pointLoader"></div>:this.state.rewardPoint} </span>
        <span className="refreshpoint">
          <img src={refresh} alt="Refresh Point" onClick={this.RefreshRewardPoint} />{" "}
        </span>
        {this.state.showpop && 
        <div className="popup" style={{zIndex:"9999"}}>
          <div className="modal-content modal-md">
            <div className="modal-body">
              <span>{this.state.errorMsg}</span>
              <br />
              <span className="modal-footer">
                <button                
                  onClick={this.closePopup}
                  className="btn btn-outline-danger btn-theme"
                >
                  OK
                </button>
              </span>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

export default ProfileRewards;
