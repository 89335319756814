import React, { Component } from "react";
import "../assets/scss/defaults.scss";
import BackWhiteData from "../components/BackWhiteData/BackWhiteData";
import { MannualProductListing } from "../containers/ChooseProductListing/ChooseMannualProductListing";
import Button from "../components/button/button";
import { history } from "../_helpers";
import { connect } from "react-redux";
import { addRemoveProductsAction } from "../_actions";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { config } from '../_config';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";


class ChooseMannualProduct extends Component {
  constructor(props) {
    super(props); 
    let token = localStorage.getItem("token");
    let purchaseNumber = localStorage.getItem("purchaseNumber");
    document.title=`Coke & Go ${config.countryName} | ChooseMannualProduct`;
    CapturePage({hitType:"pageview",page:"/ChooseMannualProduct"})    
      
    this.state = {
      token:token,
      purchaseNumber:purchaseNumber,
      AddinList:[],
      backLink:`ManuallyCorrection?purchaseNumber=${purchaseNumber}`
    }
    
    if (!Array.isArray(this.props.addedProductsList)) {
      history.goBack();
    }
    this.addProducts = this.addProducts.bind(this);
    this.incrementNumber = this.incrementNumber.bind(this);
    this.decrementNumber = this.decrementNumber.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  addProducts() {
    let finalProductList = [];
    if(this.state.AddinList.length>0){      
      this.state.AddinList.map((product)=>{
        let tempData = this.props.addedProductsList.filter((item=>item.productId===product.productId));
        if(tempData.length>0){
          if(tempData[0].orderUpdateReasonId===3){
            this.props.removeProductsAction(tempData[0]);
            finalProductList.push(product);
          } 
          else{
            this.props.updateProducts(product.productId,'increment');
            this.props.updateCartProducts(product.productId);
          }
        }
        else{
          finalProductList.push(product);
        }
      })  
    }
    
    this.props.addProductsAction(finalProductList);
    history.push(`/ManuallyCorrection?purchaseNumber=${this.state.purchaseNumber}`);
  }

  handleCheck(check, bool) {
    check[0].quantity = 1;
    let currentList = this.state.AddinList;
    if (bool) {
      //call here to add
      currentList.push(check[0])
      this.setState({
        AddinList:currentList
      })
      // this.props.addProductsAction(check);
    } else {
      let afterRemovedList = currentList.filter((item=>item.productId!=check[0].productId))
      this.setState({
        AddinList:afterRemovedList
      })
      // this.props.removeProductsAction(check[0]);
    }
  }

  incrementNumber(prodId) {
    this.props.updateProducts(prodId, "increment");
  }

  decrementNumber(prodId) {
    this.props.updateProducts(prodId, "decrement");
  }

  render() {
    return (
      <div className="ProductListing whiteFullbody">
        <div className="container">
          <div className="row">
            <div className="BackButton2 col-12 py-4">
              <BackWhiteData
                BackLink={this.state.backLink}
                title={i18n.t("ChooseProduct")}
              ></BackWhiteData>
            </div>
            <div className="PurchaseHistoryData col-12 pb-3">
              <MannualProductListing
                incrementNumber={this.incrementNumber}
                decrementNumber={this.decrementNumber}
                onClickCheck={this.handleCheck}
              ></MannualProductListing>
            </div>
            <div className="mybtn col-12" style={{ bottom: "10px" }}>
              <div className="float-right  py-2">
                {/* <Link to={process.env.PUBLIC_URL + `/ManuallyCorrection?purchaseNumber=${this.state.purchaseNumber}`}> */}
                <Button
                  type="button"
                  text={i18n.t("AddProduct")}
                  look="arrow"
                  btnStyle="Red"
                  clickHandler={this.addProducts}
                />
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const addedProductsList = state.addedProductsList;
  return {
    addedProductsList,
  };
}

const actionCreators = {
  addProductsAction: addRemoveProductsAction.addedProduct,
  removeProductsAction: addRemoveProductsAction.removeProduct,
  updateProducts: addRemoveProductsAction.updateProducts,
  updateCartProducts: addRemoveProductsAction.updateCartProducts,
  deleteProducts: addRemoveProductsAction.deleteProducts,
};

const connectedChooseProducts = connect(
  mapState,
  actionCreators
)(ChooseMannualProduct);
export { connectedChooseProducts as ChooseMannualProduct };
