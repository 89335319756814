import React, { Component } from 'react';
import SplashScreenImg from "../../_assets/images/splashscreen/loader-new.gif";
import  "./splashscreen.scss";

class SplashScreen extends Component {
    constructor(props){
        super(props)
    }
    render() {  
        return (
            <div className="splashscreen" style={{animationDuration: '3s',display:this.props.show?'block':'none'}}>
                <img src={SplashScreenImg} alt="SplashScreen" onLoad={this.props.checkLoad}/>
        </div> 
        )       
    }
}

const splashTime = {
    animationDuration: '3s'
};

export default SplashScreen