import React, { Component } from "react";
import Button from "../button/button";
import error from "../../assets/images/popup/error.svg";
import done from "../../assets/images/popup/done.svg";
import "./popupmodule.scss";
import useModal from "react-hooks-use-modal";
import i18next from "i18next";

const TransactionFailedPopup = openPopup => {
  const [Modal, open, close, isOpen] = useModal();

  if (openPopup.openPopup && !isOpen) {
    open();
  }

  return (
    <div className="model">
      <div className="PopupCenter">
        <Modal>
          <div className="popup-box">
            <div className="whitebody-popup">
              <img
                src={openPopup.imagetype === "success" ? done : error}
                alt="error"
              />
              <p className="popup-title">{openPopup.message}</p>
              <p className="popup-title">{openPopup.message2}</p>
              <div className="popup-button">
                <Button
                  look="center"
                  text="OK"
                  btnStyle="Black"
                  clickHandler={() => {
                    close();
                    openPopup.redirectHome();
                  }}
                ></Button>
                <br />
                <p>or</p>
                <Button
                  look="center"
                  text={i18next.t("AddProductManually")}
                  btnStyle="Red"
                  clickHandler={() => {
                    close();
                    openPopup.redirectSame();
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TransactionFailedPopup;
