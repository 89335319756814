import React, { Component } from "react";
import Heading from "../containers/Heading/Heading";
import i18n from "i18next";
import { ClipLoader } from "react-spinners";
import { history } from "../_helpers";
import FooterNav from "../components/FooterNav/FooterNav";
import { config } from "../_config";
import CoolerPopup from "../components/Model-Popup/Popup";
import { Link } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";

export default class ReceiptType extends Component {
    constructor(props) {
        super(props);
        document.title=`Coke & Go ${config.countryName} | receipt-type`;
        CapturePage({hitType:"pageview",page:"/receipt-type"})    
        
        this.state = {            
            receiptType: props.location.state.receiptType,
            orderid: props.location.state.orderId,
            docDetail:{
                documentType:'',
                social:'',
                ruc:'',
                document:''
            },
            showPopup:false,
            imagetype:'',
            popupmsg:'',
            isLoading:false
        }
        this.handleChange = this.handleChange.bind(this)    
        this.handleSubmit = this.handleSubmit.bind(this)    
        this.closingPopup = this.closingPopup.bind(this)    
      }
      handleChange(event){
        const { name, value } = event.target;
        var docDetail = this.state.docDetail
        console.log(name,value)
        this.setState({
            docDetail: {
                ...docDetail,
              [name]: value
            },
          });
      }
      closingPopup(){
          this.setState({
            showPopup:false
          })
      }
      handleSubmit(){
        let self = this;
        let user = JSON.parse(localStorage.getItem('user'));
        let applicationTypeId = config.applicationTypeId;
        var receiptType = self.state.receiptType;
        var orderid = self.state.orderid
        var fullname = user.firstName+" "+user.lastName
        var {documentType,document,ruc,social} = this.state.docDetail
        let userId = user.userId;       
        let authToken = user.authToken
        if(receiptType==='ELECTRONIC' && documentType===''){
            self.setState({
                showPopup:true,
                imagetype:'error',
                popupmsg:i18n.t("Please choose your document"),
            })
        }
        else if(receiptType==='ELECTRONIC' && document===''){
            self.setState({
                showPopup:true,
                imagetype:'error',
                popupmsg:i18n.t("Please enter document number"),
            })
        }
        else if(receiptType==='FACTURA' && social===''){
            self.setState({
                showPopup:true,
                imagetype:'error',
                popupmsg:i18n.t("Please enter social"),
            })
        }
        else if(receiptType==='FACTURA' && ruc===''){
            self.setState({
                showPopup:true,
                imagetype:'error',
                popupmsg:i18n.t("Please enter ruc"),
            })
        }
        else if(receiptType==='FACTURA' && ruc.length>0 && ruc.length<11){
            self.setState({
                showPopup:true,
                imagetype:'error',
                popupmsg:i18n.t("Please enter valie ruc"),
            })
        }
        else{
            
            self.setState({isLoading:true})
            fetch(config.envUrl + "VendingAppApi/postsalePurchaseOrderForLatamCountry", {
                method: "POST",
                body: new URLSearchParams({
                    applicationTypeId,
                    authToken,
                    userId,
                    receiptType,
                    documentType,
                    document,
                    ruc,
                    social,
                    orderid,
                    fullname
                }),
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              })
              .then(function (response) {
                return response.json();
              })
              .catch(function (error) {
                if (error == "TypeError: Failed to fetch") {
                  self.setState({
                    isLoading:false,
                    showPopup: true,
                    imagetype:'error',
                    popupmsg:i18n.t("CommonError"),
                  });
                }
              })
              .then(function (body) {
                if(body.success){
                  history.push("/ScanButton");
                }
                else{                  
                    console.log(body)
                    self.setState({
                        isLoading:false,
                        showPopup: true,
                        imagetype:'error',
                        popupmsg:i18n.t("CommonError"),
                    });
                }
              });
        }  
      }
      render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        let user = JSON.parse(localStorage.getItem('user'));
        return (
            <div className="h-100">        
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                            <div className="col-12 p-0">
                                <Heading title={i18n.t("Receipt Type")} firstIcon={true} secondIcon={false} backLink={{pathname:"invoice-receipt",state:this.state.orderid}} LinkIcon="back"/> 
                                <form className="receipt_type">
                                    {
                                        this.state.receiptType==="SIMPLE" ?
                                        
                                        <div className="form-group">
                                            <input
                                            id="receiptType"
                                            type="text"
                                            className="form-control"
                                            placeholder={i18n.t("Boleta de Venta Simple")}
                                            name="receiptType"
                                            defaultValue={i18n.t("Boleta de Venta Simple")}
                                            aria-label="John"
                                            aria-describedby="basic-addon2"
                                            maxLength="50"
                                            readOnly
                                            />
                                        </div>
                                        :
                                        this.state.receiptType==="ELECTRONIC" ?
                                        <>
                                        <div className="form-group">
                                            <input
                                            id="fullname" 
                                            type="text"
                                            className="form-control"
                                            placeholder={i18n.t("Nombre Completo")}
                                            name="fullname"  
                                            defaultValue={user.firstName+' '+user.lastName}
                                            readOnly
                                            // onClick={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <select className="form-control" name="documentType" defaultValue="" onChange={this.handleChange}>
                                                <option value="" disabled>Tipo de documento</option>
                                                <option value="DNI">DNI</option>
                                                <option value="CE">CE</option>
                                                <option value="PASSPORT">PASSPORT</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <input
                                            id="document"  
                                            type="text"
                                            className="form-control"
                                            placeholder={i18n.t("Documento")}
                                            name="document"  
                                            onChange={this.handleChange}
                                            />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="form-group">
                                            <input
                                            id="social"  
                                            type="text"
                                            className="form-control"
                                            placeholder={i18n.t("Razon Social")}
                                            name="social"  
                                            onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <ReactInputMask name="ruc" onChange={this.handleChange}
                                                className="form-control shadow-none border-radius-none pl-0" 
                                                mask="99999999999" maskChar="" placeholder={i18n.t("RUC")}/>
                                        </div>
                                        </>
                                    }
                                </form>
                                <div className="row text-center mt-5">
                                    <div className="col-6">
                                        <button className="theme-btn" style={{width:'90%'}} onClick={this.handleSubmit}>{i18n.t('OK')}</button>
                                    </div>
                                    <div className="col-6">
                                        <Link to={{pathname:'/invoice-receipt',state:this.state.orderid}} className="text-dark">
                                            <button className="theme-btn" style={{width:'90%'}} type="button">{i18n.t('Cancel')}</button>
                                        </Link>
                                    </div>
                                </div>
                                {
                                    this.state.isLoading &&
                                    <div className="spinner">
                                        <div style={style}>
                                        <ClipLoader size={50} color={"#FFFFFF"} />
                                        </div>
                                    </div>
                                }
                                { 
                                    this.state.showPopup && 
                                    <CoolerPopup
                                    color="Red"
                                    openPopup={this.state.showPopup}
                                    closePopup={this.closingPopup}
                                    message={this.state.popupmsg}
                                    imagetype={this.state.imagetype}
                                    />
                                }
                                <FooterNav />                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
      }
}
