import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import i18next from "i18next";
import useModal from "react-hooks-use-modal";
import topcoat_cancel from "../../_assets/images/icons/modal-close.png"
import "./style.scss"
import { useState } from 'react';
import { useEffect } from 'react';
export const Feedback = (props) => {
    const [Modal, open, close, isOpen] = useModal();
    const [isOkDisable,setOkDisable] = useState(true)
    const [md,setMD] = useState(false)
    const totalStar = [1,2,3,4,5];
    if (props.isPopUpOpen && !isOpen) {
        open();
    }
    // const CheckMouseOver = (value) =>{
    //     setRating(value);
    //     setOkDisable(false)
    // }
    useEffect(()=>{
        const msd = document.getElementById('md').getBoundingClientRect();
        setMD(msd)
    },[])
    // const closePopUp = () =>{
    //     close();
    // }
    
    return (
        <div className="model">
            <div className="PopupCenter">
                <Modal>
                <div className="popup-box feedbackmodal">
                    <div className="whitebody-popup" id="md" style={md.height>window.innerHeight?{top:"0"}:null}>
                        <p className='text-white'>{i18next.t("Please tell us what went wrong.")}</p>
                        <div className='closemodal'>
                            <img src={topcoat_cancel} onClick={props.closeFeedBack}/>
                        </div>
                        <div className='formbody'>
                           {
                            props.feedBackData && props.feedBackData.map((data,key)=>{
                                return <>
                                    <p className='text-left'>{i18next.t(data.headerName)}</p>
                                    {
                                        data.isMultiLine ?
                                        <div className='form-group'>
                                            <textarea className='form-control' name={(data.headerName).replaceAll(" ","_")} placeholder={i18next.t('type here...')} rows="3" onChange={props.handleFeedbackMsg} maxLength={2000} value={props.feedbackmsg}></textarea>
                                        </div> 
                                        :
                                        <ul className='feedbacklist'>
                                            {
                                                data.ratingDescriptionList.map((item,lkey)=>{
                                                    return <li className='form-check' key={lkey}>
                                                        <input type={data.fieldType} name={(item.name).replaceAll(" ","_")} className="feedback_checkbox" onChange={props.handleFeedbackCheckbox} id={(item.name).replaceAll(" ","_")} defaultChecked={item.isdefault} />
                                                        <label htmlFor={(item.name).replaceAll(" ","_")}>{i18next.t(item.name)}</label>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    }
                                </>
                            })
                           }
                           <div className='text-center mt-4'>
                                <button className='btn text-white w-100 text-uppercase' style={{borderRadius:'20px'}} onClick={props.handleSubmitFeedBack} disabled={props.isDisabled}>{i18next.t("Submit")}</button>                            
                            </div>
                        </div>
                        
                    </div>
                </div>
                </Modal>
            </div>
        </div>
    )
}
