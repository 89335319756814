import React, { useEffect } from 'react'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { cdsConfig } from '../_config'
import { history } from '../_helpers'

const VerfiyToken = () => {
  const [isLoading, setLoading]  = useState(true)
  useEffect(()=>{
    const setAuth2 = async () => {
      const sdk = await window.tcccLogin.LoginSdk(cdsConfig);
      await sdk.isSDKReady();
      window.sdk = sdk;
      
      // // console.log(window.sdk.isAuthenticated())
      let check = await window.sdk.isAuthenticated()
      if(!check)
      {
          await window.sdk.getAuthenticationUrl(true, false);
      }
      else
      {
        const USER_LAST_ACCOUNT_ACTION_COOKIE = 'tcccLogin_userLastAccountAction'
        const userLastAccountAction = window.sdk.readCookie(USER_LAST_ACCOUNT_ACTION_COOKIE)
        ? window.sdk.readCookie(USER_LAST_ACCOUNT_ACTION_COOKIE).toUpperCase()
        : 'B2C_1A_SIGNUP_SIGNIN_GENERIC' 
        // alert(userLastAccountAction)
        const b2cChangePasswordPolicy = 'B2C_1A_CHANGEPASSWORD_GENERIC'
        if (b2cChangePasswordPolicy === userLastAccountAction) {     
          history.push('/logout')
        }
      }
    }
    setAuth2();
  },[])

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex:'999'
  };
  return (
    <div className="loginwith">        
        {
            isLoading &&
            <div className="" style={{height:'400px'}}>
                <div className="spinner">
                    <div style={style}>
                        <ClipLoader
                            size={50}
                            color={"#FFFFFF"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>
        }
    </div>
  )
}

export default VerfiyToken