import i18next from 'i18next';
import React, { Component } from 'react'
import ReactInputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { config } from '../../_config';
import creditCardType  from "credit-card-type";
import { getAppId } from "../../_helpers/getAppId";
const ApplicationTypeId = getAppId();

export default class GlobalPayForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      debitCardWarning:false,
      BrandName:'',
      card_type:''
    }
  }

  handleChange=(e)=>{
    const value = e.target.value;
    let data = creditCardType(value)
    let brandName = data.length>0 && data[0].niceType ?  data[0].niceType: '';
    if(brandName!=''){
      const check_card_type = {"001":"Visa","002":"Mastercard","003":"American Express","004":"Discover","005":"Diners Club","006":"Carte Blanche","007":"JCB","014":"EnRoute"}
      var card_type = Object.keys(check_card_type).filter((index)=>{return check_card_type[index]===brandName})
      sessionStorage.setItem('card_number',value)
    }
    this.setState({
      BrandName:brandName,
      card_type:card_type.length>0?card_type[0]:''
    })
  }
  render() {
    
    let returnData = JSON.parse(sessionStorage.getItem("returnData"));
    let requestData = JSON.parse(returnData.requestData);
    requestData.signature = returnData.signature;
    const {CardNumber, ExpirationDate, Brand, globalpay_card_type, CVV} = this.props.cardDetail
    return (
      <div>
        <form action={returnData.postURL} method='post' onSubmit={this.props.onsubmit}>      
          <div className='addCard' >
            <div className="row">
              {config.enableCardScan &&
                  <div className='col-md-12'>
                      <div className='text-center'>
                          <label className="font-weight-600">{i18next.t('EnterCardDetails')}</label>
                      </div>
                  </div>
              }
              <div className='col-md-12'>
                <div className='text-center'>
                    <img src={this.props.logo} height={50} className="my-2" />
                    <Link to="/Card" className="float-right" ><img src={this.props.close} /></Link>
                </div>
              </div>
              <div className=" col-12 py-2">
                <label className="font-weight-600">{i18next.t('CardNumberLabel')}</label>
                <input id='card_number' type='text' className="form-control addCardInput" name='card_number' placeholder="XXXX XXXX XXXX XXXX" onChange={this.props.handleChange} value={CardNumber}/>
                {
                    this.state.debitCardWarning &&              
                    <label className="text-danger">{i18next.t("Debit Card Warning")}</label>
                }
              </div>
              <div className='col-12 py-2'>
                <label className="font-weight-600">{i18next.t('CardExpiryLabel')}</label>
                <ReactInputMask name="card_expiry_date" className="form-control addCardInput" mask="99-9999" placeholder={config.expiryDatePlaceHolder} value={ExpirationDate} onChange={this.props.handleChange}/>
                <input id='card_type' type='hidden' className="form-control addCardInput" name='card_type' placeholder='card_type' value={globalpay_card_type}/>
              </div>
              <div className={ApplicationTypeId == 23 ? "col-8 py-2" : "col-12 py-2"}>
                <label className="font-weight-600">{i18next.t('CardBrandLabel')}</label>
                <input id='Brand' type="text" className="form-control addCardInput" name="Brand" placeholder="Visa" value={Brand} readOnly />
              </div>
              {
                ApplicationTypeId == 23 &&
                <div className=" col-4 py-2">
                <label className="font-weight-600">{i18next.t('CVV')}</label>
                <ReactInputMask name="card_cvn" className="form-control addCardInput" mask={this.props.cvvMask=="999" ? "999" : "9999"} placeholder={this.props.cvvMask=="999" ? "XXX" : "XXXX"} value={CVV} onChange={this.props.handleChange}/>
                </div>
              }
              
              <div className="col-12 mt-3">
                {
                  Object.keys(requestData).map((index,key)=>{
                    return <>
                            <input id={index} name={index} value={requestData[index]} type='hidden'/>
                    </>
                  })
                }
                <button type="submit" className="btn w-100 btn-addcard">{i18next.t('Save')}</button>
              </div> 
            </div>
          </div>
          
          

        </form>
      </div>
    )
  }
}
