import React, { useState, memo } from "react";
import "./index.scss";
import i18next from "i18next";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { Image } from "antd";
import placeholder from "../../assets/images/icons/placeholder.png"
const DecouplePromo=(props)=>{    
    const {bannerImage,documentLink,landingImage,seeRulesLink,offerName,backLink} = props;
    const [imgURL,setImgURL]= useState(bannerImage)
    const [isImageLoaded,setImageLoaded]=useState(true)
    const onImageLoaded=()=>setImageLoaded(true)
    const FailToLoad=()=>setImgURL("");
    const style = {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };
    const LinkData = JSON.stringify({documentLink,landingImage,seeRulesLink,offerName,backLink})
    return(
        <>
            <div className="decouple-promo" key={Math.random()*100000}>
                {/* {
                    !isImageLoaded &&                 
                    <div className="img-placeholder">
                        <div className="spinner">
                            <div style={style}>
                                <ClipLoader size={50} color={"#000000"} />
                            </div>
                        </div>
                    </div>                    
                }                 */}
                    
                <div className={isImageLoaded?'d-block':'d-none'}>
                    {
                        documentLink?
                            <a href={documentLink}>
                                {/* <img src={imgURL} onLoad={onImageLoaded} onError={FailToLoad}/> */}
                                <Image
                                    style={{width:'100%'}}
                                    src={imgURL}
                                    placeholder={
                                    <Image
                                        src={placeholder}                                       
                                        style={{width:'100%'}}
                                        
                                    />
                                    }
                                />
                            </a>
                            :
                            <Link to={{pathname:`/offerRules`,state:LinkData}}><img src={imgURL} onLoad={onImageLoaded} onError={FailToLoad}/></Link>
                    }
                    
                </div>
                
            </div>
        </>
    )
}

export default memo(DecouplePromo)