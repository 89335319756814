import PropTypes from "prop-types";
import React from "react";
import  "./button.scss";
import ArrowBack from "./../../assets/images/LogIn/next.png";
import enable_button from "./../../assets/images/icons/enable_button.png";

const Button = ({ size, text, btnType, clickHandler, disabled }) => {
  if(size=="large"){
    return (
      <button 
        className="btn btn-theme" 
        type={btnType} 
        disabled={disabled}
      >
        {text}
      </button>
    )
  }
  if(size=="small"){
    return (
      <button 
        className="btn btn-theme" 
        type={btnType} 
        disabled={disabled}
      >
        {text}
      </button>
    )
  }
  return "";
};

Button.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  btnType: PropTypes.string,
  url: PropTypes.string
};

export default Button;
