import { Select, Spin } from "antd";
import React, { useState } from "react"
import "./style.scss"
import list from "./country-with-mobile.json"
import close from "../../_assets/images/icons/modal-close.png"
const CountryList=(props)=>{
    const [cList, setCList]=useState(list)
    const searchCountry=(e)=>{
        const {value} = e.target;
        const res = list.filter(item=>item.name.toLowerCase().includes(value.toLowerCase()));
        setCList(res)
    }

    const SelectCountry=(country)=>{
        props.selectCountry(country)
    }
    
    return (
        <div className="country-list-bg">
            <div className="country-list">
                <div className="country-list-search">
                    <div className="row">
                        <div className="col-2 text-center">
                            <img src={close} onClick={props.closeList} />
                        </div>
                        <div className="col-10 pl-0">
                            <input type="text" className="w-100" placeholder="Search country" onChange={searchCountry}/>
                        </div>
                    </div>                    
                </div>
                <div className="country-list-body">
                        <ul className="mt-0">
                            {
                                cList.map((item)=>{
                                    return <li key={Math.random()*1000000} onClick={(e)=>SelectCountry(item)}>
                                        <div className="country-name">
                                            {item.name}
                                        </div>
                                        <div className="country-code">
                                            {item.dial_code}
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
            </div>
        </div>
    )
}

export default CountryList;