import React from 'react';
import emailpic from '../assets/images/icons/Email_success.png';
import { config } from "../_config/index";
import { Modal, Button } from 'antd';
import { ClipLoader } from "react-spinners";
import i18n from "i18next";
import { Redirect } from 'react-router-dom';

class EmailSent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showPopup: false,
      popupmsg: ''
    };
    this.handleResendLink = this.handleResendLink.bind(this);
  }

  handleResendLink() {
    const { firstName, email } = this.props.location.state || {};

    const params = new URLSearchParams({
      primaryEmail: email,
      applicationTypeId: 8,
      firstName: firstName,
      platform: 'web',
      redirectURL: config.baseURL+"/NewSignUp"
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: params.toString()
    };

    this.setState({ isLoading: true });

    fetch(`${config.envUrl}VendingAppApi/userRegistrationV3`, requestOptions)
      .then(response => response.json())
      .then(data => {
        let msg = '';
        if (data.success && data.code === 5067) {
          msg = i18n.t("Email sent successfully.");
        } else {
          msg = i18n.t("An error occurred.");
        }

        this.setState({
          showPopup: true,
          popupmsg: msg,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          showPopup: true,
          popupmsg: i18n.t("CommunicationError"),
        });
      });
  }

  render() {
    const { firstName, email } = this.props.location.state || {};
    const { isLoading, showPopup, popupmsg } = this.state;

    if (!firstName || !email) {
      return <Redirect to="/hurryform" />;
    }

    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };

    return (
      <div className="MainContent">
        <div className="Email_box">
          <span><h5 className="messageText text-center">Email sent successfully</h5></span>
          <img className='messageImg' src={emailpic} alt="Email sent" />
        </div>
        <div className="Content_box">
          <h6 className="email_content">{firstName}<br />{email}</h6>
          <h6 className='email_content2'>Thanks! We sent you an email with a link to complete your account.</h6>
          <h6 className='email_message'>
            Once you receive the email, click on the button to complete your account. If you don’t see it in your inbox, check your spam/junk folder.
          </h6>
          <h6 className='email_message'>
            Having trouble? <button onClick={this.handleResendLink} className="resendLink">Re-send the link</button>
          </h6>
        </div>
        <Modal
          open={showPopup}
          footer={false}
          headers={false}
          title={false}
          closable={false}
          centered
          style={{ maxWidth: '200px' }}
          bodyStyle={{ padding: '15px'}}
        >
          <div style={{ textAlign: 'center'}}>
            <p style={{fontWeight: '500', marginBottom: '15px'}}>{popupmsg}</p>
            <Button style={{ borderRadius: '25px', width:'75%', outline:'none', borderColor: '#000000', textDecorationColor: '#000000' }} onClick={() => this.setState({ showPopup: false })}>Ok</Button>
          </div>
        </Modal>
        {isLoading && (
          <div className="spinner" style={{ display: 'block' }}>
            <div style={style}>
              <ClipLoader
                size={50}
                color={"#FF0000"}
                loading={isLoading}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EmailSent;
