import creditCardType from 'credit-card-type';
import i18next from 'i18next';
import React, { Component } from 'react'
import FooterNav from '../components/FooterNav/FooterNav';
import CoolerPopup from '../components/Model-Popup/Popup';
import { config } from '../_config';
import queryString from 'query-string';
import { history } from '../_helpers';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from '../_helpers/encryptData';
import { ClipLoader } from 'react-spinners';

export default class PaymentStatus extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | payment-status`;
        CapturePage({hitType:"pageview",page:"/payment-status"})    
        this.state = {
            isLoading:true,             
            showpopup: false,
            imagetype:"error",
            popupmsg: "",
            checkRedirection:props.location.state
        }
        
    }
    componentDidMount(){
        if(config.applicationTypeId==20){
            let {Status:Status=""} = queryString.parse(this.props.location.search);
            this.setState({
                isLoading:false,             
                showpopup: true,
                imagetype:Status=="A"?"success":"error",
                popupmsg: i18next.t(Status=="A"?"CardSuccess":"CommonError")
            })
        }
        else if(config.applicationTypeId==19){
            let paymentGateway = sessionStorage.getItem('paymentGateway');
            if(paymentGateway.toLocaleLowerCase() == 'payu'){
                let {STATUS:Status="",SIID} = queryString.parse(this.props.location.search);
                sessionStorage.setItem('newCard',SIID)
                this.setState({
                    isLoading:false,            
                    showpopup: true,
                    imagetype:Status=="success"?"success":"error",
                    popupmsg: i18next.t(Status=="success"?"CardSuccess":"CommonError")
                })
            }
            else if(paymentGateway.toLocaleLowerCase() == 'paytm'){
                let pendingPurchaseNumber=localStorage.getItem('pendingPurchaseNumber')
                let {STATUS:Status="",type,transactionID} = queryString.parse(this.props.location.search);
                if(type=='preauth' && !pendingPurchaseNumber)
                {
                    // sessionStorage.setItem('paytmTransactionID',transactionID)
                    history.push({
                        pathname:'/Home',
                        state:{transactionID:transactionID}
                    })
                    // this.state = {
                    //     isLoading:false,            
                    //     showpopup: true,
                    //     imagetype:Status=="TXN_SUCCESS"?"success":"error",
                    //     popupmsg: i18next.t(Status=="TXN_SUCCESS" ? type=="savecard" ? "CardSuccess": type=='preauth'?'Pre-authorization approved':"CommonError":"CommonError")
                    // }
                    // return false
                }
                else if(type=='preauth' && pendingPurchaseNumber){
                    this.completePendingPaymentforPaytm();
                }
                else if(type=='savecard' && Status=="TXN_SUCCESS"){
                    this.setState({
                        isLoading:false,            
                        showpopup: true,
                        imagetype:"success",
                        popupmsg: i18next.t("Card Verified successfully with Rs 1.00")
                    })
                }
                else{
                    this.setState({
                        isLoading:false,            
                        showpopup: true,
                        imagetype:"error",
                        popupmsg: i18next.t("CommonError")
                    })
                }
            }
        }

        if(config.applicationTypeId!=20 && config.applicationTypeId!=19)
        {
            this.AddCard()
        }
    }

    completePendingPaymentforPaytm=()=>{
        let self = this;
        let pendingPurchaseNumber=localStorage.getItem('pendingPurchaseNumber')
        let {STATUS:Status="",type,transactionID} = queryString.parse(this.props.location.search);
        const user = JSON.parse(decryptData(localStorage.user))
        let authToken=user.authToken;
        let PurchaseNumber = pendingPurchaseNumber
        // let defaultcard = cardList.filter((item)=>(item.isDefault)?item:null)      
        // let cardId=defaultcard[0].cardID
        let CustomerName=user.firstName+" "+user.lastName
        let applicationTypeId=config.applicationTypeId
        let uniqueDeviceId=user.userId
        let sessionUId=sessionStorage.getItem("sessionId");
        let isOTPVerified=true
        let preAuthTransactionId=transactionID
        let that=this;
        let languageCode=localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
        fetch(config.envUrl + "VendingAppApi/completePendingPaymentWithSwipe", {
            method: "POST",
            body: new URLSearchParams({
            authToken,
            PurchaseNumber,
            // cardId,
            CustomerName,
            applicationTypeId,
            // currencyCode,
            // uniqueDeviceId,
            languageCode,
            sessionUId,
            preAuthTransactionId,
            isOTPVerified,
            // cvv
            }),
            headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then(function (response) {
            return response.json();
        })
        .then(result=>{
            const {success} = result;
            localStorage.removeItem('pendingPurchaseNumber')
            if(success){
                history.push({
                    pathname: '/OrderSummary/' + PurchaseNumber,
                    state: 'fromHome'
                });
            }
            else{
                this.setState({
                    isLoading:false,            
                    showpopup: true,
                    imagetype:"error",
                    popupmsg: i18next.t("CommonError")
                })
            }
        })
          
    }

    AddCard=()=>{
        // console.log()
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let decision = params.get('decision');
        let that = this;
        if(decision=='ACCEPT'){
            let user = JSON.parse(decryptData(localStorage.getItem('user')));
            const applicationTypeId = config.applicationTypeId
            const authToken = user.authToken;
            let currencyCode = config.currencyCode;
            const paymentToken = params.get('payment_token');
            const cardNumber = sessionStorage.getItem('card_number')
            let data = creditCardType(cardNumber)
            let brandName = data.length>0 && data[0].niceType ?  data[0].niceType: '';
            const Brand = brandName;
            let sessionUId = sessionStorage.getItem('sessionId');

            fetch(config.envUrl+"VendingAppApi/addPayment", {
                method: "POST",
                body: new URLSearchParams({
                    applicationTypeId,
                    authToken,
                    currencyCode,
                    paymentToken,
                    cardNumber,
                    Brand,
                    sessionUId
                }),
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            })
            .then(function (response) {
                return response.json();
            })
            .then(function(body){                
                if(body.success && body.returnCode==5041){ 
                    const cardAddedFromPending = sessionStorage.getItem('cardAddedFromPending');
                    if(cardAddedFromPending){
                        sessionStorage.setItem("newCard",body.token)
                    }
                    that.setState({   
                        isLoading:false,             
                        showpopup: true,
                        imagetype:"success",
                        popupmsg: i18next.t("CardSuccess")
                    })
                    // localStorage.removeItem('cardlist');
                }
                else{
                    that.setState({       
                        isLoading:false,             
                        showpopup: true,
                        imagetype:"error",
                        popupmsg: i18next.t("InvalidCard")
                    })
                }
            })
            .catch(function(err){
                if(navigator.onLine){
                    that.setState({       
                        isLoading:false,             
                        showpopup: true,
                        imagetype:"error",
                        popupmsg: err?err:i18next.t("CommonError")
                    })
                }
                else{
                    that.setState({
                        isLoading:false,   
                        isOffline:true
                    })
                }                
            })
        }
        else{
            that.setState({       
                isLoading:false,             
                showpopup: true,
                imagetype:"error",
                popupmsg: i18next.t("CommonError")
            })
        }
    }
    closingPopup=()=>{
        if(config.applicationTypeId==20){
            const qrcode = decryptData(localStorage.getItem('qrcode'));
            history.push(qrcode?'/Home':'/Card')
            return false
        }
        sessionStorage.removeItem('returnData');
        const qrcode = decryptData(localStorage.getItem('qrcode'));
        const cardAddedFromPending = sessionStorage.getItem('cardAddedFromPending');
        if(cardAddedFromPending){         
            sessionStorage.removeItem('cardAddedFromPending')           
            history.push(`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+cardAddedFromPending}`)
        }
        else{
            if(qrcode){
                history.push('/Home')
            }
            else{
                let fromdata = sessionStorage.getItem('scanbutton');
                let newuser = sessionStorage.getItem('newuser');
                if(fromdata=='true' || newuser=='true'){
                    sessionStorage.removeItem('scanbutton');
                    sessionStorage.removeItem('newuser');
                    history.push('/ScanButton')
                }
                else{
                    history.push('/Card')
                }
            }
        }
    }
    render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        return (
            <div>
                { 
                    this.state.showpopup ? (
                    <CoolerPopup
                        imagetype={this.state.imagetype}
                        color="Red"
                        openPopup={this.state.showpopup}
                        closePopup={this.closingPopup}
                        message={this.state.popupmsg}
                    />) : null 
                }
                {
                    this.state.isLoading?
                    <div className="spinner">
                    <div style={style}>
                        <ClipLoader size={50} color={"#000000"} loading={this.state.isLoading}/>
                    </div>
                    </div>
                    :null
                }
                <FooterNav/>
            </div>
        )
    }
}
