import React, { Component } from "react";
import sliderData from "../../data/hero-sliders/hero-slider-one.json";
import HeroSliderOneSingle from "../../components/hero-sliders/hero-slider-one";
import "swiper/css/swiper.css";
import "./heroslider.scss";

function Pagination(sliderData) {
  var coolerId = sliderData.sliderData[0].coolerId;
  return (
    <div className="heroSlider">
      <div className="slideActivation">
        {sliderData &&
          sliderData.sliderData.map((single, key) => {
            return (
              <HeroSliderOneSingle
                data={single}
                coolerId={coolerId}
                key={key}
                currencyCode={sliderData.currencyCode}
                sliderClassName="swiper-slide"
              />
            );
          })}
      </div>
    </div>
  );
}

export default Pagination;
