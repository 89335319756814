import {
    productsConstants
} from '../_constants';
import {
    productService
} from '../_services';

export const productActions = {
    getAllProducts,
    getMannualAllProducts,
    allMannualProducts
};

function getAllProducts(products) {
    return dispatch => {
        dispatch(request());
        productService.getAllProducts(products)
            .then(
                products => dispatch(success(products)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {
            type: productsConstants.PRODUCTS_REQUEST
        }
    }

    function success(products) {
        return {
            type: productsConstants.PRODUCTS_SUCCESS,
            products
        }
    }

    function failure(error) {
        return {
            type: productsConstants.PRODUCTS_FAILURE,
            error
        }
    }
}

function getMannualAllProducts(products) {
    return dispatch => {
        dispatch(request());
        productService.getMannualAllProducts(products)
            .then(
                products => dispatch(success(products)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() {
        return {
            type: productsConstants.PRODUCTS_REQUEST
        }
    }

    function success(products) {
        return {
            type: productsConstants.PRODUCTS_SUCCESS,
            products
        }
    }

    function failure(error) {
        return {
            type: productsConstants.PRODUCTS_FAILURE,
            error
        }
    }
}

function allMannualProducts(products) {
    return dispatch => {
        dispatch(request());
        productService.allMannualProducts(products)
            .then(
                products => dispatch(success(products)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() {
        return {
            type: productsConstants.PRODUCTS_REQUEST
        }
    }

    function success(products) {
        return {
            type: productsConstants.PRODUCTS_SUCCESS,
            products
        }
    }

    function failure(error) {
        return {
            type: productsConstants.PRODUCTS_FAILURE,
            error
        }
    }
}