import i18n from "i18next";
import React, { Component } from 'react'
import i18next from "i18next";
import offerbg from "../_assets/images/icons/reward-bg.png"
import FooterNav from "../components/FooterNav/FooterNav";
import logo from "../_assets/images/icons/logo.png";
import { Trans } from "react-i18next";
import { getAppId } from "../_helpers/getAppId";
import back from "../_assets/images/icons/back.png";
import { history } from "../_helpers";
import { config } from '../_config';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";

const ApplicationTypeId = getAppId();

export default class Rewards extends Component {
    constructor(props) {
        super(props);
        document.title=`Coke & Go ${config.countryName} | Rewards`;
        CapturePage({hitType:"pageview",page:"/Rewards"})    
        this.state = {

        }
    }
    handleBackBtn = () => {
        history.push('/ScanButton')
    }    
    render() {
        return (
            <div className="main-wrapper">        
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 p-0" style={{background:`url(${offerbg})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',height:'100vh'}}>
                            <div className="content p-0 pt-4">
                                <div className="heading heading-add">                                
                                 <p className="title mb-0 text-white"><img className="pl-3 mt-1" src={back} style={{float: 'left', cursor: 'pointer'}} onClick={this.handleBackBtn}/> {i18next.t('Rewards')}</p>
                                 
                                </div>
                                <div className="pointdetail">
                                    <img src={logo} height={80} className="ml-3"/>
                                    <div className="reward_title">
                                        <h4 className={ApplicationTypeId===9 ? 'font-size2' : 'font-size1'}>
                                            <Trans i18nKey="Offer Text" components={[<br />]}>
                                            </Trans>
                                        </h4>
                                    </div>
                                    <div className="reward_details" >
                                        <p className="text-white">{i18next.t('Offer Details')}</p>
                                    </div>
                                    <FooterNav />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}
