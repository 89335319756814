import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import "./heading.scss"
import left_angle from "../../_assets/images/icons/left-angle.png"
import white_back from "../../_assets/images/icons/back.png"
import close from "../../assets/images/icons/close.png"
import contact_us from "../../assets/images/icons/contact_us.png"
export default class extends Component {
    constructor(props){
        super(props);

    }
    render() {
        return (
            <div className="heading">
                <div className="row">
                    <div className="col-1">
                        {
                            (this.props.firstIcon)?
                            (this.props.LinkIcon==='back')?
                            (
                                <Link to={(this.props.backLink=='/ScanButton' && this.props.title!='')?{pathname:this.props.backLink,state:'back'}:this.props.backLink}>
                                    <img src={this.props.className==='text-white'?white_back:left_angle} className="backImg" />
                                </Link>
                            )
                            :
                            (
                                <span onClick={this.props.handleClick}>
                                    <img src={close} className="closeImg ml-1" />
                                </span>                            
                            )
                            :null
                        }
                        
                    </div>
                    <div className={`${this.props.align=="center"?'col-10':'col-10'}`}>
                        <p className={`title mb-0 ${this.props.className==='text-white'?'text-white':'text-dark'} ${this.props.align=="center"?'text-center':'text-left'}`}>{this.props.title}</p>
                    </div>

                    {/* <div className="col-2">
                        {
                            this.props.secondIcon && (
                                <Link to="/FAQ"><img src={contact_us} className="rightImg" /></Link>
                            )
                        }
                        
                    </div> */}
                </div>
            </div>
        )
    }
}
