import {
    addedProductsConstant
} from '../_constants';


export const addRemoveProductsAction = {
    addedProduct,
    removeProduct,
    initialProducts,
    updateCartProducts,
    updateProducts,
    deleteProducts
};

function addedProduct(product) {
    return dispatch => {
        dispatch(addproduct(product));

    };

    function addproduct(product) {
        return {
            type: addedProductsConstant.ADD_PRODUCTS,
            product: product
        }
    }
}

function removeProduct(product) {
    return dispatch => {
        dispatch(removeproduct(product));

    };

    function removeproduct(product) {
        return {
            type: addedProductsConstant.REMOVE_PRODUCTS,
            product: product
        }
    }
}

function initialProducts(products) {
    return dispatch => {
        dispatch(initialProducts(products));

    };

    function initialProducts(products) {
        return {
            type: addedProductsConstant.INITIAL_PRODUCTS,
            product: products
        }
    }
}

function updateProducts(productid, factor) {
    return dispatch => {
        dispatch(updateProducts(productid, factor));

    };

    function updateProducts(productid, factor) {
        return {
            type: addedProductsConstant.UPDATE_PRODUCT,
            productid: productid,
            factor: factor
        }
    }
}

function updateCartProducts(productid) {
    return dispatch => {
        dispatch(updateCartProducts(productid));

    };

    function updateCartProducts(productid) {
        return {
            type: addedProductsConstant.UPDATE_CARTPRODUCT,
            productid: productid
        }
    }
}

function deleteProducts(productid) {
    return dispatch => {
        dispatch(deleteProducts(productid));

    };

    function deleteProducts(productid) {
        return {
            type: addedProductsConstant.DELETE_PRODUCT,
            productid: productid
        }
    }
}