import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import i18next from "i18next";
import useModal from "react-hooks-use-modal";
import star from "../../_assets/images/icons/rewards.png"
import starfil from "../../_assets/images/icons/star-fill.png"
import "./style.scss"
import { useState } from 'react';
export const Likert = (props) => {
const [Modal, open, close, isOpen] = useModal();
    const [isOkDisable,setOkDisable] = useState(true)
    const [rating,setRating] = useState(false)
    const totalStar = [1,2,3,4,5];
    if (props.isPopUpOpen && !isOpen) {
        open();
    }
    const CheckMouseOver = (value) =>{
        setRating(value);
        setOkDisable(false)
    }
    // const closePopUp = () =>{
    //     close();
    // }
    return (
        <div className="model">
            <div className="PopupCenter">
                <Modal>
                <div className="popup-box likert">
                    <div className="whitebody-popup">
                        <p className='text-white'>{i18next.t("How would you rate your experience?")}</p>
                        <div className=''>
                            {
                                totalStar.map((value,key)=>{
                                    if(value<=rating){
                                        return <img src={starfil} key={value} onClick={(e)=>{CheckMouseOver(value)}}/>
                                    }
                                    else{
                                        return <img src={star} key={value} onClick={(e)=>{CheckMouseOver(value)}} />
                                    }
                                })
                            }
                            
                            {/* <img src={star} />
                            <img src={star} />
                            <img src={star} />
                            <img src={star} /> */}
                        </div>
                        <div className='text-center mt-4'>
                            <button className='btn text-white mr-3' onClick={props.closePopUp}>{i18next.t("Dismiss")}</button>
                            <button className='btn text-white' disabled={isOkDisable} onClick={(e)=>{props.saveRating(rating)}}>{i18next.t("Submit")}</button>
                        </div>
                    </div>
                </div>
                </Modal>
            </div>
        </div>
    )
}
