import { config } from '../_config/index';
import { decryptData, encryptData } from '../_helpers/encryptData';
import { v4 as uuidv4 } from "uuid";
const {applicationTypeId} = config
const ActionNames = {
    "ScanPage" : 1,
    "RewardPage" : 4,
    "CardListingPage" : 5,
    "AddCardPage" : 6,
    "DoorClose" : 14,
    "StarRatingPage" : 20,
    "SupportPage" : 27,
    "ProfilePage" : 29,
    "NeedHelpPage": 31,
    "SettingPage": 32
  };

async function metricsTracking(actionName){
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let sessionUId = sessionStorage.getItem("sessionId");
    // if(!sessionUId){
    //     sessionUId = uuidv4();
    //     sessionStorage.setItem('sessionId',sessionUId);
    // }
    let authToken = user.authToken;
    let userId = user.userId;    
    let actionId = ActionNames[actionName];
    let remarks = actionName;
    
    let ipAddress = await fetch("https://api.ipify.org?format=json")
        .then((json)=>{return json.json()})
        .then(async(result)=>{
            return result.ip;
        })

    let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken, sessionUId, userId, applicationTypeId, actionId, remarks, ipAddress})
    }
    return fetch(`${config.envUrl}VendingAppApi/addSiteTrackinLog`, RequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
        return result;
    }).catch(function (error) {
        return {
            success: false,
            error: error
        }
    });
}

async function checkCDSEnabled(){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({applicationTypeId}),
    };
    return fetch(`${config.envUrl}VendingAppApi/cdsConfig`, requestOptions)
    .then((response) => {
        return response.json();
      })
    .then(result=>{
        return result
    })
}



export const utilService = {
    metricsTracking,
    checkCDSEnabled
};