import {
    pendingPaymentConstant
} from '../_constants';

export function pendingPayment(state = {}, action) {
    switch (action.type) {
        case pendingPaymentConstant.PURCHASEHISTORY_REQUEST:
            return {
                products: action.products
            };
        case pendingPaymentConstant.PURCHASEHISTORY_REQUEST_SUCCESS:
            return {
                products: action.products.data
            };
        case pendingPaymentConstant.PURCHASEHISTORY_FAILURE:
            return {};
        default:
            return state
    }
}