import React from 'react'
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "../button/button";
// import done from "../../assets/images/popup/done.svg";
import error from "../../assets/images/popup/error.svg";
import  "./DoorPopup.scss";

const DoorPopup = ({ Message, Desc1, br, Desc2, ButtonText, ButtonLink, ButtonText2, ButtonLink2, }) => {
    
    if (Message === "DoorOpen") {
        return (
        <div className="popup-box">
            <div className="whitebody-popup">
                <div className="circle-box">
                    <img src={error} className="mw-100"  alt="success"/>
                </div>
                <p className="popup-title">
                    {Desc1}
                    <br></br>
                    {br}
                </p>
                <p className="popup-title">
                    {Desc2}
                </p>
                <div className="popup-button">
                    <Link to={process.env.PUBLIC_URL + ButtonLink}>
                        <Button look="center" btnStyle="Black" text={ButtonText} ></Button>
                    </Link>
                </div>
            </div>
        </div>
        )
    }

    if (Message === "noproduct") {
        return (
            <div className="popup-box">
            <div className="whitebody-popup">
                <div className="circle-box">
                    <img src={error} className="mw-100"  alt="success"/>
                </div>
                <p className="popup-title">
                    {Desc1}
                    <br></br>
                    {br}
                </p>
                <p className="popup-title">
                    {Desc2}
                </p>
                <div className="popup-button">
                    <Link to={process.env.PUBLIC_URL + ButtonLink}>
                        <Button look="center" btnStyle="Black" text={ButtonText} ></Button>
                    </Link>
                </div><br />
                <div className="text-center">or</div><br />
                <div className="popup-button">
                    <Link to={process.env.PUBLIC_URL + ButtonLink2}>
                        <Button look="center" btnStyle="Red" text={ButtonText2} ></Button>
                    </Link>
                </div>
            </div>
        </div>
        )
    }


    return "";
}
DoorPopup.propTypes = {
    Message: PropTypes.string,
    Desc1: PropTypes.string,
    br:PropTypes.string,
    Desc2: PropTypes.string,
    ButtonLink: PropTypes.string,
    ButtonText: PropTypes.string,
    ButtonLink2: PropTypes.string,
    ButtonText2: PropTypes.string
};
export default DoorPopup
