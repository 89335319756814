import i18next from "i18next";
import React from "react";
import { useState } from "react";
import ReactInputMask from "react-input-mask";
import CoolerPopup from '../Model-Popup/Popup';
import { decryptData } from "../../_helpers/encryptData";
import { config } from "../../_config";
import { v4 as uuidv4 } from "uuid";
import creditCardType from "credit-card-type";
import { ClipLoader } from "react-spinners";
const AuresysPayForm=(props)=>{
    const [imagetype,setImagetype]=useState("error");
    const [openModal,setOpenModal]=useState(false);
    const [isLoading,SetisLoading]=useState(false);
    const [message,setMessage]=useState("");
    const { CardNumber,ExpirationDate } = props.card
    let Expdate = ExpirationDate.split("/");
    let month = ExpirationDate.includes('_')?"":Expdate[0]
    let year = ExpirationDate.includes('_')?"":Expdate[1]
    const [card,SetCard] = useState({
        cardnumber:CardNumber?CardNumber:"",
        month:month,
        year:year
    })

    const setCardDetail=(event)=>{
        const {value,id} = event.target
        let val = value.split("_").join("")
        let num = parseInt(val)
        if(val!=""){
            if(num>-1){
                SetCard({
                    ...card,
                    [id]:value
                })
            }         
        }
        else{
            SetCard({
                ...card,
                [id]:value
            })
        }
    }
    const addCard=(e)=>{
        e.preventDefault()
        const {cardnumber,month,year}=card;
        if(cardnumber.trim()==""){
            setOpenModal(true);
            setImagetype('error');
            setMessage(i18next.t("CardNumber"))
        }
        else if(month.trim()=="" || year.trim()==""){
            setOpenModal(true);
            setImagetype('error');
            setMessage(i18next.t(month==""?"ExpMonth":"ExpYear"))
        }
        else{
            var cardlength=false
            const Ctype = creditCardType(cardnumber);
            const validLengths = Ctype.length>0?Ctype[0].lengths:[]
            cardlength = validLengths.indexOf(cardnumber.length);
            if(cardlength>-1){
            
                try {
                    window.My2c2p.getEncrypted("2c2p-payment-form", function (encryptedData, errCode, errDesc) {
                        // console.log( errCode, errDesc)
                        if(errCode!=0){
                            setOpenModal(true);
                            setImagetype('error');
                            setMessage(errCode==2?"Please enter a valid Card number":errDesc)
                        }
                        else{
                            SetisLoading(true)
                            const cardData=encryptedData.encryptedCardInfo;
                            // console.log(cardData)
                            const user = JSON.parse(decryptData(localStorage.user))
                            const applicationTypeId = config.applicationTypeId;
                            const authToken= user.authToken;
                            let checkbrand = creditCardType(cardnumber);
                            const lastFourDigit = cardnumber.substring(cardnumber.length-4)
                            let brand = checkbrand.length>0 && checkbrand[0].niceType ?  checkbrand[0].niceType: '';
                            const transactionId=uuidv4()
                            const maxerienceRedirectURL=window.location.origin+"/payment-status";
                            let sessionUId = sessionStorage.getItem('sessionId');
                            fetch(config.envUrl+"VendingAppApi/addPayment", {
                                method: "POST",
                                body: new URLSearchParams({
                                    applicationTypeId,
                                    authToken,
                                    brand,
                                    lastFourDigit,
                                    transactionId,
                                    cardData,
                                    maxerienceRedirectURL,
                                    sessionUId
                                }),
                                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                            })
                            .then(function (response) {
                                return response.json();
                            })
                            .then(function(body){ 
                                if(body.success && body.returnCode==5041){
                                    window.location.href=body.redirectURL
                                }
                                else{
                                    SetisLoading(false)
                                    setOpenModal(true);
                                    setImagetype('error');
                                    setMessage(i18next.t("CommonError"))
                                }
                            })
                            .catch((err)=>{
                                SetisLoading(false)
                                setOpenModal(true);
                                setImagetype('error');
                                setMessage(i18next.t("CommonError"))
                            })  
                        }
                    })
                } catch (error) {
                    SetisLoading(false)
                }
            }
            else{
                setOpenModal(true);
                setImagetype('error');
                setMessage(i18next.t("Please enter a valid Card number"))
            }
        }
        
    }

    const closingPopup=()=>{
        setOpenModal(false)
    }
    const style = {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex:'999'
    };
    return (
        <>
            <form onSubmit={addCard} id="2c2p-payment-form">
                <div className='addCard' >
                    <div className="row">
                        <div className=" col-12 py-2">
                            <label className="font-weight-600">{i18next.t('CardNumberLabel')}</label>
                            <input id="cardnumber" className="form-control addCardInput" name="cardnumber" data-encrypt="cardnumber"  placeholder="XXXX XXXX XXXX XXXX" onChange={setCardDetail} value={card.cardnumber}/>
                        </div>
                        <div className=" col-12 py-2">
                            <label className="font-weight-600">{i18next.t('Expiry Month')}</label>
                            <ReactInputMask id="month" className="form-control addCardInput" mask={`99`} onChange={setCardDetail} placeholder="MM" name="month" data-encrypt="month" value={card.month} />
                        </div>
                        <div className=" col-12 py-2">
                            <label className="font-weight-600">{i18next.t('Expiry Year')}</label>
                            <ReactInputMask id="year" className="form-control addCardInput" mask={`9999`} onChange={setCardDetail} placeholder="YYYY" name="year" data-encrypt="year" value={card.year} />
                        </div>
                        <div className="col-12 mt-3">
                            <button type="submit" className="btn w-100 btn-addcard">{i18next.t('Save')}</button>
                        </div>
                    </div>
                </div>
            </form>
            <CoolerPopup
                imagetype={imagetype}
                color="Red"
                openPopup={openModal}
                closePopup={closingPopup}
                message={message}
            />
            { isLoading &&
                <div className="" style={{height:'400px'}}>
                    <div className="spinner">
                        <div style={style}>
                            <ClipLoader
                                size={50}
                                color={"#000000"}
                                loading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AuresysPayForm