import React, { Component } from "react";
import Button from "../button";
import error from "../../_assets/images/icons/error.png";
import done from "../../_assets/images/icons/success.png";
import verify from "../../_assets/images/icons/register_success.svg";
import "./popupmodule.scss";
import useModal from "react-hooks-use-modal";
import i18next from "i18next";
import { Link } from "react-router-dom";

const CoolerPopup = openPopup => {
  const [Modal, open, close, isOpen] = useModal();

  if (openPopup.openPopup && !isOpen) {
    open();
  }


  return (
    
    <div className="model">
      <div className="PopupCenter">
        <Modal>
          <div className="popup-box">
            <div className="whitebody-popup" style={openPopup.fullWithButton ? {borderRadius:'25px',overflow:'hidden'}:{}}>
              {
                openPopup.fullWithButton ?
                <>
                  <p style={{fontWeight:'600'}}>{openPopup.title}</p>
                  <p style={{fontWeight:'600'}} className="mb-5">{openPopup.description}</p>
                  <h5 className={`modalbtnFullWidth ${openPopup.imagetype=='error'?'Errorbg':'Successbg'}`} onClick={openPopup.clickHandle}>{openPopup.btnText}</h5>
                  {
                    openPopup.isPaymentPending ?
                    <>
                      <div className="modalbtnFullWidth Errorbg">
                        <button className="btn mr-4 text-black" onClick={openPopup.ignorePayment}>{i18next.t("Ignore")}</button>
                        <button className="btn text-black" onClick={openPopup.clickHandle}>{i18next.t("Payment")}</button>
                      </div>
                    </>
                    :
                    <h5 className={`modalbtnFullWidth ${openPopup.imagetype=='error'?'Errorbg':'Successbg'}`} onClick={openPopup.clickHandle}>{openPopup.btnText}</h5>
                  }                  
                </>
                :
                <table width="100%">
                  <tbody>
                    <tr>
                      <td scope="col" width="20%" className="text-center" valign="top">
                        <img src={ openPopup.imagetype === "usericon"?verify: openPopup.imagetype==="success" ? done : error} className=" rounded-start" alt="..." height={60}/>

                      </td>
                      <td className="popup-msg">
                        {openPopup.message}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Button size="popup-btn" btnType="submit" disabled={false} text="OK" 
                          clickHandler={() => {
                            close();
                            openPopup.closePopup();
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
              {/* </div> */}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CoolerPopup;
