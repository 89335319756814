import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  initReactI18next
} from "react-i18next";
import translationEN from './locales/en/translation.json';
import translationJA from './locales/ja/translationja.json';
import translationPT from './locales/brazil/translation.json';
import translationPol from './locales/poland/translation.json';
import translationAU from './locales/austria/translation.json';
import translationCH from './locales/chile/translation.json';
import translationHK from './locales/chinese/translation.json';
import translationMA from './locales/french/translation.json';
import translationTW from './locales/taiwan/translation.json';
import translationAR from './locales/egypt/translation.json';
import translationCZ from './locales/czech/translation.json';
import { config } from "./_config"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      English: {
        translations: translationEN
      },
      Portuguese:{
        translations: translationPT
      },
      Polish:{
        translations: translationPol
      },
      German:{
        translations: translationAU
      },
      Spanish:{
        translations: translationCH
      },
      Chinese:{
        translations: translationHK
      },
      French:{
        translations: translationMA
      },
      Taiwan:{
        translations: translationTW
      },
      Arabic:{
        translations: translationAR
      },
      Czech:{
        translations: translationCZ
      }
    },
    debug: false,
    lng: localStorage.selectedLanguage ? config.languageList.indexOf(localStorage.selectedLanguage)>-1? localStorage.selectedLanguage : config.defaultLanguage:config.defaultLanguage,
    load: "languageOnly",
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    order: [
      "navigator",
      "querystring",
      "cookie",
      "localStorage",
      "htmlTag",
      "path",
      "subdomain"
    ],

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
      escapeHTMLinValues:true
    }
  });

export default i18n;