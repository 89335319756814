import {
    config
} from '../_config/index';
export const paymentService = {
    getCharge
};

function getCharge(paymentobj) { //addConsumerPurchaseV3 is called here in qa
    let authToken = paymentobj.authToken;
    let coolerId = paymentobj.coolerId;
    let purchaseNumber = paymentobj.purchaseNumber;
    let products = paymentobj.products;
    let paymentTransactionId = paymentobj.paymentTransactionId;
    let paymentMethod = paymentobj.paymentMethod;
    let isRewardPointDeduction = paymentobj.isRewardPointDeduction;
    let edgeSessionId = paymentobj.edgeSessionId;
    let transactionDuration = paymentobj.transactionDuration;
    let irResultResponseTime = paymentobj.irResultResponseTime;
    let dataConsumedDuringTransaction = paymentobj.dataConsumedDuringTransaction;
    
    let transactionMethod = paymentobj.transactionMethod;
    
    let couponCode = paymentobj.couponCode;
    let totalAmountCharged = paymentobj.totalAmountCharged;
    let totalRewardPointsEarned = paymentobj.totalRewardPointsEarned;
    let paymentGatewayTransactionDetails = paymentobj.paymentGatewayTransactionDetails;
    let isCouponApplied = paymentobj.isCouponApplied;
    let freeSKU = paymentobj.freeSKU;
    let swipeTransactionId = paymentobj.swipeTransactionId
    // let transactionId = paymentobj.transactionId;
    // let eventId = paymentobj.eventId;
    // let eventTimeUTC = paymentobj.eventTimeUTC;
    // let pointDeductionMethod = paymentobj.pointDeductionMethod;
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            authToken,
            coolerId,
            purchaseNumber,
            products,            
            paymentTransactionId,
            paymentMethod,
            isRewardPointDeduction,
            edgeSessionId,
            transactionDuration,
            irResultResponseTime,
            dataConsumedDuringTransaction,
            transactionMethod,
            couponCode,
            totalAmountCharged,
            totalRewardPointsEarned,
            paymentGatewayTransactionDetails,
            isCouponApplied,
            freeSKU,
            swipeTransactionId
        })
    };

    return fetch(`${config.envUrl}VendingAppApi/addConsumerPurchaseV13`, requestOptions)
        .then(handleResponse)
        .then(charge => {
            return charge;
        }).catch(function (error) {
            return {
                success: false,
                error: error
            }
        });

    function handleResponse(response) { // for every request handling
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (data.success) {
                return data;
            } else {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        });
    }
}