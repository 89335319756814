import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "./VerifyEmail.scss";
import i18next from "i18next";
import { decryptData } from "../../_helpers/encryptData";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    var userEmailId = JSON.parse(decryptData(localStorage.getItem("user"))).primaryEmail;
    this.state = {
      userEmailId: userEmailId
    };
  }
  render() {
    return (
      <div className="Editform">
        <div className="mt-4">
          <div className="form-group">
            <label className="">{i18next.t('EmailID')}</label>
            <input
              id="emailid"
              type="email"
              className="form-control pl-0 border-radius-none"
              placeholder="Enter Email-id"
              name="emailid"
              disabled={true}
              value={this.state.userEmailId}
              aria-label="John"
              aria-describedby="basic-addon2"
            />
          </div>
        </div>
        {/* <div className="ResendLink col-12">
                    <div className="float-right py-3">
                        <Link to={process.env.PUBLIC_URL + "/login"} >
                            <span className="clrblack">Resend?</span>
                        </Link>
                    </div>
                </div> */}
      </div>
    );
  }
}

export default VerifyEmail;
