import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./VerifyOTP.scss";
import i18n from "i18next";
import { Trans } from "react-i18next";

class VerifyOTP extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let user = localStorage.getItem('user');
    return (
      <div className="">
        <div className="">
          <div className="">
            <label className="text-white">{i18n.t("OTP")}</label>
            <input
              id="otp"
              type="text"
              className="form-control border-radius-none pl-0"
              placeholder={i18n.t("EnterOTP")}
              name="otp"
              value={this.props.OTP}
              onChange={this.props.handleChange}
              aria-label="John"
              aria-describedby="basic-addon2"
            />
          </div>
        </div>
        <div className="ResendLink">
          <div className="float-right py-1" onClick={this.props.resubmitOTP}>
            <span className={user?"text-underline":"text-white text-underline"} style={{cursor:'pointer'}}>
              <Trans i18nKey="Resend">Resend?</Trans>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyOTP;
