import React, { Component } from "react";
import "./RegisterForm.scss";
import { Link } from "react-router-dom";
import EyeIcon from "../../assets/images/icons/hide.png";
import HiddenEyeIcon from "../../assets/images/icons/visible.png";
import i18n from "i18next";
import { Trans } from "react-i18next";
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from "moment";
import ReactInputMask from "react-input-mask";
import { titleCase } from "../../_helpers";
import { config } from "../../_config"
class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openeye: true,
      inputType: false,
      openeye1: true,
      inputType1: false,
    };
    this.toggleImage = this.toggleImage.bind(this);
    this.getImageName = this.getImageName.bind(this);
    this.toggleImage1 = this.toggleImage1.bind(this);
    this.getImageName1 = this.getImageName1.bind(this);
  }

  handleChange() {
    this.props.handleChange();
  }

  toggleImage() {
    this.setState({
      openeye: !this.state.openeye,
      inputType: !this.state.inputType,
    });
  }
  getImageName() {
    return this.state.openeye ? "showeye" : "hideeye";
  }

  toggleImage1() {
    this.setState({
      openeye1: !this.state.openeye1,
      inputType1: !this.state.inputType1,
    });
  }
  getImageName1() {
    return this.state.openeye1 ? "showeye" : "hideeye";
  }

  render() {
    const selectedLanguage = localStorage.getItem('selectedLanguage')
    const imagesPath = {
      showeye: EyeIcon,
      hideeye: HiddenEyeIcon,
    };
    const mobileformat = config.mobileFormat
    const mobilePlaceholder = mobileformat.replaceAll('9','X')
    return (
      <div className="signupform">
        <div className="px-2">
          <div className="form-group">
            <label htmlFor="firstname">{i18n.t("FirstName")+" *"}</label>
            <input
              type="text"
              id="firstname"
              className="form-control shadow-none border-radius-none pl-0"
              placeholder={i18n.t("FirstName")}
              name="firstName"
              value={this.props.user.firstName}
              onChange={this.props.handleChange}
              aria-label="John"
              aria-describedby="basic-addon2"
              autoComplete="off"
              maxLength="50"
              
            />
          </div>
          <div className="form-group">
            <label htmlFor="firstname">{i18n.t("LastName")+" *"}</label>
            <input
              id="lastName"
              type="text"
              className="form-control shadow-none border-radius-none pl-0"
              placeholder={i18n.t("LastName")}
              name="lastName"
              value={this.props.user.lastName}
              onChange={this.props.handleChange}
              aria-label="Username"
              aria-describedby="basic-addon1"
              autoComplete="off"
              maxLength="50"
            />
          </div>
          <div className="form-group">
            <label htmlFor="firstname">{i18n.t("Email")+" *"}</label>
            <input
              id="Primaryemail"
              type="text"
              className="form-control shadow-none border-radius-none pl-0"
              placeholder={i18n.t("EnterEmail")}
              name="Primaryemail"
              value={this.props.user.Primaryemail}
              onChange={this.props.handleChange}
              aria-label="Username"
              aria-describedby="basic-addon1"
              autoComplete="off"
              readOnly={!this.props.isEmailReadOnly?false:true}
            />
            {
              this.props.isInvalidEmail &&              
              <label className="text-danger">{i18n.t("Please enter a valid email address")}</label>
            }            
          </div>
          {
            config.applicationTypeId == 9 && (
              <div className="form-group">
                <label htmlFor="firstname">{i18n.t("CPF")+" *"}</label>
                <ReactInputMask name="cpf" onChange={this.props.handleChange} 
                className="form-control shadow-none border-radius-none pl-0" 
                mask="999.999.999-99" maskChar="_" placeholder="CPF"/>
              </div>
            )
          }
          
          <div className="row">
            <div className={`${config.countryDropDown?'col-12':'col-6'}`}>
              <div className="form-group">
                <label htmlFor="firstname">{i18n.t("Number")}</label>
                <div className="input-group">
                  { config.countryDropDown &&
                  <div className="pt-2 text-white pr-4 mr-2 mobile-code homepage" onClick={this.props.openCountryList}>
                    {this.props.selectedCountry.dial_code+' ('+this.props.selectedCountry.code+')'}
                  </div> }
                  <ReactInputMask name="mobileNumber" onChange={this.props.handleChange} 
                  className="form-control shadow-none border-radius-none pl-0" 
                  mask={config.mobileFormat} maskChar={config.isFreeTextForMobile?false:'_'} placeholder={mobilePlaceholder}/>
                </div>
              </div>
            </div>
            <div className={`${config.countryDropDown?'col-12':'col-6'}`}>
              <div className="form-group">
                <label htmlFor="firstname">{i18n.t("DoB")+" *"}</label>
                <ReactInputMask name="dob" onChange={this.props.handleDoBchange} 
                className="form-control shadow-none border-radius-none pl-0" 
                mask="99/99/9999" maskChar="_" value={this.props.user.birthDate} placeholder={config.birthDatePlaceHolder}/>
                {/* <DatePicker 
                  onChange={this.props.handleDoBchange}
                  format="DD/MM/yyyy" 
                  className="form-control shadow-none border-radius-none pl-0"
                  placeholder={config.applicationTypeId==9?'dd/mm/aaaa':'dd/mm/yyyy'}
                  suffixIcon={false}
                  disabledDate={(current)=>{ return moment() <= current}}
                /> */}
              </div>
            </div>
          </div>
          {
          this.props.isShowPassword &&
          <>
            <div className="form-group ">
              <div className="position-relative">
                <label className="mb-0">{i18n.t("Password")+" *"}</label> 
                <input
                  id="password"
                  type={this.state.inputType ? "text" : "password"}
                  className="form-control shadow-none border-radius-none pl-0"
                  placeholder={i18n.t("EnterPassword")}
                  name="password"
                  value={this.props.user.password}
                  onChange={this.props.handleChange}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  autoComplete="new-password"
                />
                <span>
                  <img
                    src={imagesPath[this.getImageName()]}
                    alt="eye"
                    onClick={this.toggleImage}
                    id="eye"
                    height="27px"
                    className="seepassword"
                  />
                </span>
              </div>
              {
                this.props.isInvalidPassword &&              
                <label className="text-danger">{i18n.t("ValidateMsg_ForInvalidFormatPassword")}</label>
              }
            </div>
            <div className="form-group">
              <div className="position-relative">
                <label className="mb-0">{i18n.t("ConfirmPassword")+" *"}</label> 
                <input
                  id="confirmpassword"
                  type={this.state.inputType1 ? "text" : "password"}
                  className="form-control shadow-none border-radius-none pl-0"
                  placeholder={i18n.t("ConfirmPassword")}
                  name="confirmpassword"
                  value={this.props.user.confirmpassword}
                  onChange={this.props.handleChange}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  autoComplete="new-password"
                />
                <span>
                  <img
                    src={imagesPath[this.getImageName1()]}
                    alt="eye"
                    onClick={this.toggleImage1}
                    id="eye1"
                    height="27px"
                    className="seepassword"
                  />
                </span>
              </div>
              {
                this.props.isPasswordMatched &&              
                <label className="text-danger">{i18n.t("ValidateMsg_ForPasswordResetNotMatch")}</label>
              }
            </div>
          </>
          }
        </div>
        <div className="iAccepted p-1">
          <div className="row">
            <div className="col-12">
              <input
                  type="checkbox"
                  className="mt-2 policy-checkbox"
                  id="accept"
                  onChange={this.props.privacyChecked}
                  checked={this.props.privacy}
                />
              <label className="text-white" htmlFor="accept1">
                {
                  selectedLanguage == 'Arabic' 
                  ? <>أوافق على <Link to={config.termsOfUseAndSale}> سياسة الخصوصية </Link> و <Link to={config.termsOfUse}> شروط الاستخدام </Link> و <Link to={config.privacyPolciy}> البيع </Link> من Coca-Cola</>
                  :
                  <Trans i18nKey="SignUpCheckBox1" components={[<LinkText href={config.termsOfUseAndSale} />,<LinkText href={config.privacyPolciy} />,<LinkText href={config.termsOfUse} />]} />
                }
              </label>
              
            </div>
            <div className="col-12 ">
              <input
                  type="checkbox"
                  className="mt-2 policy-checkbox"
                  id="mailMartketing"
                  name="mailMarketing"
                  onChange={this.props.marketingChecked}
                  checked={this.props.mailMarketing}
                  
                />
              <label className="text-white" htmlFor="mailMartketing1">
                {
                  config.applicationTypeId==17 ?
                  i18n.t("I hereby give my consent to receive direct marketing of Coke & Go promotions and all products that are marketed by Coca-Cola Africa (Proprietary) Limited whose details are found on the Terms of Use, by means of email. By clicking on this box, I confirm my direct marketing consent.")
                  :
                  <Trans i18nKey="SignUpCheckBox2" components={[<LinkText href={config.marketingTerms} />]} />
                }
              </label>
            </div>
            {/* <div className="col-12">
              <input
                  type="checkbox"
                  className="mt-2"
                  id="smsMarketing"
                  name="smsMarketing"
                  onChange={this.props.marketingChecked}
                  checked={this.props.smsMarketing}
                  
                />
              <label className="text-white" htmlFor="smsMarketing">
                Aceito receber notícias e novidades da Coca-Cola no meu e-mail e WhatsApp, bem como aceito os <a href="https://privacidade.cocacola.com.br/maxerience/index.html"><b>termos de marketing</b></a>
                {i18n.t('SignUpCheckBox3')}

              </label>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

function LinkText({ href, children }) {
  return <a href={href}><b>{children}</b></a>
}
export default RegisterForm;
