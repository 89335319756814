import React from 'react'
import { decryptData } from '../../_helpers/encryptData';
import { useState } from 'react';
import { useEffect } from 'react';
import { config } from '../../_config';
import i18next from 'i18next';
import star from "../../_assets/images/icons/star.png"
import bottle from "../../_assets/images/icons/bottle.png"
import { Link } from "react-router-dom";
import { history } from '../../_helpers';
function RewardPoints() {
    // let rewardData = JSON.parse(decryptData(localStorage.getItem('isRewardAvailable')));
    let qrcode = decryptData(localStorage.getItem('qrcode'));
    const [promotionOffers,setOffers] = useState({
        purchasedBrand:[],
        records:[]
    });
    
    useEffect(()=>{
        if(qrcode){
            getRewardPoints()
        }
    },[])

    const getRewardPoints=()=>{
        let user = JSON.parse(decryptData(localStorage.getItem('user')));
        let authToken= user.authToken;
        let macAddress= qrcode
        let applicationTypeId= config.applicationTypeId;
        let RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({authToken,applicationTypeId,macAddress})
        }
        fetch(`${config.envUrl}VendingAppApi/getPurchaseOfferQty`, RequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            if(result.success){
                setOffers({
                    purchasedBrand:result.purchasedBrand,
                    records:result.records
                })
            }
        }).catch(function (error) {
            
        });
    }
    const openScanner=()=>{
        history.push({
            pathname: "/Scanner"
        });
    }
    let count = [1,2,3,4,5,6,7,8,9,10];
    const {purchasedBrand,records} = promotionOffers
    return (
        <>
        {
            records.length>0 ?
            <>
            <div className="reward_section">
                <div className="row mb-3">
                    <div className="col-9">
                        <h5 style={{fontSize:'1.1rem'}}>{i18next.t("For every 10 drinks you purchase get one free")}</h5>
                    </div>
                    <div className="col-3 p-0 pr-2 text-right">
                        <p className="points">{records[0].PurchaseQty}/{records[0].BuyQty}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-9">
                        <div className="d-flex">
                            {
                                count.map((value,key)=>{
                                    if(key<5){
                                        return <div className="starcount" key={key}>
                                            <div className={purchasedBrand.length>0 && purchasedBrand[key] ? "star-with-brand" : "star"}>
                                                { records[0].PurchaseQty>=value ? <img src={purchasedBrand.length>0 && purchasedBrand[key] ? purchasedBrand[key] : star}/> : value}
                                            </div>
                                        </div>
                                    }
                                })
                            }
                        </div>
                        <div className="d-flex">
                        {
                            count.map((value,key)=>{
                                if(key>4){
                                    return <div className="starcount" key={key}><div className={purchasedBrand[key] ? "star-with-brand" : "star"}>
                                        { records[0].PurchaseQty>=value ? <img src={purchasedBrand[key] ? purchasedBrand[key] : star}/>:value}
                                    </div></div>
                                }
                            })
                        }  
                        </div>
                    </div>
                    <div className="col-3 p-0 bottle">
                        <img src={bottle} /><br/>
                        <small className="text-white" style={{fontSize:'10px'}}>{i18next.t("FREE")}</small>
                    </div>
                </div>
                <ul>
                </ul>
                <p className="text-white text-center" style={{fontSize:'1.1rem'}}>{promotionOffers.records[0].PurchaseQty<10?i18next.t("X points left for a free beverage",{point:(promotionOffers.records[0].BuyQty-promotionOffers.records[0].PurchaseQty)}):i18next.t("You made it! Your next drink is free.")}</p>
                {/* <div className="text-center">
                <Link to="/point-detail" className="btn  blackBtn-with-border">{i18next.t("See Rules & Details")}</Link>
                </div> */}
                <div className="row">
                    <div className="col-7">
                        <p className="redeem-now" onClick={openScanner}>{i18next.t('Buy Now')}</p>
                    </div>
                    <div className="col-5 text-right">
                        <p className='text-white mt-3'>{(config.applicationTypeId==11 || config.applicationTypeId==12) ? <Link to="/policy/rewardSeeRules" className="text-white">{i18next.t('Terms')}</Link> : <Link to={config.termsOfUseAndSale} className="text-white">{i18next.t('Terms')}</Link>}</p>
                    </div>
                </div>
            </div>
            </>
            :
            <>
            
            </>
        }
        </>
    )
}

export default RewardPoints