import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import placeholderImg from "../../_assets/images/icons/placeholder.png"

const LazyLoader = props => {
    const {src,page} = props;
    const [showPlaceholder,setStatus]=useState(true);
    var timer;
    const [count,setCount]=useState(0);
    useEffect(()=>{
        timer = setInterval(()=>{
        setCount(count+1);
        },1000)
    },[])

    const checkLoad=()=>{
        if(count<5){
        setStatus(false)
        }
    }
    return (
        <>
            <img src={src} className={`mw-100 ${showPlaceholder?'d-none':''}`} alt="cart icon" onLoad={checkLoad}/>
            <img src={placeholderImg} className={`mw-100 ${!showPlaceholder?'d-none':''}`}/>
        </>
    )
}

LazyLoader.propTypes = {
    page:PropTypes.string.isRequired,
    src:PropTypes.string.isRequired
}

export default LazyLoader