import React, { Component } from "react";
import SplashScreen from "../containers/SplashScreen/SplashScreen";
import slider1 from '../_assets/images/onboarding/profile.png';
import slider2 from '../_assets/images/onboarding/camera.png';
import slider3 from '../_assets/images/onboarding/basket.png';
import slider4 from '../_assets/images/onboarding/heart.png';
import left_angle from "../_assets/images/icons/left-angle.png"
import i18next from "i18next";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../_assets/scss/slider.scss";
import { Link } from "react-router-dom";
import FooterNav from "../components/FooterNav/FooterNav";
import Cookies from 'universal-cookie';
import { history } from "../_helpers";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { config } from "../_config";
const cookies = new Cookies();
class introSlider1 extends Component {
  constructor(props){
    super(props);
    document.title=`Coke & Go ${config.countryName} | introSlider1`;
    CapturePage({hitType:"pageview",page:"/introSlider1"})
    this.state = {
      currentSlide: 0,
      showLoader: sessionStorage.getItem("showTutorial"),
      isOldUser:localStorage.getItem('isOldUser')
    }
    this.handleNextSlider = this.handleNextSlider.bind(this);
    this.handlePreviousSlide = this.handlePreviousSlide.bind(this);
    const showLoader = sessionStorage.getItem("showTutorial")
    if(!localStorage.getItem('isOldUser')){
      localStorage.setItem('isOldUser','true');
    }
    else if(!showLoader){
      history.push('/socialLogin')
    }
    // cookies.set('isOldUser', 'true', { path: '/' });
  }
  handleNextSlider(){
    this.setState((state)=>({
      currentSlide: state.currentSlide+1
    }))
  }
  handlePreviousSlide(){
    this.setState((state)=>({
      currentSlide: state.currentSlide-1
    }))
  }
  render() {
    const showLoader = sessionStorage.getItem("showTutorial")
    const user = localStorage.getItem('user')
    return (
      <div className="custom-slider"> 
        <SplashScreen show={false}> </SplashScreen>{" "}
        {
          user ?
          <div className="gotoback">
            <Link to="/Support"><img src={left_angle} /></Link>
          </div>
          :
          this.state.currentSlide > 0
          &&
          <div className="gotoback">
            <img src={left_angle} onClick={this.handlePreviousSlide}/>
          </div>
        }
        
        <Carousel infiniteLoop={false} autoPlay={false} showThumbs={false}  showArrows={true} selectedItem={this.state.currentSlide}>
          <div className="custom-slider-item">
              <div className="slider-count slider-one">
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                  <table className="slider-heading">
                    <tbody>
                      <tr>
                        <td width={90} className="text-left "><img className="ml-4" src={slider1} /></td>
                        <td className="text-left"><span>{i18next.t('sliderOneTitle')}</span></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-start font_create_account">
                    {i18next.t('sliderOneDesc')}
                  </div>
                  <div className="">
                    <button className="theme-btn" onClick={this.handleNextSlider}>{i18next.t('nextButton')}</button>
                    <Link className="text-dark" to={showLoader?"/Support":"/socialLogin"} ><button className="theme-btn mt-2">{i18next.t('Skip introduction')}</button></Link>
                  </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="custom-slider-item">
              <div className="slider-count slider-two">
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <table className="slider-heading">
                      <tbody>
                      <tr>
                        <td width={90} className="text-left "><img className="ml-4" src={slider2} /></td>
                        <td className="text-left"><span>{i18next.t('sliderTwoTitle')}</span></td>
                      </tr>
                      </tbody>
                    </table>
                    <div className="text-start font_create_account">
                      {i18next.t('sliderTwoDesc')}
                    </div>
                    <button className="theme-btn" onClick={this.handleNextSlider}>{i18next.t('nextButton')}</button>
                  </div>
                </div>
              </div>
          </div>
          <div className="custom-slider-item">
              <div className="slider-count slider-three">
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <table className="slider-heading">
                      <tbody>
                      <tr>
                        <td width={90} className="text-left "><img className="ml-4" src={slider3} /></td>
                        <td className="text-left"><span>{i18next.t('sliderThreeTitle')}</span></td>
                      </tr>
                      </tbody>
                    </table>
                    <div className="text-start font_create_account">
                      {i18next.t('sliderThreeDesc')}
                    </div>
                    <button className="theme-btn" onClick={this.handleNextSlider}>{i18next.t('nextButton')}</button>
                  </div>
                </div>
              </div>
          </div>
          <div className="custom-slider-item">
              <div className="slider-count slider-four">
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <table className="slider-heading">
                      <tbody>
                      <tr>
                        <td width={90} className="text-left "><img className="ml-4" src={slider4} /></td>
                        <td className="text-left"><span>{i18next.t('sliderFourTitle')}</span></td>
                      </tr>
                      </tbody>
                    </table>
                    <div className="text-start font_create_account">
                      {i18next.t('sliderFourDesc')}
                    </div>
                    {
                      showLoader ?
                      <Link to="/support" className="text-dark"><button className="theme-btn">{i18next.t('Finish')}</button></Link>
                      :
                      <Link to="/socialLogin" className="text-dark"><button className="theme-btn">{i18next.t('Register')}</button></Link>
                    }
                  </div>
                </div>
              </div>              
          </div>
          
        </Carousel>  
        {
          user &&
          <FooterNav /> 
        }     
      </div>
    )
  }
}

export default introSlider1;

