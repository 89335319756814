import React, { Component } from "react";
import { history } from "../_helpers";
import "../assets/scss/defaults.scss";
import Supportlinks from "../containers/supportlinks/supportlinks";
import BackWhiteData from "../components/BackWhiteData/BackWhiteData";
import i18n from "i18next";
import arrow from "../assets/images/icons/arrow-black.png"
import { Link } from "react-router-dom";
import ModelPopup from "../components/Model-Popup/Popup";
import Heading from "../containers/Heading/Heading";
import FooterNav from "../components/FooterNav/FooterNav";
import { config } from '../_config'
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { utilService } from "../_services/util.service";
class Support extends Component {
  constructor(props){
    super(props);
    document.title=`Coke & Go ${config.countryName} | support`;
    CapturePage({hitType:"pageview",page:"/support"})    
    this.state ={
      isOffline:false
    }
    this.closingisOfflinePopup = this.closingisOfflinePopup.bind(this);
    this.sendMetricsTracking();
  }

  sendMetricsTracking = () => {
    utilService.metricsTracking("SupportPage");
  }

  closingisOfflinePopup(){
    this.setState({
      isOffline:false
    })
  }

  render() {
    return (
      <div className="h-100">        
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
              <div className="col-12">
                <Heading title={i18n.t("Nav_Support")} firstIcon={true} secondIcon={false} backLink={this.props.location.state?{pathname:this.props.location.state,state:'fromHome'}:"/ScanButton"} LinkIcon="back"/> 
                <div className="supportLinks pb-3">
                  <div className="heightfull">
                    <Supportlinks></Supportlinks>
                  </div>
                </div>
                <FooterNav />
              </div>
            </div>
          </div>
        </div>
        {
          this.state.isOffline ? (
              <ModelPopup
              openPopup={this.state.isOffline}
              closePopup={this.closingisOfflinePopup}
              message={i18n.t("ConnectionIssue")}
              imagetype={"error"}
              />
            ) : null
          }
      </div>   
    );
  }
}
export default Support;
