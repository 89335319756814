import React, { Component } from "react";
import "./ResetPasswordData.scss";
import EyeIcon from "../../assets/images/icons/hide.png";
import HiddenEyeIcon from "../../assets/images/icons/visible.png";
import EyeIcon1 from "../../assets/images/icons/invisible-eye.png";
import HiddenEyeIcon1 from "../../assets/images/icons/visible-eye.png";
import i18n from "i18next";
import { titleCase } from "../../_helpers";

class ResetPasswordData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openeye: true,
      inputType: false,
      openeye1: true,
      inputType1: false
    };
    this.toggleImage = this.toggleImage.bind(this);
    this.getImageName = this.getImageName.bind(this);
    this.toggleImage1 = this.toggleImage1.bind(this);
    this.getImageName1 = this.getImageName1.bind(this);
  }

  toggleImage() {
    this.setState({
      openeye: !this.state.openeye,
      inputType: !this.state.inputType
    });
  }
  getImageName() {
    return this.state.openeye ? "showeye" : "hideeye";
  }

  toggleImage1() {
    this.setState({
      openeye1: !this.state.openeye1,
      inputType1: !this.state.inputType1
    });
  }
  getImageName1() {
    return this.state.openeye1 ? "showeye" : "hideeye";
  }
  render() {
    const user = localStorage.getItem('user')
    const imagesPath = {
      showeye: user?EyeIcon1:EyeIcon,
      hideeye: user?HiddenEyeIcon1:HiddenEyeIcon
    };
    return (
      <div className="">
        <div className="">
          <div className="form-group " >
            <div className="position-relative">
            <label>{i18n.t('NewPassword')}</label>
            <input
              id="password"
              type={this.state.inputType ? "text" : "password"}
              className="form-control border-radius-none pl-0"
              placeholder={titleCase(i18n.t("NewPassword"))}
              name="password"
              value={this.props.password}
              onChange={this.props.onChangePassword}
              aria-label="John"
              aria-describedby="basic-addon2"
            />
            <img
                src={imagesPath[this.getImageName()]}
                alt="eye"
                onClick={this.toggleImage}
                id="eye"
                height="27px"
                className="seepassword mr-2"
              /> 
            </div>
              {
                this.props.isInvalidPassword &&              
                <label className="text-danger">{i18n.t("ValidateMsg_ForInvalidFormatPassword")}</label>
              }
          </div>
          <div className="form-group ">
            <div className="position-relative">
            <label>{i18n.t('ConfirmPassword')}</label>
            <input
              id="confirmPassword"
              type={this.state.inputType1 ? "text" : "password"}
              className="form-control border-radius-none pl-0"
              placeholder={i18n.t("ConfirmNewPassword")}
              name="confirmPassword"
              value={this.props.confirmPassword}
              onChange={this.props.onChangePassword}
              aria-label="John"
              aria-describedby="basic-addon2"
            />
            <span>
              <img
                src={imagesPath[this.getImageName1()]}
                alt="eye"
                onClick={this.toggleImage1}
                id="eye1"
                height="27px"
                className="seepassword mr-2"
              />
            </span>
            </div>
            {
              this.props.isPasswordMatched &&              
              <label className="text-danger">{i18n.t("ValidateMsg_ForPasswordResetNotMatch")}</label>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordData;
