import Modal from 'antd/lib/modal/Modal'
import useModal from "react-hooks-use-modal";
import i18next from 'i18next';
import React from 'react'
import { useEffect } from 'react';

export  const UnderMaintenance = ({status}) => {
    const [Modal, open, close, isOpen] = useModal();
    if(!isOpen){
        open()
    }
    return (
        <div className="model">
            <div className="PopupCenter">
                <Modal>
                    <div className="popup-box">
                        <div className="whitebody-popup" style={{borderRadius:'25px',overflow:'hidden'}}>
                            <h6 style={{fontWeight:'600'}}>{i18next.t("Maintenance!")}</h6>
                            <h6 style={{fontWeight:'600'}} className="mb-5">{i18next.t("We are improving for you, Please try again later")}</h6>
                            <h5 className={`modalbtnFullWidth Errorbg py-4`}></h5>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}
