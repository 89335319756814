import React, { Component } from "react";
import Heading from "../containers/Heading/Heading";
import i18next from "i18next";
import terms_english from "../assets/pdf/english/terms-of-use.pdf";
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { ClipLoader } from "react-spinners";
import { history } from "../_helpers";
import { config } from "../_config";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
class Termsofuse extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | Terms-of-use`;
    CapturePage({hitType:"pageview",page:"/Terms-of-use"})
    this.state = {
      totalPage:0,
      list:[],
      isLoading:true
    }
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
 
  }
  onDocumentLoadSuccess(data){
    let ls = [];
    for (let i = 1; i <= data.numPages; i++) {
      if(i>1){
        ls.push(i);
      }
    }
    this.setState({
      totalPage:data.numPages,
      list:ls,
      isLoading:false
    })
    console.log(data.numPages)
  }
  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <div className="RegisterSCreen">
        <div className="container">
          <Heading title={i18next.t("termsofuse")} firstIcon={true} secondIcon={false} backLink={this.props.location.state} LinkIcon="back"/>
          <div className="row">
            <div className="col-12 p-0">
              <div id="ResumeContainer ">
                
                <Document className={"PDFDocument"} loading={<div style={style}>
                  <ClipLoader size={50} color={"#FF0000"} loading={this.state.isLoading}/>
                </div>} file={terms_english} onLoadSuccess={this.onDocumentLoadSuccess} className="w-100 pdfview" >
                  <Page className={"PDFPage PDFPageOne"}  pageNumber={1} renderTextLayer={false} renderInteractiveForms={false} ></Page>
                  {
                    this.state.list.map((item,key)=>{
                      return <Page key={key} className={"PDFPage"}  pageNumber={item} renderTextLayer={false} renderInteractiveForms={false} ></Page>
                    })
                  }
                </Document>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Termsofuse;
