import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { userActions, alertActions } from "../_actions";
import { connect } from "react-redux";
import { history } from "../_helpers";
import { config } from '../_config';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
class Logout extends Component {
    constructor(props) {
        super(props);
        document.title=`Coke & Go ${config.countryName} | Logout`;
        CapturePage({hitType:"pageview",page:"/Logout"})    
        // reset login status        
        this.props.logout();
        history.push("/socialLogin", { state: {expiry:true}})
    }
    render(){
        return (
            <>
                {/* <Redirect
                    to={{
                        pathname: "/login",
                        hash:{
                            
                        }
                    }}
                    /> */}
            </>
        )
    }
}
function mapState(state) {
    const { loggingIn } = state.authentication;
    const alert = state.alert;
    return {
        loggingIn,
        alert,
    };
}
const actionCreators = {
    logout: userActions.logout
};

const connectedLogoutPage = withRouter(connect(mapState, actionCreators)(Logout));
export { connectedLogoutPage as Logout };