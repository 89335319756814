import React, { Component } from 'react'
import './DoorUnlock.scss';
import alertlogo from "../../assets/images/icons/alert.svg";
import coolerimg from "../../assets/images/icons/coolerdoor.png";

 class Door extends Component {
    render() {
        return (
            <div className="row">
                <div className="blankbox col-12"></div>
                <div className="Doorpage col-12 pb-3">
                    <div className="whitebody-big doorbox text-center">
                        <div className="Doorunlock-box">
                            <p>
                            Door in unlocked <br/>
                            pick product that you want.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="circle-imgbox">
                        <div className="whitecircle">
                            <img src={coolerimg} alt="logo" />
                        </div>
                    </div>
                </div>
                <div className=" col-12">
                    <div className="alertbox">
                        <img src={alertlogo} alt="logo" />
                        <p>
                            Please close the door after purchase
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Door
