import { config } from "../_config";
let applicationTypeId = config.applicationTypeId

var IssueDropDown = [
        "Incorrect charges",
        "Receipt",
        "Cooler not working",
        "How to use",
        "Account",
        "Others"
        ]
if(applicationTypeId == 9)
{
   IssueDropDown.push("Manual Adjustment")
//    IssueDropDown[0] = "Manual Adjustment"
}

export default IssueDropDown;