import React, { Component } from "react";
import "./loginform.scss";
import HiddenEyeIcon from "../../assets/images/icons/visible.png";
import EyeIcon from "../../assets/images/icons/hide.png";
import i18n from "i18next";
import { Link } from "react-router-dom";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openeye: true,
      inputType: false
    };
    this.toggleImage = this.toggleImage.bind(this);
    this.getImageName = this.getImageName.bind(this);
  }
  toggleImage() {
    this.setState({
      openeye: !this.state.openeye,
      inputType: !this.state.inputType
    });
  }
  getImageName() {
    return this.state.openeye ? "showeye" : "hideeye";
  }
  render() {
    const imagesPath = {
      showeye: EyeIcon,
      hideeye: HiddenEyeIcon
    };
    const imageName = this.getImageName();
    return (
      <>
        <div className="form-group"> 
          <label className="mb-0">{i18n.t("EmailAddress")}</label>         
          <input
            id="Email"
            type="text"
            className="form-control shadow-none border-radius-none pl-0"
            name="Email"
            placeholder={i18n.t("EnterEmail")}
            aria-label="Email"
            aria-describedby="basic-addon2"
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
        </div>

        <div className="form-group position-relative">
          <label className="mb-0">{i18n.t("Password")}</label>  
          <input
            id="password"
            type={this.state.inputType ? "text" : "password"}
            className="form-control shadow-none border-radius-none pl-0"
            name="password"
            placeholder={i18n.t("EnterPassword")}
            aria-label="Username"
            aria-describedby="basic-addon1"
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
            <img
              src={imagesPath[imageName]}
              alt="eye"
              onClick={this.toggleImage}
              id="eye"
              className="seepassword"
              height="27px"
            />
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-6">
              <div className="form-check">
                <input className="form-check-input ml-0" type="checkbox" name="remember" value="true" id="rememberMe" />
                <label className="form-check-label text-white ml-4" htmlFor="rememberMe">{i18n.t('RememberMe')}</label>
              </div>
            </div>
            <div className="col-6 text-right">
              <Link to="/ForgotPassword" className="text-white text-underline">{i18n.t('ForgotPassword')}</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LoginForm;
