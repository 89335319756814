import React, { Component } from 'react'
import "../assets/scss/defaults.scss";
import Door from '../containers/DoorUnlock';
import { config } from "../_config";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";


class DoorUnlocked extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | DoorUnlocked`;
        CapturePage({hitType:"pageview",page:"/DoorUnlocked"})
        
    }
    render() {
        return (
            <div className="DooeUnlocked">
                <div className="container">
                    <Door></Door>             
                </div>
            </div>
        )
    }
}

export default DoorUnlocked
