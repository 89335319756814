import PropTypes from "prop-types";
import React from "react";
import Productlist from "../productList";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { config } from "../../../_config";

const HeroSliderOneSingle = ({ data, currencyCode, sliderClassName }) => {
  const centerProducts = {
    color: "red",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  };
  return (
    <div
      className={` ${
        sliderClassName ? sliderClassName : ""
      } d-flex align-items-center`}
    >
      <div className="product-list-box" style={{paddingBottom:config.applicationTypeId==9?"120px":"50px"}}>
        {/* <div className="cooler-id">
          <p className="text-center clrGrey">
            <Trans i18nKey="CoolerId">Cooler ID </Trans> : {coolerId}
          </p>
        </div> */}
        <div className="cooler-product">
          {data && data.products.length > 0 ? (
            data.products.map((single, key) => {
              return (
                <Productlist
                  data={single}
                  key={key}
                  currencyCode={currencyCode}
                />
              );
            })
          ) : (
            <div style={centerProducts}>{i18next.t("No Products Available")}</div>
          )}
        </div>
      </div>
    </div>
  );
};

HeroSliderOneSingle.propTypes = {
  data: PropTypes.object,
};

export default HeroSliderOneSingle;
