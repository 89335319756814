import i18next from 'i18next';
import React from 'react'
import country  from "iso-country-currency"
import { config } from "../../_config";
import "./index.scss"
import LazyLoader from '../LazyLoader/LazyLoader';
export const ProductCard = (data) => {
    const {productOtherUrl, perProductPrice, productCount, discountPercentage,discountAmount} = data.data;
    const currencyCode = config.currencyCode
    let lang = currencyCode=="USD"?"en-US":config.langCode
    let options = { style: 'currency', currency: currencyCode };
    let total = perProductPrice*productCount;
    let amount = new Intl.NumberFormat(lang, options).format(perProductPrice)
    let discountedamount = new Intl.NumberFormat(lang, options).format(perProductPrice-(perProductPrice*discountPercentage/100))
    const productName = data.data.productLocalName==""?data.data.shortName==""?data.data.productName:data.data.shortName:data.data.productLocalName
  return (
    <>
        <div className='productImage' style={{height:'120px'}}>
            {/* <img src={productOtherUrl} /> */}
            <LazyLoader src={productOtherUrl} page='ordersummary' />
            {
                discountPercentage>0 && 
                <div className='disount-percent'>{discountPercentage}% {i18next.t("OFF")}</div>
            }
        </div>
        <h6>{productName}</h6>
        <div className="price">
            <div className='d-inline-block'>
                {i18next.t("Qty")}: {productCount}
            </div>
            <div className='d-inline-block float-right'>
                {
                    discountAmount==0 ?
                    amount
                    :
                    <div><span style={{color:"#777",textDecoration:"line-through"}}>{amount}</span> <span className='text-black'>{discountedamount}</span></div>
                }
            </div>
        </div>
    </>
  )
}
