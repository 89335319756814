import React, { Component } from "react";
import "./NoLongerScreen.scss";
import { config } from "../../_config";
import { CaptureEvent, CapturePage } from "../../_helpers/googleAnalytics";

export default class MannualCorrected extends Component {
  constructor(props){
    super(props);
    document.title=`Coke & Go ${config.countryName} | MannualCorrected`;
    CapturePage({hitType:"pageview",page:"/MannualCorrected"})
  }
  render() {
    return (
      <body className="NoLongerBody">
        <div className="">
          <div className="wrapper">
            <div className="box">
              <h1 className="NoHeading" style={{visibility:'hidden'}}>401</h1>
              <p className="Para">You have already update the corrected data</p>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
