import React from 'react'
import PropTypes from 'prop-types'
import OtpInput from 'react-otp-input';
import useModal from "react-hooks-use-modal";
import "./style.scss"
function OtpPopUp(props) {
    const {value,handleVerify,showModal,desc,handleChangeOTP} = props;

    const [Modal, open, close, isOpen] = useModal();

    if (showModal && !isOpen) {
        open();
    }
    return (
        <>
            <div className="modal">
                <div className="PopupCenter">
                    <Modal>
                        <div className="popup-box">
                            <div className="whitebody-popup" >
                                <div className='payuotp'>
                                    <p>{desc}</p>
                                    <div className='text-center'>
                                        <OtpInput
                                            value={value}
                                            onChange={handleChangeOTP}
                                            numInputs={6}
                                            renderSeparator={<span> </span>}
                                            renderInput={(props) => <input {...props} />}
                                            containerStyle={{justifyContent:'center'}}
                                        />
                                        <button className='btn mt-3 btn-theme-dark w-auto px-5' onClick={handleVerify}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}

OtpPopUp.propTypes = {
    value: PropTypes.string.isRequired,
    handleVerify:PropTypes.func.isRequired,
    showModal:PropTypes.bool.isRequired,
    showModal:PropTypes.bool.isRequired
}

export default OtpPopUp
