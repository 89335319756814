import React, { Component } from "react";
import "../assets/scss/defaults.scss";
import i18n from "i18next";
import Heading from "../containers/Heading/Heading";
import FooterNav from "../components/FooterNav/FooterNav";
import { history } from "../_helpers";
import { osVersion, isIOS } from "react-device-detect";
import Button from "../components/button";
import { config } from "../_config";
import ModelPopup from "../components/Model-Popup/Popup";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";
class LanguageSetting extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | LanguageSetting`;
    CapturePage({hitType:"pageview",page:"/LanguageSetting"})    
        
    let currentOSVer = osVersion.substring(0,4);
    if(!config.isMultiLangEnabled){
      history.push('/Setting')
    }
    this.state = {
        selectedLanguage: localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage,
        showLanguagePopup: false,
    };
    }

  handleSelectedLanguage = (e) => {
    e.preventDefault();
    localStorage.setItem('selectedLanguage',this.state.selectedLanguage);
    i18n.changeLanguage(this.state.selectedLanguage);
    this.setState({
        showLanguagePopup: true
    })
  }

  handleLanguageChange = (e) => {
    this.setState({
        selectedLanguage: e.target.value
    })
  }

  closeLanguagePopup = () => {
    this.setState({
        showLanguagePopup: false
    })
    // window.location.reload();
}


  render() {
    const user = decryptData(localStorage.getItem("user"));
    let capsSelectedLanguage = this.state.selectedLanguage.charAt(0).toUpperCase() + this.state.selectedLanguage.slice(1).toLowerCase();
    return (
      <div className="bg-theme h-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
              <div className="col-12">
                <Heading title={i18n.t("Language Setting")} align="center" firstIcon={true} secondIcon={false} backLink="/Setting" LinkIcon="back" />
                            <form className="pt-3" onSubmit={this.handleSelectedLanguage}>
                                {
                                  config.languageList.map((lang,key)=>{
                                    return <div>
                                            <label style={{textTransform:"capitalize"}}>{i18n.t(lang)}</label>
                                            <div style={{ float: 'right' }}>
                                                <input className="langRadioBtns"
                                                    id="selectedLang"
                                                    type='radio' name='selectedLang' value={lang} 
                                                    onClick={this.handleLanguageChange}
                                                    checked={this.state.selectedLanguage === lang ? true : false}
                                                />
                                            </div>
                                            <hr className="hrLine mt-0"/>
                                        </div>
                                  })
                                }
                                {/* <div >
                                    <label >{i18n.t("French")}</label>
                                    <div style={{ float: 'right' }}>
                                        <input 
                                            id='selectedLang'
                                            className="langRadioBtns"
                                            type='radio' name='selectedLang' value='french' 
                                            onClick={this.handleLanguageChange}
                                            checked={this.state.selectedLanguage === 'french' ? true : false}
                                        />
                                    </div>
                                    <hr className="hrLine mt-0"/>
                                </div> */}
                                <div className="mybtn col-12">
                                    <div className="text-center py-2">
                                        <Button size="large" btnType="submit" text={i18n.t('Select')} className="btn mt-3 btn-theme-dark" />
                                    </div>
                                </div>
                            </form>
                            {
                                this.state.showLanguagePopup &&
                                <ModelPopup
                                    openPopup={this.state.showLanguagePopup}
                                    closePopup={this.closeLanguagePopup}
                                    message={`${i18n.t("language set message")} ${capsSelectedLanguage}`}
                                    imagetype={"success"}
                                />
                            }
                {
                  user &&
                  <FooterNav />
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LanguageSetting;
