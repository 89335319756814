import React, { Component } from "react";
import Heading from "../containers/Heading/Heading";
import i18next from "i18next";
import { ClipLoader } from "react-spinners";
import FooterNav from "../components/FooterNav/FooterNav";
import { userService } from "../_services/user.service"
import left_angle from "../_assets/images/icons/left-angle.png"
import { history } from "../_helpers";
import { config } from '../_config';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";
class PolicyPage extends Component {
  constructor(props) {
    super(props);    
    const checkRequest = props.location.search.replace('?isMobile=','');
    document.title=`Coke & Go ${config.countryName} | policy`;
    CapturePage({hitType:"pageview",page:"/policy/:documentType"})
    this.state = {
        isLoading:true,
        checkRequest: checkRequest,
        content:'',
        title:'',

    }   
    this.handleBack = this.handleBack.bind(this);
  }
  componentDidMount(){
    let that = this
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    if(user && user.isTermAndCondition && config.applicationTypeId == 20){
      user.isTermAndCondition = false;
      userService.update(user)
      .then(result=>{
        if(result.success){
          that.getPolicyDocs();
        }
      })
    }    
    else{
      that.getPolicyDocs();
    }
  }
  getPolicyDocs(){
    let that = this
    let doc = {
      documentType: this.props.match.params.documentType
    }
    let title = [
      {
        docType: 'ContactUs',
        title: 'Contact Us'
      },
      {
        docType: 'FAQDocuments',
        title: 'FAQ'
      },
      {
        docType: 'Marketing',
        title: 'Marketing Terms'
      },
      {
        docType: 'PrivacyPolicy',
        title: 'Privacy Policy'
      },
      {
        docType: 'TermsOfSale',
        title: 'Terms Of Sale'
      },
      {
        docType: 'TermsOfUse',
        title: 'Terms Of Use'
      }
    ]
    title.map((data, key) => {
      if (data.docType == this.props.match.params.documentType) {
        that.setState({
          title: data.title
        })
      }
    })

    userService.getPolicy(doc)
      .then(result => {
        if (result.success) {
          if (result.data == "") {
            window.location.href = result.Url
            // that.setState({
            //   isLoading:false
            // })
            // document.getElementById('iframe1').src = result.Url
          }
          else {
            that.setState({
              isLoading: false,
              content: result.data
            })
            document.getElementById('iframe1').contentWindow.document.write(result.data);
            var iframe = document.getElementById('iframe1');
            var doc = (iframe.contentDocument) ? iframe.contentDocument : iframe.contentWindow.document;

            var anchors = doc.getElementsByTagName('a');
            for (var i = 0; i < anchors.length; i++)
              anchors[i].target = '_parent';

          }
        }
        else {
          that.setState({
            isLoading: false,
            content: result.data
          })
          document.getElementById('iframe1').contentWindow.document.write(result.data);
          var iframe = document.getElementById('iframe1');
          var doc = (iframe.contentDocument) ? iframe.contentDocument : iframe.contentWindow.document;

          var anchors = doc.getElementsByTagName('a');
          for (var i = 0; i < anchors.length; i++)
            anchors[i].target = '_parent';
        }
      })
  }
  handleBack(){
    // console.log("window.back",window.back);
    window.history.back();
  }
 
  render() {
    var { checkRequest } = this.state;
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    return (
      <div className="main-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="content">           
                {
                  !checkRequest &&
                    <>
                      <div className="heading">
                        <div className="row">
                            <div className="col-1">
                              <img src={left_angle} className="backImg" onClick={this.handleBack} />
                            </div>
                            <div className="col-10">
                              <p className={`title mb-0 text-dark text-left`} >{i18next.t(this.state.title)}</p>
                          </div>
                          </div>
                        </div>
                    </>
                    // <Heading title={i18next.t(this.state.title)} firstIcon={true} secondIcon={false} backLink={} LinkIcon="back"/> 
                }
                <div className="main-content" style={user?{}:{height:'auto'}}>
                    <iframe id="iframe1" className="policy-frame" style={user?{}:{height:'calc(100vh - 100px)'}} />
                </div>    
                {
                  user &&
                  <FooterNav />
                }                          
              </div>
              
            </div>
            {
                this.state.isLoading &&
                <div className="spinner">
                    <div style={style}>
                    <ClipLoader size={50} color={"#000000"} />
                    </div>
                </div>
                }
          </div>
        </div>
      </div>
    )
  }
}

export default PolicyPage;
