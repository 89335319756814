import React, { Component } from "react";
import qrborder from "../assets/images/icons/qrborder.svg";
import QrReader from "react-qr-reader";
import { history } from "../_helpers";
import { pendingpaymentService } from "../_services";
import ClipLoader from "react-spinners/ClipLoader";
import CoolerPopup from "../components/Model-Popup/Popup";
import i18n from "i18next";
import FooterNav from "../components/FooterNav/FooterNav";
import { config } from "../_config";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { encryptData } from "../_helpers/encryptData";

export class Scanner extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | Scanner`;
    CapturePage({hitType:"pageview",page:"/Scanner"})
    this.state = {
      result: localStorage.getItem("i18nextLng") == "en" ? "No result" : "No result",
      loading: false,
      connectionLost: false,
      errormsg:""
    };
    
    this.detectmobileCamera = this.detectmobileCamera.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.closingPopup = this.closingPopup.bind(this);
    // this.qrCodeSuccessCallback = this.qrCodeSuccessCallback.bind(this);
    // window.addEventListener('popstate', (event) => {
    //   html5QrCode.stop();
    // });
  }

  componentDidMount(){
    // html5QrCode = new Html5Qrcode("reader");
    // const qrCodeSuccessCallback = (decodedText, decodedResult) =>{
    //   console.log(decodedText,decodedResult)
    // }
    // const  onScanFailure=(error)=>{
    //   // handle scan failure, usually better to ignore and keep scanning.
    //   // for example:
    //   console.warn(`Code scan error = ${error}`);
    // }
    // html5QrCode.start(
    //   { facingMode: "user" },
    //   qrConfig,
      
    //   qrCodeSuccessCallback,
    //   onScanFailure
    // );
  } 

  qrCodeSuccessCallback = (decodedText, decodedResult) => {
    console.log(decodedText,decodedResult)
  };
  isValidUrl = data => {
    try {
      new URL(data);
      return true;
    } catch (_) {
      return false;
    }
  };

  handleScan = data => {
    CaptureEvent({
      category:"scan qr",
      action:"button clicked",
      label:"scanQR"
    })
    if (this.isValidUrl(data)) {
      var qrcode = new URL(data).searchParams.get("qr");
      if (qrcode) {
        //check whether pending payment or not
        // let user = JSON.parse(localStorage.getItem("user"));
        // let authUser = user.authToken;
        // let Iduser = user.userId;
        localStorage.setItem("qrcode", encryptData(qrcode));
        history.push({
          pathname: "/Home"
        });
      //   this.setState({
      //     loading: true
      //   });

      //   pendingpaymentService
      //     .getPendingPayment({
      //       authUser,
      //       Iduser
      //     })
      //     .then(pending => {
      //       this.setState({
      //         loading: false
      //       });
      //       if (pending.success && pending.message === "No Records Found") {
      //         //no pending amount
      //         this.setState({
      //           result: qrcode
      //         });
      //         localStorage.setItem("qrcode", qrcode);
      //         history.push({
      //           pathname: "/Home"
      //         });
      //       } else if (!pending.success) {
      //         this.setState({
      //           connectionLost: true,
      //           errormsg:i18n.t("ConnectionIssue")
      //         });
      //       } else {
      //         history.push({
      //           pathname: "/PendingAmount",
      //           state: {
      //             pendingAmount: pending.data.amount,
      //             authtoken: authUser,
      //             purchaseNumber: pending.data.purchaseNumber,
      //             userId: Iduser
      //           }
      //         });
      //         localStorage.removeItem("user"); //so that user cant login
      //         localStorage.removeItem("deviceData");
      //       }
      //     });
      }
    }
  };

  

  closingPopup() {
    this.setState({
      connectionLost: false
    });
    history.push("/ScanButton")
  }

  handleError = err => {
    console.error('error',err);
  };

  detectmobileCamera() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  render() {
    var cameraMode = this.detectmobileCamera() ? "environment" : "user";
    console.log("cameraMode----", cameraMode);
    const style = {
      position: "fixed",
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    };
    return (
      <div>
          <div className="supportText">
            {i18n.t('ScannerSupportText')}
          </div>
          <QrReader
            delay={3000}
            onError={this.handleError}
            onScan={this.handleScan}
            className="scanner"
            facingMode={cameraMode}
          />        
          <div style={style}>
            <ClipLoader
              size={50}
              color={"#FF0000"}
              loading={this.state.loading ? true : false}
            />
          </div>
          <FooterNav />
          {this.state.connectionLost ? (
            <CoolerPopup
              openPopup={this.state.connectionLost}
              closePopup={this.closingPopup}
              message={this.state.errormsg}
            />
          ) : null}
      </div>
    );
  }
}
