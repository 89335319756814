import {
    addedProductsConstant
} from '../_constants';

export function addedProductsList(state = {}, action) {
    switch (action.type) {
        case addedProductsConstant.INITIAL_PRODUCTS:
            return action.product
        case addedProductsConstant.ADD_PRODUCTS:
            return [
                ...state,
                ...action.product.map(obj => ({
                    ...obj,
                    orderUpdateReasonId: 1,
                    devicequantity: 0
                })) //orderid is 1,since its added new and deviceqty is zero since its added by user
            ]
        case addedProductsConstant.REMOVE_PRODUCTS:
            return state.filter((i) => i.productId !== action.product.productId);
        case addedProductsConstant.UPDATE_PRODUCT:
            return state.map((prod) => {
                if (prod.productId === action.productid) {
                    if (action.factor === 'increment') {
                        prod.quantity = prod.quantity + 1
                        return {
                            ...prod
                        }
                    } else if (action.factor === 'decrement') {
                        prod.quantity = (prod.quantity - 1) >= 1 ? (prod.quantity - 1) : 1
                        return {
                            ...prod

                        }

                    }

                } else {
                    return prod
                }
            })
        case addedProductsConstant.UPDATE_CARTPRODUCT:
            return state.map((prod) => {
                if (prod.productId === action.productid) {
                    prod.orderUpdateReasonId = 2
                    return {
                        ...prod
                    }
                } else {
                    return prod
                }
            })
        case addedProductsConstant.DELETE_PRODUCT:
            // return state.filter((prod) => prod.productId !== action.productid)
            return state.map((prod) => {
                if (prod.productId === action.productid) {
                    prod.orderUpdateReasonId = 3
                    return {
                        ...prod
                    }
                } else {
                    return prod
                }
            })
        default:
            return state
    }
}