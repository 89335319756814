import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "./ForgotPassword.scss";
import i18n from "i18next";
import { titleCase } from "../../_helpers";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
  }
  handleChange() {
    this.props.handleChange();
  }
  render() {
    return (
      <div className="">
        <div className="">
          <div className="pt-5 form-group">
            <label className="text-white text-capitalize">{i18n.t("EmailID")}</label>
            <input
              id="emailid"
              type="text"
              className="form-control border-radius-none pl-0"
              placeholder={titleCase(i18n.t("EnterEmail"))}
              name="emailid"
              value={this.props.email.emailId}
              onChange={this.props.handleChange}
              aria-label="John"
              aria-describedby="basic-addon2"
              autoComplete="false"
            />
          </div>
        </div>
        {/* <div className="ResendLink col-12">
                    <div className="float-right py-3">
                        <Link to={process.env.PUBLIC_URL + "/login"} >
                            <span className="clrblack">Resend?</span>
                        </Link>
                    </div>
                </div> */}
      </div>
    );
  }
}

export default ForgotPassword;
