import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/icons/logo.png";
import back from "../../assets/images/icons/back.png";
import { config } from "../../_config";

const PublicHeaderUS = ({ Title, subHeading, Desc, Required, backLink,backStep }) => {
    return (
        <div className="row justify-content-center publicheader">
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center p-0">
                <div className="col-12 p-0">
                    {
                        backStep &&
                        <button onClick={backStep} className="p-0" style={{border: 'none', backgroundColor: 'transparent', marginTop:'15px'}}><img src={back} className="ml-2"/></button>}<br/>
                    { 
                        backLink &&
                        <Link to={backLink}><img src={back} className="ml-1 mb-2 mb-3"/></Link>}<br/>
                    
                    <img className="logo" src={logo} />
                    <h2 className='text-black mt-3' style={{fontSize: '25px', fontWeight: 'bolder', textShadow:'1px 0 #000000', letterSpacing: '1px'}}>{Title}</h2>
                    {
                        subHeading &&
                        <h6 className="text-black" style={{fontSize: '14px', fontWeight: '700'}}>{subHeading}</h6>
                    }
                    {
                        Desc  &&                        
                        <p className="text-black m-0" style={{fontSize: '11px', fontWeight: '500'}}>{Desc}</p>
                    }
                    {
                        Required &&         
                        <p className="text-black" style={{fontSize: '11px', fontWeight: '500'}}>*{Required}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default PublicHeaderUS