import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../_assets/images/icons/logo.png";
import Button from "../components/button";
import RegisterForm from "../containers/RegisterForm";
import "../assets/scss/defaults.scss";
import BackData from "../components/BackData/BackData";
import { connect } from "react-redux";
import { userActions,alertActions } from "../_actions";
import CoolerPopup from "../components/Model-Popup/Popup";
import { history } from "../_helpers";
import i18next from "i18next";
import moment from "moment";
import validator from 'validator';
import { ClipLoader } from "react-spinners";
import Heading from "../components/PublicHeader/publicHeader";
import { config } from "../_config";
import { Trans } from "react-i18next";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData, validateString } from "../_helpers/encryptData";
import CountryList from "../components/CountryList/CountryList";

class SignUp extends Component {
  constructor(props) {
    document.title=`Coke & Go ${config.countryName} | signup`
    super(props);
    CapturePage({hitType:"pageview",page:"/signUp"})    
    if (props.location.state && props.location.state.user) {
      this.state = props.location.state.user;
    } else {
      this.state = {
        invalidPassword: false,
        isPasswordMatched: false,
        isInvalidEmail:false,
        isLoading:false,
        user: {
          firstName: "",
          lastName: "",
          Primaryemail: "",
          mobileNumber: "",
          birthDate:"",
          password: "",
          confirmpassword: "",
          privacy: false,
          mailMarketing:false,
          smsMarketing:false,
          cpf:"",
          qrcode: localStorage.getItem("qrcode")
            ? decryptData(localStorage.getItem('qrcode'))
            : ""
        },
        submitted: false,
        showPopup: false,
        popupmsg: "",
        succespopupStringMsg: "",
        isOpenCountryList:false,
        selectedCountry:{
          name: "Singapore",
          dial_code: "+65",
          code: "SG"
        }
      };
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.closingPopup = this.closingPopup.bind(this);
    this.handletDob = this.handletDob.bind(this);
  }

  handleChange(event) {
    var testString=true;
    const { name, value } = event.target;
    const { user } = this.state;
    if(name=='mobileNumber'){
      let mob = value.split('-').join('');
      mob = mob.split('(').join('');
      mob = mob.split(' ').join('');
      mob = mob.split(')').join('');
      mob = mob.split('_').join('');
      this.setState({
        user: {
          ...user,
          [name]: mob,
          smsMarketing:mob?true:false
        },
      });
    }
    else if(name=='cpf'){
      let cpf = value.split('-').join('');
      cpf = cpf.split('.').join('');
      cpf = cpf.split('_').join('');
      this.setState({
        user: {
          ...user,
          [name]: cpf,
        },
      });
    }
    else if(name=="Primaryemail"){
      if(validator.isEmail(value)){
        this.setState({
          isInvalidEmail:false
        })
      }
      else{
        this.setState({
          isInvalidEmail:true
        })
      }
      this.setState({
        user: {
          ...user,
          [name]: value,
        },
      });
    }
    else if(name=="password"){
      let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
      const validationRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
      if(format.test(value) && validationRegExp.test(value)){
        this.setState({
          invalidPassword:false
        })      
        if (user.confirmpassword === value) { 
          this.setState({
            isPasswordMatched:false
          })
        }
        else{
          this.setState({
            isPasswordMatched:user.confirmpassword==""?false:true
          })
        }  
      }
      else{
        this.setState({
          invalidPassword:true
        })
      }
      this.setState({
        user: {
          ...user,
          [name]: value,
        },
      });
    }
    else if(name=="confirmpassword"){
      if (user.password === value) { 
        this.setState({
          isPasswordMatched:false
        })
      }
      else{
        this.setState({
          isPasswordMatched:true
        })
      }
      this.setState({
        user: {
          ...user,
          [name]: value,
        },
      });
    }
    else{
      if(name=='firstName' || name=='lastName'){
        testString = validateString(value,'string');    
      }
      if(testString){
        this.setState({
          user: {
            ...user,
            [name]: value,
          },
        });
      }
    }
  }
  checkNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
    return true;
  }

  handletDob(event){
    var value = event.target.value
    let dob = value.split('/').join('');
    dob = dob.split('_').join('');
    const { user } = this.state;
    if(dob.length==8){
      let date = value.split("/").reverse().join("-"); 
      date = moment(date).format('yyyy-MM-DD')
      if(date!="Invalid date"){
        let age = this.checkAge(date);
        if(age<config.minAge || age>=config.maxAge){
          this.setState({
            popupmsg: i18next.t('ValidateMsg_ForMinMaxDoB',{minAge:config.minAge}),
            showPopup:true
          })
        }
        this.setState({
          user:{
            ...user,
            birthDate:value
          }
        }) 
      }
      else{
        this.setState({
          user:{
            ...user,
            birthDate:value
          },
          popupmsg: i18next.t('ValidateMsg_ForInvalidDoB'),
          showPopup:true
        })
      }      
    }
    else{
      this.setState({
        user:{
          ...user,
          birthDate:value
        }
      }) 
    }
       
  }

  checkAge(date){
    var today = new Date();
      var birthDate = new Date(date);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
  }

  privacyChecked(event) {
    var privacyBool = event.target.checked;
    var { user } = this.state;
    if (privacyBool) {
      user.privacy = privacyBool;
      this.setState({
        user: user,
      });
    } else {
      user.privacy = privacyBool;
      this.setState({
        user: user,
      });
    }
  }

  marketingChecked(event){
    const { name, checked } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: checked,
      },
    });
  }

  closingPopup() {   
    this.props.clearAlerts() 
    if (this.state.succespopupStringMsg === i18next.t("SignupSuccess") ) {
      history.push("/logIn", {
        state: { qrcode: this.state.user.qrcode },
      });
    }
    else{      
      this.setState({
        showPopup: false,
        popupmsg: "",
        succespopupStringMsg: ""
      });
    }
  }

  handleSubmit(event) {
    CaptureEvent({
      category:"create account",
      action:"button clicked",
      label:"signUp"
    })
    event.preventDefault();
    this.props.clearAlerts();
    //const data = new FormData(event.target);
    let { user } = this.state;
    // this.setState({ submitted: true });
    if (
      user.firstName.trim() &&
      user.lastName.trim() &&
      user.Primaryemail.trim() &&
      user.password.trim() &&
      user.confirmpassword.trim() &&
      user.birthDate
    ) {

      var validatelowerCaseLettersBool = user.password.match(/[a-z]/g)
        ? true
        : false;
      var validateupperCaseLettersBool = user.password.match(/[A-Z]/g)
        ? true
        : false;
      var validatenumbersBool = user.password.match(/[0-9]/g) ? true : false;
      var validateLengthBool = user.password.length >= 8 ? true : false;
      var validatePassword =
        validatelowerCaseLettersBool &&
        validateupperCaseLettersBool &&
        validatenumbersBool &&
        validateLengthBool;
      var age = 0;
      let dateofBirth = user.birthDate.split("/").reverse().join("-"); 
      dateofBirth = moment(dateofBirth).format('yyyy-MM-DD')
      age = this.checkAge(dateofBirth);
      const mobile = user.mobileNumber===""?"":parseInt(user.mobileNumber).toString()
      if (config.applicationTypeId==9 && !user.cpf.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForCPF"),
        });
      } 
      else if(user.cpf.length<11 && config.applicationTypeId==9){
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForInvalidCPF"),
        });
      }
      else if((mobile.length>0 && mobile.length>config.mobileLength) || (mobile.length>0 && mobile.length<config.mobileLength && !config.isFreeTextForMobile)){
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForMobile",{'length':config.mobileLength}),
        });
      }
      else if(dateofBirth=='Invalid date' || (age<config.minAge  || age>=config.maxAge)){
        this.setState({
          popupmsg: i18next.t('ValidateMsg_ForInvalidDoB'),
          showPopup:true
        })
      }
      else if(validator.isEmail(user.Primaryemail)){
        if (user.password === user.confirmpassword && validatePassword) { 
          this.setState({})
          this.setState({
            isLoading:true,
            showPopup: false,
            popupmsg: "",
          });
          let mob=this.state.selectedCountry.dial_code+"-"+this.state.user.mobileNumber
          if(config.countryDropDown){
            user.mobileNumber=mob;
          }      
          // user.birthDate = dateofBirth;
          this.props.register(user);
        } else if (!validatePassword) {
          this.setState({
            showPopup: true,
            popupmsg: i18next.t("ValidateMsg_ForInvalidFormatPassword"),
          });
        } else {
          this.setState({
            showPopup: true,
            popupmsg: i18next.t("ValidateMsg_ForPasswordResetNotMatch"),
          });
        }
      }
      else{
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForInvalidEmail"),
        });
      }
    } else {
      if (!user.firstName.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForFirstName"),
        });
      } else if (!user.lastName.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForLastName"),
        });
      } else if (!user.Primaryemail.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForEmail"),
        });
      }
      else if(!user.birthDate){
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForDoB"),
        });
      }else if (!user.password.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForPassword"),
        });
      } else if (!user.confirmpassword.trim()) {
        this.setState({
          showPopup: true,
          popupmsg: i18next.t("ValidateMsg_ForConfirmPassword"),
        });
      }
      
    }
  }

  componentWillReceiveProps(props) {
    if (props.alert.message) {
      this.setState({
        isLoading:false,
        showPopup: true,
        popupmsg:props.alert.message=="success"? <Trans i18nKey="SignupSuccess" components={[<br />]} /> :props.alert.message,
        succespopupStringMsg:props.alert.message=="success"? i18next.t("SignupSuccess") :""
      });
    }
  }
  openCountryList=()=>{
    this.setState({
      isOpenCountryList:true
    })
  }
  SelectCountry=(country)=>{
    this.setState({
      isOpenCountryList:false,
      selectedCountry:country
    })
  }

  closeList=()=>{
    this.setState({
      isOpenCountryList:false
    })
  }

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <div className="loginwith">
        <div className="container">
            <Heading Title={i18next.t('SignUp')} backLink="/login" subHeading={i18next.t('SignUpHeading')} Desc={i18next.t('SignUpDesc')} Required={i18next.t('Required')} />
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                    <div className="col-12 p-0">
                      <form onSubmit={this.handleSubmit}>
                        <RegisterForm
                          user={this.state.user}
                          handleChange={(event) => this.handleChange(event)}
                          privacyChecked={(event) => this.privacyChecked(event)}
                          handleDoBchange={this.handletDob}
                          privacy={this.state.user.privacy}
                          mailMarketing={this.state.user.mailMarketing}
                          smsMarketing={this.state.user.smsMarketing}
                          checkNumber={(event)=>this.checkNumber(event)}
                          marketingChecked={(event)=> this.marketingChecked(event)}
                          isShowPassword={true}
                          isEmailReadOnly=""
                          isInvalidPassword={this.state.invalidPassword}
                          isPasswordMatched={this.state.isPasswordMatched}
                          isInvalidEmail={this.state.isInvalidEmail}
                          openCountryList={this.openCountryList}
                          selectedCountry={this.state.selectedCountry}
                        ></RegisterForm>
                        {
                          this.state.isOpenCountryList &&
                          <CountryList selectCountry={this.SelectCountry} closeList={this.closeList}/>
                        }
                        <div className="mt-5">
                          <Button size="large" btnType="submit" disabled={(!this.state.user.privacy || this.state.user.password!=this.state.user.confirmpassword || (this.state.user.password=="" || this.state.invalidPassword || this.state.isInvalidEmail || this.state.user.Primaryemail==""))?true:false} text={i18next.t('Finish')} />
                        </div>
                      </form>
                    </div>
                </div>
            </div>
            { this.state.showPopup ? (
                <CoolerPopup
                  color="Red"
                  openPopup={
                    this.state.showPopup
                      ? true
                      : false
                  }
                  closePopup={this.closingPopup}
                  message={
                    this.state.popupmsg
                      ? this.state.popupmsg
                      : this.props.alert.message
                  }
                  imagetype={
                    this.props.alert.message === "success"
                      ? "usericon"
                      : "error"
                  }
                />
              ) : (
                ""
              )}
              <div className="spinner" style={{display:this.state && this.state.isLoading ? 'block' : 'none'}}>
                <div style={style}>
                  <ClipLoader
                    size={50}
                    color={"#FF0000"}
                    loading={this.state && this.state.isLoading ? true : false}
                  />
                </div>
              </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { registering } = state.registration;
  const alert = state.alert;
  return { registering, alert };
}

const actionCreators = {
  register: userActions.register,
  clearAlerts: alertActions.clear,
};

const connectedRegisterPage = connect(mapState, actionCreators)(SignUp);
export { connectedRegisterPage as SignUp };
