import React from "react";
import PropTypes from "prop-types";
import "./CartProduct.scss";
import { useState, useEffect } from "react";
import { Trans } from "react-i18next";

const Button = ({ increment, onClickFunction }) => {
  const handleClick = () => {
    onClickFunction(increment);
  };
  return (
    <button
      onClick={handleClick}
      type="button"
      className="btn bgRed btn-number"
      data-type="plus"
    >
      +
    </button>
  );
};

const Button2 = ({ decrement, onClickFunction }) => {
  const handleClick = () => {
    onClickFunction(decrement);
  };
  return (
    <button
      onClick={handleClick}
      type="button"
      className="btn bgRed btn-number"
      data-type="minus"
    >
      -
    </button>
  );
};

const CartProduct = ({
  data,
  prodkey,
  poductClassName,
  parentCallback,
  deleteProduct,
}) => {
  const styleBottleImg = {
    height: "68px",
    width: "22px",
  };

  useEffect(() => {
    //functional component's lifecycle method, using here to reflect the parent state change onto the child component(CartProduct)
    setCount(parseInt(data.quantity));
  });

  const [count, setCount] = useState(parseInt(data.quantity));

  const incrementCount = (increment) => {
    setCount(count + increment);
    changeQuantity(count + increment);
    parentCallback(
      increment,
      data.price ? data.price : data.productPrice,
      "increment",
      data.productId ? data.productId : data.productID
    );
  };

  const decrementCount = (decrement) => {
    if (count > 1) {
      setCount(count - decrement);
      changeQuantity(count - decrement);
      parentCallback(
        decrement,
        data.price ? data.price : data.productPrice,
        "decrement",
        data.productId ? data.productId : data.productID
      );
    }
  };

  const changeQuantity = (val) => {
    data.quantity = val;
  };

  return (
    <div
      className={` ${poductClassName ? poductClassName : ""}`}
      id={data.productId}
    >
      <div className="productimg">
        <img
          src={data.imgIcon ? data.imgIcon : data.productImage}
          alt="product image"
          style={styleBottleImg}
        />
      </div>
      <div className="product-title">
        {localStorage.getItem("i18nextLng") == "ja" ||
          navigator.language == "ja"
          ? data.productLocalName
          : data.productName}
          {/* <span className="remove" onClick={() => {
          deleteProduct(data.productId);
        }} >X</span> */}
        <br />
        <div className="below row px-2 pt-2">
          <div className=" col pro-old product-oldprice clrGrey maxwidth65">
            $ {data.price ? data.price : data.productPrice}
          </div>
          <div className="col pro-count input-group">
            <span className="input-group-btn">
              x
            </span>
            <input
              id={data.quantity}
              disabled={true}
              style={{backgroundColor:"white"}}
              type="text"
              className="form-control input-number"
              value={data.quantity}
              onChange={(e) => setCount(parseInt(e.target.value))}
            />
          </div>
        </div>
      </div>
      <div className="product-price clrRed">
      ${" "}
        {data.price
          ? data.price * data.quantity
          : data.productPrice * data.quantity}
      </div>

      {/* <button
        className="removeproduct"
        onClick={() => {
          deleteProduct(data.productId);
        }}
      >
        <Trans i18nKey="Remove">Remove</Trans>
      </button> */}
    </div>
  );
};

CartProduct.propTypes = {
  data: PropTypes.object,
  count: PropTypes.string,
};

export default CartProduct;
