import React, { Component } from 'react';
import PaymentInfo from '../components/Payment/index.js';
import { config } from "../_config";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
class payment extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | PaymentInfo`;
        CapturePage({hitType:"pageview",page:"/PaymentInfo"})
        
    }
    render() {
        return (
            <div className="Payment">
                <div className="payment-bg">
                    <div className="container">
                        <PaymentInfo></PaymentInfo>             
                    </div>
                </div>
            </div>
        )
    }
}

export default payment
