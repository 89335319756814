import React, { Component } from "react";
import Red_arrow from "../assets/images/icons/Red_Arrow.svg";
import { history } from "../_helpers";
import { Trans } from "react-i18next";
import { config } from '../_config';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | PrivacyPolicy`;
    CapturePage({hitType:"pageview",page:"/PrivacyPolicy"})    
        
    var user = props.location.query;
    this.state = user;
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    history.push({
      pathname: "/signUp",
      state: { user: this.state }
    });
  }
  render() {
    return (
      <div className="container">
        <div className="row ">
          <div className="col-12">
            <div className="backLinkTitle">
              <div className="butonleft" onClick={this.goBack}>
                <img src={Red_arrow} alt="Back" height="30px" />
              </div>
              <div className="text-center back-text clrblack">
                <h6>
                  <Trans i18nKey="PrivacyPolicy">Privacy Policy</Trans>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <br /> <br />
        <div>
          <h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>
            <Trans i18nKey="PrivacyPolicy">Privacy Policy</Trans>
          </h6>
          <br />
          <div>
            <Trans i18nKey="Para1">
              Protecting your private information is our top priority. This
              Statement of Privacy applies to eBest IOT stores, our apps,
              https://www.eBest-IOT.com and eBest IOT Inc. and governs data
              collection and usage. For the purposes of this Privacy Policy,
              unless otherwise noted, all references to eBest IOT Inc. include
              eBest IOT apps, https://www.eBest-IOT and eBest IOT. The EBest IOT
              website and app is a retail and commerce site. By using the eBest
              IOT website and our application, you consent to the data practices
              described in this statement.
            </Trans>
          </div>
          <br />
          <h6>
            <Trans i18nKey="Para2">
              Collection of your Personal Information
            </Trans>
          </h6>
          <br />
          <div>
            {" "}
            <Trans i18nKey="Para3">
              EBest IOT may collect personally identifiable information, such as
              your:
            </Trans>
          </div>
          <ul>
            <li>
              <Trans i18nKey="Para4">Name</Trans>
            </li>
            <li>
              <Trans i18nKey="Para5">E-mail Address</Trans>
            </li>
            <li>
              <Trans i18nKey="Para6">
                Videos of transactions which may include your likeness
              </Trans>
            </li>
          </ul>
          <br />
          <div>
            <Trans i18nKey="Para7">
              eBest IOT may also collect anonymous demographic information,
              which is not unique to you, such as your:
            </Trans>
          </div>
          <li>
            {" "}
            <Trans i18nKey="Para8">Age </Trans>
          </li>
          <li>
            <Trans i18nKey="Para9">Gender</Trans>
          </li>
          <li>
            <Trans i18nKey="Para10">Nationality</Trans>
          </li>
          <li>
            <Trans i18nKey="Para11">Race</Trans>
          </li>
          <li>
            <Trans i18nKey="Para12">
              The location of the coolers you’ve visited.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Para13">
              Aggregated statistics of the items you or people like you have
              purchased
            </Trans>
          </li>
          <br />
          <div>
            <Trans i18nKey="Para14">
              eBest IOT encourages you to review the privacy statements of
              application you choose to link to from eBest IOT so that you can
              understand how those websites collect, use and share your
              information. eBest IOT is not responsible for the privacy
              statements or other content on websites outside of the eBest IOT
              website.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para15">
              If you purchase eBest IOT 's products and services, we collect
              billing and credit card information. This information is used to
              complete the purchase transaction.
            </Trans>
          </div>
          <br />
          <h6>
            <Trans i18nKey="Para16">Use of your Personal Information</Trans>
          </h6>
          <br />
          <div>
            <Trans i18nKey="Para17">
              eBest IOT collects and uses your personal information to operate
              its website(s) and deliver the services you have requested.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para18">
              eBest IOT may also use your personally identifiable information to
              inform you of other products or services available from eBest IOT
              or to inform you of promotions for products sold in eBest IOT
              stores or 3rd party stores using eBest IOT Applications. EBest IOT
              may also contact you via surveys to conduct research about your
              opinion of current services or of potential new services that may
              be offered.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para19">
              eBest IOT does not sell, rent or lease its customer lists to third
              parties.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para20">
              eBest IOT may, from time to time, contact you on behalf of
              external business partners about a particular offering that may be
              of interest to you.
            </Trans>{" "}
          </div>
          <br />
          <div>
            <Trans i18nKey="Para21">
              In those cases, your unique personally identifiable information
              (e-mail, name, address, telephone number) is not transferred to
              the third party. eBest IOT may share data with trusted partners to
              help perform statistical analysis, send you email or postal mail,
              provide customer support, or arrange for deliveries. All such
              third parties are prohibited from using your personal information
              except to provide these services to eBest IOT, and they are
              required to maintain the confidentiality of your information.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para22">
              eBest IOT may keep track of the websites and pages our users visit
              within eBest IOT, in order to determine what eBest IOT services
              are the most popular. This data is used to deliver customized
              content and advertising within eBest IOT to customers whose
              behavior indicates that they are interested in a particular
              subject area.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para23">
              eBest IOT will disclose your personal information, without notice,
              only if required to do so by law or in the good faith belief that
              such action is necessary to: (a) conform to the edicts of the law
              or comply with legal process served on eBest IOT or the site; (b)
              protect and defend the rights or property of eBest IOT ; and, (c)
              act under exigent circumstances to protect the personal safety of
              users of eBest IOT , or the public.
            </Trans>
          </div>
          <br />
          <h6>
            <Trans i18nKey="Para24">Automatically Collected Information</Trans>
          </h6>
          <br />
          <div>
            <Trans i18nKey="Para25">
              Information about your computer hardware and software may be
              automatically collected by eBest IOT . This information can
              include: your IP address, browser type, domain names, access times
              and referring website addresses. This information is used for the
              operation of the service, to maintain quality of the service, and
              to provide general statistics regarding use of the eBest IOT
              website.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para26">
              Videos of each transaction are collected in order to process
              receipts of those transactions and to train our software to
              recognize products, transaction state (pulling something off a
              shelf or putting it back on) and general characteristics of the
              people recorded in the transaction like faces, hands and bodies.
              Recording of transactions starts when the consumer voluntarily
              opens the door of the cooler using the eBest IOT app and stops
              when our system has determined the doors are closed and locked and
              the transaction is complete.
            </Trans>
          </div>
          <br />
          <h6>
            <Trans i18nKey="Para27">Opt-Out & Unsubscribe</Trans>
          </h6>
          <br />
          <div>
            <Trans i18nKey="Para28">
              We respect your privacy and give you an opportunity to opt-out of
              receiving announcements of certain information. Users may opt-out
              of receiving any or all communications from eBest IOT by
              contacting us here:
            </Trans>
          </div>
          <br />
          <ul>
            <li>
              <Trans i18nKey="Para29">Email: unsubscribe@eBest-IOT.com</Trans>
            </li>
            <li>
              <Trans i18nKey="Para30">
                Online: https://www.eBest-IOT.com/unsubscribe
              </Trans>
            </li>
          </ul>
          <br />
          <h6>
            <Trans i18nKey="Para31">Minor age Users</Trans>{" "}
          </h6>
          <br />
          <div>
            <Trans i18nKey="Para32">
              eBest IOT does not knowingly collect personally identifiable
              information from children under the age of thirteen. If you are
              under the age of thirteen, you must ask your parent or guardian
              for permission to use this website or our stores.
            </Trans>
          </div>
          <br />
          <h6>
            <Trans i18nKey="Para33">
              Security of your Personal Information
            </Trans>{" "}
          </h6>
          <br />
          <div>
            <Trans i18nKey="Para34">
              eBest IOT secures your personal information from unauthorized
              access, use, or disclosure. eBest IOT uses the following methods
              for this purpose.
            </Trans>
          </div>
          <br />
          <ul>
            <li>
              <Trans i18nKey="Para35">SSL Protocol</Trans>
            </li>
            <li>
              <Trans i18nKey="Para36">Limited access computing resources</Trans>
            </li>
            <li>
              <Trans i18nKey="Para37">Data retention policies</Trans>
            </li>
          </ul>
          <br />
          <div>
            <Trans i18nKey="Para38">
              When personal information (such as a credit card number) is
              transmitted to other websites, it is protected through the use of
              encryption, such as the Secure Sockets Layer (SSL) protocol.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para39">
              When your personal data is stored by eBest IOT, we use computer
              systems with limited access housed in facilities using physical
              security measures
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para40">
              eBest IOT will retain your personal information, including name,
              email address, payment information and videos of transactions for
              the period necessary to fulfill the purposes outlined in this
              Privacy Policy unless a longer retention period is required or
              permitted by law. eBest IOT maintains videos of each transaction
              for 24 months. After 6 months faces in the transactions are
              obfuscated. Consumers can request a video of any transaction they
              have initiated once their identity is verified by an eBest IOT
              employee.
            </Trans>
          </div>
          <br />
          <h6>
            <Trans i18nKey="Para41">Use of Cookies</Trans>
          </h6>
          <br />
          <div>
            <Trans i18nKey="Para42">
              The eBest IOT web app may use 'cookies' to help you personalize
              your online experience. A cookie is a text file that is placed on
              your hard disk by a web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely
              assigned to you, and can only be read by a web server in the
              domain that issued the cookie to you.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para43">
              One of the primary purposes of cookies is to provide a convenience
              feature to save you time. The purpose of a cookie is to tell the
              Web server that you have returned to a specific page. This
              simplifies the process of recording your personal information,
              such as billing addresses, shipping addresses, and so on. When you
              return to the same eBest IOT website, the information you
              previously provided can be retrieved, so you can easily use the
              eBest IOT features that you customized.
            </Trans>
          </div>
          <br />
          <div>
            <Trans i18nKey="Para44">
              You have the ability to accept or decline cookies. Most Web
              browsers automatically accept cookies, but you can usually modify
              your browser setting to decline cookies if you prefer. If you
              choose to decline cookies, you may not be able to fully experience
              the interactive features of the eBest IOT services or websites you
              visit.
            </Trans>
          </div>
          <br />
          <h6>
            <Trans i18nKey="Para45">Changes to this Statement</Trans>
          </h6>
          <br />
          <div>
            <Trans i18nKey="Para46">
              eBest IOT will occasionally update this Statement of Privacy to
              reflect company and customer feedback. eBest IOT encourages you to
              periodically review this Statement to be informed of how eBest IOT
              is protecting your information.
            </Trans>
          </div>
          <br />
          <h6>
            <Trans i18nKey="Para47">Contact Information</Trans>
          </h6>
          <br />
          <div>
            <Trans i18nKey="Para48">
              eBest IOT welcomes your questions or comments regarding this
              Statement of Privacy. If you believe that eBest IOT has not
              adhered to this Statement, please contact eBest IOT at:{" "}
            </Trans>
          </div>
          <br />
          <div>
            <p style={{ fontWeight: "bold" }}>
              <Trans i18nKey="Para49">EBest IOT, Inc.</Trans>
            </p>
          </div>
          <div>
            <Trans i18nKey="Para50">
              {" "}
              22994, Lavender Valley CT, Ashburn, VA - USA
            </Trans>
          </div>
          <br />
          <div>
            <p style={{ fontWeight: "bold" }}>
              <Trans i18nKey="Para51">Email Address:</Trans>
            </p>
            <Trans i18nKey="Para52">legal@eBest-iot.com</Trans>
          </div>
          <br />
          <div>
            <p style={{ fontWeight: "bold" }}>
              <Trans i18nKey="Para53">Telephone Number:</Trans>
            </p>
            <Trans i18nKey="Para54">(703) 468-3062</Trans>
          </div>
          <br />
          <br />
          <br /> <br /> <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
