import React, { Component } from "react";
import "../assets/scss/defaults.scss";
import BackWhiteData from "../components/BackWhiteData/BackWhiteData";
import { PurchaseHistoryData } from "../containers/purchaseHistoryData/purchaseHistoryData";
import i18n from "i18next";
import Heading from "../containers/Heading/Heading";
import FooterNav from "../components/FooterNav/FooterNav";
import { config } from "../_config";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";

export default class PurchaseHistory extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | PurchaseHistory`;
    CapturePage({hitType:"pageview",page:"/PurchaseHistory"})
  }

  render() {
    return (
      <div className="main-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="content">
                <Heading title={i18n.t("PurchaseHistory")} firstIcon={true}  secondIcon={false} backLink="/ScanButton" LinkIcon="back"/>
                <div className="main-content" id="scrollableDiv">
                  <PurchaseHistoryData></PurchaseHistoryData>
                  <FooterNav />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
