import React from 'react';
import { userService } from '../_services';
import { decryptData } from './encryptData';
export const isExpiry = () => {
  let checkenc = localStorage.getItem("user");
  if(checkenc){
      if(checkenc.includes("success")){
          return false
      }
      else{
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        if(user){  
          let expDate = new Date(user.expiry)
          let currDate = new Date();
          currDate.setHours(currDate.getHours());
          currDate = currDate.getTime()
          expDate = expDate.getTime();
          if(currDate<expDate){
              return true;
          }
          else{
            return false;
          }
        }
        else{
            return false;
        }
      }
    }
    else{
      return false
    }

}

export const checkMaintenance =  ()=>{
  const x =  userService.checkMaintenance();
  console.log(x);
  return x;
  // if(x){
  //   return true
  // }
  // else{
  //   return false
  // }  
}