import React, { Component } from 'react'
import queryString from 'query-string';
import { config } from '../_config';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
export default class PaymentFail extends Component {
    constructor(props){
        super(props);        
        document.title=`Coke & Go ${config.countryName} | errorResponse`;
        CapturePage({hitType:"pageview",page:"/errorResponse"})    
        this.handleLoad = this.handleLoad.bind(this);
        let params = queryString.parse(this.props.location.search)
        this.state = {
            data:params
        }
    }
    
    render() {
        return (
            <div style={{padding:'50px'}}>
                
            </div>
        )
    }
}
