import {
  productsConstants
} from '../_constants';

export function products(state = {}, action) {
  switch (action.type) {
    case productsConstants.PRODUCTS_REQUEST:
      return {
        products: action.products
      };
    case productsConstants.PRODUCTS_SUCCESS:
      return {
        products: action.products
      };
    case productsConstants.PRODUCTS_FAILURE:
      return {};
    default:
      return state
  }
}