import React, { Component } from "react";
import "../assets/scss/defaults.scss";
import i18n from "i18next";
import ClipLoader from "react-spinners/ClipLoader";
import ModelPopup from "../components/Model-Popup/Popup";
import Heading from "../containers/Heading/Heading";
import FooterNav from "../components/FooterNav/FooterNav";
import { Switch } from 'antd';
import { BioSDK } from "../_helpers/common";
import { history } from "../_helpers";
import { config } from "../_config";
import { osVersion, isIOS } from "react-device-detect";
import Backbutton from "../_assets/images/icons/black_arrow.png";
import { getAppId } from "../_helpers/getAppId";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData, encryptData } from "../_helpers/encryptData";
import { utilService } from "../_services/util.service";
const ApplicationTypeId = getAppId();
class Setting extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | Setting`;
    CapturePage({hitType:"pageview",page:"/Setting"})    
        
    let currentOSVer = osVersion.substring(0,4);
    if(isIOS && (currentOSVer=="15.5" || currentOSVer=="15.6") && !config.isMultiLangEnabled){
      history.push('/ScanButton')
    }
    //this.generateOTP = this.generateOTP.bind(this);
    this.state = {
      emailId: JSON.parse(decryptData(localStorage.getItem("user"))).primaryEmail,
      OTP: "",
      showPopup: false,
      popupmsg: "",
      isLoading: false,
      connectionLost: false,
      isRegistered: false,
      isBiometricEnabled: (localStorage.getItem('isBiometricEnabled')=="true")?true:false,
      BioSuccess:false
    };
    this.closingPopup = this.closingPopup.bind(this);
    this.deviceRegistred = this.deviceRegistred.bind(this);
    this.closingBioSuccessPopup = this.closingBioSuccessPopup.bind(this);
    // this.onHandleChange = this.onHandleChange.bind(this);
    this.sendMetricsTracking();
  }

  sendMetricsTracking = () => {
    utilService.metricsTracking("SettingPage");
  }
  
  async deviceRegistred(check) {
    let that = this;
    
    if(check){
      that.setState({isLoading:true})
      const user = JSON.parse(decryptData(localStorage.getItem("user")));
      const emailId = user.primaryEmail
      try {        
        const result = await BioSDK.registerWithFido2(emailId);
        console.log(result)
        if(result.is_authenticated){
          const applicationTypeId=config.applicationTypeId
          const deviceId = result.client.id;
          const authToken = user.authToken;
          fetch(config.envUrl + "VendingAppApi/addConsumerDeviceRegistration", {
            method: "POST",
            body: new URLSearchParams({deviceId,authToken,emailId,applicationTypeId}),
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(function (response) {
            return response.json();
          })
          .catch(function (error) {
            console.log(error)
          })
          .then(function (body) {
            if(body.success && body.code==5002){
              localStorage.setItem("isBiometricEnabled","true");
              localStorage.setItem("username",encryptData(emailId));
              that.setState({
                isBiometricEnabled:true,
                isLoading:false,
                BioSuccess:true
              })
              // history.push("/scanButton")
            }
            else{                  
                console.log(body)
                this.setState({
                    isLoading:false,
                    showPopup: true,
                    imagetype:'error',
                    popupmsg:i18n.t("CommonError"),
                });
            }
          });
        }
        
      } catch (e) {
        this.setState({isLoading:false})
        if(e.message=="Username Unavailable"){
          console.log(e.message)
          localStorage.setItem("isBiometricEnabled","true");
          localStorage.setItem("username",encryptData(emailId));
          this.setState({
            isBiometricEnabled:true,
            BioSuccess:true
          })
        }
        else{
          that.setState({
            showPopup:true,
            popupmsg:i18n.t("CommonError")
          })
        }
        
      }
    }
    else{
      localStorage.setItem("isBiometricEnabled","false");
      localStorage.removeItem("username");
      this.setState({
        isBiometricEnabled:false
      })
    }
  }
  closingPopup(){
    this.setState({
      showPopup:false
    })
  }
  closingBioSuccessPopup(){
    this.setState({
      BioSuccess:false
    })
  }

  languageChange(){
    history.push('/LanguageSetting')
  }

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    const user = decryptData(localStorage.getItem("user"))
    let isEnableBioMetric = this.state.isBiometricEnabled
    let currentOSVer = osVersion.substring(0,4)
    return (
      <div className="bg-theme h-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
              <div className="col-12">
                <Heading title={i18n.t("Nav_Setting")} align="center" firstIcon={true} secondIcon={false} backLink="/ScanButton" LinkIcon="back" />
                <div className="pt-3">
                  {(isIOS && (currentOSVer=="15.5" || currentOSVer=="15.6")) ?
                  <></>:
                  <>
                    <label >{i18n.t("Bio-metric authentication")}</label>
                    <div style={{ float: 'right' }}>
                      <Switch
                        checked = {isEnableBioMetric?true:false}
                        onChange={this.deviceRegistred}
                      />
                    </div>
                    <hr className="hrLine"/>
                  </>
                  }
                </div>
                {
                  config.isMultiLangEnabled 
                  &&
                  <div onClick={this.languageChange} style={{cursor: 'pointer'}}>
                    <label >{i18n.t("Language Setting")}</label>
                    <div style={{ float: 'right', width:'20', height: '20' }}>
                    <img src={Backbutton} alt="Back" style={{ width:'20px', height: '20px' }} />
                    </div>
                    <hr className="hrLine"/>
                  </div>
                }
                {
                  user &&
                  <FooterNav />
                }
                {
                  this.state.isLoading &&
                  <div className="spinner">
                    <div style={style}>
                      <ClipLoader size={50} color={"#000000"} />
                    </div>
                  </div>
                }
                {
                  this.state.showPopup &&
                    <ModelPopup
                      openPopup={this.state.showPopup}
                      closePopup={this.closingPopup}
                      message={this.state.popupmsg}
                      imagetype={"error"}
                    />
                }
                {
                  this.state.BioSuccess &&
                    <ModelPopup
                      openPopup={this.state.BioSuccess}
                      closePopup={this.closingBioSuccessPopup}
                      message={i18n.t("Bio-metric authentication enabled")}
                      imagetype={"success"}
                    />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Setting;
