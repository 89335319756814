import {
    config
} from '../_config/index';
export const productService = {
    getAllProducts,
    getMannualAllProducts,
    allMannualProducts
};

//get product details
function getAllProducts(products) {

    let authToken = products.authToken;
    let userId = products.userId;
    let macAddress = products.macAddress;
    const applicationTypeId = config.applicationTypeId;
    let sessionUId = sessionStorage.getItem('sessionId');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            authToken,
            userId,
            macAddress,
            applicationTypeId,
            sessionUId
        })
    };

    return fetch(`${config.envUrl}VendingAppApi/getCoolerDetailV2`, requestOptions)
        .then(handleResponse)
        .then(products => {
            return products; //return products available
        })
        .catch((err)=>{
            return {
                success:false,
                error:err
            }
        });
}

function getMannualAllProducts(products) {

    let authToken = products.authToken;
    let purchaseNumber = products.purchaseNumber;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            authToken,
            purchaseNumber
        })
    };

    return fetch(`${config.envUrl}VendingAppApi/getPurchaseDetail`, requestOptions)
        .then(handleResponse)
        .then(products => {
            return products; //return products available
        });
}

function allMannualProducts(products) {

    let authToken = products.authToken;
    let userId = products.userId;
    let macAddress = products.macAddress;
    let sessionUId = sessionStorage.getItem('sessionId');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            authToken,
            userId,
            macAddress,
            sessionUId
        })
    };

    return fetch(`${config.envUrl}VendingAppApi/getCoolerDetailV2`, requestOptions)
        .then(handleResponse)
        .then(products => {
            return products; //return products available
        });
}


function handleResponse(response) { // for every request handling
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (data.success) {
            return data;
        }
        else if (!data.success && data.code === 1519) {
            return data;
        }
        else {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

    });
}