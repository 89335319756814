import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../components/button";
import "../assets/scss/defaults.scss";
import BackData from "../components/BackData/BackData";
import VerifyEmail from "../containers/VerifyEmail";
import { history } from "../_helpers";
import Popup from "../components/Model-Popup/Popup";
import i18n from "i18next";
import ClipLoader from "react-spinners/ClipLoader";
import CoolerPopup from "../components/Model-Popup/Popup";
import { config } from "../_config/index";
import Heading from "../containers/Heading/Heading";
import FooterNav from "../components/FooterNav/FooterNav";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";
import { v4 as uuidv4 } from "uuid";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | changepassword`;
    CapturePage({hitType:"pageview",page:"/changepassword"})    
        
    this.generateOTP = this.generateOTP.bind(this);
    this.state = {
      emailId: JSON.parse(decryptData(localStorage.getItem("user"))).primaryEmail,
      OTP: "",
      showPopup: false,
      popupmsg: "",
      loading: false,
      connectionLost: false,
    };
    this.closingPopup = this.closingPopup.bind(this);
    this.closingConnectionPopup = this.closingConnectionPopup.bind(this);
  }

  closingPopup() {    
    if(this.state.popupmsg===i18n.t("otpSentonMail")){
      history.push({
        pathname: "/verifyOTP",
        state: { userOTP: this.state },
      });
    }   
    this.setState({
      showPopup: false,
      popupmsg: "",
    }); 
  }

  closingConnectionPopup() {
    this.setState({
      connectionLost: false,
    });
  }

  generateOTP(e) {
    CaptureEvent({
      category:"change password",
      action:"button clicked",
      label:"changePassword"
    })
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var Email = JSON.parse(decryptData(localStorage.getItem("user"))).primaryEmail;
    const isResetPassword = true;
    const applicationTypeId = config.applicationTypeId;
    let sessionUId = sessionStorage.getItem("sessionId");
    if (!sessionUId) {
        sessionUId = uuidv4();
        sessionStorage.setItem('sessionId', sessionUId);
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ Email,isResetPassword,applicationTypeId,sessionUId }),
    };
    var self = this;
    return fetch(`${config.envUrl}VendingAppApi/forgotPasswordV1`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch(function (error) {
        if (error == "TypeError: Failed to fetch") {
          self.setState({
            connectionLost: true,
            loading: false,
          });
        }
      })
      .then((data) => {
        if (data && data.code === 5003) {
          this.setState({
            OTP: data.otpPassword,
            showPopup: true,
            popupmsg:i18n.t("otpSentonMail"),
            popupimg: "success",
            loading: false,
          });
        } 
        else if (!data.success && data.code===15487) {
          this.setState({
            showPopup: true,
            popupmsg:i18n.t("Oops! It looks like you used this email to log in with social media. Please log in with your social media account to continue"),
            popupimg: "error",
            loading: false,
          });
        }
        else if (data) {
          this.setState({
            showPopup: true,
            popupmsg: data.message,
            popupimg: "error",
            loading: false,
          });
        }
      });
  }

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    const user = JSON.parse(decryptData(localStorage.getItem("user")))
    return (
      <div className="bg-theme h-100">      
        <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
              <div className="col-12">
                <Heading title={i18n.t("Nav_ChangePassword")} firstIcon={true}  secondIcon={false} backLink="/ScanButton" LinkIcon="back"/>
                <form onSubmit={this.generateOTP}>
                  <div className="">
                    <VerifyEmail></VerifyEmail>
                    {this.state.showPopup ? (
                      <Popup
                        color="Red"
                        openPopup={true}
                        closePopup={this.closingPopup}
                        message={this.state.popupmsg}
                        imagetype={this.state.popupimg}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="spinner" style={{display:this.state.loading?'block':'none'}}>
                    <div style={style}>
                      <ClipLoader
                        size={50}
                        color={"#FF0000"}
                        loading={this.state.loading ? true : false}
                      />
                    </div>
                  </div>
                  <div className="mybtn col-12">
                    <div className="text-center py-2">
                    <Button size="large" btnType="submit" text={i18n.t('Submit')} className="btn mt-3 btn-theme-dark" />
                    </div>
                  </div>
                  {this.state.connectionLost ? (
                    <CoolerPopup
                      color="Red"
                      openPopup={this.state.connectionLost}
                      closePopup={this.closingConnectionPopup}
                      message={i18n.t("ConnectionIssue")}
                    />
                  ) : null}
                </form>
                {
                  user && 
                  <FooterNav />
                }                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChangePassword;
