import {
    config
} from '../_config/index';
export const pendingpaymentService = {
    getPendingPayment
};

function getPendingPayment(pendingobj) {
    let authToken = pendingobj.authUser;
    let userId = pendingobj.Iduser;
    const applicationTypeId = config.applicationTypeId;
    let sessionUId = sessionStorage.getItem("sessionId");
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            authToken,
            userId,
            applicationTypeId,
            sessionUId
        })
    };

    return fetch(`${config.envUrl}VendingAppApi/fetchPendingPaymentV2`, requestOptions)
        .then(handleResponse)
        .then(pendingPayment => {
            return pendingPayment;
        }).catch(function (error) {
            return {
                success: false,
                error: error
            }
        });

    function handleResponse(response) { // for every request handling
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (data.success) {
                return data;
            } else {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        });
    }
}