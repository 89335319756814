import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { ProfileCard } from "../profile-card/profilecard";
import menu from "../../assets/images/icons/account.png";
import "./nav.scss";
import { history } from "../../_helpers";
import i18next from "i18next";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import logout from "../../_assets/images/icons/logout.png"
import { cdsConfig, config } from "../../_config";
import { osVersion, isIOS} from "react-device-detect"
import { decryptData } from "../../_helpers/encryptData";
import { ClipLoader } from "react-spinners";
class Navigation extends Component {
  constructor(props){
    super(props);
    this.state ={
      isConfirmModalVisible:false,
      open:true,
      isCDSEnabled:localStorage.getItem('cdsEnabled'),
      isLoadingOn:false,
    }

    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }
  toggleConfirm(){
    this.setState({
      isConfirmModalVisible:true
    })
    this.props.toggleMenu()
  }

  handleEventClick(){
    history.push('/logout')
  }
  handleCancelClick(){
    this.setState({
      isConfirmModalVisible:false
    })
  }
  editProfile=async()=>{
    if(this.state.isCDSEnabled=='true'){
      this.setState({isLoadingOn:true})
      try {
        sessionStorage.setItem('editProfile','true')
        const sdk = await window.tcccLogin.LoginSdk(cdsConfig);
        await sdk.isSDKReady();
        window.sdk = sdk;
        const url = await window.sdk.getEditProfileUrl()
        window.location.href = url+"&redirect_type=button";        
      } catch (error) {
        this.setState({isLoadingOn:false})
      }
    }
    else{
      history.push(process.env.PUBLIC_URL + "/Profile")
    }
  }  
  changePasswordButtonClick = async() => {
    if(this.state.isCDSEnabled=='true'){
      this.setState({isLoadingOn:true})
        try {
          const sdk = await window.tcccLogin.LoginSdk(cdsConfig);
          await sdk.isSDKReady();
          window.sdk = sdk;
      
          const b2cPolicyName = cdsConfig.b2cPolicyName
          const b2cTenantId = cdsConfig.b2cTenantId
          const b2cPath = cdsConfig.b2cPath
          const params = {
              "authority": `${b2cPath}/${b2cTenantId}/${b2cPolicyName}`
          }
          await window.sdk.doRedirect(params)
        } catch (error) {
          console.log(error)
          this.setState({isLoadingOn:false})
        }    
    }
    else{
    history.push(process.env.PUBLIC_URL + "/ChangePassword")
    }
  }
  render() {
    let user = JSON.parse(decryptData(localStorage.getItem('user')));
    let currentOSVer = osVersion.substring(0,4)
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <>
        <div className={this.props.isMenuOpen ? 'navigation openMenu':'navigation'}>
          <div className="profile-name px-3 pt-3 pb-1">
            <h4 className="mb-0">{user.firstName} {user.lastName}</h4>
          </div>
          <ul className="list-group list-group-flush">
          <li className="list-group-item">
              <a href="javascript:void" onClick={this.editProfile} className="a_link d-block">{i18next.t('Nav_EditProfileInfo')}</a>
            </li>
            <li className="list-group-item">
              <a href="javascript:void" onClick={this.changePasswordButtonClick} className="a_link d-block">{i18next.t('Nav_ChangePassword')}</a>
              {/* <Link to={process.env.PUBLIC_URL + "/ChangePassword"} className="a_link d-block">{i18next.t('Nav_ChangePassword')}</Link> */}
            </li>         
            <li className="list-group-item">
              <Link to={process.env.PUBLIC_URL + "/PurchaseHistory"} className="a_link d-block">{i18next.t('Nav_PurchaseHistory')}</Link>
            </li>  
            <li className="list-group-item">
              <Link to={process.env.PUBLIC_URL + "/Card"} className="a_link d-block">{i18next.t('Nav_ManagePaymentMethod')}</Link>
            </li>   
            <li className="list-group-item">
              <Link to={process.env.PUBLIC_URL + "/support"} className="a_link d-block">{i18next.t('Nav_Support')}</Link>
            </li>        
            <li className="list-group-item">
              <Link to={process.env.PUBLIC_URL + "/edit-permission"} className="a_link d-block">{i18next.t('Nav_EditPermission')}</Link>
            </li>        
            {/* <li className="list-group-item">
              <a href="https://us.coca-cola.com/privacy-policy#rights" className="a_link d-block">{i18next.t('Nav_DoNotSellMyInfo')}</a>
            </li>         */}
            <li className="list-group-item">
              <a href={config.privacyPolciy} className="a_link d-block">{i18next.t('Nav_PrivacyPolicy')}</a>
            </li>   
            {
              (isIOS && (currentOSVer=="15.5" || currentOSVer=="15.6") && !config.isMultiLangEnabled) ?
              null
              :   
              <li className="list-group-item">
                <Link to={process.env.PUBLIC_URL + "/Setting"} className="a_link d-block">{i18next.t('Nav_Settings')}</Link>
              </li>
            }
            <li className="list-group-item" onClick={this.toggleConfirm}>
              <span className="a_link">{i18next.t('Nav_Logout')}</span>
            </li>        
          </ul>
        </div>
        <ConfirmPopup
            image={false}
            color="Red"
            openPopup={this.state.isConfirmModalVisible}
            message={i18next.t('LogoutConfirm')}
            CancelText={i18next.t('No')}
            EventText={i18next.t('Yes')}
            handleEventClick={this.handleEventClick}
            handleCancelClick={this.handleCancelClick}
        />
        {
          this.state.isLoadingOn && 
              <div className="spinner">
                  <div style={style}>
                      <ClipLoader
                          size={50}
                          color={"#000000"}
                          loading={this.state.isLoadingOn}
                      />
                  </div>
              </div>
        }
      </>
    );
  }
}

export default Navigation;
