import i18n from "i18next";
import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { config } from "../_config";
import * as moment from 'moment';
import { history } from "../_helpers";
import i18next from "i18next";
import Heading from "../containers/Heading/Heading";
import offerbg from "../_assets/images/icons/reward-bg.png"
import logo from "../_assets/images/icons/logo.png"
import FooterNav from "../components/FooterNav/FooterNav";
import { Trans } from "react-i18next";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";

export default class OfferDetail extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | offer-detail`;
        CapturePage({hitType:"pageview",page:"/offer-detail/:offertype/:offerid"})
    
        let rewardData = JSON.parse(decryptData(localStorage.getItem('reward')));
        if(!rewardData.success){
          history.push('/ScanButton');
        }
        let reward = rewardData[props.match.params.offertype];
        let data = reward.filter(item=>item.offerId==props.match.params.offerid)
        this.state = {
            BuyQty:0,
            PurchaseQty:0,
            isLoading:false,
            showPopup:false,
            errorCode:0,
            errorMsg:'',
            isOffline:false,
            reward:data[0]
        }
                

        this.closingPopup = this.closingPopup.bind(this);
        this.closingisOfflinePopup = this.closingisOfflinePopup.bind(this);
    }
    closingPopup(){
        if(this.state.errorCode==401){
            history.push('/Logout')
        }
        else{
            this.setState({
                showPopup:false,
                errorCode:0
            })
        }
    }
    checknth(d){
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
        }
    }
    closingisOfflinePopup(){
        this.setState({
          isOffline:false
        })
    }
    render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        let qrcode = decryptData(localStorage.getItem('qrcode'));
        if(!qrcode){
          return <Redirect to="/ScanButton" />
        }
        const {reward} = this.state;
        console.log('reward',reward);
        let month = moment(reward.toDate).format("MMMM")
        let year = moment(reward.toDate).format("Y")
        let d = moment(reward.toDate).format("DD");
        let day = this.checknth(d)
        let toDate = i18next.t(month) +' '+year
        let offertype = this.props.match.params.offertype
        let offername = this.props.match.params.offername
        if(offertype=='rewardPointOffers'){
            var fromMonth = moment(reward.fromDate).format("MMMM")
            var fyear = moment(reward.fromDate).format("Y")
            var fromd = moment(reward.fromDate).format("DD");
            var fromday = this.checknth(fromd)
            var fromDate = i18next.t(fromMonth) +' '+fyear;
            let offerName = reward.offerName;
        }
        return (
            <div className="main-wrapper">        
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 p-0" style={{background:`url(${offerbg})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',height:'100vh'}}>
                            <div className="content p-0">
                                <div className="heading text-center">
                                 <p className="title mb-0 text-white">{i18next.t("MenuReward")}</p>
                                </div>
                                <div className="main-content pb-5">
                                    <div className="" style={{paddingTop:"3rem"}}>
                                        <div className="reward_title offer_detail">
                                            {
                                                offertype=='promotionalOffer'
                                                ?
                                                <h4>{i18next.t("PromotionalOfferTitle",{discountPercentage:reward.discountPercentage})}</h4>    
                                                :
                                                <h4>{i18next.t("RewardOfferTitle",{rewardPoint:parseInt(reward.rewardPoints)})}</h4>    
                                            }
                                                                                
                                        </div>
                                        <div className="reward_details" >
                                            <h6 className="text-white">{i18next.t("How it works?")}</h6>
                                            {
                                                offertype=='promotionalOffer' ?
                                                <p className="text-white" style={{fontSize:'1.2rem'}}><Trans i18nKey="PromotionalOfferDesc" components={[<sup/>]} values={{d:d,day:day,toDate:toDate,discountPercentage:reward.discountPercentage}} /></p>:
                                                
                                                <p className="text-white" style={{fontSize:'1.2rem'}}><Trans i18nKey="RewardOfferDesc" components={[<sup/>]} values={{toDate:toDate,fromDate:fromDate,offerName:offername,fromDay:fromday,fromD:fromd,toDay:day,toD:d}}/></p>
                                            }
                                        </div>
                                        <div className="see_rules position-relative" style={{textDecoration:'underline'}}>
                                        <Link to="/policy/rewardSeeRules">{i18next.t("See Rules")}</Link></div>

                                        <div className="offerProducts">
                                            <div className="offerProductsContent">
                                            <ul className="product-list">
                                                {
                                                    reward.productList.map((product,key)=>{
                                                        return <li key={key} ><img src={product.image} className="offerproduct-image" /></li>                                                    
                                                    })
                                                }                                            
                                            </ul>
                                            </div>
                                        </div>
                                        <FooterNav />
                                    </div> 
                                </div>
                            </div>
                            {
                                this.state.isLoading &&
                                <div className="spinner">
                                    <div style={style}>
                                    <ClipLoader size={50} color={"#FF0000"} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}
