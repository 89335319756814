import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../components/button";
import "../assets/scss/defaults.scss";
import BackData from "../components/BackData/BackData";
import VerifyOTPs from "../containers/VerifyOTP";
import { history } from "../_helpers";
import Popup from "../components/Model-Popup/Popup";
import ClipLoader from "react-spinners/ClipLoader";
import i18n from "i18next";
import CoolerPopup from "../components/Model-Popup/Popup";
import { config } from "../_config/index";
import PublicHeading from "../components/PublicHeader/publicHeader";
import Heading from "../containers/Heading/Heading";
import FooterNav from "../components/FooterNav/FooterNav";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";
import { v4 as uuidv4 } from "uuid";
class VerifyOTP extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | VerifyOTP`;
    CapturePage({hitType:"pageview",page:"/VerifyOTP"})    
        
    var OTP = this.props.location.state
      ? this.props.location.state.userOTP.OTP
      : "";
    var emailId = this.props.location.state
      ? this.props.location.state.userOTP.emailId
      : "";
    this.submitOTP = this.submitOTP.bind(this);
    this.state = {
      actualOTP: OTP,
      userOTP: "",
      emailId: emailId,
      showDialog: false,
      dialogmsg: "",
      popupimgtype: "",
      submitting: false,
      connectionLost: false,
    };
    this.closingPopup = this.closingPopup.bind(this);
    this.closingConnectionPopup = this.closingConnectionPopup.bind(this);
  }

  closingConnectionPopup() {
    this.setState({
      connectionLost: false,
    });
  }

  submitOTP(event) {
    CaptureEvent({
      category:"submit OTP",
      action:"button clicked",
      label:"submitOTP"
    })
    event.preventDefault();
    let otp = this.state.userOTP
    if(otp==""){
      this.setState({
        showDialog: true,
        dialogmsg: i18n.t("enterOTP"),
        popupimgtype: "error",
      });
    }
    else{        
      if (this.state.userOTP === this.state.actualOTP) {
        history.push({
          pathname: "/ResetPassword",
          state: { userOTP: this.state },
        });
      } else {
        this.setState({
          showDialog: true,
          dialogmsg: i18n.t("ValidOTP"),
          popupimgtype: "error",
        });
      }
    }
  }

  handleChange(e) {
    e.preventDefault();
    const { value } = e.target;
    this.setState({
      userOTP: Number(value),
    });
  }

  resubmitOTP(e) {
    this.setState({
      submitting: true,
      popupimgtype: "success",
    });
    var Email = localStorage.getItem("user")?JSON.parse(decryptData(localStorage.getItem("user"))).primaryEmail:this.state.emailId;
    const isResetPassword = true;
    const applicationTypeId = config.applicationTypeId;
    let sessionUId = sessionStorage.getItem("sessionId");
    if (!sessionUId) {
      sessionUId = uuidv4();
      sessionStorage.setItem('sessionId', sessionUId);
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ Email, isResetPassword, applicationTypeId, sessionUId }),
    };
    var self = this;
    fetch(`${config.envUrl}VendingAppApi/forgotPasswordV1`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch(function (error) {
        if (error == "TypeError: Failed to fetch") {
          self.setState({
            connectionLost: true,
            submitting: false,
          });
        }
      })
      .then((data) => {
        if (data && data.code === 5003) {
          this.setState({
            actualOTP: data.otpPassword,
            showDialog: true,
            dialogmsg: i18n.t("otpSentonMail"),
            popupimgtype: "success",
            submitting: false,
          });
        } else if (data) {
          this.setState({
            submitting: false,
          });
        }
      });
  }

  closingPopup() {
    this.setState({
      showDialog: false,
    });
  }

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    let user = decryptData(localStorage.getItem('user'));
    const head = user?<Heading title={i18n.t("OTPVerification")} firstIcon={true}  secondIcon={false} backLink="/ChangePassword" LinkIcon="back"/>: <PublicHeading Title={i18n.t('OTPVerification')} backLink="/ForgotPassword"  />

    const button = user ? <Button size="large" btnType="submit" text={i18n.t('VerifyOTP')} className="btn mt-3 btn-theme-dark" /> : <Button size="large" text={i18n.t("VerifyOTP")} btnType="submit" />
    return (
      <div className={user?"":"loginwith"}>        
        <div className="container">
          {head}
          <form className="row justify-content-center" onSubmit={this.submitOTP}>
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
              <div className={user?"col-12 mt-5 Editform":"col-12 mt-5"}>
                <VerifyOTPs
                  user={this.state.user}
                  handleChange={(event) => this.handleChange(event)}
                  resubmitOTP={(event) => this.resubmitOTP(event)}
                ></VerifyOTPs>                
                <div className="text-center mt-5">
                {button}
                </div>
              </div>
              
            </div>
          </form>
          {
            user && 
            <FooterNav />
          }  
          {this.state.showDialog ? (
            <Popup
              color="Red"
              openPopup={this.state.showDialog}
              closePopup={this.closingPopup}
              message={this.state.dialogmsg}
              imagetype={this.state.popupimgtype}
            />
          ) : (
            ""
          )}
          {this.state.connectionLost ? (
            <CoolerPopup
              color="Red"
              openPopup={this.state.connectionLost}
              closePopup={this.closingConnectionPopup}
              message={i18n.t("ConnectionIssue")}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
export default VerifyOTP;
