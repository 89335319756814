import {
  combineReducers
} from 'redux';
import {
  authentication
} from './authentication.reducer';
import {
  products
} from './products.reducer'
import {
  alert
} from './alert.reducer';
import {
  registration
} from './registration.reducer';
import {
  purchaseHistory
} from './purchasehistory.reducer';
import {
  addedProductsList
} from './addedproductslist.reducer';
import {
  pendingPayment
} from './pendingpayment.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  products,
  registration,
  purchaseHistory,
  addedProductsList,
  pendingPayment
});

export default rootReducer;