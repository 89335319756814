import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import "../_assets/scss/survey.scss";
import left_angle from "../_assets/images/icons/back.png"
import { ClipLoader } from "react-spinners";
import { config } from '../_config';
import { history } from '../_helpers';
import i18next from 'i18next';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from '../_helpers/encryptData';
class Survey extends Component {
    constructor(props){
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        this.applicationTypeId = query.get('applicationtypeid')
        this.isHeaderVisible = query.get('isHeaderVisible')
        this.authToken = query.get('authtoken')
        this.isB10G1Enabled = query.get('isB10G1Enabled')
        this.languageCode = query.get('languageCode')
        // this.onFormSubmit = this.onFormSubmit.bind();   
        document.title=`Coke & Go ${config.countryName} | consumer-survey`;
        CapturePage({hitType:"pageview",page:"/consumer-survey"})
        this.state = {
            isLoading:false,
            isBtnDisable:true,
            surveydata:{
                AnswerQ4Opt1:'',
                AnswerQ1Opt1:'',
                AnswerQ8Opt1:'',
            }
        }     
        this.handleSelection = this.handleSelection.bind(this);
        if(this.isHeaderVisible=="false"){
            localStorage.setItem("selectedLanguage",this.capitalizeString(this.languageCode))
            i18next.changeLanguage(this.capitalizeString(this.languageCode))
        }
    }

    capitalizeString(text){
        return text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }
    onFormSubmit=(event)=>{
        event.preventDefault();
        let that = this;
        that.setState({
            isLoading:true
        })
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        const formData = new FormData(event.target);
        const applicationTypeId=this.applicationTypeId;
        const authToken = this.authToken;
        // const userId = user.userId;
        let sessionUId = sessionStorage.getItem("sessionId");
        let surveyAnswerdata = this.state.surveydata
        const keyToTrim = 'AnswerQ8Opt1';

        if (surveyAnswerdata.hasOwnProperty(keyToTrim)) {
            surveyAnswerdata[keyToTrim] = surveyAnswerdata[keyToTrim].trim();
        }
        let data = {applicationTypeId,authToken,...this.state.surveydata,sessionUId
        }

        fetch(config.envUrl + "VendingAppApi/submitSurvey3Answer", {
        method: "POST",
        body: new URLSearchParams(data),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        })
        .then(function (response) {
        return response.json();
        })
        .catch(function (error) {
            history.push(`/consumer-survey-error?applicationtypeid=${applicationTypeId}&authtoken=${authToken}&code=500&success=false`);
        })
        .then(function (body) {
            if(body.success && body.code===5049){
                history.push(`/consumer-survey-success?applicationtypeid=${applicationTypeId}&authtoken=${authToken}&code=5049&success=true`);
            }
            else{
                history.push(`/consumer-survey-error?applicationtypeid=${applicationTypeId}&authtoken=${authToken}&code=${body.code}&success=false`);
            }
        })
    }

    handleSelection(e){
        var testString=true;
        const {name,value} = e.target
        let optionData = this.state.surveydata;
        // if(name=='AnswerQ6Opt1' || name=='AnswerQ6Opt2' || name=='AnswerQ6Opt3' || name=='AnswerQ6Opt4' || name=='AnswerQ6Opt5'){
        //     if(optionData[name]==""){
        //         optionData = {...optionData,[name]:value.trim()};
        //     }
        //     else{
        //         optionData = {...optionData,[name]:""};
        //     }
        // }
    
        if(name=='AnswerQ8Opt1'){
            const regex = /^(?!.*\s{2,})(?!^\s|\s$)[a-zA-Z0-9,.? ]+$/;
            testString = value != "" ? regex.test(value) : true;
        }
        if(testString)
        optionData = {...optionData,[name]:value};
        
        let isBtnDisable=true;
        let {AnswerQ1Opt1, AnswerQ4Opt1, AnswerQ8Opt1} = optionData
        if(AnswerQ1Opt1 && AnswerQ4Opt1 && AnswerQ8Opt1){
            isBtnDisable = false
        }
        else{
            isBtnDisable = true
        }
        
        
        this.setState({
            isBtnDisable:isBtnDisable,
            surveydata:optionData
        })
    }
    clearTextArea=(value,fieldName)=>{
        if(value=="Yes"){
            let {surveydata} = this.state
            surveydata[fieldName] = ""
            this.setState({
                surveydata: surveydata
            })
        }
    }
    render() {
        const surveydata = this.state.surveydata;
        let i = 1;
        // if(this.isB10G1Enabled=='1'){
        //     i++
        // }
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        return (
            <>
                {
                    this.applicationTypeId==null || this.authToken == null ?
                    <div className='surver-error'>
                        {
                            (this.applicationTypeId==null && this.authToken == null) ?
                            <h4>Application Type Id and Auth Token are missing!</h4>
                            :
                            this.applicationTypeId==null ?
                            <h4>Application Type Id is missing!</h4>
                            :
                            <h4>Auth Token is missing!</h4>
                        }
                    </div>
                    :                    
                    <>                        
                        <div className='survey-form'>
                            {
                                this.isHeaderVisible != "false" &&
                                <div className='py-3'>
                                    <div className='row'>
                                    <div className='col-12 text-center'>
                                        <h6 className='mb-0 text-white px-3'>{i18next.t("Coke&Go")}</h6>
                                        <h6 className='mb-0 text-white px-3'>{i18next.t("We Value Your Feedback")}</h6>
                                        <Link to="/ScanButton" className='survey-back'><img src={left_angle} height="15" /></Link>
                                    </div>

                                    </div>
                                </div>
                            }
                            <form onSubmit={this.onFormSubmit} >
            
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="table_radio padding_bottom10">
                                    <tbody>
                                        <tr>
                                            <th valign="top" colSpan="13" className="pl_20 pt-1 pb-1">{i++}. {i18next.t("How likely are you to recommend Coke&Go to a friend or family member?")}</th>
                                        </tr>
                                    </tbody>
                                </table>    
                                <div className='table-responsive'>
                                    <table className="table">
                                        <tbody>
                                            <tr>                                    
                                                <th width="8%" className="ratinghead pr-2">{i18next.t("Highly unlikely")}</th>
                                                <td width="6%" align="center" className='radio_size'>0<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="0" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>1<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="1" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>2<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="2" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>3<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="3" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>4<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="4" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>5<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="5" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>6<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="6" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>7<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="7" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>8<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="8" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>9<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="9" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="6%" align="center" className='radio_size'>10<br/>
                                                    <input id="AnswerQ4Opt1" type="radio" name="AnswerQ4Opt1" value="10" onChange={this.handleSelection}/>
                                                </td>
                                                <td width="20%" className="ratinghead pl-2"><b>{i18next.t("Highly likely")}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <table width="100%" border="0" className="table_radio">
                                    <tbody>
                                        <tr>
                                            <th colSpan="6" align="left">{i++}.	{i18next.t("How easy was it for you to make a purchase?")}</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1"  value="Very easy" onChange={this.handleSelection}/>
                                                <label className='ml-2'>{i18next.t("Very easy")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1" value="Somewhat easy" onChange={this.handleSelection}/>
                                                <label className='ml-2'>{i18next.t("Somewhat easy")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1" value="Neutral" onChange={this.handleSelection}/>
                                                <label className='ml-2'>{i18next.t("Neutral")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1" value="Somewhat difficult" onChange={this.handleSelection}/>
                                                <label className='ml-2'>{i18next.t("Somewhat difficult")}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input id="AnswerQ1Opt1" type="radio" name="AnswerQ1Opt1" value="Very difficult" onChange={this.handleSelection}/>
                                                <label className='ml-2'>{i18next.t("Very difficult")}</label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                   
                                <br/>

                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="table_radio">
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" align="left"><b>{i++}. {i18next.t("Is there anything else you want to tell us about your experience with Coke&Go?")}</b></td>
                                        </tr>
                                        <tr>
                                            <td width="100%">                                         
                                                <textarea className="textarea form-control mt-2" name='AnswerQ8Opt1' onChange={this.handleSelection} value={surveydata.AnswerQ8Opt1}></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br/>
                                <div className='text-center my-3'>
                                    <button type='submit' className='btn btn-large bg-white' disabled={this.state.isBtnDisable}>{i18next.t("Submit_Survey")}</button>
                                </div>
                            </form>
                        </div>
                    </>
                }   
                <div className="spinner" style={{display:this.state && this.state.isLoading ? 'block' : 'none'}}>
                    <div style={style}>
                    <ClipLoader
                        size={50}
                        color={"#000000"}
                        loading={this.state && this.state.isLoading ? true : false}
                    />
                    </div>
                </div>
            </>     
        )
    }
}

export default  Survey