import React, { Component } from "react";
import { history } from "../_helpers";
import i18n from "i18next";
import FooterNav from "../components/FooterNav/FooterNav";
import Camera from 'react-html5-camera-photo';

const GoogleVisionUrl = "https://vision.googleapis.com/v1/images:annotate?key=" + 'AIzaSyDfkI9P_8FgU6r1RNJXPF87jwXmUmNCDl4';

const getCountOfDigits = (str) => {
  return str.replace(/[^0-9]/g, '').length;
}

const validateDate = (testdate) => {
  var date_regex = /^\d{2}\/\d{2}$/;
  return date_regex.test(testdate);
}

const containsSpecialChars = (str) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}

const ignoreNames = (str) => {
  let stringToCheck = (str || "").toLowerCase();
  let ignoreNames = ["visa", "platinum", "my", "zone", "bank", "valid", "from", "thru","rupay", "debit", "signature", "individual"];
  let shouldIgnore = ignoreNames.some(s => stringToCheck.includes(s));
  return shouldIgnore;
}

const extractCardDetails = (text2) => {
  text2 = text2?.fullTextAnnotation?.text;
  text2 = text2?.split('\n');
  let card = {
    cardNumber: '',
    name: '',
    cvv: '',
    validTo: '',
    brandName: ''
  }

  if (text2) {
    for (let i = 0; i < text2.length; i++) {
      let currText = text2[i];
      let countDigit = getCountOfDigits(currText);
      if (countDigit > 10) {
        card.cardNumber = currText.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
      }
      else if ((!isNaN(currText)) && (countDigit == 3 || countDigit == 4)) {
        card.cvv = currText;
      }
      else {
        let checkSpecialChar = containsSpecialChars(currText);
        let shouldIgnore = ignoreNames(currText);

        if (!checkSpecialChar && !shouldIgnore) {
          card.name = currText;
        }
        else {
          let ds = currText.split(' ');
          for (let j = 0; j < ds.length; j++) {
            let chk = validateDate(ds[j]);
            if (chk) {
              card.validTo = ds[j].split('/')[0] + '/' + '20' + ds[j].split('/')[1];
            }
          }
        }
      }
    }
  }


  return card;

}

export default class CardScanner extends Component {
  constructor(props) {
    super(props);
  }

  handleTakePhoto = async (uri) => {
    let cardDetails = await this.detectTextFromImage(uri);
    this.props.handleTakePhoto(cardDetails);
  }

  detectTextFromImage = async (file_path) => {

    let body = JSON.stringify({
      requests: [
        {
          image: {
            content: file_path.slice(22)
          },
          features: [
            { type: "TEXT_DETECTION", maxResults: "5" },
          ]
        }
      ]
    });

    let response = await fetch(GoogleVisionUrl, {
      method: "post",
      body: body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    }
    );

    let resp1 = await response.json();
    let credResponse = resp1?.responses[0];
    console.log(credResponse?.fullTextAnnotation?.text);
    let cardDetails = extractCardDetails(credResponse);
    console.log(cardDetails);
    return cardDetails;
  }

  render() {
    return (
      <div>
        <div>
          <Camera
            onTakePhoto={(dataUri) => this.handleTakePhoto(dataUri)}
            idealFacingMode="environment"
            isImageMirror={false}
          />
        </div>
        <FooterNav />
      </div>
    );
  }
}
