import React, { Component } from "react";
import Heading from "../containers/Heading/Heading";
import i18next from "i18next";
import faq_english from "../assets/pdf/english/faq.pdf";
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { ClipLoader } from "react-spinners";
import FooterNav from "../components/FooterNav/FooterNav";
import { config } from '../_config'
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
class Faq extends Component {
  constructor(props) {
    super(props); 
    document.title=`Coke & Go ${config.countryName} | FAQ`;
    CapturePage({hitType:"pageview",page:"/FAQ"})
           
    const checkRequest = props.location.search.replace('?isMobile=','');
    this.state = {
      totalPage:0,
      list:[],
      isLoading:true,
      checkRequest: checkRequest
    }
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    
  }
  onDocumentLoadSuccess(data){
    let ls = [];
    for (let i = 1; i <= data.numPages; i++) {
      if(i>1){
        ls.push(i);
      }
    }
    this.setState({
      totalPage:data.numPages,
      list:ls,
      isLoading:false
    })
  }
  render() {
    var { checkRequest } = this.state;
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <div className="main-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="content">           
                {
                  !checkRequest &&
                    <Heading title={i18next.t("FAQ")} firstIcon={true} secondIcon={false} backLink="/support" LinkIcon="back"/> 
                }
                <div className="main-content">
                <div className="pb-5">
                <p className="question">Estou tendo problemas para editar meu perfil, o que devo fazer?</p>
                <p className="answer">Vá em Menu {'>'} Editar perfil, preencha os campos obrigatórios (sinalizados com um *) e clique em Pronto. Se não conseguir ou receber uma mensagem de erro, por favor encaminhe um e-mail para: sacc_faleconosco@coca-cola.com para que possamos melhor atendê-lo.</p>
                <p className="question">Não estou conseguindo alterar a minha senha, o que devo fazer?</p>
                <p className="answer">Assegure-se de que sua senha tem os requerimentos mínimos necessários (ao menos uma letra maiúscula, uma letra minúscula, um número e pelo menos 8 caracteres). Se não conseguir, ou receber uma mensagem de erro, por favor encaminhe um e-mail para: sacc_faleconosco@coca-cola.com para que possamos melhor atendê-lo.</p>
                <p className="question">Preciso adicionar um novo método de pagamento ou alterar minha forma de pagamento, o que devo fazer?</p>
                <p className="answer">Por favor acesse Menu {'>'} Editar método de pagamento. Lá você consegue adicionar um novo, remover um existente e configurar seu método de pagamento padrão.</p>
                <p className="question">Preciso configurar um novo cartão de crédito como forma de pagamento padrão, o que devo fazer?</p>
                <p className="answer">Por favor acesse Menu {'>'} Editar método de pagamento. Lá você consegue adicionar um novo cartão, remover um existente e configurar seu cartão para pagamento padrão.</p>
                <p className="question">Onde eu encontro o QR code para iniciar a compra?</p>
                <p className="answer">Os QR codes estão localizados nas portas das geladeiras Coke&Go.</p>
                <p className="question">Quais são as formas de pagamento aceitas?</p>
                <p className="answer">No momento aceitamos cartões de crédito Visa, Mastercard, Amex, Discover e JCB.</p>

                <p className="question">Toda vez que tento abrir a porta de uma geladeira, aparece uma mensagem informando que o pagamento está pendente. O que devo fazer?</p>
                <p className="answer">Há um saldo pendente em sua conta. Você só poderá iniciar uma nova compra quando esse pagamento pendente for quitado.</p>

                <p className="question">Gostaria de quitar um pagamento pendente com um novo cartão, como devo proceder?</p>
                <p className="answer">Vá em Menu {'>'} Editar método de pagamento, adicione seu novo cartão e o configure como pagamento padrão. Desbloqueie a porta da geladeira e você será direcionado para a página de pagamento pendente onde seu novo cartão será usado para efetuar o pagamento.</p>
                <p className="question">Recebi uma notificação de que deixei a porta da geladeira aberta, o que devo fazer?</p>
                <p className="answer">Certifique-se de que fechou a porta corretamente e de que a mesma está trancada. Todas as compras, produtos retirados da geladeira, se você esquecer a porta da geladeira aberta, serão cobrados no seu cartão.</p>
                <p className="question">Quando tento destravar a porta da geladeira recebo a mensagem "Geladeira Indisponível", o que devo fazer?</p>
                <p className="answer">A geladeira não está disponível para uso. Por favor, tente novamente mais tarde ou entre em contato com o nosso suporte clicando em Menu {'>'} Suporte {'>'} E-mail do suporte (sacc_faleconosco@coca-cola.com).</p>

                <p className="question">A geladeira está desligada ou não funciona, o que devo fazer?</p>
                <p className="answer">A geladeira não está disponível para uso. Por favor, tente novamente mais tarde ou entre em contato com o nosso suporte clicando em Menu {'>'} Suporte {'>'} E-mail do suporte (sacc_faleconosco@coca-cola.com).</p>
                
                <p className="question">O produto na tela não corresponde à minha compra, o que devo fazer?</p>
                <p className="answer">Podem ocorrer raras ocasiões em que a geladeira identifique incorretamente o produto comprado. Se o valor for idêntico, siga com a compra. Se a geladeira identificar um produto de valor mais alto, por favor entre em contato com o telefone na lateral da geladeira, para atendimento ao consumidor.</p>
                
                <p className="question">Como serão tratados os reembolsos?</p>
                <p className="answer">Se você acredita que necessita ser reembolsado, por favor entre em contato com sacc_faleconosco@coca-cola.com do equipamento.</p>
                
                <p className="question">Como minhas informações pessoais serão tratadas?</p>
                <p className="answer">Por favor, acesse os <a href="https://privacidade.cocacola.com.br/#termos-de-uso">Termos de Uso</a> e <a href="https://privacidade.cocacola.com.br/#privacy">Política de Privacidade</a> da Coca-Cola para saber mais.</p>
                
                <p className="question">Quem devo contactar para perguntas sobre minhas informações pessoais?</p>
                <p className="answer">Dúvidas sobre Privacidade de Dados poderão ser esclarecidas pelos nossos canais de atendimento:<br/>
                <a href="https://privacidade.cocacola.com.br">Portal de Privacidade</a><br/>
                <a href="https://privacidade.cocacola.com.br/#direitos">Seus direitos conforme a LGPD</a><br/>
                Em contato direto com o Encarregado através do e-mail: encarregado.lgpd@coca-cola.com
                Dados do Encarregado: Flavio Mattos dos Santos<br/>
                Endereço: Praia de Botafogo, 374, Botafogo, Rio de Janeiro/RJ, CEP: 22.250-907
                </p>
                
                <p className="question">Preciso de Wifi para usar a geladeira?</p>
                <p className="answer mb-5">Seu telefone precisa estar conectado ao wifi ou à alguma conexão de dados do celular.</p>
                
                {/* <p className="question">Com que frequência devo atualizar o aplicativo?</p> */}
                {/* <p className="answer">Recomendamos que seja atualizado mensalmente.</p> */}
                
                {/* <Document className={"PDFDocument"} loading={<div style={style}>
                  <ClipLoader size={50} color={"#FF0000"} loading={this.state.isLoading}/>
                </div>} file={faq_english} onLoadSuccess={this.onDocumentLoadSuccess} className="w-100 pdfview" >
                  <Page className={"PDFPage PDFPageOne"}  pageNumber={1} renderTextLayer={false} renderInteractiveForms={false} ></Page>
                  {
                    this.state.list.map((item,key)=>{
                      return <Page key={key} className={"PDFPage"}  pageNumber={item} renderTextLayer={false} renderInteractiveForms={false} ></Page>
                    })
                  }
                </Document> */}
              </div>
              </div>
              <FooterNav />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Faq;
