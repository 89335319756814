import React, { useState, useEffect } from 'react';
import { gapi, loadAuth2 } from 'gapi-script'
import { cdsConfig, config } from "../_config"
import logo from "../_assets/images/icons/logo.png";
import facebook from "../_assets/images/icons/facebook.png";
import apple from "../_assets/images/icons/apple.png";
import email from "../_assets/images/icons/email.png";
import google from "../_assets/images/icons/google.png";
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { history } from '../_helpers';
import { userService } from "../_services/user.service"
import { ClipLoader } from 'react-spinners';
import AppleSignin from 'react-apple-signin-auth'
import jwt_decode from "jwt-decode";
import SplashScreen from '../containers/SplashScreen/SplashScreen';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData, encryptData } from '../_helpers/encryptData';
import { utilService } from '../_services/util.service';
import { v4 as uuidv4 } from "uuid";
// import { UserCard } from './UserCard';
// import './GoogleLogin.css';
const {checkCDSEnabled} = utilService
const SocialLogin  = () => {
    const applicationTypeId = config.applicationTypeId;
    // const cookies = new Cookies();
    const isOldUser = localStorage.getItem('isOldUser')
    const [userdata, setUser] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [cdsLoader, setcdsLoader] = useState(false);
    const [isSplashLoaded, setSplashLoad] = useState(false);
    const [isAnimationClosed, setAnimationClosed] = useState(false);

    useEffect(() => {
        document.title=`Coke & Go ${config.countryName} | socialLogin`;
        CapturePage({hitType:"pageview",page:"/socialLogin"})
        if(config.isLatamCountry){
            setcdsLoader(false)
            const checkCDS = checkCDSEnabled();
            checkCDS.then(result=>{
                const {cdsEnabled=false}= result
                if(cdsEnabled){
                    cdsLogin();
                    localStorage.setItem('cdsEnabled','true')
                }
                else{
                    setcdsLoader(false)
                    const setAuth2 = async () => {
                    const auth2 = await loadAuth2(gapi, config.GoogleClientId, '')
                    if (auth2.isSignedIn.get()) {
                        signOut()
                    } else {
                        attachSignin(document.getElementById('customBtn'), auth2);
                    }
                    }
                    setAuth2();
                }
            }) 
        }       
    }, []);

    const cdsLogin = async () => {
        const sdk = await window.tcccLogin.LoginSdk(cdsConfig);
        await sdk.isSDKReady();
        window.sdk = sdk;
        const ch = await window.sdk.isAuthenticated();
        if(!ch)
        {
            await window.sdk.getAuthenticationUrl(true, false);
        }
        else
        {
            cdsLoginAPI()            
        }
    }

    const cdsLoginAPI=async()=>{
        try {
            const cdsToken = await window.sdk.getAuthHeader();
            const accessToken = cdsToken?.accessToken;
            const isLatam=true;
            const authUser = await window.sdk.getAccountAuthenticationInfo(window.sdk.getOrigin());
            const kocid = authUser.kocid;
            // console.log(cdsToken,kocid)
            const loginRequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    applicationTypeId,
                    accessToken,
                    isLatam,
                    kocid
                })
            };
    
            fetch(`${config.envUrl}VendingAppApi/loginThirdParty`, loginRequestOptions)
            .then(function (response) {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
            .then(data => {
                if(data.success){    
                    let sessionUId = sessionStorage.getItem("sessionId");
                    if(!sessionUId){
                        sessionUId = uuidv4();
                        sessionStorage.setItem('sessionId',sessionUId);
                    }            
                    let hrs = new Date();
                    hrs.setHours(hrs.getHours() + 24);
                    data.expiry = hrs;   
                    let authToken = data.authToken
                    let PurchaseRequestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: new URLSearchParams({authToken,applicationTypeId,sessionUId})
                    }
                    let that = this;
                    fetch(`${config.envUrl}VendingAppApi/getPurchaseOfferQty`, PurchaseRequestOptions)
                    .then(function (response) {
                        return response.json();
                    })
                    .then((result) => {
                        localStorage.setItem('isRewardAvailable',JSON.stringify(result.records))
                    }).catch(function (error) {
                        console.log(error)
                        that.setState({
                            isLoading:false
                        })
                    });
                    const requestOptions = {
                        method: "POST",
                        headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                        },
                        body: new URLSearchParams({
                        authToken,
                        applicationTypeId,
                        sessionUId
                        })
                    };
                    fetch(
                        `${config.envUrl}VendingAppApi/getConsumerOfferDetails`,
                        requestOptions
                    )
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function(body){  
                        setisLoading(false);
                        localStorage.setItem('user', encryptData(JSON.stringify(data)));
                        localStorage.setItem('rememberme',"false");
                        // localStorage.setItem('rewardPoint', 0);
                        localStorage.setItem('reward',encryptData(JSON.stringify(body)))
                        localStorage.setItem('isEncrypted','true')
                        history.push("/Scanbutton")
                    })
                    
                }
                else{
                    console.log(data)
                }
            });
        } catch (error) {
            setisLoading(false);
            setcdsLoader(false)
        }  
    }

    

    useEffect(() => {
        if (!userdata) {            
            setAuth3();
        }
    }, [userdata])
    const setAuth3 = async () => {
        const auth2 = await loadAuth2(gapi, config.GoogleClientId, '')
        attachSignin(document.getElementById('customBtn'), auth2);
    }

    const updateUser = (currentUser) => {
        const firstName = currentUser.getBasicProfile().rV;
        const lastName = currentUser.getBasicProfile().uT;
        const email = currentUser.getBasicProfile().getEmail();
        const authId = currentUser.getBasicProfile().NT
        const user = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            isSocialMedia: true,
            socialType:'Google',
            authId:authId,
            requireToVerifyEmail: false
        };
        SocialSignIn(user)
    };

    const attachSignin = (element, auth2) => {
        auth2.attachClickHandler(element, {},
        (googleUser) => {            
            setisLoading(true)
            updateUser(googleUser);
        }, (error) => {
            console.log(JSON.stringify(error))
        });
    };

    const signOut = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => {
        setUser(null);
        console.log('User signed out.');
        });
    }
    const responseFacebook = (data) => {
        CaptureEvent({
            category:"login with facebook",
            action:"button clicked",
            label:"loginWithFacebook"
        })

        setisLoading(true);
        const name = data.name.split(' ')    
        const firstName = name[0];
        const lastName = name[1];
        const email = data.email;
        const authId = data.userID;
        const user = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            isSocialMedia: true,
            socialType:'Facebook',
            authId:authId,
            requireToVerifyEmail: email?false:true
        }
        SocialSignIn(user)
    }

    const responseApple = (response) => {
        CaptureEvent({
            category:"login with apple",
            action:"button clicked",
            label:"loginWithApple"
        })
        setisLoading(true);
        const data = jwt_decode(response.authorization.id_token);
        const firstName = '';
        const lastName = '';
        const email = data.email;
        const authId = data.sub;
        const user = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            isSocialMedia: true,
            socialType:'Apple',
            authId:authId,
            requireToVerifyEmail: email?false:true
        }
        SocialSignIn(user)
    }
    const SocialSignIn=(user)=>{
        
        const email = "";
        const socialKey = user.authId;
        const isSocialMedia = true;
        const socialMediaType = user.socialType
        const requireToVerifyEmail = user.requireToVerifyEmail
        let sessionUId = sessionStorage.getItem("sessionId");
        if (!sessionUId) {
            sessionUId = uuidv4();
            sessionStorage.setItem('sessionId', sessionUId);
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                email,
                socialKey,
                isSocialMedia,
                socialMediaType,
                applicationTypeId,
                requireToVerifyEmail,
                sessionUId
            })
        };

        fetch(`${config.envUrl}VendingAppApi/loginV2`, requestOptions)
        .then(function (response) {
            return response.json();
        })
        .catch(err => {
            console.log(err)
        })
        .then(data => {
            setisLoading(false);
            if(!data.success &&  data.code===1523){                
                history.push({pathname:'/socialsignup',state:user})
            }
            else if(data.success){
                
                let hrs = new Date();
                hrs.setHours(hrs.getHours() + 24);
                data.expiry = hrs;   
                let authToken = data.authToken
                let macAddress= decryptData(localStorage.getItem("qrcode"))
                let PurchaseRequestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({authToken,applicationTypeId,macAddress})
                }
                let that = this;
                fetch(`${config.envUrl}VendingAppApi/getPurchaseOfferQty`, PurchaseRequestOptions)
                .then(function (response) {
                    return response.json();
                })
                .then((result) => {
                    localStorage.setItem('isRewardAvailable',encryptData(JSON.stringify(result.records)));
                }).catch(function (error) {
                    console.log(error)
                    that.setState({
                        isLoading:false
                    })
                });
                const requestOptions = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: new URLSearchParams({
                      authToken,
                      applicationTypeId,
                      macAddress
                    })
                  };
                  fetch(
                    `${config.envUrl}VendingAppApi/getConsumerOfferDetails`,
                    requestOptions
                  )
                  .then(function (response) {
                    return response.json();
                })
                .then(function(body){  
                    setisLoading(false);
                    localStorage.setItem('user', encryptData(JSON.stringify(data)));
                    localStorage.setItem('rememberme',"false");
                    // localStorage.setItem('rewardPoint', 0);
                    localStorage.setItem('reward',encryptData(JSON.stringify(body)))
                    localStorage.setItem('isEncrypted','true')
                    history.push("/Scanbutton")
                })
                
            }
            else{
                console.log(data)
            }
        });
    }
    const responseErrorApple = (error) =>{
        console.log(error)
    }
    const checkLoad = () =>{
        setSplashLoad(true);
        setTimeout(()=>{
            setAnimationClosed(true)
        },3000)
    }
    const style = {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      };
    return (
        <div className={isAnimationClosed?'loginwith':''}>
            <SplashScreen show={isOldUser && isSplashLoaded?true:false} isSplashLoaded={isSplashLoaded} checkLoad={checkLoad}> </SplashScreen>{" "}
            <div style={{display: isAnimationClosed?'block':'none'}}>
            <div className="container">
                <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                            <div className="col-12">
                                <img src={logo} height={90}/>
                                <h3 className='text-white pl-2'>{i18next.t('loginWith_Heading')}</h3>
                                {/* <p className='text-white'>{i18next.t('loginWith_SubHeading')}</p> */}
                            </div>
                        </div>
                </div>
            </div>
            <section className="d-flex flex-column align-items-center justify-content-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center loginBtn">
                            <div className="col-12">
                                <ul className='p-0'>
                                    <li>
                                        <FacebookLogin
                                        appId={config.FacebookAppId}
                                        fields="name,email"
                                        callback={responseFacebook}
                                        render={renderProps => (
                                                <a  onClick={renderProps.onClick} className="btn btn-light w-100 btn_radius text-start fb_btn text-left d-flex" ><img src={facebook} width="28" height="28" alt="" className="float-start"/><span className='ml-2'>{i18next.t('loginWith_Facebook')}</span>
                                                </a>
                                        )}
                                        redirectUri={config.baseURL+"/socialLogin"}
                                        disableMobileRedirect={true}
                                        />
                                        
                                    </li>
                                    <li>
                                    <AppleSignin
                                        /** Auth options passed to AppleID.auth.init() */
                                        authOptions={{                                        
                                        clientId: config.AppleClientId,
                                        scope: 'email name',
                                        redirectURI: config.baseURL+"/socialLogin",                                        
                                        state: 'state',
                                        nonce: 'nonce',
                                        usePopup: true,
                                        }}
                                        className="apple-auth-btn"
                                        /** Removes default style tag */
                                        noDefaultStyle={false}
                                        buttonExtraChildren="Continue with Apple"
                                        onSuccess={responseApple}
                                        onError={responseErrorApple}
                                        skipScript={false} 
                                        render={(props) => <a {...props} className="btn btn-light w-100 btn_radius text-start fb_btn text-left d-flex" ><img src={apple} width="28" height="28" alt="" className="float-start"/><span className='ml-2'>{i18next.t('loginWith_Apple')}</span> 
                                        </a>}
                                    />
                                        
                                    </li>
                                    <li>
                                        <a id='customBtn'  className="btn btn-light w-100 btn_radius text-start fb_btn text-left d-flex" ><img src={google} width="28" height="28" alt="" className="float-start"/><span className='ml-2'>{i18next.t('loginWith_Google')}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/login" className="btn btn-light w-100 btn_radius text-start fb_btn text-left d-flex" ><img src={email} width="28" height="28" alt="" className="float-start"/><span className='ml-2'>{i18next.t('loginWith_Email')}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
            
            <div className="spinner" style={{display:isLoading?'block':'none'}}>
                <div style={style}>
                    <ClipLoader
                    size={50}
                    color={"#fff"}
                    loading={isLoading ? true : false}
                    />
                </div>
            </div>
            <div className="spinner" style={{display:cdsLoader?'block':'none',backgroundColor:"#000"}}>
                <div style={style}>
                    <ClipLoader
                    size={50}
                    color={"#fff"}
                    loading={cdsLoader ? true : false}
                    />
                </div>
            </div>
        </div>
    );
}

export default SocialLogin