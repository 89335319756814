import React, { Component } from "react";
import "../assets/scss/defaults.scss";
import BackWhiteData from "../components/BackWhiteData/BackWhiteData";
import { ProductListing } from "../containers/ChooseProductListing/ChooseProductListing";
import Button from "../components/button/button";
import { history } from "../_helpers";
import { connect } from "react-redux";
import { addRemoveProductsAction } from "../_actions";
import i18n from "i18next";
import { config } from "../_config";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";

class ChooseProduct extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | ChooseProduct`;
    CapturePage({hitType:"pageview",page:"/ChooseProduct"})
    
    this.state = {
      AddinList:[]
    }
    if (!Array.isArray(this.props.addedProductsList)) {
      history.goBack();
    }
    this.addProducts = this.addProducts.bind(this);
    this.incrementNumber = this.incrementNumber.bind(this);
    this.decrementNumber = this.decrementNumber.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  addProducts() {
    let finalProductList = [];
    if(this.state.AddinList.length>0){      
      this.state.AddinList.map((product)=>{
        let tempData = this.props.addedProductsList.filter((item=>item.productId===product.productId));
        if(tempData.length>0){
          if(tempData[0].orderUpdateReasonId===3){
            this.props.removeProductsAction(tempData[0]);
            finalProductList.push(product);
          } 
          else{
            this.props.updateProducts(product.productId,'increment');
            this.props.updateCartProducts(product.productId);
          }          
        }
        else{
          finalProductList.push(product);
        }
      })  
    }
    
    this.props.addProductsAction(finalProductList);
    history.push({
      pathname: "/Cart",
    });
  }

  handleCheck(check, bool) {
    check[0].quantity = 1;
    let currentList = this.state.AddinList;
    if (bool) {
      //call here to add
      currentList.push(check[0])
      this.setState({
        AddinList:currentList
      })
      // this.props.addProductsAction(check);
    } else {
      let afterRemovedList = currentList.filter((item=>item.productId!=check[0].productId))
      this.setState({
        AddinList:afterRemovedList
      })
      // this.props.removeProductsAction(check[0]);
    }
  }

  incrementNumber(prodId) {
    let currentList = this.state.AddinList;
    currentList.map((productlist)=>{
      if(productlist.productId===prodId){
        productlist.quantity = productlist.quantity+1
      }
    })
    this.setState({
      AddinList:currentList
    })
    // this.props.updateProducts(prodId, "increment");
  }

  decrementNumber(prodId) {
    let currentList = this.state.AddinList;
    currentList.map((productlist)=>{
      if(productlist.productId===prodId){
        if(productlist.quantity>1){
          productlist.quantity = productlist.quantity-1
        }
      }
    })
    this.setState({
      AddinList:currentList
    })
    // this.props.updateProducts(prodId, "decrement");
  }

  render() {
    return (
      <div className="ProductListing whiteFullbody">
        <div className="container">
          <div className="row">
            <div className="BackButton2 col-12 py-4">
              <BackWhiteData
                BackLink="cart"
                title={i18n.t("ChooseProduct")}
              ></BackWhiteData>
            </div>
            <div className="PurchaseHistoryData col-12 pb-3">
              <ProductListing
                incrementNumber={this.incrementNumber}
                decrementNumber={this.decrementNumber}
                onClickCheck={this.handleCheck}
              ></ProductListing>
            </div>
            <div className="mybtn col-12" style={{ bottom: "10px" }}>
              <div className="float-right  py-2">
                <Button
                  type="button"
                  text={i18n.t("AddProduct")}
                  look="arrow"
                  btnStyle="Red"
                  clickHandler={this.addProducts}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const addedProductsList = state.addedProductsList;
  return {
    addedProductsList,
  };
}

const actionCreators = {
  addProductsAction: addRemoveProductsAction.addedProduct,
  removeProductsAction: addRemoveProductsAction.removeProduct,
  updateProducts: addRemoveProductsAction.updateProducts,
  updateCartProducts: addRemoveProductsAction.updateCartProducts,
};

const connectedChooseProducts = connect(
  mapState,
  actionCreators
)(ChooseProduct);
export { connectedChooseProducts as ChooseProduct };
