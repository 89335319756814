import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../components/button";
import LoginForm from "../containers/LoginForm";
import { userActions, alertActions } from "../_actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ClipLoader from "react-spinners/ClipLoader";
import CoolerPopup from "../components/Model-Popup/Popup";
import i18next from "i18next";
import { history } from "../_helpers";
import Heading from "../components/PublicHeader/publicHeader";
import fingerprint from "../_assets/images/icons/fingerprint.png"
import { isBiometricSupported, BioSDK } from "../_helpers/common";
import { config } from "../_config";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";

class LogIn extends Component {
  constructor(props) {
    super(props);
    if(this.props.location.state){
      this.props.logout();
    }
    let firstuser =
        this.props.location.search.replace("?newuser_", "");
        if(firstuser=='true'){
          sessionStorage.setItem('newuser','true')
        }
    // reset login status
    // var qrcodeThirdParty ;
    // if (!(this.props.location.state && this.props.location.state.from)) {
    //   qrcodeThirdParty =
    //     this.props.location.search.replace("?qr=", "") ||
    //     (this.props.location.state && this.props.location.state.state.qrcode
    //       ? this.props.location.state.state.qrcode
    //       : "");
    //   if (qrcodeThirdParty) {
    //     localStorage.setItem("qrcode", qrcodeThirdParty);
    //   }
    // }
    window.history.replaceState({}, null, "/login"); //to hide the qrcode from url
    // if (qrcodeThirdParty) {
      // history.push('/ContinueOption');
    // }
    document.title=`Coke & Go ${config.countryName} | login`;
    CapturePage({hitType:"pageview",page:"/login"})
    this.state = {
      username: "",
      password: "",
      submitted: false,
      showPopup: false,
      popupmsg: "",
      rememberme:false,
      isBiometricSupported: '',
      isLoading:false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closingPopup = this.closingPopup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.RedirectoSignUp = this.RedirectoSignUp.bind(this);
    this.fingerPrintLogin = this.fingerPrintLogin.bind(this);
    this.checkSupport();
  }
  checkSupport=async()=>{
    let check = await isBiometricSupported();
    this.setState({
      isBiometricSupported:check
    })
  }
  componentDidMount() {
    this.initialScreenSize = window.innerHeight;

    // this.keyboard = setInterval(() => {
    //   if (this.initialScreenSize !== window.innerHeight) {
    //     document.getElementById("fingerprint").classList.add("keypad")
    //   } else {
    //     document.getElementById("fingerprint").classList.remove("keypad")
    //   }
    // }, 800);
  }
  componentWillReceiveProps(nextprops){
    // console.log(nextprops)
  }
  handleChange(event){
    this.setState({
      rememberme: event.target.checked
    })
  }
  handleSubmit(event) {
    CaptureEvent({
      category:"login with cred",
      action:"button clicked",
      label:"login"
    })
    
    var qrcodeThirdParty = decryptData(localStorage.getItem("qrcode"));
    event.preventDefault();
    this.props.clearAlerts();
    const data = new FormData(event.target);
    const username = data.get("Email");
    const password = data.get("password");
    const rememberme = this.state.rememberme;
    if (!username) {
      this.setState({
        submitted: true,
        showPopup: true,
        popupmsg: i18next.t("ValidateMsg_ForEmail"),
      });
    } else if (!password) {
      this.setState({
        submitted: true,
        showPopup: true,
        popupmsg: i18next.t("ValidateMsg_ForPassword"),
      });
    } else if (username && password) {
      this.setState({
        submitted: true,
        showPopup: true,
      });
      this.props.login(username, password, rememberme, qrcodeThirdParty);
    }
  }

  async fingerPrintLogin(check) {
    try {      
      let self = this
      self.setState({
        isLoading:true,
      })
      const name = decryptData(localStorage.getItem('username'));
      const result = await BioSDK.authenticateWithFido2(name);
      if(result.is_authenticated){
        self.setState({
          isLoading:false,
        })
        const deviceId = result.client.id;
        var qrcodeThirdParty = decryptData(localStorage.getItem("qrcode"));
        this.props.login(deviceId, "", "", qrcodeThirdParty);
        
      }
      else{

      }
    } catch (e) {
      console.log(e.message);
      this.setState({
        isLoading:false,
      })
      // Handle errors if required
    }
  }

  closingPopup() {
    this.props.clearAlerts();
    this.setState({
      showPopup: false,
    });
  }
  handleFocus(){
    let fingerprint = document.getElementById("fingerprint");
    if(fingerprint){
      fingerprint.classList.add("keypad-open");
    }    
  }
  handleBlur(){
    let fingerprint = document.getElementById("fingerprint");
    if(fingerprint){
      fingerprint.classList.remove("keypad-open");
    } 
  }
  RedirectoSignUp(){
    history.push('/signUp')
  }

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    const isBiometricEnabled = localStorage.getItem("isBiometricEnabled")
    // let checkOnboarding = localStorage.getItem('onBoarding');
    let checkOnboarding = false
    return (
      <div className="loginwith">
        <div className="container">
            <Heading Title={i18next.t('Login')} backLink="/socialLogin" />
        </div>
        <section className="d-flex flex-column align-items-center justify-content-center">
          <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                <div className="col-12">
                      <form onSubmit={this.handleSubmit} className="w-100 mt-5" autoComplete="off">
                        <LoginForm onFocus={this.handleFocus} onBlur={this.handleBlur}> </LoginForm>{" "} 
                        <div className="mt-5">
                          <Button size="large" btnType="submit" disabled={false} text={i18next.t('Login')} />
                          <div className="mt-3">
                            <Button size="large" btnType="sutton" disabled={false} text={i18next.t('HaveNoAccount')} clickHandler={this.RedirectoSignUp} />
                          </div>  
                          {
                            config.applicationTypeId==9 &&
                            (
                              <div className="text-center login-faq mt-3">
                                <p>Dúvidas? <Link to="/contact">Entre em contato</Link> <br/>ou <Link to={`/policy/FAQDocuments`}>Perguntas Frequentes</Link></p>
                              </div>
                            )
                          }
                        </div>
                      </form>                      
                    </div>
                </div>
                {this.state.submitted &&
                  this.state.showPopup &&
                  !this.props.loggingIn ? (
                    <CoolerPopup
                      color="Red"
                      openPopup={this.state.showPopup}
                      closePopup={this.closingPopup}
                      message={
                        this.props.alert.message
                          ? (localStorage.getItem("i18nextLng") == "ja" ||
                              navigator.language == "ja") &&
                            this.props.alert.message.includes("Activation link")
                            ? i18next.t("ActivationLink")
                            : this.props.alert.message
                          : this.state.popupmsg
                      }
                    />
                  ) : null}
            </div>
          </div>
        </section>
        <div className="spinner" style={{display:(this.props.loggingIn || this.state.isLoading)?'block':'none'}}>
          <div style={style}>
            <ClipLoader
              size={50}
              color={"#fff"}
              loading={(this.props.loggingIn || this.state.isLoading) ? true : false}
            />
          </div>
        </div>
        {
          (this.state.isBiometricSupported && isBiometricEnabled == "true")&&
          <div className="fingerprint" id="fingerprint" onClick={this.fingerPrintLogin}>
            <img src={fingerprint} />
          </div>
        }
      </div>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  const alert = state.alert;
  return {
    loggingIn,
    alert,
  };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
  clearAlerts: alertActions.clear,
};

const connectedLoginPage = withRouter(connect(mapState, actionCreators)(LogIn));
export { connectedLoginPage as LogIn };
