import React, { Component } from "react";
import Button from "../components/button";
import RegisterForm from "../containers/RegisterForm";
import "../assets/scss/defaults.scss";
import BackData from "../components/BackData/BackData";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions, alertActions } from "../_actions";
import pencil from "../assets/images/icons/edit@3x.png";
import Popup from "../components/Model-Popup/Popup";
import i18n from "i18next";
import { history } from "../_helpers";
import ClipLoader from "react-spinners/ClipLoader";
import CoolerPopup from "../components/Model-Popup/Popup";
import { config } from "../_config/index";
import defaultuser  from "../assets/images/home/defaultuser.png"
import * as moment from 'moment'
import i18next from "i18next";
import Heading from "../containers/Heading/Heading";
import ReactInputMask from "react-input-mask";
import ConfirmPopup from "../components/ConfirmPopup/ConfirmPopup";
import FooterNav from "../components/FooterNav/FooterNav";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData, validateString } from "../_helpers/encryptData";
import CountryList from "../components/CountryList/CountryList";
import list from "../components/CountryList/country-with-mobile.json"
import { utilService } from "../_services/util.service";

class Profile extends Component {
  constructor(props) {
    super(props);
    var userData = this.props.user?this.props.user:JSON.parse(decryptData(localStorage.getItem('user')));
    document.title=`Coke & Go ${config.countryName} | Profile`;
    CapturePage({hitType:"pageview",page:"/Profile"})
    if(config.countryDropDown){
      var mobile = userData.mobileNumber.split("-");
      var selectedCountry = mobile.length>1?list.filter(item=>item.dial_code==mobile[0]):[{
        name: "Singapore",
        dial_code: "+65",
        code: "SG"
      }]
    }
    this.state = {
      authToken: userData.authToken,
      firstName: userData.firstName,
      lastName: userData.lastName,
      primaryEmail: userData.primaryEmail,
      mobileNumber: config.countryDropDown?mobile[mobile.length>1?1:0]:userData.mobileNumber,
      birthDate: userData.birthDate,
      profileImageURL: userData.profileImageURL,
      CPF:userData.CPF,
      file: "",
      showPopup: false,
      popupmsg: "",
      errorshowPopup: false,
      errorpopupmsg: "",
      updating: false,
      connectionLost: false,
      accountDeleteConfirm:false,
      OutstandingConfirm:false,
      isOpenCountryList:false,
      selectedCountry:config.countryDropDown?selectedCountry[0]:{}
    };
    this.closingConnectionPopup = this.closingConnectionPopup.bind(this);
    this.onChangeProfile = this.onChangeProfile.bind(this);
    this.submitProfile = this.submitProfile.bind(this);
    this.closingPopup = this.closingPopup.bind(this);
    this.closingerrorPopup = this.closingerrorPopup.bind(this);
    this.checkNumber = this.checkNumber.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleOutstandingPayClick = this.handleOutstandingPayClick.bind(this);
    this.sendMetricsTracking();
  }

  sendMetricsTracking = () => {
    utilService.metricsTracking("ProfilePage");
  }
  
  onChangeProfile(event) {
    var testString=true;
    const { name, value } = event.target;
    if(name=='mobileNumber'){
      let mob = value.split('-').join('');
      mob = mob.split('(').join('');
      mob = mob.split(')').join('');
      mob = mob.split(' ').join('');
      mob = mob.split('_').join('');
      // console.log(mob)
      this.setState({
        [name]: mob,
      });
    }
    else{
      if(name=='firstName' || name=='lastName'){
        testString = validateString(value,'string');    
      }
      if(testString){
        this.setState({
          [name]: value,
        });
      }
    }
    
  }

  closingConnectionPopup() {
    this.setState({
      connectionLost: false,
      updating: false,
    });
    this.props.clearAlerts();
  }

  checkNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    }
    return true;
  }

  submitProfile(event) {
    CaptureEvent({
      category:"edit profile",
      action:"button clicked",
      label:"edit profile"
    })
    event.preventDefault();
    var userdata = this.state;    
    if(userdata.firstName.trim() && userdata.lastName.trim()){
      const mobile = userdata.mobileNumber===""?"":parseInt(userdata.mobileNumber).toString();
      if(mobile.length>config.mobileLength || (mobile.length>0 && mobile.length<config.mobileLength && !config.isFreeTextForMobile)){
        this.setState({
          errorshowPopup: true,
          errorpopupmsg: i18next.t("ValidateMsg_ForValidMobile"),
        });
      }
      else{
        if (navigator.onLine) {
          let mob=this.state.selectedCountry.dial_code+"-"+this.state.mobileNumber
          if(config.countryDropDown){
            userdata.mobileNumber=mob;
          }  
          if(config.applicationTypeId == 20){
            userdata.isTermAndCondition = false
          }    
          this.props.update(userdata);
        } else {
          this.setState({
            connectionLost: true,
          });
        }
      }     
    }
    else{
      if(!userdata.firstName.trim()){
        this.setState({
          errorshowPopup: true,
          errorpopupmsg: i18next.t("ValidateMsg_ForFirstName"),
        });
      }
      else if (!userdata.lastName.trim()) {
        this.setState({
          errorshowPopup: true,
          errorpopupmsg: i18next.t("ValidateMsg_ForLastName"),
        });
      } 
    }
    //if network not there then just show dialog of connection issue
    
  }

  handleCancelClick(){
    this.setState((prevState)=>({
      accountDeleteConfirm:!prevState.accountDeleteConfirm
    }))
  }

  handleEventClick(event) {
    event.preventDefault();
    this.setState({
      updating: true,
      accountDeleteConfirm:false
    });
    let self = this;
    let user = JSON.parse(decryptData(localStorage.getItem('user')));
    let authToken = user.authToken;
    const applicationTypeId = config.applicationTypeId;
    let sessionUId = sessionStorage.getItem("sessionId");
    fetch(config.envUrl + "VendingAppApi/deleteAccount", {
      method: "POST",
      body: new URLSearchParams({
        authToken,
        applicationTypeId,
        sessionUId
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then(function (response) {
      return response.json();
    })
    .catch(function (error) {
      if (error == "TypeError: Failed to fetch") {
        self.setState({
          connectionLost: true
        });
      }
    })
    .then(function (body) {
      if(body.success && body.code==5049){
        self.setState({
            updating: false,
            imagetype:"success",
            popupmsg:i18next.t("DeleteAccountSuccess"),
            showPopup:true
        })
      }
      else if(!body.success && body.code==5049){
        self.setState({
          updating: false,
          OutstandingConfirm:true
        })
      }
      else if(!body.success && body.code==5053){
        self.setState({
          updating: false,
          errorshowPopup: true,
          errorpopupmsg:i18next.t("You can't delete your account until the pending transaction is completed")
        })
      }
      else if(!body.success && body.code==401){
        history.push("/logout")
      }
      else{
        self.setState({
          updating: false,
          errorpopupmsg:i18next.t("CommonError"),
          errorshowPopup:true
      })
      }
    });   
  }

  handleOutstandingPayClick(){
    sessionStorage.setItem('clickedDelete','true')
    history.push('/PendingAmount')
  }

  closingerrorPopup(){
    this.setState({
      errorshowPopup: false
    })
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.props.updating && this.props.updating !== previousProps.updating) {
      this.setState({
        updating: true,
      });
    }
    if (this.props.updated && this.props.updated !== previousProps.updated) {
      this.setState({
        showPopup: true,
        updating: false,
        popupmsg: i18n.t("ProfileUpdated"),
      });
    }
  }

  upload(e) {
    e.preventDefault();
    document.getElementById("selectImage").click();
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files;

    reader.onloadend = () => {
      this.setState({
        file: file,
        profileImageURL: reader.result,
      });
    };

    reader.readAsDataURL(file[0]);
  }

  closingPopup() {
    this.setState({
      showPopup: false,
    });
    if(this.state.popupmsg==i18next.t("DeleteAccountSuccess")){
      history.push('/logout')
    }
    else if(this.state.popupmsg==i18n.t("ProfileUpdated")){
      history.push({pathname:"/Scanbutton",state:'back'});
    }
    else if(this.state.popupmsg==i18n.t("You can't delete your account until the pending transaction is completed")){
      
    }
    else{
      history.push("/Scanbutton");
      // let qrcode = localStorage.getItem("qrcode");
      // if (qrcode) {
      //   history.push("/Home");
      // } else {
      //   history.push("/Scanbutton");
      // }
    }
    
  }

  SelectCountry=(country)=>{
    this.setState({
      isOpenCountryList:false,
      selectedCountry:country
    })
  }

  closeList=()=>{
    this.setState({
      isOpenCountryList:false
    })
  }

  render() {
    var userData = this.state;
    let user = JSON.parse(decryptData(localStorage.getItem('user')))
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    const mobileformat = config.mobileFormat
    const mobilePlaceholder = mobileformat.replaceAll('9','X')
    const {isOpenCountryList}=this.state
    return (
      <div className="main-wrapper">        
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="content">
                <Heading title={i18n.t("Nav_EditProfileInfo")} firstIcon={true} secondIcon={false} backLink="/ScanButton" LinkIcon="back"/> 
                <div className="main-content">
                  <div className="editProfile-page">
                    <form onSubmit={this.submitProfile}>
                      <div className="pb-3">
                        <div className="">
                          <div className="Editform">                  
                            <div className="form-group">
                              <label className="mt-3">
                                {i18n.t("FirstName")}*
                              </label>
                              <input
                                id="firstName"
                                type="text"
                                className="form-control border-radius-none"
                                placeholder={i18n.t("FirstName")}
                                name="firstName"
                                value={userData.firstName}
                                onChange={this.onChangeProfile}
                                aria-label="John"
                                aria-describedby="basic-addon2"
                                maxLength="50"
                              />
                            </div>
                            <div className="form-group">
                            <label className="">
                                {i18n.t("LastName")}*
                              </label>
                              <input
                                id="lastName"
                                type="text"
                                className="form-control"
                                placeholder={i18n.t("LastName")}
                                name="lastName"
                                value={userData.lastName}
                                onChange={this.onChangeProfile}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                maxLength="50"
                              />
                            </div>
                            <div className="form-group">
                            <label className="">
                                {i18n.t("EmailID")}{config.applicationTypeId==12 ? "*": ""}
                              </label>
                              <input
                                id="Primaryemail"
                                type="text"
                                className="form-control"
                                placeholder={i18n.t("Email")}
                                name="Primaryemail"
                                value={userData.primaryEmail}
                                onChange={this.onChangeProfile}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                            </div>
                            {
                              config.applicationTypeId==9 && (
                                <div className="form-group">
                                  <label className="">
                                    CPF
                                  </label>
                                  <ReactInputMask name="CPF" className="form-control" mask="999.999.999-99"  placeholder="CPF"  value={user.CPF} disabled/>
                                </div>
                              )
                            }
                            <div className="form-group">
                              <label className="">
                                {i18n.t("Mobile")}
                              </label>
                              <div className="input-group">
                                { config.countryDropDown &&
                                <div className="pt-2 text-black pr-4 mr-2 mobile-code profilepage" onClick={()=>this.setState({isOpenCountryList:true})}>
                                  {this.state.selectedCountry.dial_code+' ('+this.state.selectedCountry.code+")"}
                                </div> }
                                  <ReactInputMask name="mobileNumber" onChange={this.onChangeProfile} className="form-control" mask={config.mobileFormat} maskChar={config.isFreeTextForMobile?false:'_'} placeholder={mobilePlaceholder} value={userData.mobileNumber} />
                              </div>
                              
                              {
                                isOpenCountryList &&
                                <CountryList selectCountry={this.SelectCountry} closeList={this.closeList}/>
                              }
                            </div>
                            <div className="form-group">
                              <label className="">
                                {i18n.t("DoB")}{config.applicationTypeId==12 ? "*": ""}
                              </label>
                              <input
                                id="birthDate"
                                type="text"
                                className="form-control"
                                name="birthDate"
                                value={userData.birthDate ? moment(userData.birthDate).format('DD-MM-YYYY'):'N/A'}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mb-3">
                      <Button size="large" text={i18next.t('AccountDelete')} className="btn bg-white btn-with-border" btnType="button" 
                        clickHandler={(e)=>{
                          CaptureEvent({
                            category:"delete account",
                            action:"button clicked",
                            label:"deleteAccount"
                          })
                          this.setState({accountDeleteConfirm:true})
                        }} 
                      />
                      <Button size="large" btnType="submit" text={i18next.t('Done')} className="btn mt-3 btn-theme-dark" />
                      </div>
                    </form> 
                  </div>
                </div>
              </div>
              <FooterNav />
            </div>
          </div>
          
          {this.state.showPopup ? (
            <Popup
              openPopup={true}
              closePopup={this.closingPopup}
              message={this.state.popupmsg}
              imagetype={"success"}
              color={"Red"}
            />
          ) : (
            ""
          )}
          {this.state.errorshowPopup ? (
            <Popup
              openPopup={true}
              closePopup={this.closingerrorPopup}
              message={this.state.errorpopupmsg}
              imagetype={"error"}
              color={"Red"}
            />
          ) : (
            ""
          )}
          <div className="spinner" style={{display:this.state && this.state.updating ? 'block' : 'none'}}>
            <div style={style}>
              <ClipLoader
                size={50}
                color={"#FF0000"}
                loading={this.state && this.state.updating ? true : false}
              />
            </div>
          </div>
          {this.state.connectionLost || this.props.alert.message ? (
            <CoolerPopup
              color="Red"
              openPopup={this.state.connectionLost || this.props.alert.message}
              closePopup={this.closingConnectionPopup}
              message={i18n.t("ConnectionIssue")}
            />
          ) : null}
          {
            this.state.accountDeleteConfirm &&
            <ConfirmPopup
              image={false}
              color="Red"
              openPopup={this.state.accountDeleteConfirm}
              message={i18next.t('DeleteAccountConfirm')}
              CancelText={i18next.t('No')}
              EventText={i18next.t('Yes')}
              handleEventClick={this.handleEventClick}
              handleCancelClick={this.handleCancelClick}
            />
          }
          
          {
            this.state.OutstandingConfirm &&
            <ConfirmPopup
              image={false}
              color="Red"
              openPopup={this.state.OutstandingConfirm}
              message={i18next.t('DeleteAccountOutstanding')}
              CancelText={i18next.t('Cancel')}
              EventText={i18next.t('Pay')}
              handleEventClick={this.handleOutstandingPayClick}
              handleCancelClick={(e)=>this.setState({OutstandingConfirm:false})}
            />
          }
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { user } = state.authentication;
  const updated = state.authentication.updated;
  const updating = state.authentication.updating;
  const alert = state.alert;
  return { user, updated, updating, alert };
}

const actionCreators = {
  update: userActions.update,
  clearAlerts: alertActions.clear,
};

const connectedProfilePage = connect(mapState, actionCreators)(Profile);
export { connectedProfilePage as Profile };
