import React, { Component } from 'react'
import FooterNav from '../components/FooterNav/FooterNav';
import { config } from '../_config';
import { history } from '../_helpers';
import ModelPopup from "../components/Model-Popup/Popup";
import i18next from 'i18next';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";

export default class CardFail extends Component {
    constructor(props){
        super(props)
        document.title=`Coke & Go ${config.countryName} | CardFail`;
        CapturePage({hitType:"pageview",page:"/CardFail"})    
        let sessionUId = sessionStorage.getItem('sessionId');
        sessionStorage.clear();
        sessionStorage.setItem('sessionId',sessionUId);
    }
    closingShowPopup=()=>{
        history.push('/Scanbutton')
    }
  render() {
    return (
        <div className="main-wrapper">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="content">       
                        <ModelPopup
                            openPopup={true}
                            closePopup={this.closingShowPopup}
                            message={i18next.t("CommonError")}
                            imagetype="error"
                        />
                        </div>
                    </div>
                </div>
            </div>
            <FooterNav/>
        </div>
    )
  }
}