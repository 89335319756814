import ReactGA from "react-ga4";

function CapturePage({hitType,page}){
    ReactGA.send({ hitType: hitType, page: page });
}

function CaptureEvent({category,action,label}){
    ReactGA.event({
        category: category,
        action: action,
        label: label, // optional
      });
}
export {CapturePage,CaptureEvent}