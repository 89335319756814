import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../components/button";
import "../assets/scss/defaults.scss";
import BackData from "../components/BackData/BackData";
import ForgotPasswords from "../containers/ForgotPassword";
import { history } from "../_helpers";
import Popup from "../components/Model-Popup/Popup";
import i18n from "i18next";
import ClipLoader from "react-spinners/ClipLoader";
import CoolerPopup from "../components/Model-Popup/Popup";
import { config } from "../_config/index";
import Heading from "../components/PublicHeader/publicHeader";
import validator from 'validator';
import FooterNav from "../components/FooterNav/FooterNav";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { v4 as uuidv4 } from "uuid";

class Forgotpassword extends Component {
  constructor(props) {
    super(props);
    document.title=`Coke & Go ${config.countryName} | forgotPassword`;
    CapturePage({hitType:"pageview",page:"/forgotPassword"})        
    this.generateOTP = this.generateOTP.bind(this);
    this.state = {
      emailId: "",
      OTP: "",
      showPopup: false,
      popupmsg: "",
      imagetype: "",
      loading: false,
      connectionLost: false,
    };
    this.closingPopup = this.closingPopup.bind(this);
    this.closingConnectionPopup = this.closingConnectionPopup.bind(this);
  }

  closingPopup() {
    if (this.state.OTP) {
      history.push({
        pathname: "/verifyOTP",
        state: { userOTP: this.state },
      });
    }
    this.setState({
      showPopup: false,
    });
  }

  closingConnectionPopup() {
    this.setState({
      connectionLost: false,
    });
  }

  generateOTP(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var email = this.state.emailId;
    var isResetPassword = false;
    let sessionUId = sessionStorage.getItem("sessionId");
    if (!sessionUId) {
      sessionUId = uuidv4();
      sessionStorage.setItem('sessionId', sessionUId);
    }
    if (email.trim()) {
      if(validator.isEmail(email)){
        const applicationTypeId = config.applicationTypeId;
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({ email,applicationTypeId,isResetPassword,sessionUId }),
        };
        var self = this;
        return fetch(
          `${config.envUrl}VendingAppApi/forgotPasswordV1`,
          requestOptions
        )
          .then((response) => {
            return response.json();
          })
          .catch(function (error) {
            if (error == "TypeError: Failed to fetch") {
              self.setState({
                connectionLost: true,
                loading: false,
              });
            }
          })
          .then((data) => {  
            if (data && data.code === 5003) {
              this.setState({
                OTP: data.otpPassword,
                showPopup: true,
                popupmsg:i18n.t("otpSentonMail"),
                imagetype: "success",
                loading: false,
              });
            } else if (data && data.code == 1524) {
              this.setState({
                showPopup: true,
                popupmsg: i18n.t("emailNotRegistered"),
                loading: false,
              });
            } 
            else if (!data.success && data.code===15487) {
              this.setState({
                showPopup: true,
                popupmsg:i18n.t("Oops! It looks like you used this email to log in with social media. Please log in with your social media account to continue"),
                popupimg: "error",
                loading: false,
              });
            }
            else if (data) {
              this.setState({
                showPopup: true,
                popupmsg: data.message,
                loading: false,
              });
            }
          });
        }
        else{
          this.setState({
            showPopup: true,
            popupmsg: i18n.t("InvalidEmail"),
            imagetype: "error",
            loading: false,
          });
        }
    } else {
      this.setState({
        showPopup: true,
        popupmsg: i18n.t("ValidateMsg_ForEmail"),
        imagetype: "error",
        loading: false,
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      emailId: value,
    });
  }
  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <div className="loginwith">
        
        <div className="container">
          <Heading Title={i18n.t('ForgotPassword')} backLink="/login"  />
          <form className="row justify-content-center" onSubmit={this.generateOTP} >            
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
              <div className="col-12">
                <ForgotPasswords
                  email={this.state}
                  handleChange={(event) => this.handleChange(event)}
                ></ForgotPasswords>
                <div className="spinner" style={{display:this.state.loading?'block':'none'}} >
                  <div style={style}>
                    <ClipLoader
                      size={50}
                      color={"#FF0000"}
                      loading={this.state.loading ? true : false}
                    />
                  </div>
                </div>
                {this.state.showPopup ? (
                  <Popup 
                    color="Red"
                    openPopup={true}
                    closePopup={this.closingPopup}
                    message={this.state.popupmsg}
                    imagetype={this.state.imagetype}
                  />
                ) : (
                  ""
                )}
                {this.state.connectionLost ? (
                  <CoolerPopup
                    color="Red"
                    openPopup={this.state.connectionLost}
                    closePopup={this.closingConnectionPopup}
                    message={i18n.t("ConnectionIssue")}
                  />
                ) : null}
                <div className="text-center py-2">
                  <Button
                    size="large"
                    text={i18n.t("Submit")}
                    btnType="submit"
                  />
                </div>
                </div>
              </div>
          </form>
        </div>
      </div>
    );
  }
}
export default Forgotpassword;
