import i18next from 'i18next';
import React, { Component } from 'react'
import { history } from '../../_helpers';
import ModelPopup from "../Model-Popup/Popup";
import { config } from "../../_config";
import { CaptureEvent, CapturePage } from "../../_helpers/googleAnalytics";

export default class SurveyResponse extends Component {
  constructor(props) {
    super(props)
    const query = new URLSearchParams(this.props.location.search);
    this.applicationTypeId = query.get('applicationtypeid')
    this.authToken = query.get('authtoken');
    this.success = query.get('success');
    this.code = query.get('code');
    document.title=`Coke & Go ${config.countryName} | consumer-survey-success`;
    CapturePage({hitType:"pageview",page:"/consumer-survey-success"})
    
    this.state = {
      message:{
        "15502":"Last Survey is already active.",
        "5049":"Survey success",
        "401":"User not authorized, Please try again later"
      }
    }
  }
  closingShowPopup=()=>{
    history.push("/ScanButton")
  }
  render() {
    return (
      <>
        <ModelPopup
        openPopup={true}
        closePopup={this.closingShowPopup}
        message={this.success==='true'?i18next.t("Survey success"):i18next.t(this.state.message[this.code]?this.state.message[this.code]:"Some thing went wrong, please try later.")}
        imagetype={this.success==='true'?'success':'error'}
        />
      </>
    )
  }
}
