import React from 'react';
import { ClipLoader } from 'react-spinners';
import { config } from '../../_config';
import { decryptData } from '../../_helpers/encryptData';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
    
    static getDerivedStateFromError(error) {      
      // sessionStorage.clear();
      // localStorage.clear();
      // window.location.href='/socialLogin'
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      var uId="0";
      if(localStorage.getItem("user")){
        let userData = JSON.parse(decryptData(localStorage.getItem("user")));
        uId=userData.userId
      }
      const {envUrl,applicationTypeId} = config
      fetch("https://api.ipify.org?format=json")
      .then((json)=>{return json.json()})
      .then(async(result)=>{
          const ipAddress = result.ip;
          const pageName = window.location.href;
          const data = JSON.stringify(localStorage)
          const errorMessage = error+" || "+data
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: new URLSearchParams({ ipAddress,applicationTypeId,pageName,uId,errorMessage }),
            };
          await fetch(
              `${envUrl}VendingAppApi/appSiteHitLog`,
              requestOptions
            )
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                // sessionStorage.clear();
                // localStorage.clear();
                // window.location.href='/socialLogin'
                  // console.log(data)
              })
      })
      // You can also log the error to an error reporting service
      // console.log(error, errorInfo);
    }
  
    render() {
      const style = {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex:'999'
      };
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <>
          <div className="" style={{height:'400px'}}>
            <div className="spinner">
                <div style={style}>
                    <ClipLoader
                        size={50}
                        color={"#000000"}
                        loading={true}
                    />
                </div>
            </div>
          </div>
        </>;
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;