import React, { Component } from "react";
import "./purchaseHistoryData.scss";
import PurchaseHistoryList from "../../components/PurchaseHistoryList/PurchaseHistoryList.js";
import { purchaseHistoryActions } from "../../_actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import i18next from "i18next";
import InfiniteScroll from 'react-infinite-scroll-component';
import { decryptData } from "../../_helpers/encryptData";
import { history } from "../../_helpers";
import ModelPopup from "../../components/Model-Popup/Popup";
class purchaseHistoryData extends Component {
  constructor(props) {
    super(props);
    let params = JSON.parse(decryptData(localStorage.getItem("user")));
    this.state = {
      pageNumber:2,
      limit:50,
      item:[],
      userId:params.userId,
      authToken:params.authToken,
      hasMore:true,
      isLoading:true,
      ErrorPopup:false,
      ErrorMsg:""
    }
    let data = {
      userId:params.userId,
      authToken:params.authToken,
      pageNumber:1,
      limit:50
    }
    this.props.getPurchaseHistory(data);
    this.fetchMoreData = this.fetchMoreData.bind(this);
  }
  fetchMoreData(){
    let data = {
      userId:this.state.userId,
      authToken:this.state.authToken,
      pageNumber:this.state.pageNumber,
      limit:this.state.limit
    }
    this.setState({
      pageNumber:this.state.pageNumber+1
    })
    this.props.getPurchaseHistory(data);
  }
  componentWillReceiveProps(props){
    // console.log(props.purchaseHistory)
    // console.log(this.state.item,props.purchaseHistory)
    if(props.purchaseHistory.products){
      if(props.purchaseHistory.products.length==0){
        this.setState({
          hasMore:false,
          isLoading:false
        })
      }
      else{
        let item = this.state.item;
        let {pageNumber,limit} = this.state;
        let list = props.purchaseHistory.products?props.purchaseHistory.products:[]
        let totalItem = item.concat(list)
        this.setState(prevState=>({
          item: totalItem,
          isLoading:false,
          hasMore:totalItem.length<(pageNumber-1)*limit?false:true
        }))
      }      
    }
  }
  handleRedirection=(data)=>{
    var item = this.state.item
    history.push({
      pathname: "/OrderSummary/" + data.orderID,
      query: {
        ordersummary: item
          ? item.filter(
              fil => fil.orderID === data.orderID
            )
          : []
      }
    })
  }
  handleSupport=(data)=>{
    if(data.ManualCorrectionStatus){
      this.setState({
        ErrorPopup:true,
        ErrorMsg:i18next.t("You have already raised your concern for this transaction")
      })
    }
    else{
      history.push({
        pathname: `/NeedHelp/${data.orderID}`,
        state:{
          fromPurchaseHistory: 'purchaseHistory',
          purchaseDate: data.date,
          SupportButtonDuration: data.SupportButtonDuration 
        }
      })
    }
    
  }
  closingErrorPopup=()=>{
    this.setState({
      ErrorPopup:false
    })
  }
  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    };
    var styling = {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)",
      color: "#000000",
      fontSize: "18px",
      width:"100%",
      textAlign:"center"
    };
    var purchaseDataList = this.props.purchaseHistory.products;
    var item = this.state.item
    // console.log(this.state.pageNumber)
    return (
      <div className="purchaseHistoryData">
        <div className="history-sec-box" >
          {this.state.isLoading ? (
            <div style={style}>
              <ClipLoader size={50} color={"#FF0000"} />
            </div>
          ) : item.length>0 ? (
            <InfiniteScroll 
              dataLength={item.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={<p className="text-center">Loading...</p>}
              scrollableTarget="scrollableDiv"
            >
            <ul className="list-box">
              {item &&
                item.map((single, key) => {
                  return (
                      <PurchaseHistoryList
                        data={single}
                        key={key}
                        sliderClassName="list-boxs"
                        onClick={this.handleRedirection}
                        handleSupport={this.handleSupport}
                      />
                  );
                })}
            </ul>
            </InfiniteScroll>
          ) : (
            <div style={styling}>{i18next.t("NoPurchaseHistory")}</div>
          )}
        </div>
        {
          this.state.ErrorPopup &&
            <ModelPopup
                color="Red"
                openPopup={this.state.ErrorPopup}
                closePopup={this.closingErrorPopup}
                message={this.state.ErrorMsg}
                imagetype="error"
                    
            />
        }
      </div>
    );
  }
}

function mapState(state) {
  const { purchaseHistory } = state;
  return { purchaseHistory };
}

const actionCreators = {
  getPurchaseHistory: purchaseHistoryActions.getPurchaseHistory
};

const connectedPurchaseHistory = connect(
  mapState,
  actionCreators
)(purchaseHistoryData);

export { connectedPurchaseHistory as PurchaseHistoryData };
