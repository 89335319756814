import React from 'react';
import PropTypes from "prop-types";
import PopUpModel from './PopUpModel';


const PopUpType = ({ data }) => { 
    return(   
        <PopUpModel Type={data.title} />
    )
}

PopUpType.propTypes = {
    data: PropTypes.object
  };


export default PopUpType
