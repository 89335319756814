import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Red_arrow from "../../assets/images/icons/Red_Arrow.svg";
import "./BackWhiteData.scss";

const BackWhiteData = ({ title, BackLink }) => {
  return (
    <div className="col-12">
      <div className="backLinkTitle">
        <div className="butonleft">
          <Link to={process.env.PUBLIC_URL + "/" + BackLink}>
            <img src={Red_arrow} alt="Back" style={{ height:'30px'}}/>
          </Link>
        </div>
        <div className="text-center back-text clrblack ">
          <h5>{title}</h5>
        </div>
      </div>
    </div>
  );
};

BackWhiteData.propTypes = {
  title: PropTypes.string,
  BackLink: PropTypes.string
};

export default BackWhiteData;
