import React,{Component} from 'react';
import PopUpType from '../containers/PopUpModel/PopUpType';
import PopupTypes from '../data/PopupTypes/PopupTypes'
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { config } from "../_config";

// export function popup1() {
//     <PopUpModel Type="1"/>
// }
  
// export function popup2() {
//     <PopUpModel Type="2"/>
// }

// export default function() {
//     <PopUpModel Type="3"/>
// }


// export class popups1 extends Component {
//     render() {
//         return (
//             <PopUpModel Type="a"/>
//         )
//     }
// }

// export class popups2 extends Component {
//     render() {
//         return (
//             <PopUpModel Type="b"/>
//         )
//     }
// }
// export default class popups3 extends Component {
//     render() {
//         return (
//             <PopUpModel Type="c"/>
//         )
//     }
// }


class popups extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | popups`;
        CapturePage({hitType:"pageview",page:"/popups"})
    }
    render() {
        return (
            <div>
                {PopupTypes &&
                    PopupTypes.map((single, key) => {
                    return (
                        <PopUpType
                        data={single}
                        key={key}
                        />
                    );
                })}
            </div>
        )
    }
}

export default popups