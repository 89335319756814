import React, { Component } from "react";
import SplashScreen from "../containers/SplashScreen/SplashScreen";
import Navigation from "../components/navigation/nav";
import ProfileHeader from "../containers/profileHeader/profileHeader";
import Loader from "../components/Loader/loader";
import HeroSlider from "../containers/hero-sliders/heroslider";
import "../assets/scss/defaults.scss";
import { history } from "../_helpers";
//import CoolerProduct from '../containers/CoolerProduct/CoolerProduct';
import { productActions } from "../_actions";
import { connect } from "react-redux";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import CoolerPopup from "../components/Cooler-Popup/Cooler-Popup";
import ModelPopup from "../components/Model-Popup/Popup";
import lockedIcon from "../_assets/images/icons/locked.png";
import unlockDoorIcon from "../_assets/images/icons/unlocked.png";
import error from "../_assets/images/icons/error.png";
import errorIcon from "../assets/images/icons/error.png";
import i18next from "i18next";
import { Trans } from "react-i18next";
import { pendingpaymentService } from "../_services";
import { v4 as uuidv4 } from "uuid";
import ManualPopup from "../components/AddManualPopup/ManualPopup";
import { config, PaymentGatewayList } from "../_config/index";
import io from "socket.io-client";
import { cardService } from "../_services/card.service";
import black_arrow from "../_assets/images/icons/black_arrow.png";
import { Link } from "react-router-dom";
import country  from "iso-country-currency"
import { Modal, Select, Tag } from "antd";
import FooterNav from "../components/FooterNav/FooterNav";
import next from "../_assets/images/icons/black_arrow.png";
import SaferpayWallet from "../components/SaferpayWallet/SaferpayWallet";
import btnLock from "../_assets/images/icons/btn_lock.png";
import btnLocked from "../_assets/images/icons/btn_locked.png";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import left_angle from "../_assets/images/icons/left-angle.png"
import { decryptData, encryptData } from "../_helpers/encryptData";
import OtpPopUp from "../components/OtpPopUp/OtpPopUp";
import Notification from "../components/Notification/Notification";
import CardValidation from "../components/CardValidation/CardValidation";
const { Option } = Select;

class Home extends Component {
  DoorErrorCodes = {
    0: "DeviceUnavailable",
    1: "NoProductSelected",
    2: "NoResponseAfterTimeout",
    3: "NoResponseAfterTimeout",
    254: "CoolerUnderMaintenance",
    253: "SeemsDoorIsOpen",
    252: "GPUNotReady",
    251: "DoorOpenTimeout",
    250: "CameraIssue",
    249: "LowInternetSpeed",
    248: "LowDiscSpace",
    247: "IssueInRecognitionSystem",
    246: "DeviceUnavailable",
    245: "LockIssue",
    243: "VoltageIssue",
    244: "TemperatureIssue"
  };

  errorCode = 0

  constructor(props) {
    super(props);
    this.unlockDoor = this.unlockDoor.bind(this);
    this.state = {
      showPopup: false,
      disableUnlockbutton: false,
      connectionLost: false,
      coolerUnavailable: false,
      deviceUnavailable: false,
      doorClosed: false,
      doorOpenedForLongTime: false,
      doorOpened: false,
      totalRewardBalance:0,
      noCoolerFound: false,
      NoRewardPoint:false,
      apiPopup:false,
      popupmsg:'',
      imagetype:'',
      cardList:[],
      offerCoupon:[],
      freeProducts:[],
      transactionID:'',      
      isLoading:true,
      isCouponApplied:false,
      ApplyCouponPopup:false,
      selectedCoupon:null,
      preAuth:null,
      currencyCode: config.currencyCode,
      isOutstadningBalance:false,
      isUnLocked:false,
      defaultPaymentGateway:"",
      showFooter:true,
      OpenWallet:false,
      paymentType:'',
      paymentTitle:i18next.t("Select Payment Method"),
      PayUOTP:"",
      showPayUModal:false,
      PayUDesc:"",
      preAuthorizeAmount:0,
      isPreAuthEnable:false,
      PayUData:{},
      socket:null,
      isPayuHavePendingAmt:false,
      isOtpButtonDisable:false,
      isFBFAvailed:false,
      showPreAuthNotification:false,
      paymentMethod:[],
      paymentFlow:"OLD",
      cvv:'',
      showCardValidation:false,
      paytmTransactionID:this.props.location.state ?this.props.location.state.transactionID:'',
      isPaytmFail:false
    };
    //check if user has pending payment then redirect to pending payment else stay here
    
      // console.log(this.props.location.state ?.transactionID??'')
      
    
    this.closingPopup = this.closingPopup.bind(this);
    this.closingConnectionPopup = this.closingConnectionPopup.bind(this);
    this.closingUnavailablePopup = this.closingUnavailablePopup.bind(this);
    this.closingdeviceUnavailablePopup = this.closingdeviceUnavailablePopup.bind(
      this
    );
    this.closingDoorClosedPopup = this.closingDoorClosedPopup.bind(
      this
    );
    this.closingDoorOpenedPopup = this.closingDoorOpenedPopup.bind(this);
    this.closingdoorOpenedForLongTimePopup = this.closingdoorOpenedForLongTimePopup.bind(this);
    this.closingNoCoolerFoundPopup = this.closingNoCoolerFoundPopup.bind(this);
    this.closingApiPopup = this.closingApiPopup.bind(this);
    this.sendToScan = this.sendToScan.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleApplyCoupon = this.handleApplyCoupon.bind(this);
    this.handleBack = this.handleBack.bind(this); 
    document.title=`Coke & Go ${config.countryName} | Home`;
    CapturePage({hitType:"pageview",page:"/Home"})
    this.increaseExpiry();
    // let transactionData = this.props.location.state
    // if(transactionData.transactionID){
    //   this.setState({
    //     transactionID:transactionData.transactionID
    //   })
    //   this.unlockDoor()
    // }
    console.log(this.props.location.state)
  }

  increaseExpiry=()=>{
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let hrs = new Date();
    hrs.setMinutes(hrs.getMinutes() + config.sessionExpiryTime);
    user.expiry = hrs;
    localStorage.setItem('user', encryptData(JSON.stringify(user)));
  }

  componentDidMount() {
    // this.setupBeforeUnloadListener()
    let params = JSON.parse(decryptData(localStorage.getItem("user")));
    let macAdd = decryptData(localStorage.getItem("qrcode"));
    params.macAddress = macAdd;
    this.props.getAllProducts(params);

    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    let Iduser = user.userId;
    let authUser = authToken
    this.setState({
      showFooter:false
    })
    pendingpaymentService
      .getPendingPayment({
        authUser,
        Iduser,
      })
      .then((pending) => {
        if (pending.success && pending.message !== "No Records Found") {
          // do nothing
          this.setState({
            isOutstadningBalance:true
          })
        } 
        else if (!pending.success) {
          this.setState({
            connectionLost: true,
          });
        }
      });      
      let that = this
      let saferPurchaseNumber = sessionStorage.getItem("purchaseNumber")
      cardService.getCardList()
      .then((result)=>{         
        if(result.success){
          that.setState({
            cardList:result.cardList,
            defaultPaymentGateway:result.paymentGateway.toLowerCase(),
            paymentFlow:result.paymentFlow
          })  
          sessionStorage.setItem('paymentFlow',result.paymentFlow)                   
          sessionStorage.setItem('paymentGateway',result.paymentGateway.toLowerCase())                   
        }

        cardService.couponList()
        .then(result=>{       
          if(result.success){
            if(result.OfferCoupon.length>0 || result.separateOfferCoupon.length>0){
              let OffCoupon = [];
              if(result.separateOfferCoupon.length>0){
                OffCoupon = OffCoupon.concat(result.separateOfferCoupon[0].FreeSKUID.split("|"))
              }
              if(result.OfferCoupon.length>0){
                OffCoupon = OffCoupon.concat(result.OfferCoupon[0].FreeSKUID.split("|"))
              }
              that.setState({
                selectedCoupon:result.separateOfferCoupon.length>0? result.separateOfferCoupon[0].CouponCode:result.OfferCoupon[0].CouponCode,
                freeProducts:OffCoupon
              }) 
            }
            that.setState({
              offerCoupon:result.OfferCoupon.concat(result.separateOfferCoupon),            
            })  
            if(that.state.paytmTransactionID || saferPurchaseNumber){
              that.setState({
                isLoading:true
              })
              that.unlockDoor()
            }
            else{
              that.setState({
                isLoading:false
              })
            }
          }
        }) 
        
      }) 
      cardService.getPurchaseQty()
      .then(data=>{
        that.setState({
          showFooter:true
        })
      })
      
      // if(saferPurchaseNumber){
      //   const that = this
      //   that.setState({
      //     isLoading:true
      //   })
      //   setTimeout(function(){
      //     that.setState({
      //       isLoading:false
      //     })
      //     that.unlockDoor()
      //   },5000)
      // }
  }

  componentWillReceiveProps(previousProps, previousState) {
    try {
      if (previousProps.isCoolerNotFound !== this.state.noCoolerFound)
        this.setState({
          noCoolerFound: true
        });
      
      if(previousProps.products !=this.props.products && (config.applicationTypeId==19 || config.applicationTypeId==20 || config.applicationTypeId==11)){
        const {products={success:false}} = previousProps.products    
        if(products.success){
          let filt = [];
          if(config.applicationTypeId==19){
            filt = products.paymentMethods.filter(item=>item.code=='PAYU')
          }
          else if(config.applicationTypeId==20){
            filt = products.paymentMethods.filter(item=>item.code=='AURESYS')
          }
          else if(config.applicationTypeId==11){
            filt = products.paymentMethods.filter(item=>item.code=='SAFERPAY')
          }
          this.setState((prevState)=>({
            ...prevState,
            paymentMethod:products.paymentMethods,
            preAuthorizeAmount:products.preAuthorizeAmount,
            isPreAuthEnable:filt.length>0?filt[0].isPreAuthEnable:(config.applicationTypeId==12)?true:false,
            PayUDesc:<Trans
                i18nKey='Security deposit of RS is blocked for the transaction & it will be reversed back after the transaction is complete'
                values={{
                  amt:products.preAuthorizeAmount
                }}
                components={[<strong />]}
              />
          }))
        }
      }
      else if(previousProps.products !=this.props.products && config.applicationTypeId==20){
        const {products={success:false}} = previousProps.products    
        if(products.success){
          // console.log(products.preAuthorizeAmount)
          this.setState((prevState)=>({
            ...prevState,
            preAuthorizeAmount:products.preAuthorizeAmount,
          }))
        }
      }
      else if(previousProps.products !=this.props.products && (config.applicationTypeId==12 )){
        const {products={success:false}} = previousProps.products    
        if(products.success){
          this.setState((prevState)=>({
            ...prevState,
            paymentMethod:products.paymentMethods,
            isPreAuthEnable:true
          }))
        }
      }
    } catch (error) {
      
    }
    // if(prods.products && prods.products.currencyCode){
    //   this.setState({currencyCode:prods.products.currencyCode})
    // }
  }

  closingNoCoolerFoundPopup() {
    this.setState({ noCoolerFound: false });
    history.push('/Scanbutton')
  }

  closingConnectionPopup() {
    this.setState({
      connectionLost: false,
    });
  }

  handleSelectChange(event){
    const value = event.target.value
    const freeSKUData = this.state.offerCoupon.filter((item)=>item.CouponCode==value)
    if(freeSKUData.length>0){
      var freeSKUList = freeSKUData[0].FreeSKUID?freeSKUData[0].FreeSKUID.split("|"):[]
    }
    this.setState({
      selectedCoupon:value,
      freeProducts:freeSKUList
    })
  }

  handleBack(){
    // localStorage.removeItem('qrcode');
    history.push('/ScanButton')
  }

  handleApplyCoupon(){
    const { products:{paymentMethods=[]} } = this.props.products
    let filt = [];
    if (config.applicationTypeId == 19) {
      filt = paymentMethods.filter(item => item.code == 'PAYU')
    }
    else if (config.applicationTypeId == 20) {
      filt = paymentMethods.filter(item => item.code == 'AURESYS')
    }
    else if(config.applicationTypeId==11){
      filt = paymentMethods.filter(item=>item.code=='SAFERPAY')
    }
    let isPreAuthEnable = (filt.length>0 && filt[0].isPreAuthEnable)?true:(config.applicationTypeId==12)?true:false
    this.setState({
      isCouponApplied:true,
      ApplyCouponPopup:false,
      isLoading:((config.applicationTypeId==20 || config.applicationTypeId==19 || config.applicationTypeId==11) && isPreAuthEnable)?true:false,
      showPopup: ((config.applicationTypeId==20 || config.applicationTypeId==19 || config.applicationTypeId==11) && isPreAuthEnable)?false:true
    })
    if(config.applicationTypeId==19 && this.state.defaultPaymentGateway=='paytm' && config.enablePaytmCard && this.state.paytmTransactionID && this.state.paymentFlow=='CARD'){
      this.UnlockDoorWithPayTM(true)
      return false
    }
    this.connectToSocket(true)
  }

  handleSkip(){
    const { products:{paymentMethods=[]} } = this.props.products
    let filt = [];
    if (config.applicationTypeId == 19) {
      filt = paymentMethods.filter(item => item.code == 'PAYU')
    }
    else if (config.applicationTypeId == 20) {
      filt = paymentMethods.filter(item => item.code == 'AURESYS')
    }
    else if(config.applicationTypeId==11){
      filt = paymentMethods.filter(item=>item.code=='SAFERPAY')
    }
    let isPreAuthEnable = (filt.length>0 && filt[0].isPreAuthEnable)?true:(config.applicationTypeId==12)?true:false
    this.setState({
      isCouponApplied:false,
      ApplyCouponPopup:false,
      selectedCoupon:null,
      isLoading:((config.applicationTypeId==20 || config.applicationTypeId==19 || config.applicationTypeId==11) && isPreAuthEnable)?true:false,
      showPopup: ((config.applicationTypeId==20 || config.applicationTypeId==19 || config.applicationTypeId==11) && isPreAuthEnable)?false:true 
    })
    if(config.applicationTypeId==19 && this.state.defaultPaymentGateway=='paytm' && config.enablePaytmCard && this.state.paytmTransactionID && this.state.paymentFlow=='CARD'){
      this.UnlockDoorWithPayTM(false)
      return false
    }
    this.connectToSocket(false)
  }
  showNotification=()=>{
    this.setState({
      showPreAuthNotification:true
    })
  }
  closeNotification=()=>{
    this.setState(prevState=>({
      ...prevState,
      showPreAuthNotification:false
    }))
    this.unlockDoor()
  }
  unlockDoor() {   
    CaptureEvent({
      category:"unlock door",
      action:"button clicked",
      label:"unlockDoor"
    })
    if(navigator.onLine){
      if(this.state.isOutstadningBalance){
        sessionStorage.removeItem('clickedDelete')
        history.push({
          pathname: "/PendingAmount"
        });
      }
      else{
        let saferPurchaseNumber = sessionStorage.getItem("purchaseNumber")
        if(config.isSaferPayWalletEnabled && !saferPurchaseNumber && !this.state.OpenWallet){
          this.setState({
            OpenWallet:true
          })
        }
        else{
          let {cardList,defaultPaymentGateway,paymentType} = this.state
          let availableCardlist = config.applicationTypeId==19?cardList.filter(item=>item.paymentGateway.toLowerCase()==defaultPaymentGateway):cardList;
          let appList = [11,12]
          if((appList.indexOf(config.applicationTypeId) < 0 && availableCardlist.length==0 && !saferPurchaseNumber) || (paymentType == 'creditCard' && availableCardlist.length==0 && !saferPurchaseNumber)){
            this.setState({
              showPopup: false,
              apiPopup: true,                        
              imagetype:"error",                        
              disableUnlockbutton: false,
              popupmsg:i18next.t(config.applicationTypeId==19?cardList.length>0?"We’re sorry but your existing payment method is not compatible with this Smart Cooler. Please tap on OK to continue and add an additional payment method":"Please add payment method":'PlzAddCard')
            })
          }
          else{
            if(config.applicationTypeId==19 && this.state.defaultPaymentGateway=='paytm' && config.enablePaytmCard && !this.state.paytmTransactionID && this.state.paymentFlow=='CARD' ){
              this.setState({
                showCardValidation:true
              })
              return false
            }
            let that = this;
            // offer list
            let OfferList = that.state.offerCoupon
            // Open Loader and disable open cooler button
            let FreeProductList = []
            let {products} = that.props;
            if(that.state.freeProducts.length>0){
              if(products.products && products.products.data){
                let list = products.products.data[0].products;
                that.state.freeProducts.map((data,key)=>{
                  let singleProduct = list.filter((item)=>(item.productSKU==data)?item:null)
                  if(singleProduct.length>0){
                    FreeProductList.push(singleProduct[0])
                  }
                })          
              }
            }
            if(OfferList.length>0 && FreeProductList.length>0){
              that.setState({
                isLoading:false,
                ApplyCouponPopup:true
              })
            }
            else{
              let isPreAuthEnable=false;
              const { products:{paymentMethods=[]} } = that.props.products
              // let filt = paymentMethods.filter(item=>item.code=='PAYU')
              let filt = [];
              if (config.applicationTypeId == 19) {
                filt = paymentMethods.filter(item => item.code == 'PAYU')
              }
              else if (config.applicationTypeId == 20) {
                filt = paymentMethods.filter(item => item.code == 'AURESYS')
              }
              else if(config.applicationTypeId==11){
                filt = paymentMethods.filter(item=>item.code=='SAFERPAY')
              }
              isPreAuthEnable = (filt.length>0 && filt[0].isPreAuthEnable)?true:(config.applicationTypeId==12)?true:false
              that.setState({             
                isLoading: (config.applicationTypeId==20 && isPreAuthEnable)?true:false,
                showPopup: that.state.defaultPaymentGateway == 'payu' && isPreAuthEnable?false:(config.applicationTypeId==20 && isPreAuthEnable) ?false:true
              });
              if(config.applicationTypeId==19 && this.state.defaultPaymentGateway=='paytm' && config.enablePaytmCard && this.state.paytmTransactionID){
                this.UnlockDoorWithPayTM(false)
                return false
              }
              
              that.connectToSocket(false)                  
            }      
          }
        }
      }
    }
    else{
      this.setState({
        coolerUnavailable:true
      })
    }
  }

  async connectToSocket(AppliedCoupon){
    let that = this;
    that.setState({isLoading:true})
    var userpar = JSON.parse(decryptData(localStorage.getItem("user"))); 
    var saferPurchaseNumber = sessionStorage.getItem('purchaseNumber')
    // Pre Authorization Here
    let defaultcard = that.state.cardList.filter((item)=>(item.isDefault)?item:null)   
    const { products:{preAuthorizeAmount=0,paymentMethods=[]} } = that.props.products
    let preAuthAmount = preAuthorizeAmount; 
    if(config.applicationTypeId == 19 || config.applicationTypeId == 20){
      that.setState({
        preAuthorizeAmount:preAuthorizeAmount,
        PayUDesc:<Trans
                  i18nKey='Security deposit of RS is blocked for the transaction & it will be reversed back after the transaction is complete'
                  values={{
                    amt:preAuthAmount
                  }}
                  components={[<strong />]}
                />
      })
    }
    let filt = [];
    if(config.applicationTypeId == 19){
      if(that.state.defaultPaymentGateway=='paytm'){
        filt = paymentMethods.filter(item=>item.code=='PAYTM')
        defaultcard = that.state.cardList.filter((item)=>(item.isDefault && item.paymentGateway.toLowerCase()=='paytm')?item:null)
      }
      else {
        filt = paymentMethods.filter(item=>item.code=='PAYU');
        defaultcard = that.state.cardList.filter((item)=>(item.isDefault && item.paymentGateway.toLowerCase()=='payu')?item:null)
      }
    }
    else if(config.applicationTypeId == 20){
      filt = paymentMethods.filter(item=>item.code=='AURESYS')
    }
    else if(config.applicationTypeId==11){
      filt = paymentMethods.filter(item=>item.code=='SAFERPAY')
    }
    let isPreAuthEnable = (filt.length>0 && filt[0].isPreAuthEnable)?true:(config.applicationTypeId==12)?true:false
    let req = {
      cardID: saferPurchaseNumber?"":that.state.paymentType != 'creditCard' && that.state.defaultPaymentGateway=='saferpay' ? '' : defaultcard[0].cardID,
      amt:preAuthAmount,
      currencyCode: this.state.currencyCode,
      paymentmethod:this.state.defaultPaymentGateway
    }
    let OfferList = that.state.offerCoupon
    var userdata = JSON.parse(decryptData(localStorage.getItem("user")));   
    let macAddress = decryptData(localStorage.getItem('qrcode'));
    let timercount,timercount2;
    var doorparams = {
      userId: userdata.userId,
      deviceid: this.props.products.products
        ? this.props.products.products.data[0].deviceId
        : history.goBack(),
    };
    let socketparam=doorparams.userId+'-'+doorparams.deviceid
    fetch(`${config.socketUrl}/GetWebPubSubTokenUrl?userId=${socketparam}`)
    .then((response)=>{
     return response.text()
    })
    .then((socketURL)=>{
      if(socketURL){
        var socket = new WebSocket(socketURL)
        socket.onopen = function () {
          console.log('connection is open'); // 
        };       
        
        socket.onerror = function (error) {
          console.log('WebSocket Error ' + error);
        };
        const V=1;
        const applicationTypeId = config.applicationTypeId
        const authToken = userpar.authToken;
        let sessionUId = sessionStorage.getItem("sessionId");
        let RequestOptions = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({authToken,applicationTypeId,sessionUId})
        }
        // checking device status
        fetch(`${config.envUrl}VendingAppApi/getTransactionSameTime`, RequestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(data=>{
          if (data.success) {
            // For Cielo Payment
            if ((config.paymentGateway == 1 && that.state.defaultPaymentGateway == 'cielo') || (config.paymentGateway == 5) || (config.applicationTypeId == 20 && !isPreAuthEnable) || (config.applicationTypeId == 19 && that.state.paymentFlow=="OLD" && !isPreAuthEnable) || (config.applicationTypeId == 11 && !isPreAuthEnable)) {
              const deviceMACAddress = macAddress;
              const userId = userpar.userId;
              const transactionId = uuidv4();
              let sessionUId = sessionStorage.getItem('sessionId');

              const t = that.props.products.products ? that.props.products.products.doorTimeoutValue : 300;
              let RequestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({ deviceMACAddress, authToken, applicationTypeId, userId, transactionId, V, t, sessionUId })
              }
              // checking device status
              fetch(`${config.envUrl}VendingAppApi/unlockCoolerDoorV1`, RequestOptions)
                .then(function (response) {
                  return response.json();
                })
                .catch(function (err) {
                  that.setState({
                    isLoading: false,
                    coolerUnavailable: true,
                    showPopup: false,
                    doorClosed: false,
                    doorOpenedForLongTime: false,
                    doorOpened: false
                  });// catch error
                })
                .then(function (body) {
                  if (body.success) {
                    setTimeout(() => {
                      that.setState({
                        isUnLocked: true,
                        isLoading: false
                      })
                    }, 2000)
                    const txId = config.applicationTypeId + ":" + body.TxID
                    socket.onmessage = function (mqttdata) {
                      console.log(mqttdata)
                      let devicedata = JSON.parse(mqttdata.data)
                      let weblogReq = {
                        authToken: authToken,
                        smartDeviceId: doorparams.deviceid,
                        deviceResponse: JSON.stringify(devicedata)
                      }
                      cardService.WebAppLog(weblogReq)
                      if (devicedata.TMtd == 2 && devicedata.UId == userpar.userId && txId == devicedata.TxID) {
                        if (devicedata.Sts === 4 && devicedata.V === 1 && txId == devicedata.TxID) {
                          // console.log('door opend')
                          // clearTimeout(dooropenforlongtime)          
                          let logRequest = {
                            MacAddress: macAddress,
                            userId: userpar.userId,
                            doorStatus: true,
                            error: devicedata.Sts,
                            Sts: devicedata.Sts
                          }
                          // cardService.MqttLog(logRequest)
                          timercount = setTimeout(function () {
                            console.log('door stil open');
                            that.errorCode = 3
                            that.setState({
                              isLoading: false,
                              showPopup: false,
                              disableUnlockbutton: false,
                              doorClosed: false,
                              doorOpenedForLongTime: true,
                              doorOpened: false
                            });
                            timercount2 = setTimeout(function () {
                              console.log('transaction cancel and door open')
                              socket.close();
                              that.errorCode = 2;
                              that.setState({
                                isLoading: false,
                                showPopup: false,
                                disableUnlockbutton: false,
                                doorClosed: false,
                                doorOpenedForLongTime: false,
                                doorOpened: false,
                                deviceUnavailable: true
                              });
                              // cardService.cancelTransaction(result.transactionID);
                            }, 10000)
                          }, that.props.products.products.doorTimeoutValue * 1000 * 2)
                        }
                        else if (devicedata.Sts === 4 && devicedata.V === 2) {
                          let logRequest = {
                            MacAddress: macAddress,
                            userId: userpar.userId,
                            doorStatus: false,
                            error: devicedata.Sts,
                            Sts: devicedata.Sts
                          }
                          // cardService.MqttLog(logRequest)
                          // console.log('door closed')
                          if (timercount2) {
                            clearTimeout(timercount2);
                          }
                          if (timercount) {
                            clearTimeout(timercount);
                          }
                        }
                        else if (devicedata.Sts === 4 && devicedata.V === 3) {
                          console.log(devicedata)
                          if (timercount2) {
                            clearTimeout(timercount2);
                          }
                          if (timercount) {
                            clearTimeout(timercount);
                          }
                          that.setState({
                            isLoading: false,
                            deviceUnavailable: false,
                            doorClosed: false,
                            showPopup: false,
                            doorOpenedForLongTime: true,
                            doorOpened: false,
                            OpenWallet: false
                          });
                          socket.close();
                        }
                        else if (devicedata.Sts == 1 && txId == devicedata.TxID) {
                          console.log("product list recieved", devicedata["NPro"])
                          if (timercount2) {
                            clearTimeout(timercount2);
                          }
                          if (devicedata.UId == doorparams.userId) {
                            // clearTimeout(dooropenforlongtime);
                            // devicedata.PurcNo = uuidv4();
                            let productlist = that.props.products.products.data[0].products;
                            let currencyCode = that.state.currencyCode
                            let PurchaseProduct = []
                            let totalPrice = 0;
                            if (devicedata["NPro"] >= 0) {

                              let logReq = {
                                smartDeviceId: doorparams.deviceid,
                                purchaseData: JSON.stringify(devicedata),
                                userId: userpar.userId
                              }
                              // cardService.MqttMachineLog(logReq)

                              for (let i = 1; i <= devicedata["NPro"]; i++) {
                                let prodObj = devicedata["P" + i];
                                let prodObjId = prodObj.SKUId;
                                productlist.map(function (fil) {
                                  let cnt = 15 - fil.productSKU.length;
                                  let prefix = "";
                                  for (let index = 0; index < cnt; index++) {
                                    prefix += "0";
                                  }
                                  let newSKU = prefix + "" + fil.productSKU;
                                  if (prodObjId === newSKU) {
                                    totalPrice += fil.productPrice * parseInt(prodObj.Q);
                                    PurchaseProduct.push({
                                      productID: fil.productId,
                                      count: prodObj.Q,
                                      userQuantity: prodObj.Q,
                                      orderUpdateReasonId: 0,
                                      productSKU: fil.productSKU
                                    });
                                    return false;
                                  };
                                });
                              }
                              var freeSKUId = ""
                              if (that.state.selectedCoupon) {
                                that.state.freeProducts.map((freedata, key) => {
                                  if (freeSKUId === "") {
                                    let sku = PurchaseProduct.filter((item) => (item.productSKU === freedata) ? item : null)
                                    if (sku.length > 0) {
                                      freeSKUId = sku[0].productSKU
                                    }
                                  }
                                })
                              }
                              if (PurchaseProduct.length >= 0){
                                let purchaseNumber = body.TxID;
                                let eventId = devicedata.SId;
                                let coolerID = that.props.products.products.data[0].coolerId;
                                let totalRewardPointsEarned = 0;
                                let resource = 'web';
                                let irResultResponseTime = devicedata.analyticalParams && devicedata.analyticalParams.irResultResponseTime
                                  ? devicedata.analyticalParams.irResultResponseTime
                                  : "";
                                let authToken = userpar.authToken;
                                let description = config.defaultLanguage;
                                let paymentGatewayTransactionDetails = 'swipe';
                                let eventTimeUTC = devicedata.T;// T from device
                                let transactionId = devicedata.TId;
                                let products = PurchaseProduct.length>0?JSON.stringify(PurchaseProduct):[];
                                let totalAmountCharged = totalPrice;
                                let transactionMethod = 2;
                                let freeSKU = PurchaseProduct.length>0?AppliedCoupon ? freeSKUId : '':"";
                                let edgeSessionId = devicedata.edgeSessionId ? devicedata.edgeSessionId : "";
                                let dataConsumedDuringTransaction = devicedata.analyticalParams && devicedata.analyticalParams.dataConsumedDuringTransaction
                                  ? devicedata.analyticalParams.dataConsumedDuringTransaction
                                  : "";
                                let cieloTransactionId = purchaseNumber;
                                let transactionDuration = devicedata.analyticalParams && devicedata.analyticalParams.transactionDuration
                                  ? devicedata.analyticalParams.transactionDuration
                                  : "";
                                let paymentMethod = config.applicationTypeId==23?"Globalpay":that.state.defaultPaymentGateway;
                                let couponCode = PurchaseProduct.length>0?AppliedCoupon ? that.state.selectedCoupon : "":"";
                                let paymentTransactionId = purchaseNumber;

                                let isDeduction = PurchaseProduct.length>0?true:false;
                                let isCouponApplied=PurchaseProduct.length>0?AppliedCoupon:false
                                let deviceId = macAddress
                                const applicationTypeId = config.applicationTypeId;
                                const cieloLinks = ""
                                const customerName = userpar.firstName + ' ' + userpar.lastName;
                                const cardToken = ""
                                const uniquedeviceId = userpar.userId
                                const ctst = devicedata.CTSt ? devicedata.CTSt : ""
                                const cbst = devicedata.CTSt ? devicedata.CBSt : ""
                                const languageCode = localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
                                let sessionUId = sessionStorage.getItem('sessionId');
                                let isPreAuthEnabled = isPreAuthEnable
                                const returnSuccessUrl = config.baseURL+'/successResponse';
                                const returnFailUrl = config.baseURL+'/CardFail';
                                const walletType = that.state.paymentType=="creditCard"?"":that.state.paymentType
                                let UnRecogErrorType = (PurchaseProduct.length==0 && devicedata["NPro"]==0) ? "Zero" : "Mismatch"
                                const walletcardtoken = sessionStorage.getItem("walletCardId") == null ? "" : sessionStorage.getItem("walletCardId");
                                const requestOptions = {
                                  method: 'POST',
                                  headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                  },
                                  body: new URLSearchParams({
                                    ctst,
                                    cbst,
                                    eventId,
                                    coolerID,
                                    totalRewardPointsEarned,
                                    resource,
                                    irResultResponseTime,
                                    authToken,
                                    description,
                                    paymentGatewayTransactionDetails,
                                    eventTimeUTC,
                                    transactionId,
                                    products,
                                    totalAmountCharged,
                                    transactionMethod,
                                    freeSKU,
                                    edgeSessionId,
                                    dataConsumedDuringTransaction,
                                    cieloTransactionId,
                                    transactionDuration,
                                    paymentMethod,
                                    isCouponApplied,
                                    couponCode,
                                    paymentTransactionId,
                                    purchaseNumber,
                                    isDeduction,
                                    deviceId,
                                    applicationTypeId,
                                    cieloLinks,
                                    customerName,
                                    cardToken,
                                    currencyCode,
                                    txId,
                                    uniquedeviceId,
                                    languageCode,
                                    sessionUId,
                                    isPreAuthEnabled,
                                    returnSuccessUrl,
                                    returnFailUrl,
                                    walletType,
                                    walletcardtoken,
                                    UnRecogErrorType
                                  })
                                };

                                fetch(`${config.envUrl}VendingAppApi/addConsumerPurchaseV14`, requestOptions)
                                  .then((response) => {
                                    return response.json();
                                  })
                                  .then((ApiResult) => {
                                    // localStorage.removeItem('qrcode');
                                    const {success,referenceId="",amount,transactionID,otpPostUrl} = ApiResult

                                    if(ApiResult.status == false && ApiResult.code == 155247){
                                      if(UnRecogErrorType=='Zero'){
                                        that.errorCode = 1;
                                        that.setState({
                                          isLoading: false,
                                          deviceUnavailable: true,
                                          doorClosed: false,
                                          doorOpenedForLongTime: false,
                                          doorOpened: false,
                                          showPopup: false
                                        });
                                      }
                                      else{
                                        that.setState({
                                          isLoading: false,
                                          imagetype: 'error',
                                          showPopup: false,
                                          apiPopup: true,
                                          deviceUnavailable: false,
                                          popupmsg: i18next.t("UnKnownProduct"),
                                          disableUnlockbutton: false
                                        })
                                      }  
                                    }
                                    // localStorage.removeItem('qrcode');
                                    else if(referenceId!="" && applicationTypeId==19){
                                      that.setState((prevState)=>({
                                        ...prevState,
                                        isUnLocked:false,
                                        showPopup:false,
                                        showPayUModal:true,
                                        isLoading:false,
                                        PayUOTP:"",
                                        PayUData:{
                                          referenceId:referenceId,
                                          transactionID:transactionID,
                                          otpPostUrl:otpPostUrl
                                        },                                
                                        isPayuHavePendingAmt:true,
                                        PayUDesc:"Enter Your OTP",
                                        isOtpButtonDisable:false,
                                        transactionID:purchaseNumber
                                      }))
                                    } 
                                    else if(applicationTypeId==11 && !that.state.isPreAuthEnable && that.state.paymentType!="creditCard"){
                                      if(ApiResult.success && amount>0){
                                        sessionStorage.setItem('intiatedFromPending','true');
                                        sessionStorage.setItem('saferWalletToken',ApiResult.data.Token);
                                        sessionStorage.setItem('purchasenumber',purchaseNumber);
                                        window.location.href=ApiResult.data.RedirectUrl
                                      }
                                      else{
                                        history.push({
                                          pathname: '/OrderSummary/' + purchaseNumber,
                                          state: 'fromHome'
                                        });
                                      }
                                    }
                                    else{
                                      const FBFcoupon = that.state.offerCoupon.filter(item=>item.OfferTypeId==8)
                                      if (applicationTypeId == 20 && ApiResult.isFBFAvailed && FBFcoupon.length>0){
                                        that.setState({isFBFAvailed: true,isPreAuthEnable: false,showPopup: false, purchaseNumber:purchaseNumber})
                                      }
                                      else{
                                        history.push({
                                          pathname: '/OrderSummary/' + purchaseNumber,
                                          state: 'fromHome'
                                        });
                                      }
                                    }
                                    // if(ApiResult.success){
                                    //   localStorage.removeItem('qrcode');
                                    //   history.push({
                                    //     pathname:'/OrderSummary/'+purchaseNumber,
                                    //     state:'fromHome'
                                    //   });
                                    // }
                                    // else{
                                    //   let code = ApiResult.code;
                                    //   let errormsg = i18next.t('CommunicationError',{errorcode:code});
                                    //   that.setState({
                                    //     isLoading:false,
                                    //     imagetype:'error',
                                    //     apiPopup:true,
                                    //     popupmsg:errormsg,
                                    //     disableUnlockbutton:false
                                    //   })
                                    // }        
                                  })
                                  .catch(function (error) {
                                    that.setState({
                                      isLoading: false,
                                      coolerUnavailable: true,
                                      showPopup: false,
                                      doorClosed: false,
                                      doorOpenedForLongTime: false,
                                      doorOpened: false
                                    });
                                  });
                              }
                              else {
                                that.errorCode = 0
                                that.setState({
                                  isLoading: false,
                                  imagetype: 'error',
                                  showPopup: false,
                                  apiPopup: true,
                                  deviceUnavailable: false,
                                  popupmsg: i18next.t("UnKnownProduct"),
                                  disableUnlockbutton: false
                                })
                              }
                            }
                            else {
                              that.errorCode = 1;
                              that.setState({
                                isLoading: false,
                                deviceUnavailable: true,
                                doorClosed: false,
                                doorOpenedForLongTime: false,
                                doorOpened: false,
                                showPopup: false
                              });
                              let logRequest = {
                                MacAddress: macAddress,
                                userId: userpar.userId,
                                doorStatus: false,
                                error: "No Product Picked",
                                Sts: devicedata.Sts
                              }
                              // cardService.MqttLog(logRequest)
                              // cardService.cancelTransaction(result.message.Payment.PaymentId,totalPrice);
                            }
                          }
                          socket.close();
                        }
                        else {
                          let logRequest = {
                            MacAddress: macAddress,
                            userId: userpar.userId,
                            doorStatus: false,
                            error: devicedata.Sts,
                            Sts: devicedata.Sts
                          }
                          // cardService.MqttLog(logRequest)
                          // if(dooropenforlongtime)
                          // {clearTimeout(dooropenforlongtime)}
                          // cardService.cancelTransaction(result.message.Payment.PaymentId,'0');
                          that.errorCode = devicedata.Sts;
                          that.setState({
                            isLoading: false,
                            deviceUnavailable: true,
                            doorClosed: false,
                            showPopup: false,
                            doorOpenedForLongTime: false,
                            doorOpened: false
                          });
                          socket.close();
                        }
                      }
                    };

                  }
                  else {
                    let msg=i18next.t("DeviceUnavailable")
                    if(body.code==15503){
                      msg=i18next.t("O CPF informado está incorreto. Informe o CPF correto para efetuar compras")
                    }
                    // cardService.cancelTransaction(result.message.Payment.PaymentId,'0');
                    that.errorCode = 0
                    that.setState({
                      isLoading: false,
                      imagetype: 'error',
                      showPopup: false,
                      deviceUnavailable: false,
                      apiPopup:true,
                      popupmsg: msg,
                      disableUnlockbutton: false
                    })
                  }
                })
            }
            else if ((config.paymentGateway == 1 && that.state.defaultPaymentGateway == 'dlocal') || config.paymentGateway == 2 || config.paymentGateway == 3 || config.paymentGateway == 4 || config.paymentGateway == 6 || (that.state.defaultPaymentGateway == 'payu' && isPreAuthEnable) || config.paymentGateway==8 || config.paymentGateway==9) {
              cardService.preauthorize(req)
                .then((result) => {
                  const {referenceId="",transactionID="",otpPostUrl="",actionUrl=""} = result;
                  const FBFcoupon = that.state.offerCoupon.filter(item=>item.OfferTypeId==8)
                  if(result.success && referenceId!=""){
                    // console.log(referenceId,transactionID)
                      that.setState((prevState)=>({
                        ...prevState,
                        showPayUModal:true,
                        showPopup:false,
                        isLoading:false,
                        PayUData:{
                          referenceId:referenceId,
                          transactionID:transactionID,
                          otpPostUrl:otpPostUrl
                        },
                        socket:socket
                      }))
                  }
                  else if(result.success && actionUrl!="" && that.state.defaultPaymentGateway == 'paytm'){
                    localStorage.setItem('socket',socket)
                    const postUrl = result.actionUrl;
                    const errorUrl = config.baseURL + '/payment-status';
                    const content = JSON.parse(result.content);
                    const paytmUrl = config.envUrl+'paytm.aspx?MD='+content.MD+'&PaReq='+content.PaReq+'&TermUrl='+content.TermUrl+'&postURL='+postUrl+'&errorURL='+errorUrl
                    window.location = paytmUrl
                  }
                  else if(result.success && result.code==15518 && config.applicationTypeId==20 && FBFcoupon.length>0){
                    that.setState((prevState)=>({
                      ...prevState,
                      isFBFAvailed:true,
                      showPopup:false,
                      isLoading:false,
                      socket:socket,
                      transactionID:result.transactionID
                    }))
                  }
                  else if (result.success) {
                    that.setState({
                      showPopup:true,
                      isLoading:false,
                    })
                    const deviceMACAddress = macAddress;
                    const userId = userpar.userId;
                    const transactionId = result.transactionID;
                    const applicationTypeId = config.applicationTypeId
                    const authToken = userpar.authToken
                    const t = that.props.products.products ? that.props.products.products.doorTimeoutValue : 300;
                    let sessionUId = sessionStorage.getItem('sessionId');
                    let RequestOptions = {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                      },
                      body: new URLSearchParams({ deviceMACAddress, authToken, applicationTypeId, userId, transactionId, V, t, sessionUId })
                    }
                    // checking device status
                    fetch(`${config.envUrl}VendingAppApi/unlockCoolerDoorV1`, RequestOptions)
                      .then(function (response) {
                        return response.json();
                      })
                      .catch(function (err) {
                        that.setState({
                          isLoading: false,
                          coolerUnavailable: true,
                          showPopup: false,
                          doorClosed: false,
                          doorOpenedForLongTime: false,
                          doorOpened: false
                        });// catch error
                      })
                      .then(function (body) {
                        if (body.success) {
                          setTimeout(() => {
                            that.setState({
                              isUnLocked: true,
                              isLoading: false
                            })
                          }, 2000)
                          const txId = config.applicationTypeId + ":" + body.TxID;
                          socket.onmessage = function (mqttdata) {
                            let devicedata = JSON.parse(mqttdata.data)
                            console.log(devicedata)
                            let weblogReq = {
                              authToken: authToken,
                              smartDeviceId: doorparams.deviceid,
                              deviceResponse: JSON.stringify(devicedata)
                            }
                            cardService.WebAppLog(weblogReq)
                            if (devicedata.TMtd == 2 && devicedata.UId == userpar.userId && txId == devicedata.TxID) {
                              if (devicedata.Sts === 4 && devicedata.V === 1 && txId == devicedata.TxID) {
                                console.log('door opend')
                                // clearTimeout(dooropenforlongtime)          
                                let logRequest = {
                                  MacAddress: macAddress,
                                  userId: userpar.userId,
                                  doorStatus: true,
                                  error: devicedata.Sts,
                                  Sts: devicedata.Sts
                                }
                                // cardService.MqttLog(logRequest)
                                timercount = setTimeout(function () {
                                  console.log('door stil open');
                                  that.errorCode = 3
                                  that.setState({
                                    isLoading: false,
                                    showPopup: false,
                                    disableUnlockbutton: false,
                                    doorClosed: false,
                                    doorOpenedForLongTime: true,
                                    doorOpened: false
                                  });
                                  timercount2 = setTimeout(function () {
                                    console.log('transaction cancel and door open')
                                    socket.close();
                                    that.errorCode = 2;
                                    that.setState({
                                      isLoading: false,
                                      showPopup: false,
                                      disableUnlockbutton: false,
                                      doorClosed: false,
                                      doorOpenedForLongTime: false,
                                      doorOpened: false,
                                      deviceUnavailable: true
                                    });
                                    cardService.cancelTransaction(result.transactionID);
                                  }, 10000)
                                }, that.props.products.products.doorTimeoutValue * 1000)
                              }
                              else if (devicedata.Sts === 4 && devicedata.V === 2 && txId == devicedata.TxID) {
                                let logRequest = {
                                  MacAddress: macAddress,
                                  userId: userpar.userId,
                                  doorStatus: false,
                                  error: devicedata.Sts,
                                  Sts: devicedata.Sts
                                }
                                // cardService.MqttLog(logRequest)
                                console.log('door closed')
                                if (timercount2) {
                                  clearTimeout(timercount2);
                                }
                                if (timercount) {
                                  clearTimeout(timercount);
                                }
                              }
                              else if (devicedata.Sts === 4 && devicedata.V === 3) {
                                console.log(devicedata)
                                if (timercount2) {
                                  clearTimeout(timercount2);
                                }
                                if (timercount) {
                                  clearTimeout(timercount);
                                }
                                that.setState({
                                  isLoading: false,
                                  deviceUnavailable: false,
                                  doorClosed: false,
                                  showPopup: false,
                                  doorOpenedForLongTime: true,
                                  doorOpened: false,
                                  OpenWallet: false
                                });
                                cardService.cancelTransaction(result.transactionID);
                                socket.close();
                              }
                              else if (devicedata.Sts == 1 && txId == devicedata.TxID) {
                                console.log("product list recieved", devicedata["NPro"])
                                if (timercount2) {
                                  clearTimeout(timercount2);
                                }
                                if (devicedata.UId == doorparams.userId) {
                                  // clearTimeout(dooropenforlongtime);
                                  // devicedata.PurcNo = uuidv4();
                                  let productlist = that.props.products.products.data[0].products;
                                  console.log(productlist)
                                  let PurchaseProduct = []
                                  if (devicedata["NPro"] >= 0) {
                                    let logReq = {
                                      smartDeviceId: doorparams.deviceid,
                                      purchaseData: JSON.stringify(devicedata),
                                      userId: userpar.userId
                                    }
                                    // cardService.MqttMachineLog(logReq)
                                    let totalPrice = 0;
                                    for (let i = 1; i <= devicedata["NPro"]; i++) {
                                      let prodObj = devicedata["P" + i];
                                      let prodObjId = prodObj.SKUId;
                                      productlist.map(function (fil) {
                                        let cnt = 15 - fil.productSKU.length;
                                        let prefix = "";
                                        for (let index = 0; index < cnt; index++) {
                                          prefix += "0";
                                        }
                                        let newSKU = prefix + "" + fil.productSKU;
                                        if (prodObjId === newSKU) {
                                          totalPrice += fil.productPrice * parseInt(prodObj.Q);
                                          PurchaseProduct.push({
                                            productID: fil.productId,
                                            count: prodObj.Q,
                                            userQuantity: prodObj.Q,
                                            orderUpdateReasonId: 0,
                                            productSKU: fil.productSKU
                                          });
                                          return false;
                                        };
                                      });
                                    }
                                    var freeSKUId = ""
                                    if (that.state.selectedCoupon) {
                                      that.state.freeProducts.map((freedata, key) => {
                                        if (freeSKUId === "") {
                                          let sku = PurchaseProduct.filter((item) => (item.productSKU === freedata) ? item : null)
                                          if (sku.length > 0) {
                                            freeSKUId = sku[0].productSKU
                                          }
                                        }
                                      })
                                    }

                                    if (PurchaseProduct.length >= 0) {
                                      let eventId = devicedata.SId;
                                      let coolerID = that.props.products.products.data[0].coolerId;
                                      let totalRewardPointsEarned = 0;
                                      let resource = 'web';
                                      let irResultResponseTime = devicedata.analyticalParams && devicedata.analyticalParams.irResultResponseTime
                                        ? devicedata.analyticalParams.irResultResponseTime
                                        : "";
                                      let authToken = userpar.authToken;
                                      let description = config.defaultLanguage;
                                      let paymentGatewayTransactionDetails = config.paymentGateway == 1 ? 'cielo' : config.paymentGateway == 2 ? 'saferpay' : config.paymentGateway == 3 ? 'dlocal' : '';
                                      let eventTimeUTC = devicedata.T;// T from device
                                      let transactionId = devicedata.TId;
                                      let products = PurchaseProduct.length>0?JSON.stringify(PurchaseProduct):[];
                                      let totalAmountCharged = totalPrice;
                                      let transactionMethod = 2;
                                      let freeSKU = PurchaseProduct.length>0?AppliedCoupon ? freeSKUId : '':"";
                                      let edgeSessionId = devicedata.edgeSessionId ? devicedata.edgeSessionId : "";
                                      let dataConsumedDuringTransaction = devicedata.analyticalParams && devicedata.analyticalParams.dataConsumedDuringTransaction
                                        ? devicedata.analyticalParams.dataConsumedDuringTransaction
                                        : "";;
                                      // let swipeTransactionId=result.transactionID;
                                      let transactionDuration = devicedata.analyticalParams && devicedata.analyticalParams.transactionDuration
                                        ? devicedata.analyticalParams.transactionDuration
                                        : "";
                                      let paymentMethod = that.state.defaultPaymentGateway;
                                      let couponCode = PurchaseProduct.length>0?AppliedCoupon ? that.state.selectedCoupon : "":"";
                                      let paymentTransactionId = result.transactionID;
                                      let purchaseNumber = result.transactionID;
                                      let isDeduction = PurchaseProduct.length>0?true:"";
                                      let isCouponApplied=PurchaseProduct.length>0?AppliedCoupon:false
                                      let deviceId = macAddress;
                                      let currencyCode = that.state.currencyCode;
                                      const applicationTypeId = config.applicationTypeId;
                                      let uniquedeviceId = userpar.userId
                                      const ctst = devicedata.CTSt ? devicedata.CTSt : ""
                                      const cbst = devicedata.CTSt ? devicedata.CBSt : ""
                                      const languageCode = localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
                                      const walletType = (sessionStorage.getItem('walletType') == "creditCard" || sessionStorage.getItem('walletType') == null) ? "" : sessionStorage.getItem('walletType');
                                      const walletcardtoken = sessionStorage.getItem("walletCardId") == null ? "" : sessionStorage.getItem("walletCardId");
                                      let sessionUId = sessionStorage.getItem('sessionId');
                                      let isPreAuthEnabled = true
                                      let swipeTransactionId=result.transactionID
                                      let cieloTransactionId = result.transactionID
                                      let UnRecogErrorType = (PurchaseProduct.length==0 && devicedata["NPro"]==0) ? "Zero" : "Mismatch"
                                      const requestOptions = {
                                        method: 'POST',
                                        headers: {
                                          'Content-Type': 'application/x-www-form-urlencoded'
                                        },
                                        body: new URLSearchParams({
                                          ctst,
                                          cbst,
                                          eventId,
                                          coolerID,
                                          totalRewardPointsEarned,
                                          resource,
                                          irResultResponseTime,
                                          authToken,
                                          description,
                                          paymentGatewayTransactionDetails,
                                          eventTimeUTC,
                                          transactionId,
                                          products,
                                          totalAmountCharged,
                                          transactionMethod,
                                          freeSKU,
                                          edgeSessionId,
                                          dataConsumedDuringTransaction,
                                          transactionDuration,
                                          paymentMethod,
                                          isCouponApplied,
                                          couponCode,
                                          paymentTransactionId,
                                          purchaseNumber,
                                          isDeduction,
                                          deviceId,
                                          applicationTypeId,
                                          currencyCode,
                                          txId,
                                          uniquedeviceId,
                                          languageCode,
                                          walletType,
                                          walletcardtoken,
                                          sessionUId,
                                          isPreAuthEnabled,
                                          swipeTransactionId,
                                          cieloTransactionId,
                                          UnRecogErrorType
                                        })
                                      };

                                      fetch(`${config.envUrl}VendingAppApi/addConsumerPurchaseV14`, requestOptions)
                                        .then((response) => {
                                          return response.json();
                                        })
                                        .then((ApiResult) => {
                                          if(ApiResult.status == false && ApiResult.code == 155247){
                                            if(UnRecogErrorType=='Zero'){
                                              that.errorCode = 1;
                                              that.setState({
                                                isLoading: false,
                                                deviceUnavailable: true,
                                                doorClosed: false,
                                                doorOpenedForLongTime: false,
                                                doorOpened: false,
                                                showPopup: false
                                              });
                                            }
                                            else{
                                              that.setState({
                                                isLoading: false,
                                                imagetype: 'error',
                                                showPopup: false,
                                                apiPopup: true,
                                                deviceUnavailable: false,
                                                popupmsg: i18next.t("UnKnownProduct"),
                                                disableUnlockbutton: false
                                              })
                                            }  
                                          }
                                          else{
                                            let sessionUId = sessionStorage.getItem('sessionId');
                                            sessionStorage.clear();
                                            sessionStorage.setItem('sessionId',sessionUId);
                                            // localStorage.removeItem('qrcode');
                                            history.push({
                                              pathname: '/OrderSummary/' + purchaseNumber,
                                              state: 'fromHome'
                                            });
                                          }
                                          
                                          // if(ApiResult.code==5014 && ApiResult.success){
                                          //   localStorage.removeItem('qrcode');
                                          //   history.push({
                                          //     pathname:'/OrderSummary/'+purchaseNumber,
                                          //     state:'fromHome'
                                          //   });
                                          // }
                                          // else{
                                          //   let code = ApiResult.code;
                                          //   let errormsg = "";
                                          //   if(code === -4 || code === 503){
                                          //     errormsg = i18next.t('CommunicationError',{errorcode:result.code})
                                          //   }
                                          //   else if(code === -1 || code === -2 || code === -3){
                                          //     errormsg = i18next.t('CardVerificationFail',{errorcode:result.code})
                                          //   }
                                          //   else {
                                          //     errormsg = i18next.t('cardAuthError',{errorcode:result.code})
                                          //   }
                                          //   that.setState({
                                          //     isLoading:false,
                                          //     imagetype:'error',
                                          //     apiPopup:true,
                                          //     popupmsg:errormsg,
                                          //     disableUnlockbutton:false
                                          //   })
                                          // }        
                                        })
                                        .catch(function (error) {
                                          that.setState({
                                            isLoading: false,
                                            coolerUnavailable: true,
                                            showPopup: false,
                                            doorClosed: false,
                                            doorOpenedForLongTime: false,
                                            doorOpened: false
                                          });
                                        });
                                    }
                                    else {
                                      that.errorCode = 0
                                      that.setState({
                                        isLoading: false,
                                        imagetype: 'error',
                                        showPopup: false,
                                        apiPopup: true,
                                        deviceUnavailable: false,
                                        popupmsg: i18next.t("UnKnownProduct"),
                                        disableUnlockbutton: false
                                      })
                                      cardService.cancelTransaction(result.transactionID);
                                    }
                                  }
                                  else {
                                    that.errorCode = 1;
                                    that.setState({
                                      isLoading: false,
                                      deviceUnavailable: true,
                                      doorClosed: false,
                                      doorOpenedForLongTime: false,
                                      doorOpened: false,
                                      showPopup: false
                                    });
                                    let logRequest = {
                                      MacAddress: macAddress,
                                      userId: userpar.userId,
                                      doorStatus: false,
                                      error: "No Product Picked",
                                      Sts: devicedata.Sts
                                    }
                                    // cardService.MqttLog(logRequest)
                                    cardService.cancelTransaction(result.transactionID);
                                  }
                                }
                                socket.close();
                              }
                              else {
                                let logRequest = {
                                  MacAddress: macAddress,
                                  userId: userpar.userId,
                                  doorStatus: false,
                                  error: devicedata.Sts,
                                  Sts: devicedata.Sts
                                }
                                // cardService.MqttLog(logRequest)
                                // if(dooropenforlongtime)
                                // {clearTimeout(dooropenforlongtime)}
                                cardService.cancelTransaction(result.transactionID);
                                that.errorCode = devicedata.Sts;
                                that.setState({
                                  isLoading: false,
                                  showPopup: false,
                                  deviceUnavailable: true,
                                  doorClosed: false,
                                  doorOpenedForLongTime: false,
                                  doorOpened: false
                                });
                                socket.close();
                              }
                            }
                          };


                        }
                        else {
                          let msg=i18next.t("DeviceUnavailable")
                          if(body.code==15503){
                            msg=i18next.t("O CPF informado está incorreto. Informe o CPF correto para efetuar compras")
                          }
                          cardService.cancelTransaction(result.transactionID);
                          that.errorCode = 0
                          that.setState({
                            isLoading: false,
                            imagetype: 'error',
                            showPopup: false,
                            deviceUnavailable: false,
                            apiPopup:true,
                            popupmsg: msg,
                            disableUnlockbutton: false,
                            OpenWallet: false,
                          })
                        }
                      })
                  }
                  else {
                    that.errorCode = result.code
                    let code = result.code;
                    let errormsg = "";
                    if (code === -4 || code === 503) {
                      errormsg = i18next.t('CommunicationError', { errorcode: result.code })
                    }
                    else if (code === -1 || code === -2 || code === -3) {
                      errormsg = i18next.t('CardVerificationFail', { errorcode: result.code })
                    }
                    else if (code === 15512) {
                      errormsg = i18next.t('It appears that you are currently making a purchase at a different Coke&Go machine. Please complete the other transaction.')
                    }
                    else {
                      if (config.paymentGateway == 4) {
                        errormsg = i18next.t('It seems you do not have enough funds, please select another payment method and try again', { errorcode: result.code })
                      }
                      else {
                        errormsg = i18next.t('cardAuthError', { errorcode: result.code })
                      }
                    }
                    that.setState({
                      isLoading: false,
                      imagetype: 'error',
                      apiPopup: true,
                      showPopup: false,
                      OpenWallet: false,
                      popupmsg: errormsg,
                      disableUnlockbutton: false
                    })
                  }
                })
            }
            else{
              that.setState({
                isLoading: false,
                imagetype: 'error',
                apiPopup: true,
                showPopup: false,
                OpenWallet: false,
                popupmsg: i18next.t('This device has some PG configuration issue.'),
                disableUnlockbutton: false
              })
            }
          }
          else{
            that.setState({
              isLoading: false,
              imagetype: 'error',
              apiPopup: true,
              showPopup: false,
              OpenWallet: false,
              popupmsg: i18next.t('It appears that you are currently making a purchase at a different Coke&Go machine. Please complete the other transaction.'),
              disableUnlockbutton: false
            })
          }
        })
      }
      else{
        that.setState({
          isLoading:false,
          imagetype:'error',
          deviceUnavailable:true,
          popupmsg:i18next.t("CommonError"),
          disableUnlockbutton:false
        })
      }    
    })
    .catch(err=>{
      console.log(err)
      return err
    })
  }

  ContinueTransaction=()=>{
    if (config.applicationTypeId == 20 && !this.state.isPreAuthEnable){
      history.push({
        pathname: '/OrderSummary/' + this.state.purchaseNumber,
        state: 'fromHome'
      });
    }
    let that = this;
    that.setState({
      showPopup:true
    })
    const {transactionID,socket,isCouponApplied:AppliedCoupon} = that.state
    const V=1;
    const deviceid=that.props.products.products
    ? that.props.products.products.data[0].deviceId
    : history.goBack()
    let timercount,timercount2;
    var userpar = JSON.parse(decryptData(localStorage.getItem("user")));   
    let macAddress = decryptData(localStorage.getItem('qrcode'));
    const deviceMACAddress = macAddress;
    const userId = userpar.userId;
    const transactionId = transactionID;
    const applicationTypeId = config.applicationTypeId
    const authToken = userpar.authToken
    const t = that.props.products.products ? that.props.products.products.doorTimeoutValue : 300;
    let sessionUId = sessionStorage.getItem('sessionId');
    let RequestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({ deviceMACAddress, authToken, applicationTypeId, userId, transactionId, V, t, sessionUId })
    }
    // checking device status
    fetch(`${config.envUrl}VendingAppApi/unlockCoolerDoorV1`, RequestOptions)
      .then(function (response) {
        return response.json();
      })
      .catch(function (err) {
        that.setState({
          isLoading: false,
          coolerUnavailable: true,
          showPopup: false,
          doorClosed: false,
          doorOpenedForLongTime: false,
          doorOpened: false
        });// catch error
      })
      .then(function (body) {
        if (body.success) {
          setTimeout(() => {
            that.setState({
              isUnLocked: true,
              isLoading: false
            })
          }, 2000)
          const txId = config.applicationTypeId + ":" + body.TxID;
          socket.onmessage = function (mqttdata) {
            let devicedata = JSON.parse(mqttdata.data)
            let weblogReq = {
              authToken: authToken,
              smartDeviceId: deviceid,
              deviceResponse: JSON.stringify(devicedata)
            }
            cardService.WebAppLog(weblogReq)
            if (devicedata.TMtd == 2 && devicedata.UId == userpar.userId && txId == devicedata.TxID) {
              if (devicedata.Sts === 4 && devicedata.V === 1 && txId == devicedata.TxID) {
                console.log('door opend')
                // clearTimeout(dooropenforlongtime)          
                let logRequest = {
                  MacAddress: macAddress,
                  userId: userpar.userId,
                  doorStatus: true,
                  error: devicedata.Sts,
                  Sts: devicedata.Sts
                }
                // cardService.MqttLog(logRequest)
                timercount = setTimeout(function () {
                  console.log('door stil open');
                  that.errorCode = 3
                  that.setState({
                    isLoading: false,
                    showPopup: false,
                    disableUnlockbutton: false,
                    doorClosed: false,
                    doorOpenedForLongTime: true,
                    doorOpened: false
                  });
                  timercount2 = setTimeout(function () {
                    console.log('transaction cancel and door open')
                    socket.close();
                    that.errorCode = 2;
                    that.setState({
                      isLoading: false,
                      showPopup: false,
                      disableUnlockbutton: false,
                      doorClosed: false,
                      doorOpenedForLongTime: false,
                      doorOpened: false,
                      deviceUnavailable: true
                    });
                    cardService.cancelTransaction(transactionID);
                  }, 10000)
                }, that.props.products.products.doorTimeoutValue * 1000)
              }
              else if (devicedata.Sts === 4 && devicedata.V === 2 && txId == devicedata.TxID) {
                let logRequest = {
                  MacAddress: macAddress,
                  userId: userpar.userId,
                  doorStatus: false,
                  error: devicedata.Sts,
                  Sts: devicedata.Sts
                }
                // cardService.MqttLog(logRequest)
                console.log('door closed')
                if (timercount2) {
                  clearTimeout(timercount2);
                }
                if (timercount) {
                  clearTimeout(timercount);
                }
              }
              else if (devicedata.Sts === 4 && devicedata.V === 3) {
                console.log(devicedata)
                if (timercount2) {
                  clearTimeout(timercount2);
                }
                if (timercount) {
                  clearTimeout(timercount);
                }
                that.setState({
                  isLoading: false,
                  deviceUnavailable: false,
                  doorClosed: false,
                  showPopup: false,
                  doorOpenedForLongTime: true,
                  doorOpened: false,
                  OpenWallet: false
                });
                cardService.cancelTransaction(transactionID);
                socket.close();
              }
              else if (devicedata.Sts == 1 && txId == devicedata.TxID) {
                console.log("product list recieved", devicedata["NPro"])
                if (timercount2) {
                  clearTimeout(timercount2);
                }
                if (devicedata.UId == userpar.userId) {
                  // clearTimeout(dooropenforlongtime);
                  // devicedata.PurcNo = uuidv4();
                  let productlist = that.props.products.products.data[0].products;
                  console.log(productlist)
                  let PurchaseProduct = []
                  if (devicedata["NPro"] >= 0) {
                    let logReq = {
                      smartDeviceId: deviceid,
                      purchaseData: JSON.stringify(devicedata),
                      userId: userpar.userId
                    }
                    // cardService.MqttMachineLog(logReq)
                    let totalPrice = 0;
                    for (let i = 1; i <= devicedata["NPro"]; i++) {
                      let prodObj = devicedata["P" + i];
                      let prodObjId = prodObj.SKUId;
                      productlist.map(function (fil) {
                        let cnt = 15 - fil.productSKU.length;
                        let prefix = "";
                        for (let index = 0; index < cnt; index++) {
                          prefix += "0";
                        }
                        let newSKU = prefix + "" + fil.productSKU;
                        if (prodObjId === newSKU) {
                          totalPrice += fil.productPrice * parseInt(prodObj.Q);
                          PurchaseProduct.push({
                            productID: fil.productId,
                            count: prodObj.Q,
                            userQuantity: prodObj.Q,
                            orderUpdateReasonId: 0,
                            productSKU: fil.productSKU
                          });
                          return false;
                        };
                      });
                    }
                    var freeSKUId = ""
                    if (that.state.selectedCoupon) {
                      that.state.freeProducts.map((freedata, key) => {
                        if (freeSKUId === "") {
                          let sku = PurchaseProduct.filter((item) => (item.productSKU === freedata) ? item : null)
                          if (sku.length > 0) {
                            freeSKUId = sku[0].productSKU
                          }
                        }
                      })
                    }

                    if (PurchaseProduct.length >= 0) {
                      let eventId = devicedata.SId;
                      let coolerID = that.props.products.products.data[0].coolerId;
                      let totalRewardPointsEarned = 0;
                      let resource = 'web';
                      let irResultResponseTime = devicedata.analyticalParams && devicedata.analyticalParams.irResultResponseTime
                        ? devicedata.analyticalParams.irResultResponseTime
                        : "";
                      let authToken = userpar.authToken;
                      let description = config.defaultLanguage;
                      let paymentGatewayTransactionDetails = 'auresys'
                      let eventTimeUTC = devicedata.T;// T from device
                      let transactionId = devicedata.TId;
                      let products = PurchaseProduct.length>0?JSON.stringify(PurchaseProduct):[];
                      let totalAmountCharged = totalPrice;
                      let transactionMethod = 2;
                      let freeSKU = PurchaseProduct.length>0?AppliedCoupon ? freeSKUId : '':"";
                      let edgeSessionId = devicedata.edgeSessionId ? devicedata.edgeSessionId : "";
                      let dataConsumedDuringTransaction = devicedata.analyticalParams && devicedata.analyticalParams.dataConsumedDuringTransaction
                        ? devicedata.analyticalParams.dataConsumedDuringTransaction
                        : "";;
                      let transactionDuration = devicedata.analyticalParams && devicedata.analyticalParams.transactionDuration
                        ? devicedata.analyticalParams.transactionDuration
                        : "";
                      let paymentMethod = PaymentGatewayList[config.paymentGateway];
                      let couponCode = PurchaseProduct.length>0?AppliedCoupon ? that.state.selectedCoupon : "":"";
                      let paymentTransactionId = transactionID;
                      let purchaseNumber = transactionID;
                      let isDeduction = PurchaseProduct.length>0?true:"";
                      let isCouponApplied=PurchaseProduct.length>0?AppliedCoupon:false
                      let deviceId = macAddress;
                      let currencyCode = that.state.currencyCode;
                      const applicationTypeId = config.applicationTypeId;
                      let uniquedeviceId = userpar.userId
                      const ctst = devicedata.CTSt ? devicedata.CTSt : ""
                      const cbst = devicedata.CTSt ? devicedata.CBSt : ""
                      const languageCode = localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
                      const walletType = (sessionStorage.getItem('walletType') == "creditCard" || sessionStorage.getItem('walletType') == null) ? "" : sessionStorage.getItem('walletType');
                      const walletcardtoken = sessionStorage.getItem("walletCardId") == null ? "" : sessionStorage.getItem("walletCardId");
                      let sessionUId = sessionStorage.getItem('sessionId');
                      let UnRecogErrorType = (PurchaseProduct.length==0 && devicedata["NPro"]==0) ? "Zero" : "Mismatch"
                      const requestOptions = {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: new URLSearchParams({
                          ctst,
                          cbst,
                          eventId,
                          coolerID,
                          totalRewardPointsEarned,
                          resource,
                          irResultResponseTime,
                          authToken,
                          description,
                          paymentGatewayTransactionDetails,
                          eventTimeUTC,
                          transactionId,
                          products,
                          totalAmountCharged,
                          transactionMethod,
                          freeSKU,
                          edgeSessionId,
                          dataConsumedDuringTransaction,
                          transactionDuration,
                          paymentMethod,
                          isCouponApplied,
                          couponCode,
                          paymentTransactionId,
                          purchaseNumber,
                          isDeduction,
                          deviceId,
                          applicationTypeId,
                          currencyCode,
                          txId,
                          uniquedeviceId,
                          languageCode,
                          walletType,
                          walletcardtoken,
                          sessionUId,
                          UnRecogErrorType
                        })
                      };

                      fetch(`${config.envUrl}VendingAppApi/addConsumerPurchaseV14`, requestOptions)
                        .then((response) => {
                          return response.json();
                        })
                        .then((ApiResult) => {
                          if(ApiResult.status == false && ApiResult.code == 155247){
                            if(UnRecogErrorType=='Zero'){
                              that.errorCode = 1;
                              that.setState({
                                isLoading: false,
                                deviceUnavailable: true,
                                doorClosed: false,
                                doorOpenedForLongTime: false,
                                doorOpened: false,
                                showPopup: false
                              });
                            }
                            else{
                              that.setState({
                                isLoading: false,
                                imagetype: 'error',
                                showPopup: false,
                                apiPopup: true,
                                deviceUnavailable: false,
                                popupmsg: i18next.t("UnKnownProduct"),
                                disableUnlockbutton: false
                              })
                            }  
                          }
                          else{
                            let sessionUId = sessionStorage.getItem('sessionId');
                            sessionStorage.clear();
                            sessionStorage.setItem('sessionId',sessionUId);
                            // localStorage.removeItem('qrcode');
                            history.push({
                              pathname: '/OrderSummary/' + purchaseNumber,
                              state: 'fromHome'
                            });  
                          }      
                        })
                        .catch(function (error) {
                          that.setState({
                            isLoading: false,
                            coolerUnavailable: true,
                            showPopup: false,
                            doorClosed: false,
                            doorOpenedForLongTime: false,
                            doorOpened: false
                          });
                        });
                    }
                    else {
                      that.errorCode = 0
                      that.setState({
                        isLoading: false,
                        imagetype: 'error',
                        showPopup: false,
                        apiPopup: true,
                        deviceUnavailable: false,
                        popupmsg: i18next.t("UnKnownProduct"),
                        disableUnlockbutton: false
                      })
                      cardService.cancelTransaction(transactionID);
                    }
                  }
                  else {
                    that.errorCode = 1;
                    that.setState({
                      isLoading: false,
                      deviceUnavailable: true,
                      doorClosed: false,
                      doorOpenedForLongTime: false,
                      doorOpened: false,
                      showPopup: false
                    });
                    let logRequest = {
                      MacAddress: macAddress,
                      userId: userpar.userId,
                      doorStatus: false,
                      error: "No Product Picked",
                      Sts: devicedata.Sts
                    }
                    // cardService.MqttLog(logRequest)
                    cardService.cancelTransaction(transactionID);
                  }
                }
                socket.close();
              }
              else {
                let logRequest = {
                  MacAddress: macAddress,
                  userId: userpar.userId,
                  doorStatus: false,
                  error: devicedata.Sts,
                  Sts: devicedata.Sts
                }
                // cardService.MqttLog(logRequest)
                // if(dooropenforlongtime)
                // {clearTimeout(dooropenforlongtime)}
                cardService.cancelTransaction(transactionID);
                that.errorCode = devicedata.Sts;
                that.setState({
                  isLoading: false,
                  showPopup: false,
                  deviceUnavailable: true,
                  doorClosed: false,
                  doorOpenedForLongTime: false,
                  doorOpened: false
                });
                socket.close();
              }
            }
          };


        }
        else {
          let msg=i18next.t("DeviceUnavailable")
          cardService.cancelTransaction(transactionID);
          that.errorCode = 0
          that.setState({
            isLoading: false,
            imagetype: 'error',
            showPopup: false,
            deviceUnavailable: false,
            apiPopup:true,
            popupmsg: msg,
            disableUnlockbutton: false,
            OpenWallet: false,
          })
        }
      })
  }

  sendToScan(){
    // localStorage.removeItem('qrcode');
    history.push('/Scanner')
  }

  closingUnavailablePopup() {
    this.setState({
      disableUnlockbutton: false,
      coolerUnavailable: false,
      connectionLost:false
    });
  }

  closingdeviceUnavailablePopup() {	
    let sessionUId = sessionStorage.getItem('sessionId');
    sessionStorage.clear();
    sessionStorage.setItem('sessionId',sessionUId);
	  this.setState({
      disableUnlockbutton: false,
      deviceUnavailable: false,
    });
    if(this.errorCode!=3){
      // localStorage.removeItem('qrcode');
      history.push('/Scanbutton');
    }
  }

  closingApiPopup() {
    if(this.state.popupmsg==i18next.t(config.applicationTypeId==19?this.state.cardList.length>0?"We’re sorry but your existing payment method is not compatible with this Smart Cooler. Please tap on OK to continue and add an additional payment method":"Please add payment method":'PlzAddCard')){
      if(this.state.defaultPaymentGateway=='paytm' && this.state.paymentFlow=='WALLET'){
        history.push({pathname:'/link-with-paytm',state:"Home"});
      }
      else{
        history.push('/Card');
      }
      // alert('redirect to cart')
    }
    else if(this.state.popupmsg==i18next.t("UnKnownProduct")){
      // localStorage.removeItem('qrcode');
      history.push('/Scanbutton');
    }
    else if(this.state.popupmsg=='Network Error, Please re try to Submit the CVV.'){
      history.push('/Scanbutton');
    }
    else{
      this.setState({
        apiPopup: false,
        showPopup:false,
        disableUnlockbutton:false
      });
    }    
  }
  closingPopup() {
    this.setState({
      showPopup: false,
    });
  }

  closingDoorClosedPopup() {
    this.setState({
      doorClosed: false,
    });
  }

  closingDoorOpenedPopup() {
    this.setState({
      doorOpened: false,
    });
  }

  closingdoorOpenedForLongTimePopup() {
    this.setState({ 
      doorOpenedForLongTime: false,
      disableUnlockbutton:true,
      isFBFAvailed:false,
      showPopup:false,
      isUnLocked:false
    });
    history.push('/Scanbutton')
  }

  toggleNoRewardPopup() {
    this.setState({
      NoRewardPoint: !this.state.NoRewardPoint,
    });
  }
  handlePaymentChange=(e)=>{
    sessionStorage.setItem('walletType',e.target.value)
    this.setState({
      paymentType:e.target.value,
      paymentTitle:i18next.t(e.target.value=="creditCard"?"Pay via Credit Card":e.target.value=="GOOGLEPAY"?"Pay via GPay":"Pay via Apple Pay")
    })
  }
  closeWalletModal=()=>{
    this.setState({
      OpenWallet:false
    })
  }
  PayViaWallet=()=>{
    if(this.state.paymentType == "creditCard"){
      this.unlockDoor()
    }
    else if(this.state.paymentType != "creditCard" && !this.state.isPreAuthEnable && config.applicationTypeId!=12){
      this.unlockDoor()
    }
    else{
      this.setState({isLoading:true})
      const applicationTypeId = config.applicationTypeId;
      const amount = this.props.products.products ?this.props.products.products.preAuthorizeAmount:0;
      const returnSuccessUrl = config.baseURL+'/successResponse';
      const returnFailUrl = config.baseURL+'/CardFail'
      const CurrencyCode = config.currencyCode;
      const user = JSON.parse(decryptData(localStorage.user))
      const authToken = user.authToken
      const walletType = this.state.paymentType
      const deviceid = decryptData(localStorage.getItem('qrcode'));
      let sessionUId = sessionStorage.getItem("sessionId");
      let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({applicationTypeId,amount,returnSuccessUrl,returnFailUrl,CurrencyCode,authToken,walletType,deviceid,sessionUId})
      }
      // checking device status
      fetch(`${config.envUrl}VendingAppApi/saferPaymentPageInitialize`, RequestOptions)
      .then(function (response) {
        return response.json();
      })
      .then((data)=>{
        if(data.success && data.code==5041){
          let token = data.data.Token;
          sessionStorage.setItem('saferWalletToken',token);
          window.location.href = data.data.RedirectUrl
        }
        else{
          this.setState({
            isLoading:false,
            imagetype:'error',
            apiPopup:true,
            popupmsg:i18next.t("CommonError"),
          })
        }
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }
  handleChangeOTP=(value)=>{
    this.setState((prevState)=>({
      ...prevState,
      PayUOTP:value,
      isOtpButtonDisable:value.length==6?true:false
    }))
  }

  handleVerifyOTP=()=>{
    try {
      let that = this;
      that.setState({
        isLoading:true
      })
      const {PayUOTP:otpCode,PayUData:{transactionID,referenceId,otpPostUrl},isPayuHavePendingAmt} = that.state
      const user = JSON.parse(decryptData(localStorage.user))
      const authToken = user.authToken
      let sessionUId = sessionStorage.getItem("sessionId");
      let applicationTypeId = config.applicationTypeId;
      let deviceId = decryptData(localStorage.getItem('qrcode'));
      let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({applicationTypeId,authToken,sessionUId,otpCode,transactionID,referenceId,otpPostUrl,deviceId})
      }
      // checking device status
      fetch(`${config.envUrl}VendingAppApi/submitPayuOTP`, RequestOptions)
      .then(function (response) {
        return response.json();
      })
      .then((result)=>{
        if(result.success && isPayuHavePendingAmt){
          this.CompletePaymentForPayU(result.transactionID)
        }
        else if(result.code==1525){
          that.setState({
            apiPopup:true,
            isLoading: false,
            showPayUModal:false,
            popupmsg:"Please enter the valid OTP"
          })
        }
        else if(result.success && result.transactionID){
          that.setState({
            showPopup:true,
            isLoading: false,
            showPayUModal:false,
            transactionID:result.transactionID
          })
          this.payViaPayU(result);
        }
        else{
          that.setState({
            apiPopup:true,
            isLoading: false,
            showPayUModal:false,
            showPopup:false,
            popupmsg:i18next.t("CommonError"),
          })
        }
      })
    } catch (error) {
      
    }
  }

  payViaPayU=(result)=>{
    let timercount,timercount2;
    try {
      let that = this;
      const {preAuthorizeAmount} = this.state
      const user = JSON.parse(decryptData(localStorage.user))
      const authToken = user.authToken
      let sessionUId = sessionStorage.getItem("sessionId");
      let applicationTypeId = config.applicationTypeId;
      let deviceMACAddress = decryptData(localStorage.getItem('qrcode'));
      let macAddress = deviceMACAddress;
      const V=1;
      const userId = user.userId;
      const transactionId = result.transactionID;
      let CouponApplied = that.state.isCouponApplied
      const t = that.props.products.products ? that.props.products.products.doorTimeoutValue : 300;
      const { products:{paymentMethods=[]} } = that.props.products
      let filt = paymentMethods.filter(item=>item.code=='PAYU')
      let isPreAuthEnabled = (filt.length>0 && filt[0].isPreAuthEnable)?true:false
      let RequestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({ deviceMACAddress, authToken, applicationTypeId, userId, transactionId, V, t, sessionUId })
      }
      // checking device status
      fetch(`${config.envUrl}VendingAppApi/unlockCoolerDoorV1`, RequestOptions)
        .then(function (response) {
          return response.json();
        })
        .catch(function (err) {
          that.setState({
            isLoading: false,
            coolerUnavailable: true,
            showPopup: false,
            doorClosed: false,
            doorOpenedForLongTime: false,
            doorOpened: false
          });// catch error
        })
        .then(function (body) {
          if (body.success) {
            setTimeout(() => {
              that.setState({
                isUnLocked: true,
                isLoading: false
              })
            }, 2000)
            var socket = that.state.socket;
            const txId = applicationTypeId + ":" + body.TxID;
            const deviceId = that.props.products.products
            ? that.props.products.products.data[0].deviceId:""
            socket.onmessage = function (mqttdata) {
              let devicedata = JSON.parse(mqttdata.data)
              console.log(devicedata)
              let weblogReq = {
                authToken: authToken,
                smartDeviceId: deviceId,
                deviceResponse: JSON.stringify(devicedata)
              }
              cardService.WebAppLog(weblogReq)
              if (devicedata.TMtd == 2 && devicedata.UId == user.userId && txId == devicedata.TxID) {
                if (devicedata.Sts === 4 && devicedata.V === 1 && txId == devicedata.TxID) {
                  console.log('door opend')
                  // clearTimeout(dooropenforlongtime)          
                  
                  // cardService.MqttLog(logRequest)
                  timercount = setTimeout(function () {
                    console.log('door stil open');
                    that.errorCode = 3
                    that.setState({
                      isLoading: false,
                      showPopup: false,
                      disableUnlockbutton: false,
                      doorClosed: false,
                      doorOpenedForLongTime: true,
                      doorOpened: false
                    });
                    timercount2 = setTimeout(function () {
                      console.log('transaction cancel and door open')
                      socket.close();
                      that.errorCode = 2;
                      that.setState({
                        isLoading: false,
                        showPopup: false,
                        disableUnlockbutton: false,
                        doorClosed: false,
                        doorOpenedForLongTime: false,
                        doorOpened: false,
                        deviceUnavailable: true
                      });
                      cardService.cancelTransaction(result.transactionID,preAuthorizeAmount);
                    }, 10000)
                  }, that.props.products.products.doorTimeoutValue * 1000)
                }
                else if (devicedata.Sts === 4 && devicedata.V === 2 && txId == devicedata.TxID) {
                  
                  console.log('door closed')
                  if (timercount2) {
                    clearTimeout(timercount2);
                  }
                  if (timercount) {
                    clearTimeout(timercount);
                  }
                }
                else if (devicedata.Sts === 4 && devicedata.V === 3) {
                  console.log(devicedata)
                  if (timercount2) {
                    clearTimeout(timercount2);
                  }
                  if (timercount) {
                    clearTimeout(timercount);
                  }
                  that.setState({
                    isLoading: false,
                    deviceUnavailable: false,
                    doorClosed: false,
                    showPopup: false,
                    doorOpenedForLongTime: true,
                    doorOpened: false,
                    OpenWallet: false
                  });
                  cardService.cancelTransaction(result.transactionID,preAuthorizeAmount);
                  socket.close();
                }
                else if (devicedata.Sts == 1 && txId == devicedata.TxID) {
                  console.log("product list recieved", devicedata["NPro"])
                  if (timercount2) {
                    clearTimeout(timercount2);
                  }
                  if (devicedata.UId == user.userId) {
                    // clearTimeout(dooropenforlongtime);
                    // devicedata.PurcNo = uuidv4();
                    let productlist = that.props.products.products.data[0].products;
                    console.log(productlist)
                    let PurchaseProduct = []
                    if (devicedata["NPro"] >= 0) {
                      let logReq = {
                        smartDeviceId: deviceId,
                        purchaseData: JSON.stringify(devicedata),
                        userId: user.userId
                      }
                      // cardService.MqttMachineLog(logReq)
                      let totalPrice = 0;
                      for (let i = 1; i <= devicedata["NPro"]; i++) {
                        let prodObj = devicedata["P" + i];
                        let prodObjId = prodObj.SKUId;
                        productlist.map(function (fil) {
                          let cnt = 15 - fil.productSKU.length;
                          let prefix = "";
                          for (let index = 0; index < cnt; index++) {
                            prefix += "0";
                          }
                          let newSKU = prefix + "" + fil.productSKU;
                          if (prodObjId === newSKU) {
                            totalPrice += fil.productPrice * parseInt(prodObj.Q);
                            PurchaseProduct.push({
                              productID: fil.productId,
                              count: prodObj.Q,
                              userQuantity: prodObj.Q,
                              orderUpdateReasonId: 0,
                              productSKU: fil.productSKU
                            });
                            return false;
                          };
                        });
                      }
                      var freeSKUId = ""
                      if (that.state.selectedCoupon) {
                        that.state.freeProducts.map((freedata, key) => {
                          if (freeSKUId === "") {
                            let sku = PurchaseProduct.filter((item) => (item.productSKU === freedata) ? item : null)
                            if (sku.length > 0) {
                              freeSKUId = sku[0].productSKU
                            }
                          }
                        })
                      }

                      if (PurchaseProduct.length >= 0) {
                        let eventId = devicedata.SId;
                        let coolerID = that.props.products.products.data[0].coolerId;
                        let totalRewardPointsEarned = 0;
                        let resource = 'web';
                        let irResultResponseTime = devicedata.analyticalParams && devicedata.analyticalParams.irResultResponseTime
                          ? devicedata.analyticalParams.irResultResponseTime
                          : "";
                        let authToken = user.authToken;
                        let description = config.defaultLanguage;
                        let paymentGatewayTransactionDetails = "payu";
                        let eventTimeUTC = devicedata.T;// T from device
                        let transactionId = devicedata.TId;
                        let products = PurchaseProduct.length>0?JSON.stringify(PurchaseProduct):[];
                        let totalAmountCharged = totalPrice;
                        let transactionMethod = 2;
                        let freeSKU = PurchaseProduct.length>0?CouponApplied ? freeSKUId : '':"";
                        let edgeSessionId = devicedata.edgeSessionId ? devicedata.edgeSessionId : "";
                        let dataConsumedDuringTransaction = devicedata.analyticalParams && devicedata.analyticalParams.dataConsumedDuringTransaction
                          ? devicedata.analyticalParams.dataConsumedDuringTransaction
                          : "";;
                        // let swipeTransactionId=result.transactionID;
                        let transactionDuration = devicedata.analyticalParams && devicedata.analyticalParams.transactionDuration
                          ? devicedata.analyticalParams.transactionDuration
                          : "";
                        let paymentMethod = PaymentGatewayList[config.paymentGateway];
                        let couponCode = PurchaseProduct.length>0?CouponApplied ? that.state.selectedCoupon : "":"";
                        let paymentTransactionId = result.transactionID;
                        let purchaseNumber = result.transactionID;
                        let isDeduction = PurchaseProduct.length>0?true:"";
                        let isCouponApplied=PurchaseProduct.length>0?CouponApplied:false
                        let deviceId = macAddress;
                        let currencyCode = that.state.currencyCode;
                        const applicationTypeId = config.applicationTypeId;
                        let uniquedeviceId = user.userId
                        const ctst = devicedata.CTSt ? devicedata.CTSt : ""
                        const cbst = devicedata.CTSt ? devicedata.CBSt : ""
                        const languageCode = localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
                        let UnRecogErrorType = (PurchaseProduct.length==0 && devicedata["NPro"]==0) ? "Zero" : "Mismatch"
                        let sessionUId = sessionStorage.getItem('sessionId');
                        const requestOptions = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                          },
                          body: new URLSearchParams({
                            ctst,
                            cbst,
                            eventId,
                            coolerID,
                            totalRewardPointsEarned,
                            resource,
                            irResultResponseTime,
                            authToken,
                            description,
                            paymentGatewayTransactionDetails,
                            eventTimeUTC,
                            transactionId,
                            products,
                            totalAmountCharged,
                            transactionMethod,
                            freeSKU,
                            edgeSessionId,
                            dataConsumedDuringTransaction,
                            transactionDuration,
                            paymentMethod,
                            isCouponApplied,
                            couponCode,
                            paymentTransactionId,
                            purchaseNumber,
                            isDeduction,
                            deviceId,
                            applicationTypeId,
                            currencyCode,
                            txId,
                            uniquedeviceId,
                            languageCode,
                            sessionUId,
                            isPreAuthEnabled,
                            UnRecogErrorType
                          })
                        };

                        fetch(`${config.envUrl}VendingAppApi/addConsumerPurchaseV14`, requestOptions)
                          .then((response) => {
                            return response.json();
                          })
                          .then((ApiResult) => {
                            let sessionUId = sessionStorage.getItem('sessionId');
                            sessionStorage.clear();
                            sessionStorage.setItem('sessionId',sessionUId);
                            const {success,referenceId="",amount,transactionID,otpPostUrl} = ApiResult

                            if(ApiResult.status == false && ApiResult.code == 155247){
                              if(UnRecogErrorType=='Zero'){
                                that.errorCode = 1;
                                that.setState({
                                  isLoading: false,
                                  deviceUnavailable: true,
                                  doorClosed: false,
                                  doorOpenedForLongTime: false,
                                  doorOpened: false,
                                  showPopup: false
                                });
                              }
                              else{
                                that.setState({
                                  isLoading: false,
                                  imagetype: 'error',
                                  showPopup: false,
                                  apiPopup: true,
                                  deviceUnavailable: false,
                                  popupmsg: i18next.t("UnKnownProduct"),
                                  disableUnlockbutton: false
                                })
                              }  
                            }
                            // localStorage.removeItem('qrcode');
                            else if(referenceId!="" && applicationTypeId==19){
                              that.setState((prevState)=>({
                                ...prevState,
                                isUnLocked:false,
                                showPopup:false,
                                showPayUModal:true,
                                isLoading:false,
                                PayUOTP:"",
                                PayUData:{
                                  referenceId:referenceId,
                                  transactionID:transactionID,
                                  otpPostUrl:otpPostUrl
                                },                                
                                isPayuHavePendingAmt:true,
                                PayUDesc:isPreAuthEnabled?"Actual Transaction amount is higher than the Security deposit, hence need you to confirm the Balance Payment":"Enter Your OTP",
                                isOtpButtonDisable:false
                              }))
                            } 
                            else{
                              history.push({
                                pathname: '/OrderSummary/' + purchaseNumber,
                                state: 'fromHome'
                              }); 
                            }   
                          })
                          .catch(function (error) {
                            that.setState({
                              isLoading: false,
                              coolerUnavailable: true,
                              showPopup: false,
                              doorClosed: false,
                              doorOpenedForLongTime: false,
                              doorOpened: false
                            });
                          });
                      }
                      else {
                        that.errorCode = 0
                        that.setState({
                          isLoading: false,
                          imagetype: 'error',
                          showPopup: false,
                          apiPopup: true,
                          deviceUnavailable: false,
                          popupmsg: i18next.t("UnKnownProduct"),
                          disableUnlockbutton: false
                        })
                        cardService.cancelTransaction(result.transactionID,preAuthorizeAmount);
                      }
                    }
                    else {
                      that.errorCode = 1;
                      that.setState({
                        isLoading: false,
                        deviceUnavailable: true,
                        doorClosed: false,
                        doorOpenedForLongTime: false,
                        doorOpened: false,
                        showPopup: false
                      });
                      let logRequest = {
                        MacAddress: macAddress,
                        userId: user.userId,
                        doorStatus: false,
                        error: "No Product Picked",
                        Sts: devicedata.Sts
                      }
                      // cardService.MqttLog(logRequest)
                      cardService.cancelTransaction(result.transactionID,preAuthorizeAmount);
                    }
                  }
                  socket.close();
                }
                else {
                  let logRequest = {
                    MacAddress: macAddress,
                    userId: user.userId,
                    doorStatus: false,
                    error: devicedata.Sts,
                    Sts: devicedata.Sts
                  }
                  // cardService.MqttLog(logRequest)
                  // if(dooropenforlongtime)
                  // {clearTimeout(dooropenforlongtime)}
                  cardService.cancelTransaction(result.transactionID,preAuthorizeAmount);
                  that.errorCode = devicedata.Sts;
                  that.setState({
                    isLoading: false,
                    showPopup: false,
                    deviceUnavailable: true,
                    doorClosed: false,
                    doorOpenedForLongTime: false,
                    doorOpened: false
                  });
                  socket.close();
                }
              }
            };


          }
          else {
            let msg=i18next.t("DeviceUnavailable")
            if(body.code==15503){
              msg=i18next.t("O CPF informado está incorreto. Informe o CPF correto para efetuar compras")
            }
            cardService.cancelTransaction(result.transactionID,preAuthorizeAmount);
            that.errorCode = 0
            that.setState({
              isLoading: false,
              imagetype: 'error',
              showPopup: false,
              deviceUnavailable: false,
              apiPopup:true,
              popupmsg: msg,
              disableUnlockbutton: false,
              OpenWallet: false,
            })
          }
        })
    } catch (error) {
      
    }
  }

  CompletePaymentForPayU=(preAuthTransactionId)=>{
    const {PayUData:{referenceId,transactionID},transactionID:txId,defaultPaymentGateway,cardList} = this.state;
    const user = JSON.parse(decryptData(localStorage.user))
    let authToken=user.authToken;
    let PurchaseNumber = txId
    let availableCardlist = cardList.filter(item=>item.paymentGateway==defaultPaymentGateway);
    let defaultcard = availableCardlist.filter((item)=>(item.isDefault)?item:null)      
    let cardId=defaultcard[0].cardID
    let CustomerName=user.firstName+" "+user.lastName
    let applicationTypeId=config.applicationTypeId
    let currencyCode=config.currencyCode
    let uniqueDeviceId=user.userId
    let sessionUId=sessionStorage.getItem("sessionId");
    let isOTPVerified=true
    // let preAuthTransactionId=transactionID
    let languageCode=localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
    fetch(config.envUrl + "VendingAppApi/completePendingPaymentWithSwipe", {
      method: "POST",
      body: new URLSearchParams({
        authToken,
        PurchaseNumber,
        cardId,
        CustomerName,
        applicationTypeId,
        currencyCode,
        uniqueDeviceId,
        languageCode,
        sessionUId,
        preAuthTransactionId,
        isOTPVerified
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then(function (response) {
      return response.json();
    })
    .then(res=>{
      history.push({
        pathname: '/OrderSummary/' + PurchaseNumber,
        state: 'fromHome'
      }); 
    })
  }

  VerifyWithPayTM=()=>{
    let self = this;
    const {cardList,currencyCode,defaultPaymentGateway,preAuthorizeAmount,cvv,paytmTransactionID,isPaytmFail} = self.state
    if(isPaytmFail){
      self.payPendingAmount()
      return false
    }
    self.setState({
      isLoading:true
    })
    try {
      let defaultcard = cardList.filter((item)=>(item.isDefault && item.paymentGateway.toLowerCase()=='paytm')?item:null)
      if(defaultcard.length>0){
      let req = {
        cardID: defaultcard[0].cardID,
        amt:preAuthorizeAmount,
        currencyCode: currencyCode,
        paymentmethod:defaultPaymentGateway,
        cvv:cvv
      }
      cardService.preauthorize(req)
      .then((result) => {
        const {actionUrl="",content,success} = result;
        if(success && actionUrl!=""){
          const postUrl = actionUrl;
          const errorUrl = config.baseURL + '/payment-status';
          const urlData = JSON.parse(content);
          const paytmUrl = config.envUrl+'paytm.aspx?MD='+urlData.MD+'&PaReq='+urlData.PaReq+'&TermUrl='+urlData.TermUrl+'&postURL='+postUrl+'&errorURL='+errorUrl
          window.location = paytmUrl
        }
        else{
          self.errorCode = result.code
          let code = result.code;
          let errormsg = "";
          if (code === 15525) {
            errormsg = i18next.t('CardVerificationFail')
          }
          else {
              errormsg = i18next.t('Network Error, Please re try to Submit the CVV.')          
          }
          self.setState({
            isLoading: false,
            imagetype: 'error',
            apiPopup: true,
            showCardValidation:false,
            showPopup: false,
            OpenWallet: false,
            popupmsg: errormsg,
            disableUnlockbutton: false,
            cvv:''
          })
        }
      })
      }
      else{
        let errormsg = i18next.t('You don\'t have default card, Please mark your card as default.')
        self.setState({
          isLoading: false,
          imagetype: 'error',
          apiPopup: true,
          showPopup: false,
          OpenWallet: false,
          popupmsg: errormsg,
          disableUnlockbutton: false
        })
      }
    } catch (error) {
      let errormsg = "Network Error, Please re try to Submit the CVV."
      self.setState({
        isLoading: false,
        imagetype: 'error',
        apiPopup: true,
        showPopup: false,
        OpenWallet: false,
        popupmsg: errormsg,
        disableUnlockbutton: false
      })
    }
  }

  UnlockDoorWithPayTM=(AppliedCoupon)=>{
    const applicationTypeId = config.applicationTypeId
    let self = this;
    const {paytmTransactionID:transactionId,} = self.state
    var user = JSON.parse(decryptData(localStorage.getItem("user")));  
    const {authToken,userId} = user 
    let macAddress = decryptData(localStorage.getItem('qrcode'));
    let deviceMACAddress = macAddress;
    let sessionUId = localStorage.getItem('sessionUId');
    let timercount,timercount2;
    let deviceId =self.props.products.products
        ? this.props.products.products.data[0].deviceId
        : ''
    let socketparam=userId+'-'+deviceId
    let RequestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({authToken,applicationTypeId,sessionUId})
    }
    fetch(`${config.envUrl}VendingAppApi/getTransactionSameTime`, RequestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(data=>{
      if (data.success) {
      // Get Socket connection URL
      fetch(`${config.socketUrl}/GetWebPubSubTokenUrl?userId=${socketparam}`)
      .then((response)=>{
      return response.text()
      })
      .then((socketURL)=>{
        if(socketURL){
          var socket = new WebSocket(socketURL)
          socket.onopen = function () {
            console.log('connection is open'); // 
          }; 
          socket.onerror = function (error) {
            console.log('WebSocket Error ' + error);
          };
          //Door unlock 
          const V=1;
          const t = self.props.products.products ? self.props.products.products.doorTimeoutValue : 300;
          let RequestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({ deviceMACAddress, authToken, applicationTypeId, userId, transactionId, V, t, sessionUId })
          }
          // checking device status
          fetch(`${config.envUrl}VendingAppApi/unlockCoolerDoorV1`, RequestOptions)
          .then(function (response) {
            return response.json();
          })
          .then(body=>{
            if (body.success) {
              setTimeout(() => {
                self.setState({
                  isUnLocked: true,
                  isLoading: false
                })
              }, 2000)
              const txId = config.applicationTypeId + ":" + body.TxID
              socket.onmessage = function (mqttdata) {
                let devicedata = JSON.parse(mqttdata.data)
                console.log(devicedata)
                let weblogReq = {
                  authToken: authToken,
                  smartDeviceId: deviceId,
                  deviceResponse: JSON.stringify(devicedata)
                }
                cardService.WebAppLog(weblogReq)

                if (devicedata.TMtd == 2 && devicedata.UId == userId && txId == devicedata.TxID) {
                  if (devicedata.Sts === 4 && devicedata.V === 1 && txId == devicedata.TxID) {
                    // console.log('door opend')
                    
                    timercount = setTimeout(function () {
                      console.log('door stil open');
                      self.errorCode = 3
                      self.setState({
                        isLoading: false,
                        showPopup: false,
                        disableUnlockbutton: false,
                        doorClosed: false,
                        doorOpenedForLongTime: true,
                        doorOpened: false
                      });
                      timercount2 = setTimeout(function () {
                        console.log('transaction cancel and door open')
                        socket.close();
                        self.errorCode = 2;
                        self.setState({
                          isLoading: false,
                          showPopup: false,
                          disableUnlockbutton: false,
                          doorClosed: false,
                          doorOpenedForLongTime: false,
                          doorOpened: false,
                          deviceUnavailable: true
                        });
                        // cardService.cancelTransaction(result.transactionID);
                      }, 10000)
                    }, self.props.products.products.doorTimeoutValue * 1000 * 2)
                  }
                  else if (devicedata.Sts === 4 && devicedata.V === 2) {                    
                    // console.log('door closed')
                    if (timercount2) {
                      clearTimeout(timercount2);
                    }
                    if (timercount) {
                      clearTimeout(timercount);
                    }
                  }
                  else if (devicedata.Sts === 4 && devicedata.V === 3) {
                    console.log(devicedata)
                    if (timercount2) {
                      clearTimeout(timercount2);
                    }
                    if (timercount) {
                      clearTimeout(timercount);
                    }
                    self.setState({
                      isLoading: false,
                      deviceUnavailable: false,
                      doorClosed: false,
                      showPopup: false,
                      doorOpenedForLongTime: true,
                      doorOpened: false,
                      OpenWallet: false
                    });
                    socket.close();
                  }
                  else if (devicedata.Sts == 1 && txId == devicedata.TxID) {
                    console.log("product list recieved", devicedata["NPro"])
                    if (timercount2) {
                      clearTimeout(timercount2);
                    }
                    if (devicedata.UId == userId) {
                      // clearTimeout(dooropenforlongtime);
                      // devicedata.PurcNo = uuidv4();
                      let productlist = self.props.products.products.data[0].products;
                      let currencyCode = self.state.currencyCode
                      let PurchaseProduct = []
                      let totalPrice = 0;
                      if (devicedata["NPro"] >= 0) {
                        for (let i = 1; i <= devicedata["NPro"]; i++) {
                          let prodObj = devicedata["P" + i];
                          let prodObjId = prodObj.SKUId;
                          productlist.map(function (fil) {
                            let cnt = 15 - fil.productSKU.length;
                            let prefix = "";
                            for (let index = 0; index < cnt; index++) {
                              prefix += "0";
                            }
                            let newSKU = prefix + "" + fil.productSKU;
                            if (prodObjId === newSKU) {
                              totalPrice += fil.productPrice * parseInt(prodObj.Q);
                              PurchaseProduct.push({
                                productID: fil.productId,
                                count: prodObj.Q,
                                userQuantity: prodObj.Q,
                                orderUpdateReasonId: 0,
                                productSKU: fil.productSKU
                              });
                              return false;
                            };
                          });
                        }
                        var freeSKUId = ""
                        if (self.state.selectedCoupon) {
                          self.state.freeProducts.map((freedata, key) => {
                            if (freeSKUId === "") {
                              let sku = PurchaseProduct.filter((item) => (item.productSKU === freedata) ? item : null)
                              if (sku.length > 0) {
                                freeSKUId = sku[0].productSKU
                              }
                            }
                          })
                        }
                        if (PurchaseProduct.length >= 0) {
                          let purchaseNumber = body.TxID;
                          let eventId = devicedata.SId;
                          let coolerID = self.props.products.products.data[0].coolerId;
                          let totalRewardPointsEarned = 0;
                          let resource = 'web';
                          let irResultResponseTime = devicedata.analyticalParams && devicedata.analyticalParams.irResultResponseTime
                            ? devicedata.analyticalParams.irResultResponseTime
                            : "";
                          // let authToken = authToken;
                          let description = config.defaultLanguage;
                          let paymentGatewayTransactionDetails = 'paytm';
                          let eventTimeUTC = devicedata.T;// T from device
                          let transactionId = devicedata.TId;
                          let products = PurchaseProduct.length>0?JSON.stringify(PurchaseProduct):[];
                          let totalAmountCharged = totalPrice;
                          let transactionMethod = 2;
                          let freeSKU = PurchaseProduct.length>0?AppliedCoupon ? freeSKUId : '':"";
                          let edgeSessionId = devicedata.edgeSessionId ? devicedata.edgeSessionId : "";
                          let dataConsumedDuringTransaction = devicedata.analyticalParams && devicedata.analyticalParams.dataConsumedDuringTransaction
                            ? devicedata.analyticalParams.dataConsumedDuringTransaction
                            : "";
                          let cieloTransactionId = purchaseNumber;
                          let transactionDuration = devicedata.analyticalParams && devicedata.analyticalParams.transactionDuration
                            ? devicedata.analyticalParams.transactionDuration
                            : "";
                          let paymentMethod = self.state.defaultPaymentGateway;
                          let couponCode = PurchaseProduct.length>0?AppliedCoupon ? self.state.selectedCoupon : "":"";
                          let paymentTransactionId = purchaseNumber;

                          let isDeduction = PurchaseProduct.length>0?true:false;
                          let isCouponApplied=PurchaseProduct.length>0?AppliedCoupon:false
                          let deviceId = macAddress
                          const applicationTypeId = config.applicationTypeId;
                          const cieloLinks = ""
                          const customerName = user.firstName + ' ' + user.lastName;
                          const cardToken = ""
                          const uniquedeviceId = userId
                          const ctst = devicedata.CTSt ? devicedata.CTSt : ""
                          const cbst = devicedata.CTSt ? devicedata.CBSt : ""
                          const languageCode = localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
                          let sessionUId = sessionStorage.getItem('sessionId');
                          let isPreAuthEnabled = true
                          let UnRecogErrorType = (PurchaseProduct.length==0 && devicedata["NPro"]==0) ? "Zero" : "Mismatch"
                          const errorURL = config.baseURL+'/payment-status';
                          const requestOptions = {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: new URLSearchParams({
                              ctst,
                              cbst,
                              eventId,
                              coolerID,
                              totalRewardPointsEarned,
                              resource,
                              irResultResponseTime,
                              authToken,
                              description,
                              paymentGatewayTransactionDetails,
                              eventTimeUTC,
                              transactionId,
                              products,
                              totalAmountCharged,
                              transactionMethod,
                              freeSKU,
                              edgeSessionId,
                              dataConsumedDuringTransaction,
                              cieloTransactionId,
                              transactionDuration,
                              paymentMethod,
                              isCouponApplied,
                              couponCode,
                              paymentTransactionId,
                              purchaseNumber,
                              isDeduction,
                              deviceId,
                              applicationTypeId,
                              cieloLinks,
                              customerName,
                              cardToken,
                              currencyCode,
                              txId,
                              uniquedeviceId,
                              languageCode,
                              sessionUId,
                              isPreAuthEnabled,
                              errorURL,
                              UnRecogErrorType
                            })
                          };

                          fetch(`${config.envUrl}VendingAppApi/addConsumerPurchaseV14`, requestOptions)
                            .then((response) => {
                              return response.json();
                            })
                            .then((ApiResult) => {
                              if(ApiResult.code==15516 || ApiResult.success){
                                history.push({
                                  pathname: '/OrderSummary/' + purchaseNumber,
                                  state: 'fromHome'
                                }); 
                              } 
                              else if(ApiResult.code==15524 && !ApiResult.success){     
                                window.history.replaceState({}, '')                         
                                self.setState({
                                  cvv:'',
                                  showCardValidation:true,
                                  showPopup:false,
                                  isPaytmFail:true                                
                                })
                              }  
                              else if(ApiResult.status == false && ApiResult.code == 155247){
                                if(UnRecogErrorType=='Zero'){
                                  self.errorCode = 1;
                                  self.setState({
                                    isLoading: false,
                                    deviceUnavailable: true,
                                    doorClosed: false,
                                    doorOpenedForLongTime: false,
                                    doorOpened: false,
                                    showPopup: false
                                  });
                                }
                                else{
                                  self.setState({
                                    isLoading: false,
                                    imagetype: 'error',
                                    showPopup: false,
                                    apiPopup: true,
                                    deviceUnavailable: false,
                                    popupmsg: i18next.t("UnKnownProduct"),
                                    disableUnlockbutton: false
                                  })
                                }  
                              }
                            })
                            .catch(function (error) {
                              self.setState({
                                isLoading: false,
                                coolerUnavailable: true,
                                showPopup: false,
                                doorClosed: false,
                                doorOpenedForLongTime: false,
                                doorOpened: false
                              });
                            });
                        }
                        else {
                          self.errorCode = 0
                          self.setState({
                            isLoading: false,
                            imagetype: 'error',
                            showPopup: false,
                            apiPopup: true,
                            deviceUnavailable: false,
                            popupmsg: i18next.t("UnKnownProduct"),
                            disableUnlockbutton: false
                          })
                        }
                      }
                      else {
                        self.errorCode = 1;
                        self.setState({
                          isLoading: false,
                          deviceUnavailable: true,
                          doorClosed: false,
                          doorOpenedForLongTime: false,
                          doorOpened: false,
                          showPopup: false
                        });
                        window.history.replaceState({}, '')
                        cardService.cancelTransaction(transactionId)
                        // cardService.cancelTransaction(result.message.Payment.PaymentId,totalPrice);
                      }
                    }
                    socket.close();
                  }
                  else {
                    self.errorCode = devicedata.Sts;
                    self.setState({
                      isLoading: false,
                      deviceUnavailable: true,
                      doorClosed: false,
                      showPopup: false,
                      doorOpenedForLongTime: false,
                      doorOpened: false
                    });
                    socket.close();
                  }
                }
                else{
                  //Cancel transaction
                }
              }
            }
            else{
              //Cancel transaction
              window.history.replaceState({}, '')
              cardService.cancelTransaction(transactionId)
              let msg=i18next.t("DeviceUnavailable")
              // cardService.cancelTransaction(result.message.Payment.PaymentId,'0');
              self.errorCode = 0
              self.setState({
                isLoading: false,
                imagetype: 'error',
                showPopup: false,
                deviceUnavailable: false,
                apiPopup:true,
                popupmsg: msg,
                disableUnlockbutton: false
              })
            }
          })
          .catch(function (err) {
            self.setState({
              isLoading: false,
              coolerUnavailable: true,
              showPopup: false,
              doorClosed: false,
              doorOpenedForLongTime: false,
              doorOpened: false
            });// catch error
            cardService.cancelTransaction(transactionId)
              // history.push('/ScanButton')
          })
        }
        else{
          //Cancel transaction
          cardService.cancelTransaction(transactionId)
          history.push('/ScanButton')
        }
      })
      }
    })
  }

  payPendingAmount=()=>{
    let self = this;
    const {cardList,currencyCode,cvv,paytmTransactionID} = self.state
    const user = JSON.parse(decryptData(localStorage.user))
    let authToken=user.authToken;
    let PurchaseNumber = paytmTransactionID
    let defaultcard = cardList.filter((item)=>(item.isDefault)?item:null)      
    let cardId=defaultcard[0].cardID
    let CustomerName=user.firstName+" "+user.lastName
    let applicationTypeId=config.applicationTypeId
    let uniqueDeviceId=user.userId
    let sessionUId=sessionStorage.getItem("sessionId");
    let isOTPVerified=false
    let preAuthTransactionId=paytmTransactionID
    let that=this;
    let languageCode=localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
    fetch(config.envUrl + "VendingAppApi/completePendingPaymentWithSwipe", {
      method: "POST",
      body: new URLSearchParams({
        authToken,
        PurchaseNumber,
        cardId,
        CustomerName,
        applicationTypeId,
        currencyCode,
        uniqueDeviceId,
        languageCode,
        sessionUId,
        preAuthTransactionId,
        isOTPVerified,
        cvv
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then(function (response) {
      return response.json();
    })
    .then(result=>{
      const {actionUrl="",content,success} = result;
      if(success && actionUrl!=""){
        localStorage.setItem('pendingPurchaseNumber',PurchaseNumber)
        const postUrl = actionUrl;
        const errorUrl = config.baseURL + '/payment-status';
        const urlData = JSON.parse(content);
        const paytmUrl = config.envUrl+'paytm.aspx?MD='+urlData.MD+'&PaReq='+urlData.PaReq+'&TermUrl='+urlData.TermUrl+'&postURL='+postUrl+'&errorURL='+errorUrl
        window.location = paytmUrl
      }
      else{
        self.errorCode = result.code
        let code = result.code;
        let errormsg = "";
        if (code === 15525) {
          errormsg = i18next.t('CardVerificationFail')
        }
        else {
            errormsg = i18next.t('Network Error, Please re try to Submit the CVV.')          
        }
        self.setState({
          isLoading: false,
          imagetype: 'error',
          apiPopup: true,
          showCardValidation:false,
          showPopup: false,
          OpenWallet: false,
          popupmsg: errormsg,
          disableUnlockbutton: false,
          cvv:''
        })
      }
    })
    .catch(err=>{
      self.setState({
        isLoading: false,
        imagetype: 'error',
        apiPopup: true,
        showCardValidation:false,
        showPopup: false,
        OpenWallet: false,
        popupmsg: "Network Error, Please re try to Submit the CVV.",
        disableUnlockbutton: false,
        cvv:''
      })
    })
  }

  render() {
    let user = JSON.parse(decryptData(localStorage.getItem('user')));
    const { products } = this.props;
    let FreeProductList = []
    if(this.state.freeProducts.length>0){
      if(products.products && products.products.data){
        let list = products.products.data[0].products;
        this.state.freeProducts.map((data,key)=>{
          let singleProduct = list.filter((item)=>(item.productSKU===data)?item:null)
          if(singleProduct.length>0){
            FreeProductList.push(singleProduct[0])
          }
        })          
      }
    }
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <section className="">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{padding: '0px 0px 0px 0px'}} className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
              <div className="col-12 p-0">
                <div style={{padding: '0px 15px 0px 15px'}} className="profile-name pt-4">
                  {
                    // config.showSupportButton 
                    // ? 
                    <h4 style={{display: "table"}} className="">
                        <Link to='/scanButton'>
                          <img src={left_angle} className="largeBackImg pr-1"/>
                        </Link>
                        <span style={{display: "table-cell"}}>{i18next.t("What's Inside?")}</span>
                      </h4>
                    // : <h4 className="">{i18next.t("Hello")} {user.firstName}!</h4>
                  }
                </div>

                <div className="layout p-0">
                  {
                    this.props.isCoolerNotFound ? (<ModelPopup
                      openPopup={this.state.noCoolerFound}
                      closePopup={this.closingNoCoolerFoundPopup}
                      message={i18next.t("NoCoolerFound")}
                    />) :
                      products.products ? (
                        <div className="Product-listing-data" >
                          {/* { config.showSupportButton==false && <div style={{margin:'0px 15px 0px 15px'}}><button className="btn w-100 btn-unlockcooler mb-2 text-center" onClick={this.unlockDoor}
                            disabled={this.state.disableUnlockbutton}                 
                            hidden={
                              products.products.data[0].products.length > 0 ? false : true
                            }>{i18next.t('OpenCooler')}</button></div> } */}
                          {/* {config.showSupportButton==true && <div style={{padding: '0px 15px 0px 42px', fontSize:'18px'}}><p className="home-subtitle">{i18next.t('ProductListMessage')}</p></div>}
                          {config.showSupportButton==false && <div style={{padding: '0px 15px 0px 15px'}}><p className="home-subtitle">{i18next.t('ProductList')}</p></div>} */}
                          <div style={{padding: '0px 15px 0px 42px', fontSize:'18px', borderBottom: '1.4px solid #b8b8b8'}}><p className="home-subtitle">{i18next.t('ProductListMessage')}</p></div>
                          <HeroSlider sliderData={[products.products.data[0]]} currencyCode={this.state.currencyCode}>
                            {" "}
                          </HeroSlider>
                          { products.products.data[0].products.length > 0 &&
                            <button className="unlockcooler_btn pl-0" onClick={config.applicationTypeId==20 && this.state.isPreAuthEnable?this.showNotification:this.unlockDoor}>
                            <img src={btnLocked}></img>
                            <span>
                              {i18next.t('OpenCooler')}
                            </span>
                          </button> }
                          {/* <div className="btn-section mt-2">
                            <div className="row">
                              <div className="col-6">
                                <button onClick={this.handleBack}
                                  className="btn btn-back btn-unlockcooler"
                                >                
                                  <img src={black_arrow} />                
                                  {i18next.t("Back")}                 
                                </button>
                              </div>
                              <div className="col-6 text-right pl-0">
                                <button
                                  className="btn btn-unlockcooler"
                                  onClick={this.unlockDoor}
                                  disabled={this.state.disableUnlockbutton}                        
                                  hidden={
                                    products.products.data[0].products.length > 0 ? false : true
                                  }
                                >                                
                                  {i18next.t('OpenCooler')}
                                  <img src={black_arrow} />
                                </button>
                              </div>
                              
                            </div>
                          </div>                           */}
                        </div>
                      ) : (
                        <div className="spinner">
                          <div style={style}>
                            <ClipLoader size={50} color={"#000000"} />
                          </div>
                        </div>
                        )
                  }{" "}
                  { this.state.showFooter &&
                  <FooterNav />
                  }

                    {
                      this.state.isLoading?
                      <div className="spinner">
                        <div style={style}>
                          <ClipLoader size={50} color={"#000000"} loading={this.state.isLoading}/>
                        </div>
                      </div>
                      :null
                    }

                    {
                      this.state.apiPopup &&
                        <ModelPopup
                            color="Red"
                            openPopup={this.state.apiPopup}
                            closePopup={this.closingApiPopup}
                            message={i18next.t(this.state.popupmsg,{errorCode:this.errorCode})}
                            imagetype={this.state.imagetype}
                                
                        />
                    }

                    {/* {
                      this.state.NoRewardPoint ? (
                          <ManualPopup
                            text={i18next.t("lowRewardPoints")}
                            closePopup={this.toggleNoRewardPopup.bind(this)}
                          />
                        ) : null
                    } */}

                    {
                      this.state.showPopup ? (
                        <div className="coolerUnlockdiv">
                          <div className="coolerUnlockContent">
                            <div className="coolerUnlockBody">
                              <img src={this.state.isUnLocked?unlockDoorIcon:lockedIcon} />
                              {
                                this.state.isUnLocked ?
                                <>
                                  <h3>{i18next.t('UnlockCoolerTitle')}</h3>
                                  <h5>{i18next.t('UnlockCoolerDesc')}</h5>
                                </>
                                :
                                <>
                                <h3 className="mt-3">{i18next.t('BeingUnlockCoolerTitle')}</h3>
                                </>
                              }
                              
                            </div>
                            {
                              this.state.isUnLocked &&
                                <div className="coolerUnlockFooter">
                                  <table width="100%">
                                    <tbody>
                                      <tr>
                                        <td scope="col" width="30%" className="text-center" valign="top">
                                          <img src={ error} className="" alt="..." style={{height:'60px',width:'60px'}} />
                                        </td>
                                        <td className="UnlockCoolerMessage">
                                          {i18next.t('UnlockCoolerMessage')}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                            }
                          </div>
                        </div>
                      ) : null
                    }

                    {
                      this.state.deviceUnavailable ? (
                        <ModelPopup
                          openPopup={this.state.deviceUnavailable}
                          closePopup={this.closingdeviceUnavailablePopup}
                          message={(this.errorCode==243||this.errorCode==244)?<Trans i18nKey={this.DoorErrorCodes[this.errorCode]} components={[<br/>]}></Trans>:i18next.t(this.DoorErrorCodes[this.errorCode],{errorCode:this.errorCode})}
                          imagetype={"error"}
                        />
                      ) : null
                    }

                    {
                      this.state.isFBFAvailed ? (
                        <ModelPopup
                          openPopup={this.state.isFBFAvailed}
                          closePopup={this.ContinueTransaction}
                          message={i18next.t('Free Voucher is already availed by this Card!')}
                          imagetype={"error"}
                        />
                      ) : null
                    }

                    {
                      this.state.doorOpened ? (
                        <ModelPopup
                          openPopup={this.state.doorOpened}
                          closePopup={this.closingDoorOpenedPopup}
                          message={i18next.t("DoorOpened")}
                        />
                      ) : null
                    }
                    {
                      this.state.doorClosed ? (
                        <ModelPopup
                          openPopup={this.state.doorClosed}
                          closePopup={this.closingDoorClosedPopup}
                          message={i18next.t("DoorClosed")}
                        />
                      ) : null
                    }
                    {
                      this.state.doorOpenedForLongTime ? (
                        <ModelPopup
                          openPopup={this.state.doorOpenedForLongTime}
                          closePopup={this.closingdoorOpenedForLongTimePopup}
                          message={i18next.t("DoorOpenedForLongTime")}
                        />
                      ) : null
                    }
                    <Modal title={false} closable={false} centered={true} visible={this.state.ApplyCouponPopup} footer={false} className="ApplyCouponModal">
                      <div className="text-center">
                          <h5 className="">{i18next.t("Available Rewards")}</h5>
                          <div className="couponSection">
                            <select 
                              placeholder="Please select coupon" 
                              defaultValue={this.state.selectedCoupon} 
                              className="form-control" 
                              onChange={this.handleSelectChange}    
                              style={{fontSize:'14px'}}                          
                            >
                                {                       
                                  this.state.offerCoupon.map((coupon,key)=>{
                                    return <option value={coupon.CouponCode} key={key}>{coupon.OfferName}</option>
                                  })
                                }
                            </select>
                            <h6 className="mt-2" style={{textDecoration:'underline'}}>{i18next.t("Free Products")}</h6>
                            <div className="FreeProductList" >
                                {
                                FreeProductList.map((singleData,key)=>{
                                  return (
                                    <div className="SingleFreeProd" key={key}>
                                      <img src={singleData.productImage} />
                                      
                                    </div>
                                  )
                                })
                                }
                            </div>
                          </div>
                          <div className="p-3 mb-4 d-flex text-center">                                    
                              <button                                    
                                  className="btn btn-with-border bg-white"
                                  onClick={this.handleSkip}
                              >{i18next.t("Skip")}</button>
                              <button  
                                  className="btn btn-theme-dark ml-2"  
                                  onClick={this.handleApplyCoupon}
                              >{i18next.t("Apply")}</button>
                          </div>
                      </div>
                  </Modal>
                  <SaferpayWallet 
                    isModalOpen={this.state.OpenWallet} 
                    preAuthAmt={this.props.products.products ?this.props.products.products.preAuthorizeAmount:0} 
                    title={this.state.paymentTitle}
                    currencyCode={config.currencyCode}
                    handlePaymentChange={this.handlePaymentChange}
                    paymentType={this.state.paymentType}
                    closeWalletModal={this.closeWalletModal}
                    PayViaWallet={this.PayViaWallet}
                    paywithCard=""   
                    paymentMethod={this.state.paymentMethod}  
                    isPreAuthEnable={this.state.isPreAuthEnable}               
                  />
                  <OtpPopUp 
                    showModal={this.state.showPayUModal}
                    handleVerify={this.handleVerifyOTP}
                    value={this.state.PayUOTP}
                    handleChangeOTP={this.handleChangeOTP}
                    desc={this.state.PayUDesc}
                    isDisable={this.state.isOtpButtonDisable}
                  />
                  <Notification 
                    showPopup={this.state.showPreAuthNotification} 
                    amount={this.state.preAuthorizeAmount}
                    closeNotification={this.closeNotification}
                  />
                  <CardValidation 
                    value={this.state.cvv} 
                    onChange={(value)=>this.setState({cvv:value,invalidCVV:value.toString().length==3?false:true})} 
                    showCardValidation={this.state.showCardValidation}
                    onCancel={()=>this.setState({showCardValidation:false})}
                    verifyCVV={this.VerifyWithPayTM}
                    text={this.state.paytmTransactionID?'Purchase value higher than Pre authorized amount. Kindly re -enter CVV number of your saved card to complete payment':'Enter CVV number of your saved card to pre-authorization the payment for transaction and unlock the cooler door.'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
  }
}

function mapState(state) {
  const { products } = state;
  const isCoolerNotFound = state.products.products && state.products.products.code === 1519 ? true : false;
  return {
    products,
    isCoolerNotFound
  };
}

const actionCreators = {
  getAllProducts: productActions.getAllProducts,
};

const connectedHomePage = connect(mapState, actionCreators)(Home);
export { connectedHomePage as Home };
