import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Modal from 'react-awesome-modal';
import Button from "../button/button";
import "./Payment.scss";
class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  openModal() {
    this.setState({
      visible: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }
  render() {
    return (
      <div className="row">
        <div className="blankbox col-12"></div>
        <div className="col-12">
          <div className="payment-title mb-4">
            <p>Payment Information</p>
          </div>
          <div className="payment-amount mb-4">
            <p>¥ 173</p>
          </div>
        </div>
        <div className="col-12">
          <div className="btn-blue-sec row mx-auto">
            <div className="col-6 pl-0 pr-2">
              <div className="py-2">
                <Link to={process.env.PUBLIC_URL + "/cart"}>
                  <Button type="button" text="Cancel" look="center" />
                </Link>
              </div>
            </div>
            <div className="col-6 pr-0 pl-2">
              <div className="py-2">
                <div className="model">
                  <Link href="#" onClick={() => this.openModal()}>
                    <Button type="button" text="Pay" look="center" />
                  </Link>
                  <div className="PopupCenter">
                    {/*} <Modal 
                                                visible={this.state.visible}
                                                width="400"
                                                height="auto"
                                                effect="fadeInUp"
                                                onClickAway={() => this.closeModal()}
                                            >
                                            <div className="popup-box">
                                                <div className="whitebody-popup">
                                                    <p className="popup-title">
                                                        Payment Success for amount ¥173
                                                    </p>
                                                    {/* <div className="popup-button">
                                                        <Link href="#" onClick={() => this.closeModal()}>
                                                            <Button look="center" text="Ok" btnStyle="Black" ></Button>
                                                        </Link>
                                                    </div> */}
                    {/*<div className="popup-button">
                                                        <Link to={process.env.PUBLIC_URL + "/" }>
                                                            <Button look="center" text="Ok" btnStyle="Black" ></Button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                                </Modal>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentPage;
