import { config } from '../_config/index';
import { decryptData } from '../_helpers/encryptData';

async function getDecouplePromotion(displayType){
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    const applicationTypeId = config.applicationTypeId;
    let macAddress= decryptData(localStorage.getItem('qrcode'));
    let screenName=displayType;
    let width=window.innerWidth;
    let sessionUId = sessionStorage.getItem("sessionId");
    return fetch(config.envUrl + "VendingAppApi/getConsumerOfferDetailsNew", {
        method: "POST",
        body: new URLSearchParams({
            authToken,
            applicationTypeId,
            macAddress,
            screenName,
            width,
            sessionUId
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        return data;
    })
    .catch(function (error) {
        return {
            success: false,
            error: error
        }
    }); 
}

export {getDecouplePromotion}