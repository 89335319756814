import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../components/button";
import "../assets/scss/defaults.scss";
import BackData from "../components/BackData/BackData";
import VerifyOTPs from "../containers/VerifyOTP";
import { history } from "../_helpers";
import Popup from "../components/Model-Popup/Popup";
import ClipLoader from "react-spinners/ClipLoader";
import i18n from "i18next";
import CoolerPopup from "../components/Model-Popup/Popup";
import { config } from "../_config/index";
import PublicHeading from "../components/PublicHeader/publicHeader";
import Heading from "../containers/Heading/Heading";
import FooterNav from "../components/FooterNav/FooterNav";
import { Trans } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import ReactInputMask from "react-input-mask";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";
class VerifyPaytmOTP extends Component {
  constructor(props) {
    super(props);
    //console.log(props.location.state)
    document.title=`Coke & Go ${config.countryName} | verify-paytm-otp`;
    CapturePage({hitType:"pageview",page:"/verify-paytm-otp"})    
        
    this.state = {
      isLoading:false,
      otp:"",
      showDialog: false,
      dialogmsg: "",
      popupimgtype: "",
      connectionLost: false,
      seconds: 60,
      redirectpage:props.location.state
    };
    this.timer = 0;
    this.resendOTP = this.resendOTP.bind(this);
    this.submitOTP = this.submitOTP.bind(this);
    this.closingPopup = this.closingPopup.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closingConnectionPopup = this.closingConnectionPopup.bind(this);
    this.startTimer()
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      seconds: seconds,
    });
    
    // Check if we're at zero.
    if (seconds == 0) { 
      clearInterval(this.timer);
    }
  }
  

  closingConnectionPopup() {
    this.setState({
      connectionLost: false,
    });
  }

  handleChange(e) {
    const { value } = e.target;
    let otp = value.split(" ").join("");
      otp = otp.split("_").join("");
    this.setState({
      otp: otp,
    });
  }

  resendOTP(e) {
    this.setState({
      isLoading:true
    })
    const user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken;
    let uniquedeviceId = sessionStorage.getItem("uniquedeviceId");
    let phoneNumber = sessionStorage.getItem("phoneNumber");
    let sessionUId = sessionStorage.getItem('sessionId');
    const deviceId = decryptData(localStorage.getItem("qrcode"));
    let RequestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({authToken,uniquedeviceId,phoneNumber,sessionUId,deviceId})
    }
    // checking device status
    fetch(`${config.envUrl}VendingAppApi/sendPaytmOTPrequest`, RequestOptions)
    .then(function (response) {
      return response.json();
    })
    .then((result)=>{
      this.setState({
        isLoading:false,
        seconds:60
      })
      if(result.success){
        this.timer=0;
        this.startTimer()
        sessionStorage.setItem("stateToken",result.state_token);
        this.setState({
          otp:""
        })
      }
      else{
        
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  submitOTP(event){
    event.preventDefault();
    let otp = this.state.otp;
    if(otp.length!=6){
      this.setState({
        showDialog: true,
        dialogmsg: i18n.t("ValidOTP"),
        popupimgtype: "error",
      });
    }
    else{
      this.setState({
        isLoading:true
      })
      const user = JSON.parse(decryptData(localStorage.getItem("user")));
      let authToken = user.authToken;
      let uniquedeviceId = sessionStorage.getItem("uniquedeviceId");
      let statetoken = sessionStorage.getItem("stateToken")
      let phoneNumber = sessionStorage.getItem("phoneNumber");
      let sessionUId = sessionStorage.getItem('sessionId');
      const deviceId = decryptData(localStorage.getItem("qrcode"));
      let otp = this.state.otp;
      let RequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,uniquedeviceId,statetoken,otp,phoneNumber,sessionUId,deviceId})
      }
      // checking device status
      fetch(`${config.envUrl}VendingAppApi/verifyPaytmOTPrequest`, RequestOptions)
      .then(function (response) {
        return response.json();
      })
      .then((result)=>{
        this.setState({
          isLoading:false        
        })
        if(result.success){
          sessionStorage.setItem("ssotoken",result.ssotoken);
          // history.push("/Home")
          this.setState({
            showDialog: true,
            dialogmsg: i18n.t("CardSuccess"),
            popupimgtype:'success'
          })
          // localStorage.removeItem("cardlist")
        }
        else if(!result.success && (result.code==15489 || result.code==15490)){
          this.setState({
            showDialog: true,
            dialogmsg: result.message,
            popupimgtype:'error'
          });
        }
        else{
          this.setState({
            showDialog: true,
            dialogmsg: i18n.t("CommonError"),
            popupimgtype:'error'
          });
        }
      })
      .catch((err)=>{
        console.log(err);
        this.setState({
          isLoading:false
        })
      })
    }
  }

  closingPopup() {
    this.setState({
      showDialog: false,
    });
    if(this.state.dialogmsg==i18n.t("CardSuccess")){
      let newuser = sessionStorage.getItem('newuser');
      if(this.state.redirectpage.length > 10){
        history.push(`/PayOutstandingbalance/${this.state.redirectpage}`)
      }
      else if(this.state.redirectpage=='Home'){
        history.push("/Home")
      }
      else if(this.state.redirectpage=='ScanButton' || newuser=='true'){
        history.push('/ScanButton')
      }
      else{
        history.push("/Card")
      }
    }
  }

  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    let user = localStorage.getItem('user');
    
    return (
      <div className={user?"":"loginwith"}>        
        <div className="container">
        <Heading title={i18n.t("OTPVerification")} align="center" firstIcon={true}  secondIcon={false} backLink="/link-with-paytm" LinkIcon="back"/>
          <form className="row justify-content-center" onSubmit={this.submitOTP}>
            <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
              <div className={user?"col-12 mt-5 Editform":"col-12 mt-5"}>
                    <div className="">
                        <label className="text-white">{i18n.t("OTP")}</label>
                        {/* <input
                        id="otp"
                        type="text"
                        className="form-control border-radius-none pl-0"
                        placeholder={i18n.t("EnterOTP")}
                        name="otp"
                        onChange={this.handleChange}
                        /> */}
                        <input type='text' maxLength={6} id='otp' name="otp" className="form-control border-radius-none pl-0" mask="999999"  placeholder={i18n.t("EnterOTP")} onChange={this.handleChange}/>
                        {/*<ReactInputMask name="otp" className="form-control border-radius-none pl-0" mask="999999"  placeholder={i18n.t("EnterOTP")} onChange={this.handleChange}  value={this.state.otp}/> */}
                    </div>
                    <div className="row">
                        <div className="col-6">
                          {
                            this.state.seconds>0 &&
                            <b>00:{this.state.seconds<10?"0"+this.state.seconds:this.state.seconds}</b>
                          }
                            
                        </div>
                        <div className="col-6 text-right">
                            <div className="ResendLink">
                                <div className="py-1">
                                    {
                                      this.state.seconds>0 ?
                                        <span className="text-underline" style={{cursor:'pointer',color:'#ccc'}}>
                                        <Trans i18nKey="Resend">Resend?</Trans>
                                        </span>
                                        :
                                        <span className="text-underline" style={{cursor:'pointer'}} onClick={this.resendOTP}>
                                          <Trans i18nKey="Resend">Resend?</Trans>
                                        </span>
                                    }
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <Button size="large" btnType="submit" text={i18n.t('OTPVerification')} className="btn mt-2 btn-theme-dark" />
                    </div>
              </div>
              
            </div>
          </form>
          <FooterNav /> 
          {this.state.showDialog ? (
            <Popup
              color="Red"
              openPopup={this.state.showDialog}
              closePopup={this.closingPopup}
              message={this.state.dialogmsg}
              imagetype={this.state.popupimgtype}
            />
          ) : (
            ""
          )}
          {this.state.connectionLost ? (
            <CoolerPopup
              color="Red"
              openPopup={this.state.connectionLost}
              closePopup={this.closingConnectionPopup}
              message={i18n.t("ConnectionIssue")}
            />
          ) : null}
          <div className="spinner" style={{display:this.state && this.state.isLoading ? 'block' : 'none'}}>
            <div style={style}>
              <ClipLoader
                size={50}
                color={"#000000"}
                loading={this.state && this.state.isLoading ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default VerifyPaytmOTP;
