import React, { Component } from "react";
import Heading from "../containers/Heading/Heading";
import i18next from "i18next";
import FooterNav from "../components/FooterNav/FooterNav";
import { config } from "../_config";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { history } from "../_helpers";

export default class Rules extends Component {
    constructor(props) {
        super(props);
        document.title=`Coke & Go ${config.countryName} | offerRules`;
        CapturePage({hitType:"pageview",page:"/offerRules"})    
        if(props.location.state){
          const linkData = JSON.parse(props.location.state);
          this.state={
            landingImage:linkData.landingImage,
            seeRulesLink:linkData.seeRulesLink,
            offerName:linkData.offerName,
            backLink: linkData.backLink
          }          
        }
        else{
          history.push('/scanbutton')
        }
      }
      render() {
        const style = {
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        };
        const {landingImage,seeRulesLink,offerName} = this.state;
        return (
          <div className="bg-theme h-100">      
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                  <div className="col-12">
                    <Heading title={offerName} firstIcon={true}  secondIcon={false} backLink={this.state.backLink} LinkIcon="back"/>
                    <div style={{height:'calc(100vh - 200px)', overflowY: 'scroll'}}>
                      {
                        landingImage!="" ?
                        <a href={seeRulesLink}><img src={landingImage} className="w-100"/></a>
                        :
                        <iframe src={seeRulesLink} className="w-100" style={{height:'calc(100vh - 130px)',border:'none'}}/>                        
                      }
                    </div>
                    <FooterNav/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
}
