import React, { Component } from 'react';
import Searching from "../../assets/images/home/searching.svg";
import  "./loader.scss";

class Loader extends Component{
    render () {
      return (        
            <div className="loader alignCenter">
                <div className="loaderImage">
                    <img src={Searching} alt="Searching"/>
                </div>
                <div className="loaderText">
                    <p>Looking for nearby coolers . . .</p>
                </div>
            
            </div>
      );
    }
  }
  
  
  
  export default Loader