import React, { Component } from "react";
import { purchaseHistoryService } from "../_services";
import "swiper/css/swiper.css";
import BackWhiteData from "../components/BackWhiteData/BackWhiteData";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import i18n from "i18next";
import { Trans } from "react-i18next";
import Heading from "../containers/Heading/Heading";
import { ProductCard } from "../components/ProductCard/ProductCard";
import { Link } from "react-router-dom";
import country  from "iso-country-currency"
import { config } from "../_config";
import FooterNav from "../components/FooterNav/FooterNav";
import card from "../_assets/images/icons/card.png"
import star from "../_assets/images/icons/star.svg"
import moment from "moment";
import { history } from "../_helpers";
import CoolerPopup from "../components/Model-Popup/Popup";
import { Likert } from "../components/Likert/Likert";
import { Feedback } from "../components/Feedback/Feedback";
import { Contact } from "../components/Contact/Contact";
import { cardService } from "../_services/card.service";
import SaferpayWallet from "../components/SaferpayWallet/SaferpayWallet";
import ModelPopup from "../components/Model-Popup/Popup";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData, encryptData, validateString } from "../_helpers/encryptData";

export class OrderSummary extends Component {
  constructor(props) {
    super(props);
    var orderSummaryData = [];
    var orderid = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    var userData = JSON.parse(decryptData(localStorage.getItem("user")));
    var historyobj = {
      authToken: userData.authToken,
      userId: userData.userId,
      purchaseNumber:orderid
    };
    this.timer = 0;
    document.title=`Coke & Go ${config.countryName} | OrderSummary`;
    CapturePage({hitType:"pageview",page:"/OrderSummary/:id"})
    
    this.state = {
      items: props.location.query ? props.location.query.ordersummary : "",
      purchaseNumber:orderid,
      seconds:config.applicationTypeId == 20 ? 60 : 10,
      isLoading:false,
      isLikertOpen:true,
      OpenFeedback:false,
      feedBackData:null,
      openContact:false,
      isDisabled:true,
      OpenWallet:false,
      paymentTitle:i18n.t("Select Payment Method"),
      paymentType:"",
      feedbackmsg:"",
      ErrorPopup:"",
      ErrorMsg:i18n.t("CommonError"),
      paymentMethod:props.location.query ? props.location.query.ordersummary.length>0?props.location.query.ordersummary[0].paymentMethods:[]:[]
    };
    if (!this.props.location.query) {
      purchaseHistoryService.getPurchaseHistory(historyobj).then((history) => {
        
        orderSummaryData = history.data.filter(
          (his) => his.orderID === orderid
        );
        // console.log(orderSummaryData)
        this.setState({ items: orderSummaryData,paymentMethod: orderSummaryData[0].paymentMethods});
        // if(this.props.location.state=="fromHome" && orderSummaryData && orderSummaryData[0].paymentStatus=="Completed"){
        //   this.timer = setInterval(this.countDown, 1000);        
        // }
      });
    }
    this.handleNeedHelp = this.handleNeedHelp.bind(this)
    this.countDown = this.countDown.bind(this);
    this.sendPostSaleAPI = this.sendPostSaleAPI.bind(this);
    this.handleCompletePayment = this.handleCompletePayment.bind(this);
    this.closeLikert = this.closeLikert.bind(this);
    this.saveRating = this.saveRating.bind(this);
    this.handleFeedbackCheckbox = this.handleFeedbackCheckbox.bind(this);
    this.handleFeedbackMsg = this.handleFeedbackMsg.bind(this);
    this.closeFeedBack = this.closeFeedBack.bind(this);
    this.handleSubmitFeedBack = this.handleSubmitFeedBack.bind(this);
    sessionStorage.removeItem('isMailSent')
    // sessionStorage.removeItem('ratingDone')
  }

  componentDidMount(){
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken
    let applicationTypeId = config.applicationTypeId
    let macAddress= decryptData(localStorage.getItem('qrcode'))
    let PurchaseRequestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({authToken,applicationTypeId,macAddress})
    }
    let that = this;
    fetch(`${config.envUrl}VendingAppApi/getPurchaseOfferQty`, PurchaseRequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
        localStorage.setItem('isRewardAvailable',encryptData(JSON.stringify(result.records)))
    })
    this.unregisterHistoryListener = history.listen((location, action) => {
      console.log(location,action);
      let path = location.pathname.includes("NeedHelp");      
      let path1 = location.pathname.includes("OrderSummary");      
      var dataSummary = this.state;
      if(dataSummary.items && dataSummary.items[0].paymentStatus=="Completed"
          && this.props.location.state=="fromHome" && config.postSaleAPI && (action=='POP' || action=='PUSH') && !path && !path1){
        this.sendPostSaleAPI('push')
      }
      return true
    });
    cardService.couponList()
  }
  componentWillUnmount() {
    if(this.props.location.state=="fromHome" && config.postSaleAPI){
      this.unregisterHistoryListener();
    }
  }
  handleCompletePayment(){
    if(config.isSaferPayWalletEnabled){
      this.setState({
        OpenWallet:true,

      })
    }
    else{
      history.push({
        pathname:"/PayOutstandingbalance/"+this.state.purchaseNumber,
        state:'fromHome'
      })
    }
  }
  countDown(){
    let seconds = this.state.seconds - 1;
    this.setState({
      seconds: seconds,
    });
    if(seconds==0){
      clearInterval(this.timer);
      if(config.postSaleAPI){
        this.sendPostSaleAPI('')
      }
      else{
        
        history.push("/ScanButton")
      }
    }
  }

  startTimer(){
    if(this.props.location.state=="fromHome"){
      var dataSummary = this.state;
      if(dataSummary.items && dataSummary.items[0].paymentStatus=="Completed"){
        this.timer = setInterval(this.countDown, 1000);        
      }
      else{
        sessionStorage.setItem("transactionFail","true")
      }      
    }
  }

  sendPostSaleAPI(type){
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken
    let applicationTypeId = config.applicationTypeId
    let orderid = this.state.purchaseNumber
    let sessionUId = sessionStorage.getItem('sessionId');
    let PurchaseRequestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({authToken,applicationTypeId,orderid,sessionUId})
    }
    fetch(`${config.envUrl}VendingAppApi/callCWIPostSalePurchaseOrder`, PurchaseRequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
        if(result.success && result.code==5014 && type==''){
          history.push('/ScanButton')
        }
    })
  }

  handleNeedHelp(){
    let orderDetail = this.state.items;
    if(orderDetail[0].ManualCorrectionStatus){
      this.setState({
        ErrorPopup:true,
        ErrorMsg:i18n.t("You have already raised your concern for this transaction")
      })
    }
    else{
      if(this.state.items.length>0){
        this.setState({isLoading:true})
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let authToken = user.authToken
        let applicationTypeId = config.applicationTypeId
        let purchaseNumber = this.state.purchaseNumber
        let orderCorrectionReason = ' '
        let sessionUId = sessionStorage.getItem("sessionId");
        let PurchaseRequestOptions = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({authToken,applicationTypeId,purchaseNumber, orderCorrectionReason, sessionUId})
        }
        fetch(`${config.envUrl}VendingAppApi/updatePostSaleCalledStatus`, PurchaseRequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            if(result.success && result.code==5049){
              history.push({
                pathname:`/NeedHelp/${purchaseNumber}`, 
                state:{
                  fromPurchaseHistory: 'orderSummary',
                  purchaseDate: this.state.items[0].date,
                  SupportButtonDuration: this.state.items[0].SupportButtonDuration
                }
              })
            }
            else{
              this.setState({
                ErrorPopup:true,
                isLoading:false,
                ErrorMsg:i18n.t("CommonError")
              })
            }
        })
        .catch(err=>{
          this.setState({isLoading:false})
        })
      }  
    }  
  }

  closeLikert(){
    this.setState({
      isLikertOpen:false
    })
    sessionStorage.setItem("ratingDone",this.state.purchaseNumber)
    this.startTimer()
  }

  saveRating(rating){
    let that = this;
    that.setState({
      isLoading:true
    })
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken
    let purchaseNumber = that.state.purchaseNumber
    let applicationTypeId = config.applicationTypeId;
    let sessionUId = sessionStorage.getItem('sessionId');
    let PurchaseRequestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({authToken,rating,purchaseNumber,applicationTypeId,sessionUId})
    }
    fetch(`${config.envUrl}VendingAppApi/saveStarRatings`, PurchaseRequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
      if(result.success){
        let feedBackData = result.data;
        let updatedData = feedBackData
        feedBackData.map((data,key)=>{
          if(!data.isMultiLine){
            data.ratingDescriptionList.map((item,lkey)=>{
              if(item.isdefault){
                updatedData[key].ratingDescriptionList[lkey].result = 1
              }
              else{
                updatedData[key].ratingDescriptionList[lkey].result = 0
              }
            })
          }
          else{
            updatedData[key].ratingDescriptionList = [{"result":""}]
          }
        })
        if(rating>3){
          this.startTimer()
        }

        let btnFlag = true;
        feedBackData.map((data,key)=>{
          if(!data.isMultiLine){
            data.ratingDescriptionList.map((item,lkey)=>{
              if(item.result == 1){
                btnFlag = false
              }
            })
          }
        })


        that.setState({
          isLoading:false,
          isLikertOpen:false,
          OpenFeedback:rating>3?false:true,
          feedBackData:updatedData,
          isDisabled: btnFlag
        })
      }
      else{
        that.setState({
          isLoading:false,
          isLikertOpen:false
        })
      }
      sessionStorage.setItem("ratingDone",that.state.purchaseNumber)
      // this.startTimer()
    })
  }
  handleFeedbackCheckbox(event){
    const name = event.target.name.replaceAll("_"," ");
    let ischecked = event.target.checked;
    let feedBackData = this.state.feedBackData;
    let updatedData = feedBackData
    feedBackData.map((data,key)=>{
      if(!data.isMultiLine){
        data.ratingDescriptionList.map((item,lkey)=>{
          if(updatedData[key].ratingDescriptionList[lkey].name==name){
            updatedData[key].ratingDescriptionList[lkey].result = ischecked?1:0
          }
        })
      }
    })
    let isBtndisable = true;
    updatedData.map((data,key)=>{
      if(!data.isMultiLine){
        data.ratingDescriptionList.map((item,lkey)=>{
          if(updatedData[key].ratingDescriptionList[lkey].result){
            isBtndisable=false
          }          
        })
      }
      else{
        if(isBtndisable){
          isBtndisable = updatedData[key].ratingDescriptionList[0].result==""?true:false
        }
      }
    })
    this.setState({
      feedBackData:updatedData,
      isDisabled:isBtndisable
    })
    console.log(updatedData)
  }
  handleFeedbackMsg(event){
    const name = event.target.name.replaceAll("_"," ");
    let msg = event.target.value;
    let testString = validateString(msg,'description');  
    if(testString){
      let feedBackData = this.state.feedBackData;
      let updatedData = feedBackData;
      let isBtndisable=true;
      feedBackData.map((data,key)=>{
        if(data.isMultiLine){
          updatedData[key].ratingDescriptionList = [{result:msg}]
        }
      })
      this.setState({
        feedBackData:updatedData,
        isDisabled:msg==""?true:false,
        feedbackmsg:msg
      })
    }
  }
  handleSubmitFeedBack(){
    let that = this;
    that.setState({
      isLoading:true
    })
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let authToken = user.authToken
    let purchaseNumber = that.state.purchaseNumber
    let sessionUId = sessionStorage.getItem("sessionId");
    let feedback = JSON.stringify(that.state.feedBackData)
    let PurchaseRequestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({authToken,feedback,purchaseNumber,sessionUId})
    }
    fetch(`${config.envUrl}VendingAppApi/updateFeedBack`, PurchaseRequestOptions)
    .then(function (response) {
        return response.json();
    })
    .then((result) => {
      that.setState({
        isLoading:false,
        isLikertOpen:false,
        OpenFeedback:false,
        openContact:false
      })
      that.startTimer()
    })
      
  }
  closeFeedBack(){
    this.setState({
      OpenFeedback:false,
      openContact:false,
    })
    this.startTimer()
  }

  handlePaymentChange=(e)=>{
    sessionStorage.setItem('walletType',e.target.value)
    this.setState({
      paymentType:e.target.value,
      paymentTitle:i18n.t(e.target.value=="creditCard"?"Pay via Credit Card":e.target.value=="GOOGLEPAY"?"Pay via GPay":"Pay via Apple Pay")
    })
  }
  closeWalletModal=()=>{
    this.setState({
      OpenWallet:false
    })
  }
  PayViaWallet=()=>{
    this.setState({isLoading:true})
    const dataSummary = this.state
    const applicationTypeId = config.applicationTypeId;
    const amount = dataSummary.items[0].paidAmount-dataSummary.items[0].amountDeductedByPaymentGateway;
    const returnSuccessUrl = config.baseURL+'/successResponse';
    const returnFailUrl = config.baseURL+'/CardFail'
    const CurrencyCode = config.currencyCode;
    const user = JSON.parse(decryptData(localStorage.getItem("user")))
    const authToken = user.authToken
    const walletType = this.state.paymentType
    const deviceid = "";
    const purchasenumber=this.state.purchaseNumber
    let sessionUId = sessionStorage.getItem("sessionId");
    let RequestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({applicationTypeId,amount,returnSuccessUrl,returnFailUrl,CurrencyCode,authToken,walletType,deviceid,purchasenumber,sessionUId})
    }
    // checking device status
    fetch(`${config.envUrl}VendingAppApi/saferPaymentPageInitialize`, RequestOptions)
    .then(function (response) {
      return response.json();
    })
    .then((data)=>{
      if(data.success && data.code==5041){
        let token = data.data.Token;
        sessionStorage.setItem('saferWalletToken',token);
        sessionStorage.setItem('intiatedFromPending','true')
        sessionStorage.setItem('purchasenumber',purchasenumber)
        window.location.href = data.data.RedirectUrl
      }
      else{
        this.setState({
          ErrorPopup:true,
          ErrorMsg:i18n.t("CommonError"),
          isLoading:false
        })
      }
    })
    .catch((err)=>{
      this.setState({
        ErrorPopup:true,
        ErrorMsg:i18n.t("CommonError"),
        isLoading:false
      })
    })
  }
  closingErrorPopup=()=>{
    this.setState({
      ErrorPopup:false
    })
  }
  render() {
    var dataSummary = this.state;
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    var totalPrice = 0;
    var rewardAmount = 0;
    var otherAmount = 0;
    if (dataSummary.items === "")
      return (
        <div style={style}>
          <ClipLoader size={50} color={"#FF0000"} />
        </div>
      );

    const styleBottleImg = {
      height: "79px",
      width: "22px",
    };
    const currencyCode = config.currencyCode
    
    let lang = currencyCode=="USD"?"en-US":config.langCode
    let options = { style: 'currency', currency: currencyCode};
    let duesAmount = new Intl.NumberFormat(lang, options).format(dataSummary.items[0].paidAmount-dataSummary.items[0].amountDeductedByPaymentGateway-dataSummary.items[0].totalDiscountAmount);
    let discountAmount = new Intl.NumberFormat(lang, options).format(dataSummary.items[0].discountAmount);
    let amountDeductedByPaymentGateway = new Intl.NumberFormat(lang, options).format(dataSummary.items[0].amountDeductedByPaymentGateway);
    let totalAmount = new Intl.NumberFormat(lang, options).format(dataSummary.items[0].paidAmount-dataSummary.items[0].discountAmount);

    var now = moment(new Date()); //todays date
    var end = moment(dataSummary.items[0].date); // another date
    var duration = moment.duration(now.diff(end));
    var hrs = duration.asHours();
    var isRatingDone = sessionStorage.getItem("ratingDone")
    let rewards = JSON.parse(decryptData(localStorage.getItem('reward')));
    const {promotionalOffer=[],OfferCoupon=[]} = rewards
    let rewardData = JSON.parse(decryptData(localStorage.getItem('isRewardAvailable')));
    return (
      <div className="main-wrapper">        
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="content">
                <Heading title={(this.props.location.state=="fromHome")?"":i18n.t("OrderSummary")}  firstIcon={true} secondIcon={false} backLink={(this.props.location.state=="fromHome")?"/ScanButton":"/PurchaseHistory"} LinkIcon="back"/>   
                {
                  (this.props.location.state!="fromHome" && dataSummary.items[0].paymentStatus=="Failed") ? 
                    <p className="text-danger text-center" style={{fontSize:'12px'}}>{i18n.t('PendingSubTitle')}</p>   
                    :
                    null
                }
                <div className="main-content pb-5">
                  <h5 className="ordersummary_title">{i18n.t('YourBasket')}</h5>  
                  {
                    config.applicationTypeId==19 &&
                    <div className="pb-2" style={{borderBottom:'1px solid #ccc'}}>
                      <p className="mb-0"><b>GST INV No</b>: {dataSummary.items[0].orderID} </p>
                      <p className="mb-0"><b>GSTIN No</b>: {dataSummary.items[0].GSTINNumber} </p>
                      <p className="mb-0"><b>FSSAI No</b>: {dataSummary.items[0].FSSAINumber} </p>
                    </div> 
                  }
                  {
                    dataSummary.items[0].paymentStatus!="Completed" && 
                    <div className="row purchaseDate">
                      <div className="col-6">
                        {i18n.t("Date")}
                      </div>
                      <div className="col-6 text-right text-dark">
                        {end.format("DD-MM-YYYY")}
                      </div>
                    </div>
                  }
                  <div className="productcontainer">                  
                    <div className="d-inline-flex">
                    { dataSummary && dataSummary.items[0].product.map((single, key) => {
                      totalPrice =
                      totalPrice + single.perProductPrice * single.productCount;
                      rewardAmount = dataSummary.items[0].amountDeductedByRewardPoint;
                      otherAmount = dataSummary.items[0].amountDeductedByPaymentGateway;
                      let lang = currencyCode=="USD"?"en-US":config.langCode
                      let options = { style: 'currency', currency: currencyCode,currencyDisplay: 'narrowSymbol'};
                      let signlePrice = new Intl.NumberFormat(lang, options).format(single.perProductPrice);
                      let purchasePrice = new Intl.NumberFormat(lang, options).format(single.perProductPrice*single.productCount);
                      return (
                        <div className='productCard' key={key}>
                        <ProductCard data={single}  />
                        </div>
                      )
                      })
                    }
                    </div>
                    {  ((this.props.location.state=="fromHome" && isRatingDone==this.state.purchaseNumber && !this.state.OpenFeedback && !this.state.openContact) || this.props.location.state!="fromHome" ) &&
                    <FooterNav />
                    }
                  </div>                  
                  {/* <hr/> */}
                  <table className="table mt-3 price_details">
                    <tbody>
                      <tr>
                        <td className="summary_title">{i18n.t("Subtotal")}</td>
                        <td className="text-right">{new Intl.NumberFormat(lang, options).format(config.applicationTypeId==19?dataSummary.items[0].subTotal:totalPrice)}</td>
                      </tr>
                    { 
                      (config.applicationTypeId==20 && dataSummary.items[0].paymentStatus=="Refunded") 
                        &&
                        <tr>
                          <td className="refund_title">{i18n.t("Refund Amount")}</td>
                          <td className="text-right" style={{color: '#F85901'}}>{new Intl.NumberFormat(lang, options).format(config.applicationTypeId==19?dataSummary.items[0].subTotal:totalPrice)}</td>
                        </tr>
                      }
                      
                      {
                        config.applicationTypeId==19 &&
                        <>
                          <tr>
                            <td>
                              <p className="mb-0"><b>CGST @02.50%</b></p>
                            </td>
                            <td className="text-right" style={{fontSize:'15px'}}>
                              ₹{dataSummary.items[0].CGSTAmount}                            
                            </td>
                          </tr>
                          <tr>
                          <td>
                            <p className="mb-0"><b>SGST @02.50%</b></p>
                          </td>
                          <td className="text-right" style={{fontSize:'15px'}}>
                            ₹{dataSummary.items[0].CGSTAmount}                            
                          </td>
                        </tr>
                        </>
                      }
                      {
                      (dataSummary.items[0].totalDiscountAmount)>0 && 
                        <tr>
                          <td className="summary_title text-success">{i18n.t("Reward Applied")}</td>
                          <td className="text-right text-success">- {new Intl.NumberFormat(lang, options).format(dataSummary.items[0].totalDiscountAmount)}</td>
                        </tr>
                      }
                      {
                        (dataSummary.items[0].paymentDetails && dataSummary.items[0].paymentDetails.length>0) &&
                        <>
                          {
                            dataSummary.items[0].paymentDetails.map((cards,key)=>{
                              let amount = new Intl.NumberFormat(lang, options).format(cards.amount)
                              return <tr key={key} className="card_details">
                                  <th key={"label"+key} style={{display:'contents'}}>
                                    <div style={{marginTop:'10px'}}><img src={card} height={15} style={{marginLeft:'10px'}}/> {config.applicationTypeId == 19 ? <span className="ml-2">{i18n.t(cards.lastDigits==""?cards.payType=="APPLEPAY"?"Paid with Apple pay":"Paid with GPay":"Wallet/Card ending in")}: {cards.lastDigits}</span> : <span className="ml-2">{i18n.t(cards.lastDigits==""?cards.payType=="APPLEPAY"?"Paid with Apple pay":"Paid with GPay":"Card ending in")}: {cards.lastDigits}</span>}</div>
                                  </th>
                                  <th key={"price"+key} className="text-right" style={{fontSize:'14px', color: 'rgb(40, 167, 69)'}}>{amount}</th>
                                </tr>
                            })
                          }
                        </>
                      }
                      {
                        dataSummary.items[0].manualSettledAmount>0 && 
                        <>
                          <tr>
                            <th>{i18n.t("Mannual Settled Amount")}</th>
                            <th className="text-right" style={{fontSize:'14px'}}>
                              {new Intl.NumberFormat(lang,options).format(dataSummary.items[0].manualSettledAmount)}
                            </th>
                          </tr>
                        </>
                      }
                      <tr className="border-0">
                        <td className="summary_title border-0">
                          {
                            (rewardData.length>0) && 
                            <small className="summary_total">{dataSummary.items[0].totalRewardsEarned}<img src={star} /> {i18n.t("Earned")}</small>
                          }
                        </td>
                        {
                          (config.applicationTypeId==20 && dataSummary.items[0].paymentStatus=="Refunded")
                            ?
                            <td className="text-right border-0" ><small className="summary_total">{i18n.t("Total")}</small>{new Intl.NumberFormat(lang, options).format(totalPrice-totalPrice)}</td>
                            :
                            <td className="text-right border-0" ><small className="summary_total">{i18n.t("Total")}</small>{new Intl.NumberFormat(lang, options).format(totalPrice-dataSummary.items[0].totalDiscountAmount)}</td>
                        }
                      </tr>
                    </tbody>
                  </table>
                  {/* {
                   ( dataSummary.items[0].paymentDetails && dataSummary.items[0].paymentDetails.length>0 && config.paymentGateway!=4) &&
                    <table className="card_details">
                      <thead>
                        {
                          dataSummary.items[0].paymentDetails.map((cards,key)=>{
                            let amount = new Intl.NumberFormat(lang, options).format(cards.amount)
                            return <tr key={key} className="card_details">
                                <th key={"label"+key} style={{display:'contents'}}>
                                  <img src={card} height={15}/> <span className="ml-2">{i18n.t("Card ending in")}: {cards.lastDigits}</span>
                                </th>
                                <th key={"price"+key} className="text-right text-success">{amount}</th>
                              </tr>
                          })
                        }
                        
                      </thead>
                    </table>
                  }
                  <div className="totalPrice">
                    <p>{i18n.t('Total')}</p>
                    <h4 className="m-0">{totalAmount}</h4>                  
                  </div>
                  <div className="clearboth"></div>               
                  {(dataSummary.items[0].discountAmount)>0 && 
                      <p className="text-success text-right coupon_text">BUY 10 GET 1 COUPON APPLIED</p>
                    } */}
                  {
                   (dataSummary.items[0].SupportEnabled == true && config.showSupportButton == true && (dataSummary.items[0].paymentStatus=="Completed" || dataSummary.items[0].paymentStatus=="Unrecognized")) &&
                    <div className="text-center">
                      <span style={{fontSize:'18px', fontWeight: '600', cursor: 'pointer', textDecoration: 'underline'}} onClick={this.handleNeedHelp}>{i18n.t("Need help")}</span>
                    </div>
                  }
                  {
                    dataSummary.items[0].paymentStatus=="Completed" && 
                    <button className="btn payment-status success mt-3">
                      <span>{i18n.t('PaymentSuccessStatus')}</span>
                    </button>
                  }
                  {
                    (dataSummary.items[0].paymentStatus=="Completed" && !dataSummary.items[0].isReceiptSendStatus && config.applicationTypeId===15 && hrs<24) &&
                    <div className="text-center mt-3 mb-2">
                      <Link className="text-dark" to={{pathname:'/invoice-receipt',state:dataSummary.items[0].orderID}}>
                        <button className="theme-btn">Generar boleta de venta</button>
                      </Link>
                    </div>
                  }
                  {
                    (dataSummary.items[0].paymentStatus=="Failed" || dataSummary.items[0].paymentStatus=="Pending" || dataSummary.items[0].paymentStatus=="Unrecognized") && 
                    <>
                      {/* <h6 className="mb-3 text-success">{i18n.t('Paid')} {amountDeductedByPaymentGateway}</h6> */}
                      <button className="btn payment-status error mt-3">
                        <span>{i18n.t('OutstandingBalance')} {duesAmount}</span>
                      </button>                    
                    </>
                  }
                  {
                    dataSummary.items[0].paymentStatus=="Cancelled" && 
                    <>
                      {/* <h6 className="mb-3 text-success">{i18n.t('Paid')} {amountDeductedByPaymentGateway}</h6> */}
                      <button className="btn payment-status error mt-3">
                        <span>{i18n.t('Transaction cancelled')}</span>
                      </button>                    
                    </>
                  }
                  {
                    dataSummary.items[0].paymentStatus=="Refunded" && 
                    <button className="btn payment-status success mt-3">
                      <span>{i18n.t('Refund Successful')}</span>
                    </button>
                  }
                  {
                    config.paymentGateway==4 ?
                    (this.props.location.state!="fromHome" && (dataSummary.items[0].paymentStatus=="Failed" || dataSummary.items[0].paymentStatus=="Pending")) &&
                      <Link to={{pathname:`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+dataSummary.items[0].orderID}`,state:'ordersummary'}} className="btn mt-3 mb-5 btn-theme-dark w-100 payoutstanding ">{i18n.t("PayOutstandingBalance")}</Link>
                    :
                    (config.paymentGateway==2 && this.props.location.state!="fromHome" && dataSummary.items[0].paymentStatus=="Failed") ?
                    <button  className="btn mt-3 mb-5 btn-theme-dark w-100 payoutstanding" 
                      onClick={()=>{
                        CaptureEvent({
                          category:"pay outstanding balance",
                          action:"button clicked",
                          label:"payOutstandingBalance"
                        })
                        this.setState({OpenWallet:true})}
                      }>
                        {i18n.t("PayOutstandingBalance")}</button>
                    :
                    (this.props.location.state!="fromHome" && dataSummary.items[0].paymentStatus=="Failed" || dataSummary.items[0].paymentStatus=="Pending") &&
                      <Link to={{pathname:`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+dataSummary.items[0].orderID}`,state:'ordersummary'}} className="btn mt-3 mb-5 btn-theme-dark w-100 payoutstanding ">{i18n.t("PayOutstandingBalance")}</Link>
                  }
                  {
                    (this.props.location.state=='fromHome' && config.showSupportButton == false) &&
                    <div className="text-center">
                      <button type="button" className="theme-btn" onClick={this.handleNeedHelp}>{i18n.t("Need help")}</button>
                    </div>
                    // :
                    // (!config.postSaleAPI && this.props.location.state=='fromHome') ?
                    // <div className="text-center">
                    //   <a href={`mailto:${config.supportEmail}`} className="text-dark"><button className="theme-btn">{i18n.t("Need help")}</button></a>
                    // </div>
                    // :
                    // ''
                  }
                  {
                    this.state.isLoading && 
                      <div className="" style={{height:'400px'}}>
                        <div className="spinner">
                            <div style={style}>
                                <ClipLoader
                                    size={50}
                                    color={"#000000"}
                                    loading={this.state.isLoading}
                                />
                            </div>
                        </div>
                      </div>
                  }
                  {
                    (this.props.location.state=="fromHome" && isRatingDone==this.state.purchaseNumber && !this.state.OpenFeedback && !this.state.openContact  && (dataSummary.items[0].paymentStatus!="Completed" && dataSummary.items[0].paymentStatus!="Unrecognized")) &&
                    <CoolerPopup
                      openPopup={!this.state.OpenWallet}
                      imagetype="error"
                      title={i18n.t("Payment pending!")}
                      description={i18n.t("We couldn’t complete your order, to continue using smart coolers please add a new payment form or check with your bank account")}
                      btnText={i18n.t("Complete payment")}
                      clickHandle={this.handleCompletePayment}
                      fullWithButton={true}   
                      isPaymentPending={false}
                    />
                  }
                  {
                    (this.state.isLikertOpen && isRatingDone!=this.state.purchaseNumber && this.props.location.state=="fromHome") &&
                    <Likert 
                      isPopUpOpen={this.state.isLikertOpen}
                      saveRating={this.saveRating}
                      closePopUp={this.closeLikert}
                    />
                  }
                  {
                    this.state.OpenFeedback &&
                    <Feedback 
                      isPopUpOpen={this.state.OpenFeedback}
                      feedBackData={this.state.feedBackData}
                      handleFeedbackCheckbox={this.handleFeedbackCheckbox}
                      handleFeedbackMsg={this.handleFeedbackMsg}
                      handleSubmitFeedBack={this.handleSubmitFeedBack}
                      feedbackmsg={this.state.feedbackmsg}
                      closeFeedBack = {this.closeFeedBack}
                      isDisabled={this.state.isDisabled}
                    />
                  }
                  {/* {
                    this.state.openContact &&
                    <Contact 
                      isPopUpOpen={this.state.openContact}
                      closeFeedBack = {this.closeFeedBack}
                    />
                  } */}
                  <SaferpayWallet 
                    paymentMethod={this.state.paymentMethod}
                    isModalOpen={this.state.OpenWallet} 
                    preAuthAmt={dataSummary.items[0].paidAmount-dataSummary.items[0].amountDeductedByPaymentGateway} 
                    title={this.state.paymentTitle}
                    currencyCode={config.currencyCode}
                    handlePaymentChange={this.handlePaymentChange}
                    paymentType={this.state.paymentType}
                    closeWalletModal={this.closeWalletModal}
                    PayViaWallet={this.PayViaWallet}
                    paywithCard={{pathname:`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+dataSummary.items[0].orderID}`,state:'ordersummary'}}
                  />
                  {
                      this.state.ErrorPopup &&
                        <ModelPopup
                            color="Red"
                            openPopup={this.state.ErrorPopup}
                            closePopup={this.closingErrorPopup}
                            message={this.state.ErrorMsg}
                            imagetype="error"
                                
                        />
                    }
                  </div>
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OrderSummary;
