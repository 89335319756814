import React from "react";
import PropTypes from "prop-types";

const { useState } = React;

const Button = ({ increment, onClickFunction, prodId, disabled }) => {
  const handleClick = () => {
    onClickFunction(increment, prodId);
  };
  return (
    <button
      onClick={handleClick}
      type="button"
      className="btn bgRed btn-number"
      data-type="plus"
      disabled={!disabled}
    >
      +
    </button>
  );
};

const Button2 = ({ decrement, onClickFunction, prodId, disabled }) => {
  const handleClick = () => {
    onClickFunction(decrement, prodId);
  };
  return (
    <button
      onClick={handleClick}
      type="button"
      className="btn bgRed btn-number"
      data-type="minus"
      disabled={!disabled}
    >
      -
    </button>
  );
};

function ChooseProductList({
  data,
  incrementFun,
  decrementFun,
  onProductSelect,
}) {
  var [count, setCount] = useState(1);
  var [disabledbuttons, setEnable] = useState(false);

  const incrementCount = (increment) => {
    setCount(count + increment);
  };
  const decrementCount = (decrement) => {
    if (count > 1) {
      setCount(count - decrement);
    }
  };

  const styleBottleImg = {
    height: "79px",
    width: "22px",
  };

  return (
    <li className="history-box">
      <div className="cart-img">
        <img
          src={data.productImage}
          className="mw-100"
          alt="cart icon"
          style={styleBottleImg}
        />
      </div>
      <div className="history-detail">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id={"gridCheck" + data.productId}
            onClick={(e) => {
              onProductSelect(data.productId, e.target.checked);
              setEnable(e.target.checked);
            }}
          />
          <label
            className="history-title form-check-label"
            htmlFor={"gridCheck" + data.productId}
          >
            <span>
              {localStorage.getItem("i18nextLng") == "ja" ||
                navigator.language == "ja"
                ? data.productLocalName
                : data.productName}
            </span>
          </label>
        </div>
      </div>
      <div className="history-price">
        <div className="input-group">
          <span className="input-group-btn">
            <Button2
              decrement={1}
              onClickFunction={(e) => {
                decrementFun(data.productId);
                decrementCount(1);
              }}
              prodId={data.productId}
              disabled={disabledbuttons}
            >
              -
            </Button2>
          </span>
          <input
            type="text"
            className="form-control input-number"
            value={count}
          />
          <span className="input-group-btn">
            <Button
              increment={1}
              onClickFunction={(e) => {
                incrementFun(data.productId);
                incrementCount(1);
              }}
              prodId={data.productId}
              disabled={disabledbuttons}
            >
              +
            </Button>
          </span>
        </div>
      </div>
      <span> </span>
      <div style={{ color: "grey", marginLeft: "5px" }}>
        ${data.price ? data.price : data.productPrice}
      </div>
    </li>
  );
}

ChooseProductList.propTypes = {
  data: PropTypes.object,
};

export default ChooseProductList;
