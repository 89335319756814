import React from 'react'
import { useEffect } from 'react';
import useModal from "react-hooks-use-modal";
const Notification = ({amount,showPopup,closeNotification}) => {
    const [Modal, open, close, isOpen] = useModal();
    
    useEffect(() => {
        if(showPopup && !isOpen){
            open();
        }
        if(!showPopup){
            close();
        }
    })
    return (
        <div className="model">
            <div className="PopupCenter">
                <Modal >
                    <div className="popup-box">
                        <div className="whitebody-popup text-center">
                            <h6>ATTENTION</h6>
                            <p>Kindly note that a ${amount} will be held temporarily for authorization purposes only. This amount will be released back to your account within 5 days.</p>
                            <button className='btn bg-white btn-with-border' style={{width:'160px'}} onClick={closeNotification}>OK</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default Notification