import {
  config
} from '../_config/index'
export const purchaseHistoryService = {
  getPurchaseHistory
};

function getPurchaseHistory(history) {
  let authToken = history.authToken;
  let userId = history.userId;
  let purchaseNumber = history.purchaseNumber?history.purchaseNumber:''
  let limit = history.limit;
  let pageNumber = history.pageNumber
  const applicationTypeId = config.applicationTypeId;
  let sessionUId = sessionStorage.getItem("sessionId");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: new URLSearchParams({
      authToken,
      userId,
      applicationTypeId,
      purchaseNumber,
      limit,
      pageNumber,sessionUId
    })
  };

  return fetch(
      `${config.envUrl}VendingAppApi/orderHistory`,
      requestOptions
    )
    .then(handleResponse)
    .then(products => {
      if (products.code == 5015) {
        return {
          data: []
        };
      } else {
        return products; //return products available
      }
    });
}

function handleResponse(response) {
  // for every request handling

  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (data.success) {
      return data;
    } else {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
  });
}