import React, { Component } from "react";
import "./NoLongerScreen.scss";
import { config } from "../../_config";
import { CaptureEvent, CapturePage } from "../../_helpers/googleAnalytics";

export default class ThankyouPage extends Component {
  constructor(props){
    super(props);
    document.title=`Coke & Go ${config.countryName} | ThankyouPage`;
    CapturePage({hitType:"pageview",page:"/ThankyouPage"})
  }
  render() {
    return (
      <body className="NoLongerBody">
        <div className="">
          <div className="wrapper">
            <div className="row">
              <div className="box">
                <div className="col-md-12"><h1 className="NoHeading">Thank You</h1></div>
                <div className="col-md-12"><p className="Para">Request Submitted Successfully!</p></div>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
