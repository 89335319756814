import React from 'react'
import Heading from '../containers/Heading/Heading'
import i18next from 'i18next'
import contactImg from "../_assets/images/icons/contact.png";
import mail from "../_assets/images/icons/sendmail.png";
import { Trans } from 'react-i18next';
const Contact = () => {
  return (
    <>
        <div className="main-wrapper">        
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="content">
                            <Heading title={i18next.t("Contact")} firstIcon={true} secondIcon={false} backLink="/login" LinkIcon="back" align="center"/> 
                            <div className="main-content">
                                <div className='contact-page px-0'>
                                    <img src={contactImg} className='contact-img' />
                                    <h5><Trans i18nKey={`Priority Service with Care Team (KORA)`} components={[<br/>]}></Trans></h5>
                                    <a href='mailto:smartcooler.carebr@coca-cola.com'>smartcooler.carebr@coca-cola.com</a><br/>
                                    <img src={mail} className='mt-3' />
                                </div>
                            </div>  
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Contact